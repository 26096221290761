import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { CustomerUser } from "../../../entities/CustomerUser";
import { Customer_Brand } from "../../../entities/Customer_Brand";
import { FETCH_BRANDS } from "../../../screens/PartnerSignUp/CompleteSignUpPartner/graphql";
import { MultiSelect } from "../../PartnerSignUpForm/CompleteSignUpPartner/MultiSelect";

export const BrandsField = ({
  field,
  setFieldValue,
  customerUser,
}: {
  field: any;
  setFieldValue: any;
  customerUser: CustomerUser;
}) => {
  const [brandsData, setBrandsData] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectValue, setSelectValue] = useState<
    {
      value: string;
      label: string;
    }[]
  >(
    customerUser.brand.map((item: Customer_Brand) => {
      return { value: item.brand.id.toString(), label: item.brand.name };
    })
  );

  const [fetchBrands, { data }] = useLazyQuery(FETCH_BRANDS, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      const filteredBrands = data.brands.filter((brand: any) => {
        for (let index = 0; index < customerUser.brand.length; index++) {
          const element = customerUser.brand[index];
          if (brand.id === element.brand.id) {
            return false;
          }
        }

        return true;
      });
      const brands: { value: string; label: string }[] = filteredBrands.map(
        (item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );

      setBrandsData(brands);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const handleOnChange = (values: any) => {
    if (values.length <= 3) {
      let ids = values.map((item: { value: string; label: string }) =>
        Number(item.value)
      );

      setFieldValue(field.name, ids);
      setSelectValue(values);
    }
  };

  return (
    <MultiSelect
      value={selectValue}
      options={brandsData}
      onChange={handleOnChange}
    />
  );
};
