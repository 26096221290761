import React, { useCallback, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Link, Text } from "@chakra-ui/layout";
import { ChangePasswordForm } from "../../components/ChangePasswordForm";
import { useEffect } from "react";
import { useStores } from "../../hooks/UseStores";
import { Heading, Stack, useToast } from "@chakra-ui/react";
import { Header } from "../../components/Header";
import { CHANGE_PASSWORD } from "../../services/graphql";
import { useMutation } from "@apollo/client";

export const ChangePasswordScreen: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState<string | null>("");
  const [token, setToken] = useState<string | null>("");

  const changePasswordSubmitCompleted = useCallback(() => {
    toast({
      status: "success",
      title: t("common.actionExecutedSuccessfully"),
    });
    const searchParams = new URLSearchParams(location.search);
    searchParams.delete("email");
    searchParams.delete("token");
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  }, [history, location, t, toast]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    setEmail(searchParams.get("email"));
    setToken(searchParams.get("token"));
  }, [location]);

  const [changePasswordSubmit, { loading: mutationSubmitting }] = useMutation(
    CHANGE_PASSWORD,
    {
      onCompleted: changePasswordSubmitCompleted,
      onError: (error) => {
        console.log(error);
        toast({
          status: "error",
          title: t("common.error"),
        });
      },
    }
  );

  const handleSubmit = useCallback(
    async (email: string, password: string) => {
      if (!token) {
        throw new Error("Missing token");
      }

      changePasswordSubmit({
        variables: {
          email,
          password,
          token,
        },
      });
    },
    [token, changePasswordSubmit]
  );

  if (session.isLogged()) {
    return <Redirect to={"/"} />;
  }

  return (
    <Stack w={"100%"}>
      <Header dark />
      <Stack alignItems={"center"}>
        <Stack width={"100%"} maxW={"420px"} marginTop={"2rem"}>
          <Heading as="h4" size="md" marginBottom={"1rem"}>
            {t("auth.changePassword")}
          </Heading>

          <ChangePasswordForm
            email={email}
            isLoading={mutationSubmitting}
            onSubmit={handleSubmit}
          />

          <Text style={{ marginTop: "2rem", textAlign: "center" }}>
            <Link color="teal.500" href={"#/login"}>
              {t("auth.backToLogin")}
            </Link>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
});
