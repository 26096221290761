import React, { useCallback, useState } from "react";
import { Flex, useDisclosure, useMediaQuery, useToast } from "@chakra-ui/react";
import { TFunction, useTranslation } from "react-i18next";
import { mainTheme } from "../../../config/theme";
import { AnimalImage } from "./AnimalImage";
import { AddAnimalBox } from "./AddAnimalBox";
import { Animal } from "../../../models/Animal";
import { PrimaryLooversText } from "../../LooversText/LooversText";
import { AnimalModal } from "./AnimalModal";
import {
  ADD_ANIMAL,
  DELETE_ANIMAL,
  FETCH_ANIMAL_TYPES,
  FETCH_USER_ANIMALS,
  UPDATE_ANIMAL,
} from "../../../screens/CustomerDashboard/graphql";
import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { useStores } from "../../../hooks/UseStores";
import { AnimalType } from "../../../entities/AnimalType";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useMemo } from "react";
import { AnimalFormType } from "../AddAnimal/AnimalForm";
import { GET_CUSTOMER_BY_ID } from "../../../services/graphql";

type Props = {
  fetchPromos: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
};

/**
 * @returns Desktop/Mobile component containing animals section
 */
export const AnimalsSection = observer(({ fetchPromos }: Props) => {
  const { session } = useStores();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [animal, setAnimal] = useState<Animal>();
  const toast = useToast();

  const [fetchCustomer] = useLazyQuery(GET_CUSTOMER_BY_ID, {
    onCompleted: (val) => {
      session.mapCustomerUserData(val.customer);

      if (session.customerUser) {
        fetchPromos({
          variables: {
            customerId: session.customerUser.id,
            includeNationalPromos: true,
          },
        });
      }
    },
    fetchPolicy: "no-cache",
    onError: (error) => {
      console.log("FETCH_PROMOS", error);
    },
  });

  const [fetchCustomerAnimals, { loading, data: customerAnimals, refetch }] =
    useLazyQuery(FETCH_USER_ANIMALS, {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log("fetch animal types", error);
      },
    });

  const [addAnimal] = useMutation(ADD_ANIMAL, {
    onCompleted: () => {
      onClose();
      refetch && refetch();
      toast({
        status: "success",
        title: t("dashboard.petCreated"),
      });

      if (session.customerUser) {
        fetchCustomer({
          variables: {
            userId: session.customerUser.id,
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateAnimal] = useMutation(UPDATE_ANIMAL, {
    onCompleted: () => {
      onClose();
      refetch && refetch();
      toast({
        status: "success",
        title: t("dashboard.petUpdated"),
      });

      if (session.customerUser) {
        fetchCustomer({
          variables: {
            userId: session.customerUser.id,
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [deleteAnimal] = useMutation(DELETE_ANIMAL, {
    onCompleted: () => {
      onClose();
      refetch && refetch();
      toast({
        status: "success",
        title: t("dashboard.petDeleted"),
      });

      if (session.customerUser) {
        fetchCustomer({
          variables: {
            userId: session.customerUser.id,
          },
        });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [fetchAnimalTypes, { data: dataFetchAnimalType }] = useLazyQuery(
    FETCH_ANIMAL_TYPES,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log("fetch animal types", error);
      },
    }
  );

  const animalTypes: AnimalType[] = useMemo(() => {
    return (
      dataFetchAnimalType &&
      dataFetchAnimalType.animalTypes.map((item: AnimalType) => ({
        id: item.id,
        name: item.name,
      }))
    );
  }, [dataFetchAnimalType]);

  useEffect(() => {
    if (session.customerUser) {
      fetchCustomerAnimals({
        variables: { customerId: session.customerUser.id },
      });
      fetchAnimalTypes();
    }
  }, [fetchAnimalTypes, fetchCustomerAnimals, session]);

  const handleSubmit = useCallback(
    (data: AnimalFormType) => {
      const animalType = animalTypes.find(
        (item) => item.name === data.animal_type
      );

      if (animalType) {
        if (data.id) {
          updateAnimal({
            variables: {
              id: data.id,
              name: data.name,
              birth_date: new Date(data.birth_date),
              breed_id: Number(data.breed.value),
              weight: Number(data.weight),
              special_need_id: data.special_need_id,
              image: data.animal_image,
              size: data.size,
              animal_type_id: animalType.id,
            },
          });
        } else {
          addAnimal({
            variables: {
              customer_id: session.customerUser?.id,
              name: data.name,
              birth_date: new Date(data.birth_date),
              breed_id: Number(data.breed.value),
              weight: Number(data.weight),
              special_need_id: data.special_need_id,
              image: data.animal_image,
              size: data.size,
              animal_type_id: animalType.id,
            },
          });
        }
      }

      onClose();
    },
    [animalTypes, onClose, updateAnimal, addAnimal, session]
  );

  const handleDelete = useCallback(
    (data: AnimalFormType) => {
      const animalType = animalTypes.find(
        (item) => item.name === data.animal_type
      );

      if (animalType) {
        if (data.id) {
          deleteAnimal({
            variables: {
              id: data.id,
            },
          });
        }
      }

      onClose();
    },
    [animalTypes, deleteAnimal, onClose]
  );

  const handleItemClick = useCallback(
    (animal?: Animal) => {
      setAnimal(animal);
      onOpen();
    },
    [onOpen]
  );

  if (!session.customerUser || loading) {
    return null;
  }

  return (
    <>
      {isLargerThan768 ? (
        <DesktopAnimalSection
          animals={customerAnimals?.customer.animals}
          onClick={handleItemClick}
          t={t}
          isLargerThan768={isLargerThan768}
        />
      ) : (
        <MobileAnimalSection
          animals={customerAnimals?.customer.animals}
          onClick={handleItemClick}
          t={t}
          isLargerThan768={isLargerThan768}
        />
      )}
      <AnimalModal
        animal={animal}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setAnimal(undefined);
        }}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
      />
    </>
  );
});

const MobileAnimalSection: React.FC<{
  animals?: Animal[];
  onClick: (animal?: Animal) => void;
  t: TFunction<"translation">;
  isLargerThan768: boolean;
}> = ({ animals, onClick, t, isLargerThan768 }) => {
  const animalBoxSize = "100%";
  const animalBoxPlusIconSize = "100%";

  return (
    <Flex
      w="100%"
      flexDirection={"column"}
      backgroundColor={mainTheme.colors.primaryYellow}
      alignItems="flex-start"
      justifyContent="center"
      padding="4rem"
    >
      <PrimaryLooversText
        text={t("dashboard.animals")}
        textColor={mainTheme.colors.darkPurple}
      />
      <Flex flexDirection="column" w="100%" marginTop={"1rem"}>
        {animals?.map((animal: Animal) => (
          <AnimalImage
            key={animal.id}
            boxWidth={animalBoxSize}
            boxHeight={animalBoxSize}
            boxJustifyContent="center"
            boxTextAlign="center"
            animal={animal}
            onClick={() => onClick(animal)}
            isLargerThan768={isLargerThan768}
          />
        ))}
        <AddAnimalBox
          boxSize={animalBoxSize}
          plusIconSize={animalBoxPlusIconSize}
          onOpen={() => onClick()}
        />
      </Flex>
    </Flex>
  );
};

const DesktopAnimalSection: React.FC<{
  animals?: Animal[];
  onClick: (animal?: Animal) => void;
  t: TFunction<"translation">;
  isLargerThan768: boolean;
}> = ({ animals, onClick, t, isLargerThan768 }) => {
  const animalBoxSize = "20%";
  const animalBoxPlusIconSize = "100%";

  return (
    <Flex
      w="100%"
      flexDirection={"column"}
      backgroundColor={mainTheme.colors.primaryYellow}
      alignItems="flex-start"
      justifyContent="center"
      padding="4rem"
    >
      <PrimaryLooversText
        text={t("dashboard.animals")}
        textColor={mainTheme.colors.darkPurple}
      />
      <Flex
        flexDirection="row"
        w="100%"
        align="flex-start"
        marginTop={"1rem"}
        flexWrap={"wrap"}
      >
        {animals?.map((animal: Animal) => (
          <AnimalImage
            key={animal.id}
            boxWidth={animalBoxSize}
            boxHeight={animalBoxSize}
            boxJustifyContent="center"
            boxTextAlign="center"
            animal={animal}
            onClick={() => onClick(animal)}
            isLargerThan768={isLargerThan768}
          />
        ))}
        <AddAnimalBox
          boxSize={animalBoxSize}
          plusIconSize={animalBoxPlusIconSize}
          onOpen={() => onClick()}
        />
      </Flex>
    </Flex>
  );
};
