import { useLazyQuery } from "@apollo/client";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { Field } from "formik";
import { useEffect, useState } from "react";
import { Brand } from "../../entities/Brand";
import { FETCH_BRANDS } from "../../screens/PartnerPromotions/graphql";

type Props = {
  productId: number | null;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
};

export const ProductBrandSelect = ({ productId, setFieldValue }: Props) => {
  const [brandsData, setBrandsData] = useState<Brand[]>([]);

  const [fetchAdminBrands, { data: dataFetchAdminBrand }] = useLazyQuery(
    FETCH_BRANDS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const dataB: Brand[] = dataFetchAdminBrand.brands.map(
          (brand: Brand) => {
            return new Brand(Number(brand.id), brand.name);
          }
        );

        if (dataB.length > 0 && !productId) {
          setFieldValue("brandId", dataB[0].id);
        }

        setBrandsData(dataB.sort((a, b) => (a.name > b.name ? 1 : -1)));
      },
      onError: (error) => {
        console.log("BrandSelect", error);
      },
    }
  );

  useEffect(() => {
    fetchAdminBrands();
  }, [fetchAdminBrands]);

  return (
    <Field name={"brandId"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={meta.description && meta.touched} as="fieldset">
          <FormLabel htmlFor={"brand"}>{"Brand"}</FormLabel>
          <Select
            name={field.name}
            value={field.value && field.value.toString()}
            onChange={(event) => {
              setFieldValue(field.name, Number(event.target.value));
            }}
          >
            {brandsData.map((item: Brand) => {
              return (
                <option
                  key={item.id.toString()}
                  id={item.name}
                  value={item.id.toString()}
                >
                  {item.name}
                </option>
              );
            })}
          </Select>
        </FormControl>
      )}
    </Field>
  );
};
