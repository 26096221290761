import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PromoBreed } from "../../../entities/PromoBreed";
import { FETCH_PROMO_BREEDS } from "../../../screens/PartnerPromotions/graphql";
import Select from "react-select";

type Props = {
  animal_id: number | undefined;
  field: any;
  setFieldValue: (name: string, value: any) => void;
  animal_type: string;
};

export interface item {
  value: string;
  label: string;
}

export const BreedSelect = ({
  animal_id,
  field,
  setFieldValue,
  animal_type,
}: Props) => {
  const [fetchPromoBreeds, { data: dataFetchPromoBreeds }] = useLazyQuery(
    FETCH_PROMO_BREEDS,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log("PromoBreedsSelect", error);
      },
    }
  );

  useEffect(() => {
    fetchPromoBreeds();
  }, []);

  const promoBreedsFilteredData = useMemo(() => {
    if (!dataFetchPromoBreeds) return [];

    const promoBreeds: PromoBreed[] = dataFetchPromoBreeds.promoBreeds.map(
      (item: PromoBreed) => {
        return {
          id: item.id,
          name: item.name,
          animal: item.animal,
          dog_size: item.dog_size,
        };
      }
    );

    return promoBreeds.filter(
      (breed) => breed.name !== "All" && breed.animal === animal_type
    );
  }, [animal_type, dataFetchPromoBreeds]);

  useEffect(() => {
    if (!animal_id) {
      const defaultBreed = promoBreedsFilteredData.find(
        (item) => item.name === "Meticcio" || item.name === "Gatto europeo"
      );

      if (defaultBreed) {
        const cv: item = {
          label: defaultBreed.name,
          value: defaultBreed.id.toString(),
        };

        setFieldValue(field.name, cv);
      }
    }
  }, [animal_id, field.name, promoBreedsFilteredData, setFieldValue]);

  return (
    <Select
      isMulti={false}
      isSearchable
      name="colors"
      value={field.value}
      options={promoBreedsFilteredData.map((pb: PromoBreed): item => {
        return {
          label: pb.name.toString(),
          value: pb.id.toString(),
        };
      })}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(i: item | null) => {
        if (i !== null) setFieldValue(field.name, i);
      }}
    />
  );
};
