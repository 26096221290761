import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo } from "react";
import { Promo, UsersPerimeter } from "../../entities/Promo";
import { PromoBreed } from "../../entities/PromoBreed";
import { FETCH_PROMO_BREEDS } from "../../screens/PartnerPromotions/graphql";
import Select from "react-select";
import { useTranslation } from "react-i18next";

export interface item {
  value: string;
  label: string;
}

type Props = {
  field: any;
  setFieldValue: (name: string, value: any) => void;
  users_perimeter: UsersPerimeter;
  promo: Promo;
  isDisabled: boolean;
  showAllBreeds?: boolean;
};

export const PromoBreedSelect = ({
  field,
  setFieldValue,
  users_perimeter,
  promo,
  isDisabled,
  showAllBreeds = false,
}: Props) => {
  const { t } = useTranslation();

  const [fetchPromoBreeds, { data: dataFetchPromoBreeds }] = useLazyQuery(
    FETCH_PROMO_BREEDS,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log("PromoBreedsSelect", error);
      },
    }
  );

  useEffect(() => {
    fetchPromoBreeds();
  }, []);

  const promoBreedsFilteredData = useMemo(() => {
    const promoBreeds: PromoBreed[] =
      dataFetchPromoBreeds?.promoBreeds?.map((item: PromoBreed) => {
        return {
          id: item.id,
          name: item.name,
          animal: item.animal,
          dog_size: item.dog_size,
        };
      }) || [];

    return promoBreeds.filter(
      (breed) =>
        (users_perimeter === "all" || users_perimeter === breed.animal) &&
        (showAllBreeds ? true : breed.name !== "All")
    );
  }, [dataFetchPromoBreeds?.promoBreeds, showAllBreeds, users_perimeter]);

  useEffect(() => {
    if (dataFetchPromoBreeds?.promoBreeds.length > 0 && promo.id === -1) {
      const defaultItem = promoBreedsFilteredData.find((item) =>
        showAllBreeds
          ? item.name === "All"
          : item.name === "Meticcio" || item.name === "Gatto europeo"
      );

      if (defaultItem) {
        const i: item = {
          label: defaultItem.name,
          value: defaultItem.id.toString(),
        };

        setFieldValue(field.name, i);
      }
    }
  }, [
    dataFetchPromoBreeds?.promoBreeds.length,
    field.name,
    promo.id,
    promoBreedsFilteredData,
    setFieldValue,
    showAllBreeds,
  ]);

  const currentValue = useMemo(() => {
    return field.value && field.value.label === "All"
      ? {
          label: t("promoForm.allRaces"),
          value: field.value.value,
        }
      : field.value;
  }, [field, t]);

  return (
    <Select
      isDisabled={isDisabled}
      isMulti={false}
      isSearchable
      value={currentValue}
      options={promoBreedsFilteredData.map((pb: PromoBreed): item => {
        return {
          label:
            pb.name === "All" ? t("promoForm.allRaces") : pb.name.toString(),
          value: pb.id.toString(),
        };
      })}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(i: item | null) => {
        if (i !== null) setFieldValue(field.name, i);
      }}
    />
  );
};
