import { gql } from "@apollo/client";

export const FETCH_ANIMALS = gql`
  query fetchAnimals {
    animals {
      id
      name
      birth_date
      weight
      size
      image
      special_need {
        id
        name
      }
      breed {
        id
        name
        dog_size
      }
      customer {
        id
      }
      animal_type {
        id
        name
      }
    }
  }
`;

export const FETCH_PROMOS = gql`
  query fetchPromos {
    promos {
      id
      valid_from
      valid_until
      promo_type
      users_perimeter
      description
      activity_type
      is_national
      brand {
        id
        name
      }
      special_needs {
        id
        name
      }
      promo_breed {
        id
        name
      }
      activity {
        id
        name
      }
      store {
        id
        store_code
        business_name
      }
    }
  }
`;

export const FETCH_CUSTOMER_AVAILABLE_PROMOS = gql`
  query fetchCustomerAvailablePromos(
    $customerId: Int!
    $includeNationalPromos: Boolean
  ) {
    customerAvailablePromos(
      customerId: $customerId
      includeNationalPromos: $includeNationalPromos
    ) {
      id
      valid_from
      valid_until
      promo_type
      users_perimeter
      description
      activity_type
      is_national
      interested_brand
      email_title
      promo_uses {
        id
        receipt_photo
        used_date
      }
      brand {
        id
        name
      }
      special_needs {
        id
        name
      }
      promo_breed {
        id
        name
        animal
      }
      activity {
        id
        name
      }
    }
  }
`;

export const FETCH_ANIMAL_TYPES = gql`
  query fetchAnimalTypes {
    animalTypes {
      id
      name
    }
  }
`;

export const ADD_ANIMAL = gql`
  mutation addAnimal(
    $customer_id: Int!
    $name: String!
    $birth_date: DateTime!
    $breed_id: Int!
    $weight: Float!
    $special_need_id: Int!
    $size: String!
    $animal_type_id: Int!
    $image: String!
  ) {
    createOneAnimal(
      data: {
        name: $name
        birth_date: $birth_date
        weight: $weight
        size: $size
        image: $image
        special_need: { connect: { id: $special_need_id } }
        breed: { connect: { id: $breed_id } }
        animal_type: { connect: { id: $animal_type_id } }
        customer: { connect: { id: $customer_id } }
      }
    ) {
      id
    }
  }
`;

export const UPDATE_ANIMAL = gql`
  mutation updateAnimal(
    $id: Int!
    $name: String!
    $birth_date: DateTime!
    $breed_id: Int!
    $weight: Float!
    $special_need_id: Int!
    $size: String!
    $animal_type_id: Int!
    $image: String!
  ) {
    updateOneAnimal(
      where: { id: $id }
      data: {
        name: { set: $name }
        birth_date: { set: $birth_date }
        weight: { set: $weight }
        size: { set: $size }
        image: { set: $image }
        special_need: { connect: { id: $special_need_id } }
        breed: { connect: { id: $breed_id } }
        animal_type: { connect: { id: $animal_type_id } }
      }
    ) {
      id
    }
  }
`;

export const DELETE_ANIMAL = gql`
  mutation deleteAnimal($id: Int!) {
    deleteOneAnimal(where: { id: $id }) {
      id
    }
  }
`;

export const UPDATE_CUSTOMER_STORE_CODE = gql`
  mutation updateCustomerFavoriteStore($customerId: Int!, $storeCode: String!) {
    updateCustomerFavoriteStore(
      customerId: $customerId
      storeCode: $storeCode
    ) {
      id
      business_name
      banner_name
      store_type
      subscription
      addresses {
        address {
          address
          city {
            city
            cap
            district
          }
        }
      }
    }
  }
`;

export const FETCH_USER_ANIMALS = gql`
  query fetchUserAnimals($customerId: Int!) {
    customer(where: { id: $customerId }) {
      id
      animals {
        id
        name
        birth_date
        weight
        size
        image
        special_need {
          id
          name
          animal
          need_type
        }
        breed {
          id
          name
          animal
        }
        animal_type {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_CUSTOMER_REVIEWS = gql`
  query fetchCustomerReviews($customerId: Int!) {
    customer(where: { id: $customerId }) {
      id
      store_reviews {
        id
        title
        description
        stars
        date
        store {
          id
        }
      }
    }
  }
`;

export const ADD_REVIEW = gql`
  mutation addStoreReview(
    $customer_id: Int!
    $store_id: Int!
    $title: String!
    $description: String!
    $stars: Int!
    $date: String!
  ) {
    createStoreReview(
      title: $title
      description: $description
      stars: $stars
      date: $date
      store_id: $store_id
      customer_id: $customer_id
    ) {
      id
      title
      description
      stars
      date
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation updateStoreReview(
    $id: Int!
    $title: String!
    $description: String!
    $stars: Int!
    $date: DateTime!
  ) {
    updateOneStore_Review(
      where: { id: $id }
      data: {
        title: { set: $title }
        description: { set: $description }
        stars: { set: $stars }
        date: { set: $date }
      }
    ) {
      id
      title
      description
      stars
      date
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation deleteStoreReview($id: Int!, $customer_id: Int!) {
    deleteStoreReview(id: $id, customer_id: $customer_id) {
      id
    }
  }
`;

export const ADD_PRODUCT_REVIEW = gql`
  mutation addProductReview(
    $title: String!
    $customer_id: Int!
    $product_id: Int!
    $image: String
    $comment: String!
    $totalRating: Int!
    $packagingRating: Int!
    $lookAndFeelRating: Int!
    $petOpinionRating: Int!
    $productFeaturesRating: Int!
    $avgRating: Int!
    $date: String!
  ) {
    createProductReview(
      title: $title
      comment: $comment
      date: $date
      image: $image
      totalRating: $totalRating
      packagingRating: $packagingRating
      lookAndFeelRating: $lookAndFeelRating
      petOpinionRating: $petOpinionRating
      productFeaturesRating: $productFeaturesRating
      avgRating: $avgRating
      product_id: $product_id
      customer_id: $customer_id
    ) {
      id
      title
      comment
      image
      date
      totalRating
      packagingRating
      lookAndFeelRating
      petOpinionRating
      productFeaturesRating
      avgRating
    }
  }
`;

export const UPDATE_PRODUCT_REVIEW = gql`
  mutation updateProductReview(
    $id: Int!
    $title: String!
    $comment: String!
    $image: String
    $date: String!
    $totalRating: Int!
    $packagingRating: Int!
    $lookAndFeelRating: Int!
    $petOpinionRating: Int!
    $productFeaturesRating: Int!
    $avgRating: Int!
    $customer_id: Int!
    $product_id: Int!
  ) {
    updateProductReview(
      id: $id
      title: $title
      comment: $comment
      date: $date
      image: $image
      totalRating: $totalRating
      packagingRating: $packagingRating
      lookAndFeelRating: $lookAndFeelRating
      petOpinionRating: $petOpinionRating
      productFeaturesRating: $productFeaturesRating
      avgRating: $avgRating
      customer_id: $customer_id
      product_id: $product_id
    ) {
      id
      title
      comment
      image
      totalRating
      packagingRating
      lookAndFeelRating
      petOpinionRating
      productFeaturesRating
      avgRating
      customer {
        id
      }
      product {
        id
      }
    }
  }
`;

export const DELETE_PRODUCT_REVIEW = gql`
  mutation deleteProductReview($id: Int!, $product_id: Int!) {
    deleteProductReview(id: $id, product_id: $product_id) {
      id
    }
  }
`;

export const FETCH_CUSTOMER_PRODUCT_REVIEWS = gql`
  query fetchCustomerProductReviews($customer_id: Int!) {
    customer(where: { id: $customer_id }) {
      product_reviews {
        id
        title
        comment
        image
        date
        totalRating
        packagingRating
        lookAndFeelRating
        petOpinionRating
        productFeaturesRating
        avgRating
        customer {
          id
        }
        product {
          id
        }
      }
    }
  }
`;
