import { useLazyQuery } from "@apollo/client";
import { Select } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { SpecialNeed } from "../../../entities/SpecialNeed";
import { FETCH_SPECIAL_NEEDS } from "../../../screens/PartnerPromotions/graphql";

type Props = {
  field: any;
  setFieldValue: (name: string, value: any) => void;
  animal_type: string;
  formikProps: any;
};

export const SpecialNeedSelect = ({
  field,
  setFieldValue,
  animal_type,
  formikProps,
}: Props) => {
  const [fetchSpecialNeeds, { data: dataFetchSpecialNeed, loading }] =
    useLazyQuery(FETCH_SPECIAL_NEEDS, {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log("SpecialNeedSelect", error);
      },
    });

  const specialNeedsFilteredData = useMemo(() => {
    if (loading || !dataFetchSpecialNeed) return [];
    const specialNeeds: SpecialNeed[] = dataFetchSpecialNeed.specialNeeds.map(
      (item: SpecialNeed) => {
        return {
          id: item.id,
          name: item.name,
          animal: item.animal,
          need_type: item.need_type,
        };
      }
    );

    return animal_type && formikProps.values.special_need_radio
      ? specialNeeds.filter(
          (specialNeed) =>
            specialNeed.animal === animal_type &&
            formikProps.values.special_need_radio === specialNeed.need_type
        )
      : specialNeeds;
  }, [
    animal_type,
    dataFetchSpecialNeed,
    formikProps.values.special_need_radio,
    loading,
  ]);

  useEffect(() => {
    fetchSpecialNeeds();
  }, []);

  useEffect(() => {
    if (specialNeedsFilteredData.length > 0 && !formikProps.values.id) {
      setFieldValue(field.name, Number(specialNeedsFilteredData[0].id));
    }
  }, [
    field.name,
    formikProps.values.id,
    setFieldValue,
    specialNeedsFilteredData,
  ]);

  return (
    <Select
      name={field.name}
      onChange={(event) => {
        setFieldValue(field.name, Number(event.target.value));
      }}
      value={
        formikProps.values.id
          ? String(formikProps.values.special_need_id)
          : undefined
      }
    >
      {specialNeedsFilteredData.map((item: SpecialNeed) => {
        return (
          <option
            key={item.id.toString()}
            id={item.id.toString()}
            value={item.id.toString()}
          >
            {item.name[0].toUpperCase() + item.name.substring(1)}
          </option>
        );
      })}
    </Select>
  );
};
