import {
  VStack,
  Text,
  HStack,
  Button,
  Link,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { mainTheme } from "../../config/theme";
import { Promo } from "../../entities/Promo";
import { useStores } from "../../hooks/UseStores";

export const PartnerDashbaordDataSection = () => {
  const { t } = useTranslation();
  const { session } = useStores();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const [promoUsedCount, setPromoUsedCount] = useState(0);

  console.log(session.partnerUser);

  // useEffect(() => {
  //   let total = 0;

  //   session.partnerUser?.promo.forEach((p: Promo) => {
  //     total += p.promo_uses.length;
  //   });

  //   setPromoUsedCount(total);
  // }, [session.partnerUser?.promo]);

  return (
    <VStack width={"100%"} spacing="1rem" alignItems={"flex-start"}>
      <Text
        fontSize={["md", "xl"]}
        marginBottom="1rem"
        color={mainTheme.colors.textPrimary}
      >
        {t("partnerdashboard.dataDescription")}
      </Text>
      <Text color={mainTheme.colors.textPrimary} fontSize={["md"]}>
        {t("partnerdashboard.yourCode")}
        <strong>{session.partnerUser?.store_code}</strong>
      </Text>
      <HStack>
        <Text color={mainTheme.colors.textPrimary} fontSize={["md"]}>
          {t("partnerdashboard.promoCreated")}
          <strong>{session.partnerUser?.promo.length}</strong>
        </Text>
        <Button size={isLargerThan768 ? undefined : "md"} colorScheme="teal">
          <Link
            fontSize={isLargerThan768 ? undefined : 9}
            href={"#/partner/promotions"}
          >
            {t("common.addPromotion")}
          </Link>
        </Button>
      </HStack>

      <HStack>
        <Text color={mainTheme.colors.textPrimary} fontSize={["md"]}>
          {t("partnerdashboard.promoUsed")}{" "}
          <strong>{session.partnerUser?.promoUsed?.length || 0}</strong>
        </Text>
        <Button size={isLargerThan768 ? undefined : "md"} colorScheme="teal">
          <Link
            fontSize={isLargerThan768 ? undefined : 9}
            href={"#/partner/scanqrcode"}
          >
            {t("common.scanQR")}
          </Link>
        </Button>
      </HStack>
    </VStack>
  );
};
