import { AddIcon } from "@chakra-ui/icons";
import { Stack } from "@chakra-ui/react";
import { mainTheme } from "../../../config/theme";

export const AddAnimalBox = ({
  boxSize,
  boxRightMargin,
  onOpen,
}: {
  boxSize: string;
  plusIconSize: string;
  boxRightMargin?: string;
  onOpen: () => void;
}) => {
  return (
    <Stack
      position="relative"
      cursor="pointer"
      display="flex"
      w={boxSize}
      h={"15rem"}
      maxW={"15rem"}
      maxH={"15rem"}
      border={"1px dashed"}
      borderColor={mainTheme.colors.darkPurple}
      justifyContent="center"
      alignItems="center"
      marginRight={boxRightMargin}
      onClick={onOpen}
      borderRadius={"2rem"}
    >
      <AddIcon
        w={"3rem"}
        h={"3rem"}
        alt="add animal plus icon"
        color={mainTheme.colors.darkPurple}
      />
    </Stack>
  );
};
