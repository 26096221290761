import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Promo } from "../../entities/Promo";
import { DogSize } from "../../entities/PromoBreed";

type Props = {
  field: any;
  setFieldValue: (name: string, value: any) => void;
  formikProps: any;
  promo: Promo;
  isAnimalForm?: boolean;
};

export const AnimalSizeSelect = ({
  field,
  setFieldValue,
  formikProps,
  promo,
  isAnimalForm = false,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Select
      name={field.name}
      value={promo.id === -1 ? field.value.toString() : promo.size.toString()}
      onChange={(event) => {
        console.log(formikProps, event.target.value);
        setFieldValue(field.name, event.target.value);
      }}
    >
      {!isAnimalForm && (
        <option value={DogSize.All}>{t("promoForm.all")}</option>
      )}
      <option value={DogSize.Mini}>{"Mini (1-10kg)"}</option>
      <option value={DogSize.Medium}>{"Medium (11-30kg)"}</option>
      <option value={DogSize.Maxi}>{"Maxi (da 31kg)"}</option>
    </Select>
  );
};
