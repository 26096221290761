import { Box, Flex } from "@chakra-ui/layout";
import { Image, HStack } from "@chakra-ui/react";
import { Product } from "../../entities/Product";
import EmptyProductImage from "../../assets/images/empty-product.png";
import ReactStars from "react-rating-stars-component";
import { FaStar } from "react-icons/fa";
import { ChatIcon } from "@chakra-ui/icons";

type Props = {
  product: Product;
  hasReview: boolean;
  onClick: () => void;
};

export const ProductBox: React.FC<Props> = ({
  product,
  hasReview,
  onClick,
}) => {
  const productImages = product.product_images
    ? JSON.parse(product.product_images)
    : null;

  return (
    <Flex
      flexDir={"column"}
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      onClick={onClick}
      cursor="pointer"
      marginX={"40px"}
      marginBottom={"40px"}
      align={"flex-start"}
      justify={"center"}
    >
      <Image
        src={
          productImages && productImages.length > 0
            ? productImages[0]
            : EmptyProductImage
        }
        alt={product.name}
        style={{
          width: "215px",
          height: "172px",
          margin: "0 auto",
        }}
      />

      <Box p="6">
        <Box display="flex" alignItems="baseline">
          {product.brand && (
            <Box
              color="gray.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {product.brand.name}
            </Box>
          )}
        </Box>

        <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
          {product.name}
        </Box>

        {/* <Box>{product.description}</Box> */}
        <HStack justifyContent={"space-between"}>
          <Box display="flex" mt="2" alignItems="center">
            {product.greenFootprintRating && (
              <ReactStars
                count={5}
                value={product.greenFootprintRating}
                size={8}
                isHalf={false}
                emptyIcon={<FaStar color={"grey"} size={5} />}
                fullIcon={<FaStar color={"grey"} size={5} />}
                activeColor="#ffd700"
                edit={false}
              />
            )}
          </Box>
          {hasReview && <ChatIcon />}
        </HStack>
      </Box>
    </Flex>
  );
};
