import { useMutation } from "@apollo/client";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Header } from "../../../components/Header";
import { LOGIN_ADMIN } from "../../../services/graphql";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { LoginForm } from "../../../components/LoginForm/LoginForm";
import { useStores } from "../../../hooks/UseStores";
import { Box, Flex, Spinner, Text, useMediaQuery } from "@chakra-ui/react";
import { mainTheme } from "../../../config/theme";
import { observer } from "mobx-react";

export const AdminLoginScreen: React.FC = observer(() => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { session } = useStores();

  const handleLoginCompleted = useCallback(
    (data) => {
      window.scrollTo(0, 0);
      session.login(data.loginAdmin.admin, data.loginAdmin.token, "admin");
    },
    [session]
  );

  const handleLoginError = useCallback(() => {
    window.scrollTo(0, 0);
    setShowErrorMessage(true);
  }, []);

  const [login, { loading: mutationSubmitting }] = useMutation(LOGIN_ADMIN, {
    onCompleted: handleLoginCompleted,
    onError: (error) => {
      console.log(error);
      handleLoginError();
    },
  });

  const handleSubmit = useCallback(
    (values) => {
      const { email, password } = values;

      setShowErrorMessage(false);

      login({
        variables: {
          email,
          password,
        },
      });
    },
    [login]
  );

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"1fr auto"}>
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Box
          flexDirection={"column"}
          backgroundColor={mainTheme.colors.background}
          w={"100%"}
          justifyContent={"center"}
        >
          <Header dark textAlign={"center"} />
        </Box>
        <FormContainer
          style={{
            padding: isLargerThan768 ? undefined : "2rem",
            paddingTop: "0px",
          }}
        >
          <LoginForm
            onSubmit={handleSubmit}
            isSubmitting={mutationSubmitting}
            userType="admin"
          />

          {showErrorMessage && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {t("auth.messages.loginError")}
            </Alert>
          )}
          {session.sessionError && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {t("auth.messages.sessionError")}
            </Alert>
          )}
          {(mutationSubmitting || session.isLoading) && (
            <Flex
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              m={6}
              gridGap={0}
            >
              {session.isLoading && (
                <Text color={mainTheme.colors.darkPurple}>
                  {t("common.redirect")}
                </Text>
              )}
              <Spinner
                thickness="3px"
                speed="0.65s"
                emptyColor={mainTheme.colors.lightPurple}
                color={mainTheme.colors.darkPurple}
                size="md"
                margin={"2rem"}
              />
            </Flex>
          )}
        </FormContainer>
      </Flex>
    </Box>
  );
});

const FormContainer = styled.div`
  width: 100%;
  max-width: 420px;
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
`;
