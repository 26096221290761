import keys from "../config/config";
import { getStripe } from "../utils/utils";

let sessionToken: string | undefined;

const setSession = (token: string) => {
  sessionToken = token;
};

const clearSession = () => {
  sessionToken = undefined;
};

const makeApiRequest = async (
  method: "POST" | "GET" | "PATCH" | "DELETE",
  path: string,
  body?: any
): Promise<any> => {
  const headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    mode: "no-cors",
  });
  if (sessionToken) {
    headers.append("Authorization", `Bearer ${sessionToken}`);
  }

  try {
    const response = await fetch(`${keys.API_BASE_URL}${path}`, {
      method: method,
      body: body ? JSON.stringify(body) : undefined,
      headers: headers,
    });

    if (!response.ok) {
      const message = await parseErrorMessage(response);

      if (response.status === 400) {
        throw new Error("logout");
      }

      throw new Error(`${response.status}: ${response.statusText}. ${message}`);
    }
    const responseJson = await response.json();
    return responseJson;
  } catch (err) {
    console.warn(`Failed request ${method} ${path}`, err);
    throw err;
  }
};

const parseErrorMessage = async (response: Response) => {
  try {
    const data = await response.json();
    if (!data.ErrorMessage) {
      throw new Error();
    }
    return data.ErrorMessage;
  } catch (err) {
    return "";
  }
};

const stripeCheckout = async (
  store_email: string,
  couponId: string
): Promise<boolean> => {
  const response = await makeApiRequest("POST", "stripe/checkout", {
    email: store_email,
    couponId,
  });

  if (response.statusCode === 500) {
    throw new Error(response.message);
  }

  // Redirect to Checkout.
  const stripe = await getStripe();
  const { error } = await stripe!.redirectToCheckout({
    sessionId: response.id,
  });
  if (error) {
    throw new Error(error.message);
  }

  return true;
};

const stripeSessionCheckout = async (
  sessionId: string,
  store_email: string
): Promise<any> => {
  const response = await makeApiRequest("POST", "stripe/checkout/session", {
    sessionId: sessionId,
    email: store_email,
  });

  if (response.statusCode === 500) {
    throw new Error(response.message);
  }

  return response.json;
};

const stripeAccessPortal = async (
  customerId: string,
  store_email: string
): Promise<string> => {
  const response = await makeApiRequest("POST", "stripe/portal/access", {
    customerId: customerId,
    email: store_email,
  });

  if (response.statusCode === 500) {
    throw new Error(response.message);
  }

  if (response.status === 400) {
    throw new Error("logout");
  }

  return response.url;
};

const api = {
  setSession,
  clearSession,
  stripeCheckout,
  stripeSessionCheckout,
  stripeAccessPortal,
};

export default api;
