import { Store_Address } from "./Store_Address";

export class FavoriteStore {
  private _id: number;
  private _business_name: string;
  private _banner_name: string;
  private _store_type: string;
  private _addresses: Store_Address[];
  private _store_image: string | null;
  private _subscription: string;

  constructor(
    id: number,
    business_name: string,
    banner_name: string,
    store_type: string,
    addresses: Store_Address[],
    store_image: string | null,
    subscription: string
  ) {
    this._id = id;
    this._business_name = business_name;
    this._banner_name = banner_name;
    this._store_type = store_type;
    this._addresses = addresses;
    this._store_image = store_image;
    this._subscription = subscription;
  }

  public get id() {
    return this._id;
  }
  public get business_name() {
    return this._business_name;
  }
  public get banner_name() {
    return this._banner_name;
  }
  public get store_type() {
    return this._store_type;
  }
  public get addresses() {
    return this._addresses;
  }
  public get store_image() {
    return this._store_image;
  }
  public get subscription() {
    return this._subscription;
  }
}
