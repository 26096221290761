import { makeAutoObservable } from "mobx";
import { Address } from "../types";
import { Promo } from "./Promo";
import { Promo_Code_use } from "./Promo_Code_Use";
import { Store_Brand } from "./Store_Brand";
import { Store_Service } from "./Store_Service";

type Props = {
  id: number;
  firstNameB2B: string;
  surnameB2B: string;
  first_name_b2b: string;
  surname_b2b: string;
  openingTime: string;
  services: Store_Service[];
  other_service_one: string;
  other_service_two: string;
  brands: Store_Brand[];
  businessName: string;
  storeType: string;
  bannerName: string;
  emailB2B: string;
  pec: string;
  vatNumber: string;
  taxCode: string;
  sdi: string;
  mobileB2B: string;
  phoneB2B?: string;
  subscription:
    | "superlight-month"
    | "superlight-year"
    | "base-month"
    | "base-year"
    | "premium-month"
    | "premium-year";
  promoCode: string;
  couponId: string;
  isPaymentRegistrationComplete: boolean;
  isRegistrationComplete: boolean;
  store_code: string;
  stripe_id?: string;
  subscription_id?: string;
  promo: Promo[];
  promoUsed: Promo_Code_use[];
  addresses: Address[];
  store_opening_days: StoreOpeningDaysObject;
  store_image: string;
  store_photos: string[];
};

export type StoreOpeningDaysObject = {
  monday: string;
  tuesday: string;
  wednesday: string;
  thursday: string;
  friday: string;
  saturday: string;
  sunday: string;
};

export class PartnerUser {
  private _id: number;
  private _firstNameB2B: string;
  private _surnameB2B: string;
  private _openingTime: string;
  private _services: Store_Service[];
  private _other_service_one: string = "";
  private _other_service_two: string = "";
  private _brands: Store_Brand[];
  private _businessName: string;
  private _storeType: string;
  private _bannerName: string;
  private _emailB2B: string;
  private _pec: string;
  private _vatNumber: string;
  private _taxCode: string;
  private _sdi: string;
  private _mobileB2B: string;
  private _phoneB2B?: string;
  private _subscription:
    | "superlight-month"
    | "superlight-year"
    | "base-month"
    | "base-year"
    | "premium-month"
    | "premium-year";
  private _promoCode: string;
  private _couponId: string;
  private _isPaymentRegistrationComplete: boolean;
  private _isRegistrationComplete: boolean;
  private _store_code: string;
  private _stripe_id?: string;
  private _subscription_id?: string;
  private _promo: Promo[];
  private _promoUsed: Promo_Code_use[];
  private _addresses: Address[];
  private _store_opening_days: StoreOpeningDaysObject = {
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  };
  private _store_image: string = "";
  private _store_photos: string[] = [];

  constructor() {
    this._id = -1;
    this._firstNameB2B = "";
    this._surnameB2B = "";
    this._openingTime = "";
    this._services = [];
    this._brands = [];
    this._businessName = "";
    this._storeType = "";
    this._bannerName = "";
    this._emailB2B = "";
    this._pec = "";
    this._vatNumber = "";
    this._taxCode = "";
    this._sdi = "";
    this._mobileB2B = "";
    this._phoneB2B = "";
    this._subscription = "superlight-month";
    this._promoCode = "";
    this._couponId = "";
    this._isPaymentRegistrationComplete = false;
    this._isRegistrationComplete = false;
    this._store_code = "";
    this._stripe_id = "";
    this._subscription_id = "";
    this._promo = [];
    this._addresses = [];
    this._promoUsed = [];

    makeAutoObservable(this);
  }

  public mapPartnerUserData({
    id,
    firstNameB2B,
    surnameB2B,
    openingTime,
    services,
    brands,
    businessName,
    storeType,
    bannerName,
    emailB2B,
    pec,
    vatNumber,
    taxCode,
    sdi,
    mobileB2B,
    phoneB2B,
    subscription,
    promoCode,
    couponId,
    isPaymentRegistrationComplete,
    isRegistrationComplete,
    store_code,
    stripe_id,
    subscription_id,
    promo,
    addresses,
    store_opening_days,
    store_image,
    store_photos,
    other_service_one,
    other_service_two,
    promoUsed,
  }: Props) {
    this._id = id;
    this._firstNameB2B = firstNameB2B;
    this._surnameB2B = surnameB2B;
    this._openingTime = openingTime;
    this._services = services;
    this._other_service_one = other_service_one;
    this._other_service_two = other_service_two;
    this._brands = brands;
    this._businessName = businessName;
    this._storeType = storeType;
    this._bannerName = bannerName;
    this._emailB2B = emailB2B;
    this._pec = pec;
    this._vatNumber = vatNumber;
    this._taxCode = taxCode;
    this._sdi = sdi;
    this._mobileB2B = mobileB2B;
    this._phoneB2B = phoneB2B;
    this._subscription = subscription;
    this._promoCode = promoCode;
    this._couponId = couponId;
    this._isPaymentRegistrationComplete = isPaymentRegistrationComplete;
    this._isRegistrationComplete = isRegistrationComplete;
    this._store_code = store_code;
    this._stripe_id = stripe_id;
    this._subscription_id = subscription_id;
    this._promo = promo;
    this._addresses = addresses;
    this._store_opening_days = store_opening_days;
    this._store_image = store_image;
    this._store_photos = store_photos;
    this._promoUsed = promoUsed;
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get firstNameB2B(): string {
    return this._firstNameB2B;
  }

  public set firstNameB2B(value: string) {
    this._firstNameB2B = value;
  }

  public get surnameB2B(): string {
    return this._surnameB2B;
  }
  public set surnameB2B(value: string) {
    this._surnameB2B = value;
  }

  public get openingTime(): string {
    return this._openingTime;
  }
  public set openingTime(value: string) {
    this._openingTime = value;
  }

  public get businessName(): string {
    return this._businessName;
  }
  public set businessName(value: string) {
    this._businessName = value;
  }

  public get storeType(): string {
    return this._storeType;
  }
  public set storeType(value: string) {
    this._storeType = value;
  }

  public get bannerName(): string {
    return this._bannerName;
  }
  public set bannerName(value: string) {
    this._bannerName = value;
  }

  public get emailB2B(): string {
    return this._emailB2B;
  }
  public set emailB2B(value: string) {
    this._emailB2B = value;
  }

  public get vatNumber(): string {
    return this._vatNumber;
  }
  public set vatNumber(value: string) {
    this._vatNumber = value;
  }

  public get taxCode(): string {
    return this._taxCode;
  }
  public set taxCode(value: string) {
    this._taxCode = value;
  }

  public get sdi(): string {
    return this._sdi;
  }
  public set sdi(value: string) {
    this._sdi = value;
  }

  public get pec(): string {
    return this._pec;
  }
  public set pec(value: string) {
    this._pec = value;
  }

  public get mobileB2B(): string {
    return this._mobileB2B;
  }
  public set mobileB2B(value: string) {
    this._mobileB2B = value;
  }

  public get phoneB2B(): string {
    return this._phoneB2B || "";
  }
  public set phoneB2B(value: string) {
    this._phoneB2B = value;
  }

  public get brands(): Store_Brand[] {
    return this._brands;
  }
  public set brands(value: Store_Brand[]) {
    this._brands = value;
  }

  public get services(): Store_Service[] {
    return this._services;
  }

  public set services(value: Store_Service[]) {
    this._services = value;
  }

  public get other_service_one(): string {
    return this._other_service_one;
  }

  public get other_service_two(): string {
    return this._other_service_two;
  }

  public get promoCode(): string {
    return this._promoCode;
  }
  public set promoCode(value: string) {
    this._promoCode = value;
  }

  public get couponId(): string {
    return this._couponId;
  }
  public set couponId(value: string) {
    this._couponId = value;
  }

  public get subscription():
    | "superlight-month"
    | "superlight-year"
    | "base-month"
    | "base-year"
    | "premium-month"
    | "premium-year" {
    return this._subscription;
  }
  public set subscription(
    value:
      | "superlight-month"
      | "superlight-year"
      | "base-month"
      | "base-year"
      | "premium-month"
      | "premium-year"
  ) {
    this._subscription = value;
  }

  public get isPaymentRegistrationComplete(): boolean {
    return this._isPaymentRegistrationComplete;
  }
  public set isPaymentRegistrationComplete(value: boolean) {
    this._isPaymentRegistrationComplete = value;
  }

  public get isRegistrationComplete(): boolean {
    return this._isRegistrationComplete;
  }

  public set isRegistrationComplete(value: boolean) {
    this._isRegistrationComplete = value;
  }

  public get store_code(): string {
    return this._store_code;
  }

  public get stripe_id(): string {
    return this._stripe_id || "";
  }

  public get subscription_id(): string {
    return this._subscription_id || "";
  }

  public get promo(): Promo[] {
    return this._promo;
  }

  public get addresses(): Address[] {
    return this._addresses;
  }

  public set addresses(value: Address[]) {
    this._addresses = value;
  }

  public get store_opening_days(): StoreOpeningDaysObject {
    return this._store_opening_days;
  }

  public set store_opening_days(val: StoreOpeningDaysObject) {
    this._store_opening_days = val;
  }

  public get store_image(): string {
    return this._store_image;
  }

  public get store_photos(): string[] {
    return this._store_photos;
  }

  public get promoUsed() {
    return this._promoUsed;
  }
}
