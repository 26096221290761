import { Box, Flex, useMediaQuery, Heading, Stack } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { HeaderMio } from "../../components/Header";
import { mainTheme } from "../../config/theme";
import { AnimalsSection } from "../../components/CustomerDashboard/AnimalsSection/AnimalsSection";
import { StoreSection } from "../../components/CustomerDashboard/StoreSection";
import { LoyaltyCardQRCode } from "../../components/CustomerDashboard/LoyaltyCardQRCode";
import { CustomerLevels } from "../../components/CustomerDashboard/CustomerLevels/CustomerLevels";
import { PromotionBanner } from "../../components/CustomerDashboard/PromotionsSection/PromotionBanner";
import { observer } from "mobx-react";
import { useStores } from "../../hooks/UseStores";
import { CustomerUser } from "../../entities/CustomerUser";
import { PointsGrid } from "../../components/CustomerDashboard/PointsGrid/PointsGrid";
import { TFunction, useTranslation } from "react-i18next";
import { InterestedBrand, Promo } from "../../entities/Promo";
import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from "@apollo/client";
import { FETCH_CUSTOMER_AVAILABLE_PROMOS } from "./graphql";
import { Animal } from "../../models/Animal";
import { Customer_Brand } from "../../entities/Customer_Brand";
import { FETCH_CAROUSELS } from "../Admin/AdminSetup/graphql";
import { Carousel } from "react-responsive-carousel";

/**
 * @returns customer dashboard containing user details, loyalty card qr code,
 * animals and favourite stores
 */
export const CustomerDashboardScreen: React.FC = observer(() => {
  const { session } = useStores();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const showPromotionalBanners = true;
  const { t } = useTranslation();

  const [dataFetchPromo, setDataFetchPromo] = useState<Promo[]>([]);

  const [fetchCarousels, { data: carousesData }] = useLazyQuery(
    FETCH_CAROUSELS,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchCarousels();
  }, []);

  const handleCarouselItemClick = useCallback(
    (index: number) => {
      if (carousesData) {
        const carousel = carousesData.carousels[index];

        window.open(carousel.link, "_blank");
      }
    },
    [carousesData]
  );

  const [fetchPromos, { data: promoQuery }] = useLazyQuery(
    FETCH_CUSTOMER_AVAILABLE_PROMOS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const animals: Animal[] = session.customerUser!.animals;
        const brands: Customer_Brand[] = session.customerUser!.brand;
        const pArr: Promo[] = promoQuery.customerAvailablePromos;

        const currentDate = new Date();
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setSeconds(1);

        const currentDatePlusTwoDays = new Date(
          new Date().getTime() + 24 * 60 * 60 * 1000 * 2
        );
        currentDatePlusTwoDays.setHours(0);
        currentDatePlusTwoDays.setMinutes(0);
        currentDatePlusTwoDays.setSeconds(1);

        setDataFetchPromo(
          pArr.filter((promo: Promo) => {
            const vf = new Date(promo.valid_from);
            const vu = new Date(promo.valid_until);
            vf.setTime(vf.getTime() + vf.getTimezoneOffset() * 60 * 1000);
            vu.setTime(vu.getTime() + vu.getTimezoneOffset() * 60 * 1000);
            vf.setHours(0);
            vf.setMinutes(0);
            vf.setSeconds(0);
            vu.setHours(23);
            vu.setMinutes(59);
            vu.setSeconds(59);

            const canFilterTime =
              (currentDatePlusTwoDays.getTime() >= vf.getTime() &&
                currentDate.getTime() < vf.getTime()) ||
              (currentDate.getTime() <= vu.getTime() &&
                currentDate.getTime() >= vf.getTime());

            if (!canFilterTime) return false;

            let canFilterBreed: boolean = false;

            if (animals.length > 0) {
              if (promo.users_perimeter === "all") {
                canFilterBreed = true;
              } else {
                for (let index = 0; index < animals.length; index++) {
                  const animal = animals[index];

                  if (promo.users_perimeter === animal.animal_type.name) {
                    if (
                      promo.promo_breed.name === "All" ||
                      promo.promo_breed.name === animal.breed.name ||
                      animal.animal_type.name === "smallAnimal"
                    ) {
                      canFilterBreed = true;
                      break;
                    }
                  }
                }
              }
            } else if (
              animals.length === 0 &&
              promo.users_perimeter === "all" &&
              promo.is_national
            ) {
              return true;
            }

            if (
              !canFilterBreed ||
              promo.interested_brand !== InterestedBrand.single_brand
            )
              return canFilterBreed;

            // Check if the promo is giving a single product and search if the user has chosen that brand
            let canFilterBrand: boolean = false;

            for (let index = 0; index < brands.length; index++) {
              const element = brands[index];

              if (element.brand.name === promo.brand.name) {
                canFilterBrand = true;
                break;
              }
            }

            return canFilterBrand;
          })
        );
      },
      onError: (error) => {
        console.log("FETCH_PROMOS", error);
      },
    }
  );

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <HeaderMio
        dark
        textAlign={"center"}
        customerUser={session.customerUser}
      />

      {isLargerThan768 && session.customerUser
        ? DesktopMainContent(
            showPromotionalBanners,
            session.customerUser,
            t,
            fetchPromos,
            dataFetchPromo,
            carousesData,
            handleCarouselItemClick
          )
        : session.customerUser
        ? MobileMainContent(
            showPromotionalBanners,
            session.customerUser,
            t,
            fetchPromos,
            dataFetchPromo
          )
        : null}
    </Box>
  );
});

const MobileMainContent = (
  showPromotionalBanners: boolean,
  customerUser: CustomerUser,
  t: TFunction<"translation">,
  fetchPromos: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void,
  dataFetchPromo: Promo[]
) => {
  return (
    <Flex
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
    >
      <Box
        display="flex"
        flexDirection={"column"}
        backgroundColor={mainTheme.colors.background}
        w={"100%"}
        maxWidth={"420px"}
        paddingTop={"1rem"}
        paddingRight="2rem"
        paddingLeft="2rem"
      >
        <Box marginTop="2rem">
          <CustomerLevels />
        </Box>

        <Box marginTop="2rem">
          <LoyaltyCardQRCode />
        </Box>

        <StoreSection customerUser={customerUser} />

        {showPromotionalBanners && (
          <Box marginTop="2rem">
            <PromotionBanner
              fetchPromos={fetchPromos}
              dataFetchPromo={dataFetchPromo}
            />
          </Box>
        )}

        <Box
          backgroundColor={mainTheme.colors.darkPurple}
          w="100%"
          flexDirection={"column"}
          alignItems="flex-start"
          justifyContent="center"
          padding="1rem"
        >
          {customerUser.favorite_store && (
            <Heading
              as="h2"
              size="xl"
              color={mainTheme.colors.primaryYellow}
              textAlign={"center"}
              marginBottom={"0.5em"}
            >
              {t(
                `partner.storeTypes.${customerUser.favorite_store.store_type}`
              )}
            </Heading>
          )}
        </Box>

        <AnimalsSection fetchPromos={fetchPromos} />

        <PointsGrid />
      </Box>
    </Flex>
  );
};

const DesktopMainContent = (
  showPromotionalBanners: boolean,
  customerUser: CustomerUser,
  t: TFunction<"translation">,
  fetchPromos: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void,
  dataFetchPromo: Promo[],
  carousesData: any,
  handleCarouselItemClick: any
) => {
  return (
    <Flex flexDirection={"column"}>
      <Box marginTop="2rem">
        <CustomerLevels />
      </Box>

      <Stack marginTop={"1rem"}>
        {carousesData && (
          <Carousel showThumbs={false} onClickItem={handleCarouselItemClick}>
            {carousesData.carousels.map((carousel: any) => (
              <div key={carousel.id}>
                <img src={carousel.image} />
                {/* <p className="legend">Legend 1</p> */}
              </div>
            ))}
          </Carousel>
        )}
      </Stack>

      <LoyaltyCardQRCode />

      {/* <Divider opacity={0} bgColor="white" w="100%" h="12vh" /> */}

      <StoreSection customerUser={customerUser} />

      <Box
        backgroundColor={mainTheme.colors.darkPurple}
        w="100%"
        flexDirection={"column"}
        alignItems="flex-start"
        justifyContent="center"
        padding="4rem"
      >
        {showPromotionalBanners && (
          <PromotionBanner
            fetchPromos={fetchPromos}
            dataFetchPromo={dataFetchPromo}
          />
        )}
      </Box>

      <AnimalsSection fetchPromos={fetchPromos} />

      <PointsGrid />
    </Flex>
  );
};
