import React, { useCallback } from "react";
import LogoWhite from "../../assets/images/logo_bianco.png";
import Logo from "../../assets/images/logo.png";
import {
  VStack,
  HStack,
  Flex,
  Image,
  Link,
  Spacer,
  useMediaQuery,
  Text,
} from "@chakra-ui/react";
import { UsernameImageProfile } from "../CustomerDashboard/UsernameImageProfile";
import { HeaderLogo, StyledHeader } from "./styled";
import { useStores } from "../../hooks/UseStores";
import { useHistory } from "react-router-dom";
import { CustomerUser } from "../../entities/CustomerUser";

interface HeaderProps {
  login?: boolean;
  dark?: boolean;
  backgroundColor?: string;
  textAlign?: string;
  editIcon?: boolean;
  customerUser?: CustomerUser;
  selectedTab?: string;
}

interface MediaHeaderProps {
  login?: boolean;
  dark?: boolean;
  backgroundColor?: string;
  textAlign?: string;
  editIcon?: boolean;
  isLargerThan768: boolean;
  customerUser?: CustomerUser;
  selectedTab?: string;
}

interface BasicHeaderProps {
  login?: boolean;
  dark?: boolean;
  backgroundColor?: string;
  textAlign?: string;
  editIcon?: boolean;
  isLargerThan768: boolean;
  customerUser?: CustomerUser;
}

/**
 * login: used to render the login header which consists of a single image (the logo)
 * editIcon: default is true. Sets the visibility of the edit icon on the profile image
 */
export const HeaderMio: React.FC<HeaderProps> = ({
  login = false,
  dark = false,
  backgroundColor = "transparent",
  textAlign = "left",
  editIcon = true,
  customerUser,
  selectedTab = "home",
}) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  return login
    ? BasicHeader({
        dark: dark,
        backgroundColor: backgroundColor,
        textAlign: textAlign,
        isLargerThan768: isLargerThan768,
      })
    : DashbaordHeader({
        editIcon: editIcon,
        isLargerThan768: isLargerThan768,
        customerUser,
        selectedTab: selectedTab,
      });
};

const BasicHeader: React.FC<BasicHeaderProps> = ({
  dark = false,
  backgroundColor = "transparent",
  isLargerThan768,
}) => {
  return (
    <Flex
      justify={isLargerThan768 ? "flex-start" : "center"}
      backgroundColor={backgroundColor}
    >
      <Image
        src={dark ? Logo : LogoWhite}
        alt={"logo"}
        display={"inline"}
        width={150}
        onClick={() => {
          window.open("https://loovers.eu/", "_blank");
        }}
        style={{ cursor: "pointer" }}
      />
    </Flex>
  );
};

interface LinkProps {
  url: string;
  currentSelectedTab: string;
  linkText: string;
  tabName: string;
  onClick?: () => void;
}

const DashboardLink: React.FC<LinkProps> = ({
  url,
  currentSelectedTab,
  linkText,
  tabName,
  onClick,
}) => {
  return (
    <Link
      href={url}
      fontWeight={currentSelectedTab === tabName ? "bold" : "normal"}
      onClick={onClick}
      textAlign="center"
    >
      {linkText}
    </Link>
  );
};

/**
 * @returns Flex component containing Loovers logo, the username and the profile image
 */
const DashbaordHeader: React.FC<MediaHeaderProps> = ({
  editIcon,
  isLargerThan768,
  customerUser,
  selectedTab = "home",
}) => {
  const { session } = useStores();
  const history = useHistory();

  const logout = useCallback(() => {
    session.logout();
    history.replace("/login");
    window.location.reload();
  }, [history, session]);

  return (
    <HStack
      flexDirection={isLargerThan768 ? "row" : "column"}
      alignItems={isLargerThan768 ? "center" : "center"}
      marginX={isLargerThan768 ? "2rem" : "3rem"}
      paddingY={isLargerThan768 ? "1rem" : "0rem"}
      spacing={isLargerThan768 ? "2.8rem" : "0rem"}
    >
      <BasicHeader dark isLargerThan768={isLargerThan768} />

      <DashboardLink
        url={"#/dashboard/customer"}
        linkText={"Home"}
        currentSelectedTab={selectedTab}
        tabName={"home"}
      />

      <DashboardLink
        url={"#/dashboard/customer/editProfile"}
        linkText={"Profilo"}
        currentSelectedTab={selectedTab}
        tabName={"editProfile"}
      />

      <DashboardLink
        url={"#/dashboard/customer/products"}
        linkText={"Prodotti"}
        currentSelectedTab={selectedTab}
        tabName={"products"}
      />

      <a
        style={{ display: "table-cell" }}
        href="https://loovers.eu/it/contattaci/"
        target="_blank"
        rel="noreferrer"
      >
        Assistenza
      </a>

      {isLargerThan768 && <Spacer />}

      <VStack
        align={isLargerThan768 ? "flex-end" : "center"}
        marginTop={isLargerThan768 ? "0rem" : "1.5rem"}
      >
        <UsernameImageProfile editIcon={editIcon} customerUser={customerUser} />

        <Link onClick={logout}>
          <Text fontSize={["md", "xl"]}>{"Logout"}</Text>
        </Link>
      </VStack>
    </HStack>
  );
};

interface Props {
  dark?: boolean;
  textAlign?: string;
}

export const Header: React.FC<Props> = ({
  dark = false,
  textAlign = "left",
}) => (
  <StyledHeader textAlign={textAlign}>
    <a href="https://loovers.eu/">
      <HeaderLogo src={dark ? Logo : LogoWhite} alt={"logo"} />
    </a>
  </StyledHeader>
);
