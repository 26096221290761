import { gql } from "@apollo/client";

export const storeFields = `
id
business_name
store_type
banner_name
email_b2b
mobile_phone_b2b
phone_b2b
pec
subscription
promo_code
coupon_id
vat_number
tax_code
sdi
is_payment_registration_complete
is_registration_complete
store_code
subscription_id
stripe_id
first_name_b2b
surname_b2b
store_opening_days
store_image
store_photos
other_service_one
other_service_two
addresses {
  id
  type
  address {
    id
    address
    city {
      id
      cap
      city
      district
    }
  }
}
brands {
  id
  brand {
    id
    name
  }
}
services {
  id
  service {
    id
    name
  }
}
promo {
  id
  valid_from
  valid_until
}
promoUsed {
  id
  receipt_photo
  used_date
  promo {
    id
    code
    activity_type
    interested_brand
    valid_from
    valid_until
    email_title
    description
    promo_type
    users_perimeter
    size
    is_service
    is_national
    activity {
      id
      name
    }
    brand {
      id
      name
    }
    special_needs {
      id
      name
    }
    promo_breed {
      id
      name
    }
  }
  customer {
    id
    email
    first_name
    surname
  }
}


`;

const customerFields = `
id
profile_image
first_name
surname
district
email
username
mobile_phone
gender
birth_date
store_code
loyality_points
brand {
  id
  brand {
    id
    name
  }
}
used_promo {
  id
  used_date
  promoId
  receipt_photo
}
animals {
  id
  name
  birth_date
  weight
  size
  image
  breed {
    id
    name
  } 
  animal_type {
    name
  }
  special_need {
    name
    animal
    need_type
  }
}
favorite_store {
  id
  business_name
  banner_name
  store_type
  store_image
  subscription
  addresses {
    address {
      address
      city {
        city
        cap
        district
      }
    }
  }
}
`;

const adminFields = `
id
first_name
surname
mobile_phone
promo {
  id
  valid_from
  valid_until
  promo_uses {
    id
        receipt_photo
        used_date
    promo {
      id
      code
      activity_type
      interested_brand
      valid_from
      valid_until
      email_title
      description
      promo_type
      users_perimeter
      size
      is_service
      is_national
      activity {
        id
        name
      }
      brand {
        id
        name
      }
      special_needs {
        id
        name
      }
      promo_breed {
        id
        name
      }
    }
    customer {
      id
      email
      first_name
      surname
    }
    store {
      id
      business_name
      banner_name
      email_b2b
      vat_number
      tax_code
      store_code
    }
  }
}
`;

export const SIGNUP_PARTNER = gql`
  mutation signupStore(
    $business_name: String!
    $store_type: String!
    $address_business: String!
    $address_business_city: String!
    $address_business_district: String!
    $address_business_cap: String!
    $banner_name: String!
    $email_b2b: String!
    $vat_number: String!
    $tax_code: String!
    $pec: String!
    $sdi: String
    $password: String!
    $mobile_phone_b2b: String!
    $phone_b2b: String
    $promo_code: String
    $subscription: String!
    $supply_contract_confirmation: Boolean!
    $privacy_contract_confirmation: Boolean!
    $clauses_confirmation: Boolean!
    $marketing_confirmation: Boolean!
    $coupon_id: String
    $is_payment_registration_complete: Boolean!
  ) {
    signupStore(
      business_name: $business_name
      store_type: $store_type
      address_business: $address_business
      address_business_city: $address_business_city
      address_business_district: $address_business_district
      address_business_cap:  $address_business_cap
      banner_name: $banner_name
      email_b2b: $email_b2b
      vat_number: $vat_number
      tax_code: $tax_code
      pec: $pec
      sdi: $sdi
      password: $password
      mobile_phone_b2b: $mobile_phone_b2b
      phone_b2b: $phone_b2b
      promo_code: $promo_code
      subscription: $subscription
      supply_contract_confirmation: $supply_contract_confirmation
      privacy_contract_confirmation: $privacy_contract_confirmation
      clauses_confirmation: $clauses_confirmation
      marketing_confirmation: $marketing_confirmation
      coupon_id: $coupon_id
        is_payment_registration_complete: $is_payment_registration_complete
    ) {
      token
      store {
        ${storeFields}
      }
    }
  }
`;

export const CHECK_PROMO_CODE = gql`
  mutation checkPromoCode(
    $promo_code: String!
    $selected_subscription: String!
    $coupon_id: String
  ) {
    checkPromoCode(
      promo_code: $promo_code
      selected_subscription: $selected_subscription
      coupon_id: $coupon_id
    ) {
      isPromoCodeValid
      promoCodeValue
      couponId
    }
  }
`;

export const SIGNUP_CUSTOMER = gql`
  mutation signupCustomer(
    $profile_image: String!
    $first_name: String!
    $surname: String!
    $district: String!
    $email: String!
    $username: String!
    $password: String!
    $mobile_phone: String!
    $gender: String!
    $birth_date: String!
    $privacy_contract_confirmation: Boolean!
    $marketingTermsCustomer: Boolean!
    $store_code: String
    $brands_ids: String!
  ) {
    signupCustomer(
      profile_image: $profile_image
      first_name: $first_name
      surname: $surname
      district: $district
      email: $email
      username: $username
      password: $password
      mobile_phone: $mobile_phone
      gender: $gender
      birth_date: $birth_date
      privacy_contract_confirmation: $privacy_contract_confirmation
      marketingTermsCustomer: $marketingTermsCustomer
      store_code: $store_code
      brands_ids: $brands_ids
    ) {
      token
      customer {
        ${customerFields}
      }
    }
  }
`;

export const UPDATE_CUSTOMER_DETAILS = gql`
  mutation updateCustomerDetails(
    $id: Int!
    $profile_image: String!
    $first_name: String!
    $surname: String!
    $district: String!
    $email: String!
    $username: String!
    $password: String!
    $mobile_phone: String!
    $gender: String!
    $birth_date: String!
    $store_code: String
    $brands_ids: String!
  ) {
    updateCustomerDetails(
      id: $id
      profile_image: $profile_image
      first_name: $first_name
      surname: $surname
      district: $district
      email: $email
      username: $username
      password: $password
      mobile_phone: $mobile_phone
      gender: $gender
      birth_date: $birth_date
      store_code: $store_code
      brands_ids: $brands_ids
    ) {
        ${customerFields}
      
    }
  }
`;

export const LOGIN_CUSTOMER = gql`
  mutation loginCustomer($email: String!, $password: String!) {
    loginCustomer(email: $email, password: $password) {
      token
      customer {
        ${customerFields}
      }
    }
  }
`;

export const LOGIN_STORE = gql`
  mutation loginStore($email: String!, $password: String!) {
    loginStore(email: $email, password: $password) {
      token
      store {
        ${storeFields}
      }
    }
  }
`;

export const LOGIN_ADMIN = gql`
  mutation loginAdmin($email: String!, $password: String!) {
    loginAdmin(email: $email, password: $password) {
      token
      admin {
        ${adminFields}
      }
    }
  }
`;

/**
 * Authenticated queries and mutations
 */
export const GET_STORE_BY_ID = gql`
  query store($userId: Int!) {
    store(where: { id: $userId }) {
      ${storeFields}
    }
  }
`;

export const GET_CUSTOMER_BY_ID = gql`
  query customer($userId: Int!) {
    customer(where: { id: $userId }) {
      ${customerFields}
    }
  }
`;

export const GET_ADMIN_BY_ID = gql`
  query admin($userId: Int!) {
    admin(where: { id: $userId }) {
      ${adminFields}
    }
  }
`;

export const STRIPE_SUBSCRIPTION = gql`
  mutation stripeSubscription(
    $user_id: Int!
    $payment_method: String!
    $token: String!
  ) {
    stripeSubscription(
      user_id: $user_id
      payment_method: $payment_method
      token: $token
    ) {
      response
      store {
        ${storeFields}
      }
    }
  }
`;

export const LOST_PASSWORD = gql`
  mutation lostPassword($email: String!) {
    lostPassword(email: $email) {
      status
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $email: String!
    $password: String!
    $token: String!
  ) {
    changePassword(email: $email, password: $password, token: $token) {
      status
    }
  }
`;

export const UPDATE_CUSTOMER_PROFILE_IMAGE = gql`
  mutation updateCustomerProfileImage($id: Int!, $profile_image: String!) {
    updateCustomerProfileImage(id: $id, profile_image: $profile_image) {
      profile_image
    }
  }
`;

export const ADD_CUSTOMER_LOOVERS_POINTS = gql`
  mutation addCustomerLooversPoints($customerID: Int!, $points_to_add: Int!) {
    addCustomerLooversPoints(customerID: $customerID, points_to_add: $points_to_add) {
        ${customerFields}
    }
  }
`;
