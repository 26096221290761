export class SpecialNeed {
  private _id: number;
  private _name: string;
  private _animal: string; // dog or cat
  private _need_type: string; // fisiologico o dietetico

  constructor(id: number, name: string, animal: string, need_type: string) {
    this._id = id;
    this._name = name;
    this._animal = animal;
    this._need_type = need_type;
  }

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get animal() {
    return this._animal;
  }

  public get need_type() {
    return this._need_type;
  }
}
