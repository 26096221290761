import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Promo } from "../../entities/Promo";
import { CreatePromotionForm } from "./CreatePromotionForm";
var QRCode = require("qrcode.react");

interface ModalProps {
  promo: Promo;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (data: any) => void;
  handleDelete: () => void;
  setActivity_id: React.Dispatch<React.SetStateAction<number>>;
  activity_id: number;
  setSingle_brand_id: React.Dispatch<React.SetStateAction<number>>;
  single_brand_id: number;
  setSpecial_need: React.Dispatch<React.SetStateAction<string>>;
  special_need: string;
}

export const CreatePromotionModal = ({
  promo,
  isOpen,
  onClose,
  handleSubmit,
  handleDelete,
  setActivity_id,
  activity_id,
  setSingle_brand_id,
  single_brand_id,
  special_need,
  setSpecial_need,
}: ModalProps) => {
  const { t } = useTranslation();

  const downloadQR = () => {
    const canvas = document.getElementById(
      String(promo.id)
    ) as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode-" + promo.id + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scheda Promozione</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {promo.id !== -1 && (
            <Center marginBottom={"2rem"}>
              <VStack justify="center">
                <QRCode id={promo.id.toString()} value={promo.id.toString()} />
                <Button colorScheme="teal" variant="ghost" onClick={downloadQR}>
                  {t("promoForm.downloadQRCode")}
                </Button>
              </VStack>
            </Center>
          )}
          <CreatePromotionForm
            promo={promo}
            onCancel={() => {
              onClose();
            }}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
            setActivity_id={setActivity_id}
            activity_id={activity_id}
            setSingle_brand_id={setSingle_brand_id}
            single_brand_id={single_brand_id}
            special_need={special_need}
            setSpecial_need={setSpecial_need}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
