import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Textarea,
  Input,
  RadioGroup,
  HStack,
  Radio,
  InputGroup,
  InputLeftAddon,
  FormHelperText,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  ActivityType,
  Genders,
  InterestedBrand,
  Promo,
  Segments,
  UsersPerimeter,
} from "../../entities/Promo";
import { useStores } from "../../hooks/UseStores";
import { DatePicker } from "../DatePicker/DatePicker";
import { ModalFooterForm } from "../ModalFooterForm";
import { BrandSelect } from "./BrandSelect";
import { ActivitiesSelect } from "./ActivitiesSelect";
import { Store_Service } from "../../entities/Store_Service";
import { DogSize } from "../../entities/PromoBreed";
import { SpecialNeedsSelect } from "./SpecialNeedsSelect";
import { PromoBreedSelect } from "./PromoBreedSelect";
import { AnimalSizeSelect } from "./AnimalSize";
import { Brand } from "../../entities/Brand";
import { useLazyQuery } from "@apollo/client";
import { Activity } from "../../entities/Activity";
import { Store_Brand } from "../../entities/Store_Brand";
import {
  FETCH_ACTIVITIES,
  FETCH_STORE_BRANDS,
} from "../../screens/PartnerPromotions/graphql";
import { mainTheme } from "../../config/theme";
import { FETCH_BRANDS } from "../../screens/PartnerPromotions/graphql";
import { DistrictsForm } from "./DistrictsForm";
import { CustomerTypeForm } from "./CustomerTypeForm";

interface Props {
  promo: Promo;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  onDelete?: () => void;
  setActivity_id: React.Dispatch<React.SetStateAction<number>>;
  activity_id: number;
  setSingle_brand_id: React.Dispatch<React.SetStateAction<number>>;
  single_brand_id: number;
  setSpecial_need: React.Dispatch<React.SetStateAction<string>>;
  special_need: string;
}

export const CreatePromotionForm: React.FC<Props> = ({
  promo,
  onCancel,
  onSubmit,
  onDelete,
  setActivity_id,
  activity_id,
  setSingle_brand_id,
  single_brand_id,
  special_need,
  setSpecial_need,
}) => {
  const { session } = useStores();
  const { t } = useTranslation();

  const [showPromoType, setShowPromoType] = useState(
    promo.activity_type === ActivityType.valueDiscountCoupon ||
      promo.activity_type === ActivityType.percentageDiscountCoupon
  );

  const validationSchema1 = Yup.object().shape({
    valid_from: Yup.date().required(t("promoForm.valid_fromRequired")),
    valid_until: Yup.date()
      .min(Yup.ref("valid_from"), t("promoForm.valid_until_after_valid_from"))
      .required(t("promoForm.valid_untilRequired")),
    email_title: Yup.string()
      .required(t("promoForm.emailRequired"))
      .min(5, t("promoForm.min5"))
      .max(40, t("promoForm.max40")),
    description: Yup.string()
      .required(t("promoForm.descriptionRequired"))
      .min(10, t("promoForm.min10"))
      .max(400, t("promoForm.max150")),
    promo_type: Yup.number()
      .typeError(t("promoForm.promoTypeTypeError"))
      .positive(t("promoForm.promoTypePositive"))
      .required(t("promoForm.promoTypeRequired")),
    checkbox: Yup.bool().oneOf([true], "Devi accettare"),
  });

  const validationSchema2 = Yup.object().shape({
    valid_from: Yup.date().required(t("promoForm.valid_fromRequired")),
    valid_until: Yup.date()
      .min(Yup.ref("valid_from"), t("promoForm.valid_until_after_valid_from"))
      .required(t("promoForm.valid_untilRequired")),
    email_title: Yup.string()
      .required(t("promoForm.emailRequired"))
      .min(5, t("promoForm.min5"))
      .max(40, t("promoForm.max40")),
    description: Yup.string()
      .required(t("promoForm.descriptionRequired"))
      .min(10, t("promoForm.min10"))
      .max(400, t("promoForm.max150")),
    checkbox: Yup.bool().oneOf([true], "Devi accettare"),
  });

  const [filteredBrandsData, setFilteredBrandsData] = useState<Brand[]>([]);

  const [fetchBrands, { data: dataFetchBrand }] = useLazyQuery(
    FETCH_STORE_BRANDS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const storeBrands: Store_Brand[] = dataFetchBrand.storeBrands.filter(
          (item: Store_Brand) => {
            if (item.store.id === session.partnerUser?.id) return true;
            return false;
          }
        );

        const brands: Brand[] = storeBrands.map((item: Store_Brand): Brand => {
          return new Brand(item.brand.id, item.brand.name);
        });

        if (brands.length > 0 && promo.id === -1)
          setSingle_brand_id(brands[0].id);
        else setSingle_brand_id(promo.brand.id);

        setFilteredBrandsData(brands);
      },
      onError: (error) => {
        console.log("BrandSelect", error);
      },
    }
  );

  const [fetchAdminBrands, { data: dataFetchAdminBrand }] = useLazyQuery(
    FETCH_BRANDS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const dataB: Brand[] = dataFetchAdminBrand.brands.map(
          (brand: Brand) => {
            return new Brand(Number(brand.id), brand.name);
          }
        );

        if (dataB.length > 0 && promo.id === -1)
          setSingle_brand_id(dataB[0].id);
        else setSingle_brand_id(promo.brand.id);

        setFilteredBrandsData(dataB);
      },
      onError: (error) => {
        console.log("BrandSelect", error);
      },
    }
  );

  useEffect(() => {
    if (!session.adminUser)
      fetchBrands({ variables: { storeId: session.partnerUser?.id } });
    else fetchAdminBrands();
  }, [
    fetchAdminBrands,
    fetchBrands,
    session.adminUser,
    session.partnerUser?.id,
  ]);

  const [filteredActivitiesData, setFilteredActivitiesData] = useState<
    Activity[]
  >([]);

  const [fetchActivities, { data: dataFetchActivity }] = useLazyQuery(
    FETCH_ACTIVITIES,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const activities: Activity[] = dataFetchActivity.activities.map(
          (item: Activity) => {
            return new Activity(
              item.id,
              item.name,
              item.serviceId,
              item.service
            );
          }
        );

        if (!session.adminUser) {
          const filteredActivities = activities.filter((item: Activity) => {
            let isEqualToId = false;

            session.partnerUser?.services.forEach((ss: Store_Service) => {
              if (ss.service.id === item.serviceId) {
                isEqualToId = true;
              }
            });

            return isEqualToId;
          });

          if (filteredActivities.length > 0 && promo.id === -1)
            setActivity_id(filteredActivities[0].id);
          else setActivity_id(promo.activity.id);

          setFilteredActivitiesData(filteredActivities);
        } else {
          if (activities.length > 0 && promo.id === -1)
            setActivity_id(activities[0].id);
          else setActivity_id(promo.activity.id);

          setFilteredActivitiesData(activities);
        }
      },
      onError: (error) => {
        console.log("ActivitySelect", error);
      },
    }
  );

  const fetchAllActivitiesByServiceId = useCallback(async () => {
    fetchActivities();
  }, [fetchActivities]);

  useEffect(() => {
    fetchAllActivitiesByServiceId();
  }, []);

  useEffect(() => {
    setSpecial_need(promo.id === -1 ? "all" : promo.special_need);
  }, [promo.id, promo.special_need, setSpecial_need]);

  const currentDatePlusTwoDays = new Date(
    new Date().getTime() + 24 * 60 * 60 * 1000 * 2
  );
  currentDatePlusTwoDays.setHours(0);
  currentDatePlusTwoDays.setMinutes(0);
  currentDatePlusTwoDays.setSeconds(0);

  const isPromoModifiable = Boolean(session.adminUser)
    ? true
    : promo.id === -1
    ? false
    : currentDatePlusTwoDays.getTime() < new Date(promo.valid_from).getTime();

  const canDisableFields = promo.id === -1 ? false : !isPromoModifiable;
  console.log("promo", promo);
  return (
    <Formik
      initialValues={{
        id: promo.id,
        activity_type:
          promo.id === -1
            ? ActivityType.valueDiscountCoupon
            : ActivityType[promo.activity_type as ActivityType],
        interested_brand:
          promo.id === -1
            ? InterestedBrand.all
            : InterestedBrand[promo.interested_brand as InterestedBrand],
        valid_from:
          promo.id === -1
            ? new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
            : new Date(promo.valid_from),
        valid_until:
          promo.id === -1
            ? new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 2)
            : new Date(promo.valid_until),
        email_title: promo.id === -1 ? "" : promo.email_title,
        description: promo.id === -1 ? "" : promo.description,
        promo_type: promo.id === -1 ? 0 : promo.promo_type,
        users_perimeter:
          promo.id === -1
            ? UsersPerimeter.all
            : UsersPerimeter[promo.users_perimeter as UsersPerimeter],
        size: promo.id === -1 ? DogSize.All : DogSize[promo.size as DogSize],
        activity_id: promo.id === -1 ? -1 : promo.activity.id,
        single_brand_id: promo.id === -1 ? -1 : promo.brand.id,
        special_needs_id: promo.id === -1 ? -1 : promo.special_needs.id,
        promo_breed:
          promo.id !== -1
            ? {
                value: promo.promo_breed.id.toString(),
                label: promo.promo_breed.name.toString(),
              }
            : {
                value: "-1",
                label: "",
              },
        all_activities_brands_id:
          promo.id === -1
            ? -1
            : promo.interested_brand === InterestedBrand.all
            ? promo.is_service
              ? promo.activity.id
              : promo.brand.id
            : promo.interested_brand === InterestedBrand.single_service
            ? promo.activity.id
            : promo.brand.id,
        checkbox: false,
        segment: promo.id === -1 ? Segments.tutti : promo.segment,
        gender: promo.id === -1 ? Genders.all : promo.gender,
        districts:
          promo.id === -1
            ? { label: "", value: "" }
            : promo.districts
            ? { label: promo.districts, value: promo.districts }
            : { label: "", value: "" },
        customer_type:
          promo.id === -1 ? "" : promo.customer_type ? promo.customer_type : "",
      }}
      validationSchema={showPromoType ? validationSchema1 : validationSchema2}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {!session.adminUser && (
            <Field name="pdvCode" disabled>
              {({ field }: any) => (
                <FormControl id={field.name}>
                  <FormLabel>{t("promoForm.pdvCode")}</FormLabel>
                  <Input disabled value={session.partnerUser?.store_code} />
                  <FormHelperText>
                    {t("promoForm.pdvCodeHelper")}
                  </FormHelperText>
                </FormControl>
              )}
            </Field>
          )}

          <Field name={"interested_brand"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={meta.description && meta.touched}
                as="fieldset"
                isDisabled={canDisableFields}
              >
                <FormLabel as="legend">
                  {t("promoForm.interested_brand.label")}
                </FormLabel>
                <RadioGroup
                  name={field.name}
                  value={field.value}
                  onChange={(value) => {
                    setFieldValue(field.name, value);
                  }}
                  disabled={canDisableFields}
                >
                  <HStack wrap="wrap" spacing="2rem">
                    <Radio value={InterestedBrand.all}>
                      {t("promoForm.interested_brand.all")}
                    </Radio>
                    <Radio value={InterestedBrand.all_brands}>
                      {t("promoForm.interested_brand.all_brands")}
                    </Radio>
                    <Radio value={InterestedBrand.all_services}>
                      {t("promoForm.interested_brand.all_services")}
                    </Radio>
                    <Radio value={InterestedBrand.single_brand}>
                      {t("promoForm.interested_brand.single_brand")}
                    </Radio>
                    <Radio value={InterestedBrand.single_service}>
                      {t("promoForm.interested_brand.single_service")}
                    </Radio>
                  </HStack>
                </RadioGroup>
              </FormControl>
            )}
          </Field>

          {props.values.interested_brand === InterestedBrand.single_brand && (
            <Field name={"single_brand_id"}>
              {({ field, form: { setFieldValue }, meta }: any) => (
                <FormControl
                  isInvalid={meta.description && meta.touched}
                  as="fieldset"
                  isDisabled={canDisableFields}
                >
                  <BrandSelect
                    field={field}
                    setFieldValue={setFieldValue}
                    brandsData={filteredBrandsData}
                    single_brand_id={single_brand_id}
                    setSingle_brand_id={setSingle_brand_id}
                    promo={promo}
                  />
                </FormControl>
              )}
            </Field>
          )}

          {props.values.interested_brand === InterestedBrand.single_service && (
            <Field name={"activity_id"}>
              {({ field, form: { setFieldValue }, meta }: any) => (
                <FormControl
                  isInvalid={meta.description && meta.touched}
                  as="fieldset"
                  isDisabled={canDisableFields}
                >
                  <ActivitiesSelect
                    field={field}
                    setFieldValue={setFieldValue}
                    activitiesData={filteredActivitiesData}
                    setActivity_id={setActivity_id}
                    activity_id={activity_id}
                    promo={promo}
                  />
                </FormControl>
              )}
            </Field>
          )}

          <Field name={"users_perimeter"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={Boolean(meta.error) && meta.touched}
                isDisabled={canDisableFields}
              >
                <FormLabel>{t("promoForm.usersPerimeter.label")}</FormLabel>
                <Select
                  name={field.name}
                  defaultValue={props.values.users_perimeter}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                >
                  <option value={UsersPerimeter.all}>
                    {t("promoForm.usersPerimeter.all")}
                  </option>
                  <option value={UsersPerimeter.dog}>
                    {t("promoForm.usersPerimeter.dog")}
                  </option>
                  <option value={UsersPerimeter.cat}>
                    {t("promoForm.usersPerimeter.cat")}
                  </option>
                  <option value={UsersPerimeter.smallAnimal}>
                    {t("promoForm.usersPerimeter.smallAnimal")}
                  </option>
                </Select>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={"segment"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={Boolean(meta.error) && meta.touched}
                isDisabled={canDisableFields}
              >
                <FormLabel>Segmento</FormLabel>
                <Select
                  name={field.name}
                  defaultValue={props.values.segment}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                >
                  <option value={Segments.tutti}>Tutti</option>
                  <option value={Segments.alimentoSecco}>Alimento Secco</option>
                  <option value={Segments.alimentoUmido}>Alimento Umido</option>
                  <option value={Segments.snack}>Snack</option>
                  <option value={Segments.lettiera}>Lettiera</option>
                  <option value={Segments.accessoriConfort}>
                    Accessori Confort
                  </option>
                  <option value={Segments.accessoriIgene}>
                    Accessori Igene
                  </option>
                  <option value={Segments.antiparassitari}>
                    Antiparassitari
                  </option>
                  <option value={Segments.giochi}>Giochi</option>
                </Select>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {UsersPerimeter.dog === props.values.users_perimeter && (
            <Field name={"size"}>
              {({ field, form: { setFieldValue }, meta }: any) => (
                <FormControl
                  isInvalid={meta.description && meta.touched}
                  as="fieldset"
                  isDisabled={canDisableFields}
                >
                  <FormLabel>{"Taglia"}</FormLabel>
                  <AnimalSizeSelect
                    field={field}
                    setFieldValue={setFieldValue}
                    formikProps={props.values}
                    promo={promo}
                  />
                </FormControl>
              )}
            </Field>
          )}

          {UsersPerimeter.smallAnimal !== props.values.users_perimeter && (
            <Field name={"special_needs_id"}>
              {({ field, form: { setFieldValue }, meta }: any) => (
                <FormControl
                  isInvalid={meta.description && meta.touched}
                  as="fieldset"
                  isDisabled={canDisableFields}
                >
                  <FormLabel>{t("promoForm.specialNeeds")}</FormLabel>
                  <SpecialNeedsSelect
                    field={field}
                    setFieldValue={setFieldValue}
                    users_perimeter={props.values.users_perimeter}
                    formikProps={props}
                    promo={promo}
                    isPromoModifiable={isPromoModifiable}
                    special_need={special_need}
                    setSpecial_need={setSpecial_need}
                  />
                </FormControl>
              )}
            </Field>
          )}

          {UsersPerimeter.smallAnimal !== props.values.users_perimeter &&
            UsersPerimeter.all !== props.values.users_perimeter && (
              <Field name={"promo_breed"}>
                {({ field, form: { setFieldValue }, meta }: any) => (
                  <FormControl
                    isInvalid={meta.description && meta.touched}
                    as="fieldset"
                    isDisabled={canDisableFields}
                  >
                    <FormLabel>{t("animal.breed")}</FormLabel>
                    <PromoBreedSelect
                      field={field}
                      setFieldValue={setFieldValue}
                      users_perimeter={props.values.users_perimeter}
                      promo={promo}
                      isDisabled={canDisableFields}
                      showAllBreeds
                    />
                  </FormControl>
                )}
              </Field>
            )}

          <Field name={"activity_type"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isDisabled={canDisableFields}
                isInvalid={meta.description && meta.touched}
              >
                <FormLabel>{t("promoForm.type")}</FormLabel>
                <Select
                  name={field.name}
                  defaultValue={props.values.activity_type}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                    setShowPromoType(
                      event.target.value === ActivityType.valueDiscountCoupon ||
                        event.target.value ===
                          ActivityType.percentageDiscountCoupon
                    );
                  }}
                >
                  <option value={ActivityType.valueDiscountCoupon}>
                    {t("promoForm.valueDiscountCoupon")}
                  </option>
                  <option value={ActivityType.percentageDiscountCoupon}>
                    {t("promoForm.percentageDiscountCoupon")}
                  </option>
                  <option value={ActivityType.freeProductCoupon}>
                    {t("promoForm.freeProductCoupon")}
                  </option>
                  <option value={ActivityType.promotionalDayCoupon}>
                    {t("promoForm.promotionalDayCoupon")}
                  </option>
                </Select>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          {(props.values.activity_type === ActivityType.valueDiscountCoupon ||
            props.values.activity_type ===
              ActivityType.percentageDiscountCoupon) && (
            <Field name="promo_type">
              {({ field, form: { setFieldValue }, meta }: any) => (
                <FormControl
                  id={field.name}
                  isInvalid={Boolean(meta.error) && meta.touched}
                  isDisabled={canDisableFields}
                >
                  <FormLabel>{t("promoForm.promoType") + " *"}</FormLabel>
                  <InputGroup>
                    <InputLeftAddon
                      children={
                        props.values.activity_type ===
                        ActivityType.valueDiscountCoupon
                          ? "€"
                          : "%"
                      }
                    />
                    <Input
                      value={field.value}
                      onChange={(event) => {
                        setFieldValue(field.name, Number(event.target.value));
                      }}
                    />
                  </InputGroup>
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          )}

          <Field name={"valid_from"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={Boolean(meta.error) && meta.touched}
                as="fieldset"
                isDisabled={canDisableFields}
              >
                <FormLabel>{t("promoForm.startDate")}</FormLabel>
                <DatePicker
                  name={"valid_from"}
                  minDate={
                    isPromoModifiable || promo.id === -1
                      ? new Date()
                      : undefined
                  }
                  value={field.value}
                  onChange={(value: Date) => {
                    setFieldValue(field.name, value);

                    if (value.getTime() > props.values.valid_until.getTime())
                      setFieldValue(
                        "valid_until",
                        new Date(value.getTime() + 60 * 60 * 48 * 1000)
                      );
                  }}
                  disabled={canDisableFields}
                />
              </FormControl>
            )}
          </Field>

          <Field name={"valid_until"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={Boolean(meta.error) && meta.touched}
                as="fieldset"
                isDisabled={canDisableFields}
              >
                <FormLabel>{t("promoForm.endDate")}</FormLabel>
                <DatePicker
                  name={"valid_until"}
                  minDate={
                    isPromoModifiable || promo.id === -1
                      ? new Date(
                          props.values.valid_from.getTime() +
                            60 * 60 * 24 * 1000
                        )
                      : undefined
                  }
                  value={
                    props.values.valid_from.getTime() > field.value.getTime()
                      ? new Date(
                          props.values.valid_from.getTime() +
                            60 * 60 * 24 * 1000
                        )
                      : field.value
                  }
                  onChange={(value: Date) => {
                    setFieldValue(field.name, value);
                  }}
                  disabled={canDisableFields}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name="email_title">
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                id={field.name}
                isInvalid={Boolean(meta.error) && meta.touched}
                isDisabled={canDisableFields}
              >
                <FormLabel>{t("promoForm.emailTitle") + " *"}</FormLabel>
                <Input
                  value={field.value}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={"description"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={Boolean(meta.error) && meta.touched}
                isDisabled={canDisableFields}
              >
                <FormLabel htmlFor={"description"}>
                  {t("promoForm.promoDescription") + " *"}
                </FormLabel>
                <Textarea
                  name={field.name}
                  value={field.value}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                  }}
                  size={"sm"}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          {session.adminUser && (
            <Field name={"gender"}>
              {({ field, form: { setFieldValue }, meta }: any) => (
                <FormControl
                  isInvalid={Boolean(meta.error) && meta.touched}
                  isDisabled={canDisableFields}
                >
                  <FormLabel>Sesso</FormLabel>
                  <Select
                    name={field.name}
                    defaultValue={props.values.gender}
                    onChange={(event) => {
                      setFieldValue(field.name, event.target.value);
                    }}
                  >
                    <option value={Genders.all}>Entrambi</option>
                    <option value={Genders.male}>Maschio</option>
                    <option value={Genders.female}>Femmina</option>
                  </Select>
                  <FormHelperText>
                    {"Scegli il sesso degli utenti"}
                  </FormHelperText>
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          )}

          {session.adminUser && (
            <Field name={"districts"}>
              {({ field, form: { setFieldValue }, meta }: any) => (
                <FormControl
                  isInvalid={Boolean(meta.error) && meta.touched}
                  isDisabled={canDisableFields}
                >
                  <FormLabel>Province</FormLabel>
                  <DistrictsForm
                    districts={props.values.districts}
                    canDisableFields={canDisableFields}
                    setFieldValue={setFieldValue}
                    promo={promo}
                    field={field}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          )}

          {session.adminUser && (
            <CustomerTypeForm
              customerType={props.values.customer_type}
              canDisableFields={canDisableFields}
            />
          )}

          <Field name={"checkbox"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={Boolean(meta.error) && meta.touched}
                isDisabled={canDisableFields}
              >
                {/* <FormLabel htmlFor={"description"}>
                  {t("promoForm.promoDescription") + " *"}
                </FormLabel> */}
                <Checkbox
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.checked)}
                >
                  {t("promoForm.checkbox")}
                </Checkbox>
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <ModalFooterForm
            onCancel={onCancel}
            onDelete={isPromoModifiable ? onDelete : undefined}
            isOnConfirmDisabled={
              promo.id === -1 || isPromoModifiable ? false : true
            }
          />

          {!props.isValid && props.submitCount > 0 && (
            <Text fontSize="sm" color={mainTheme.colors.danger}>
              {t("auth.messages.formError")}
            </Text>
          )}
        </Form>
      )}
    </Formik>
  );
};
