import { useLazyQuery } from "@apollo/client";
import { FormLabel, Spinner } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store_Service } from "../../../entities/Store_Service";
import { FETCH_SERVICES } from "../../../screens/PartnerSignUp/CompleteSignUpPartner/graphql";
import { MultiSelect } from "../../PartnerSignUpForm/CompleteSignUpPartner/MultiSelect";

type Props = {
  storeServices: Store_Service[];
  setServicesIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const ServicesForm = ({ storeServices, setServicesIds }: Props) => {
  const { t } = useTranslation();

  const storeServicesData = storeServices.map((ss: Store_Service) => {
    return {
      value: ss.service.id.toString(),
      label: t(`promoForm.${ss.service.name}.label`),
    };
  });
  const [selectValue, setSelectValue] =
    useState<{ value: string; label: string }[]>(storeServicesData);
  const [servicesData, setServicesData] = useState<
    { value: string; label: string }[]
  >([]);

  const [fetchServices, { loading: isFetching, data }] = useLazyQuery(
    FETCH_SERVICES,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const services: { value: string; label: string }[] = data.services.map(
          (item: any) => {
            return {
              value: item.id.toString(),
              label: t(`promoForm.${item.name}.label`),
            };
          }
        );

        setServicesData(services);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const handleOnChange = (values: any) => {
    setSelectValue(values);

    let ids = values.map(
      (item: { value: string; label: string }) => item.value
    );

    setServicesIds(ids);
  };

  return (
    <VStack w="100%" spacing={"0px"}>
      <FormLabel>{t("partner.services")}</FormLabel>
      {isFetching && <Spinner />}

      <MultiSelect
        value={selectValue}
        options={servicesData}
        onChange={handleOnChange}
      />
    </VStack>
  );
};
