import { CustomerUser } from "./CustomerUser";
import { PartnerUser } from "./PartnerUser";
import { Promo } from "./Promo";
import { PromoBreed } from "./PromoBreed";

export class Promo_Code_use {
  private _id: number;
  private _receipt_photo: string;
  private _customer: CustomerUser;
  private _promo: Promo;
  private _used_date: Date;
  private _store: PartnerUser;

  constructor() {
    this._id = -1;
    this._receipt_photo = "";
    this._customer = new CustomerUser();
    this._promo = new Promo(new PromoBreed(-1, "", "", ""));
    this._used_date = new Date();
    this._store = new PartnerUser();
  }

  public get id() {
    return this._id;
  }

  public get customer() {
    return this._customer;
  }

  public get promo() {
    return this._promo;
  }

  public get receipt_photo() {
    return this._receipt_photo;
  }

  public get used_date() {
    return this._used_date;
  }

  public get store() {
    return this._store;
  }
}
