import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Header } from "../../../components/Header";
import { Alert, AlertIcon, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { mainTheme } from "../../../config/theme";
import { useStores } from "../../../hooks/UseStores";
import { useMutation } from "@apollo/client";
import { CompleteSignUpPartnerForm } from "../../../components/PartnerSignUpForm/CompleteSignUpPartner/CompleteSignUpPartnerForm";
import { COMPLETE_STORE_SIGNUP } from "./graphql";
import { StoreOpeningDaysObject } from "../../../entities/PartnerUser";

export const CompleteSignUpPartnerScreen: React.FC = () => {
  const { session } = useStores();

  const { t } = useTranslation();
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const [showServicesErrorMessage, setShowServicesErrorMessage] =
    useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [openingTimeJson, setOpeningTimeJson] = useState<
    StoreOpeningDaysObject | undefined
  >({
    monday: "",
    tuesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    wednesday: "",
    sunday: "",
  });

  const [brandsIds, setBrandsIds] = useState<string[]>([]);
  const [servicesIds, setServicesIds] = useState<string[]>([]);

  const handleSignUpCompleted = useCallback((data) => {
    window.location.reload();
  }, []);

  const handleSignUpError = useCallback(() => {
    setShowErrorMessage(true);
  }, []);

  const [completeStoreSignup, { loading: mutationSubmitting }] = useMutation(
    COMPLETE_STORE_SIGNUP,
    {
      onCompleted: handleSignUpCompleted,
      onError: (error) => {
        console.log(error);
        handleSignUpError();
      },
    }
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (servicesIds.length === 0) {
        setShowServicesErrorMessage(true);
        return;
      } else {
        setShowServicesErrorMessage(false);
        setShowDoneMessage(true);

        completeStoreSignup({
          variables: {
            id: session.partnerUser?.id,
            first_name: values.firstNameB2B,
            surname: values.surnameB2B,
            address_store: values.addressStore,
            address_store_city: values.addressStoreCity,
            address_store_district: values.addressStoreDistrict.value,
            address_store_cap: values.addressStoreCap,
            address_store2: values.addressStore2,
            address_store2_city: values.addressStore2City,
            address_store2_district: values.addressStore2District.value,
            address_store2_cap: values.addressStore2Cap,
            store_opening_days: JSON.stringify(openingTimeJson),
            services: servicesIds.join(","),
            brands: brandsIds.join(","),
            store_image: values.store_image,
            other_service_one: values.otherServiceOne,
            other_service_two: values.otherServiceOne,
          },
        });
      }
    },
    [
      brandsIds,
      completeStoreSignup,
      openingTimeJson,
      servicesIds,
      session.partnerUser?.id,
    ]
  );

  return (
    <Flex flexDirection={"column"}>
      <Header dark />

      <Flex
        flexDirection={"column"}
        paddingTop={"5rem"}
        paddingLeft={"1rem"}
        backgroundColor={mainTheme.colors.primaryGreen}
      >
        <HeroH1>
          {t("partner.dashboard.welcome") +
            ", " +
            session.partnerUser?.businessName}
        </HeroH1>
        <HeroH2>{t("partner.dashboard.completeSignup.title")}</HeroH2>
      </Flex>

      <Container>
        <FormContainer>
          <CompleteSignUpPartnerForm
            onSubmit={handleSubmit}
            isSubmitting={mutationSubmitting}
            setOpeningTimeJson={setOpeningTimeJson}
            setBrandsIds={setBrandsIds}
            setServicesIds={setServicesIds}
          />
          {showServicesErrorMessage && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {t("auth.messages.servicesError")}
            </Alert>
          )}

          {showErrorMessage && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {t("auth.messages.formError")}
            </Alert>
          )}

          {showDoneMessage && (
            <Flex direction={"column"} alignItems={"center"} m={6} gridGap={3}>
              <Alert
                status={"success"}
                color={mainTheme.colors.primaryGreen}
                backgroundColor={mainTheme.colors.background}
                borderRadius={"5px"}
              >
                <AlertIcon />
                {t("auth.messages.storeSignupPart2Completed")}
              </Alert>
              <Flex gridGap={5}>
                <Text>{t("common.redirect")}</Text>
                <Spinner
                  thickness="3px"
                  speed="0.65s"
                  emptyColor={mainTheme.colors.lightPurple}
                  color={mainTheme.colors.darkPurple}
                  size="md"
                />
              </Flex>
            </Flex>
          )}
        </FormContainer>
      </Container>
    </Flex>
  );
};

const HeroH1 = styled.h1`
  color: ${(props) => props.theme.colors.textWhite};
  font-size: 2.5rem;
  line-height: 1em;
  font-weight: bold;
  font-family: Noe Display, Helvetica, Arial, sans-serif;
  white-space: pre-line;
  margin-bottom: 1.5rem;

  @media (min-width: 767px) {
    font-size: 3.5rem;
  }
`;

const HeroH2 = styled.h2`
  color: ${(props) => props.theme.colors.textWhite};
  font-size: 1rem;
  font-family: "Proxima Nova", Sans-serif;

  @media (min-width: 767px) {
    font-size: 1.5rem;
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primaryGreen};
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textWhite};
  padding: 3rem 1.5rem;
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;
