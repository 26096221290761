import React, { useEffect, useState } from "react";
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "../../i18n";
import { SignUpCustomerScreen } from "../SignUpCustomer";
import { SignUpPartnerScreen } from "../PartnerSignUp/InitialSignUpPartner";
import { ChakraProvider, Flex, Spinner } from "@chakra-ui/react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  useQuery,
} from "@apollo/client";
import config from "../../config/config";
import { mainTheme } from "../../config/theme";
import { ThemeProvider } from "styled-components";
import chakraTheme from "../../config/chakraTheme";
import { CustomerDashboardScreen } from "../CustomerDashboard/CustomerDashboardScreen";
import { PageNotFoundScreen } from "../PageNotFoundScreen/PageNotFoundScreen";
import { Stores, UserType } from "../../types";
import { Session } from "../../store/Session";
import { useTranslation } from "react-i18next";
import { I18NLang } from "../../i18n";
import { StoresContext } from "../../contexts";
import { PrivateRoute } from "../../components/PrivateRoute/PrivateRoute";
import { observer } from "mobx-react";
import { setContext } from "@apollo/client/link/context";
import {
  GET_ADMIN_BY_ID,
  GET_CUSTOMER_BY_ID,
  GET_STORE_BY_ID,
} from "../../services/graphql";
import { PaymentSignUpPartnerScreen } from "../PartnerSignUp/PaymentSignUpPartner";
import { useStores } from "../../hooks/UseStores";
import { PartnerDashboardScreen } from "../PartnerDashboard/PartnerDashboardScreen";
import { PartnerPromotionsScreen } from "../PartnerPromotions/PartnerPromotionsScreen";
import { CompleteSignUpPartnerScreen } from "../PartnerSignUp/CompleteSignUpPartner/CompleteSignUpPartnerScreen";
import { LoginScreen } from "../Login";
import { LostPasswordScreen } from "../LostPassword";
import { ChangePasswordScreen } from "../ChangePassword";
import { Stripe } from "../../store/Stripe";
import { PartnerScanQRCodeScreen } from "../PartnerScanQRCodeScreen/PartnerScanQRCodeScreen";
import { AdminLoginScreen } from "../Admin/AdminLogin";
import { AdminPromotionsScreen } from "../Admin/AdminPromotions";
import { PartnerSubscriptionScreen } from "../PartnerSubscriptionScreen/PartnerSubscriptionScreen";
import { EditProfileDetailsScreen } from "../CustomerDashboard/EditProfileDetails";
import { ProductsScreen } from "../CustomerDashboard/ProductsScreen";
import { AdminProductsScreen } from "../Admin/AdminProducts/AdminProducts";
import { AdminSetupScreen } from "../Admin/AdminSetup";

// get the authentication token from local storage if it exists
let sessionItem = localStorage.getItem("session");
let localSession = sessionItem ? JSON.parse(sessionItem) : null;

const httpLink = createHttpLink({
  uri: config.GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: localSession ? `Bearer ${localSession.token}` : "",
    },
  };
});

const graphQLClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const Root: React.FC = observer(() => {
  const [stores] = useState<Stores>(() => {
    const root = {} as Stores;
    root.session = new Session(root);
    root.stripe = new Stripe(root);
    return root;
  });
  const { session } = stores;

  const { i18n, ready } = useTranslation(undefined, { useSuspense: false });

  useEffect(() => {
    if (ready && i18n) {
      session.setLanguage(i18n.language as I18NLang);

      session.restoreSession();
    }
  }, [i18n, ready, session]);

  return (
    <StoresContext.Provider value={stores}>
      <ThemeProvider theme={mainTheme}>
        <ChakraProvider theme={chakraTheme}>
          <ApolloProvider client={graphQLClient}>
            <Router>
              {(localSession && localSession.token) || session.isLogged() ? (
                // Renders the dashboard if the user has an active session or if he has just logged in
                <AuthNavigator key={session.sessionToken} session={session} />
              ) : (
                // Renders the auth forms
                <Switch>
                  <Route
                    path={"/signup/customer"}
                    component={SignUpCustomerScreen}
                  />
                  <Route
                    path={"/signup/partner"}
                    component={SignUpPartnerScreen}
                  />
                  <Route
                    path={"/lost-password"}
                    component={LostPasswordScreen}
                  />
                  <Route
                    path={"/change-password"}
                    component={ChangePasswordScreen}
                  />
                  <Route path={"/login"} component={LoginScreen} />
                  <Route path="/admin" component={AdminNavigator} />
                  <Route
                    exact
                    path={"/"}
                    component={() => <Redirect to={"login"} />}
                  />
                  <Route component={PageNotFoundScreen} />
                </Switch>
              )}
            </Router>
          </ApolloProvider>
        </ChakraProvider>
      </ThemeProvider>
    </StoresContext.Provider>
  );
});

type AuthNavigatorProps = {
  session: Session;
};

const AuthNavigator: React.FC<AuthNavigatorProps> = ({ session }) => {
  const [userType, setUserType] = useState<UserType | "">("");

  useEffect(() => {
    setUserType(
      localSession && !!localSession.userType
        ? localSession.userType
        : !!session.partnerUser
        ? "partner"
        : !!session.customerUser
        ? "customer"
        : !!session.adminUser
        ? "admin"
        : ""
    );
  }, [session]);

  const { loading, error, data } = useQuery(
    userType === "partner"
      ? GET_STORE_BY_ID
      : userType === "customer"
      ? GET_CUSTOMER_BY_ID
      : GET_ADMIN_BY_ID,
    {
      variables: {
        userId:
          userType === "admin"
            ? localSession
              ? parseInt(localSession.userId)
              : session.adminUser?.id
            : userType === "partner"
            ? localSession
              ? parseInt(localSession.userId)
              : session.partnerUser?.id
            : localSession
            ? parseInt(localSession.userId)
            : session.customerUser?.id,
      },
      skip: session.isLogged(),
    }
  );

  if (error) {
    session.logout();
    session.setSessionError(true);
    window.location.reload();
  }

  if (data) {
    session.setUser(
      userType === "partner"
        ? data.store
        : userType === "customer"
        ? data.customer
        : data.admin,
      localSession.userType
    );
  }

  return loading || session.isLoading ? (
    <Flex justifyContent={"center"} alignItems={"center"} h={"100vh"}>
      <Spinner
        thickness="2px"
        speed="0.65s"
        emptyColor={mainTheme.colors.lightPurple}
        color={mainTheme.colors.darkPurple}
        size="lg"
      />
    </Flex>
  ) : (
    <PrivateRoute
      path={`/`}
      component={
        userType === "partner"
          ? PartnerAuthNavigator
          : userType === "customer"
          ? CustomerAuthNavigator
          : AdminAuthNavigator
      }
      isAuthenticated={session.isLogged()}
    />
  );
};

const PartnerAuthNavigator: React.FC = observer(() => {
  const { session } = useStores();

  return (
    <Switch>
      {session.partnerUser?.isRegistrationComplete ? (
        <>
          <Redirect to="/partner/dashboard" />
          <Route
            path={`/partner/dashboard`}
            component={PartnerDashboardScreen}
          />
          <Route
            path={`/partner/promotions`}
            component={PartnerPromotionsScreen}
          />
          <Route
            path={`/partner/scanqrcode`}
            component={PartnerScanQRCodeScreen}
          />
          <Route
            path={`/partner/subscription`}
            component={PartnerSubscriptionScreen}
          />
        </>
      ) : session.partnerUser?.isPaymentRegistrationComplete||
      (session.partnerUser?.subscription && session.partnerUser.subscription.includes("superlight")) ? (
        <>
          <Route
            path={`/partner/complete_sign_up_infos`}
            component={CompleteSignUpPartnerScreen}
          />
          <Redirect to="/partner/complete_sign_up_infos" />
        </>
      ) : (
        <>
          <Route
            path={`/partner/complete_sign_up`}
            component={PaymentSignUpPartnerScreen}
          />
          <Redirect to="/partner/complete_sign_up" />
        </>
      )}
    </Switch>
  );
});

const CustomerAuthNavigator: React.FC = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/dashboard/customer`}
        component={CustomerDashboardScreen}
      />
      <Route
        exact
        path={`/dashboard/customer/editProfile`}
        component={EditProfileDetailsScreen}
      />
      <Route
        exact
        path={`/dashboard/customer/products`}
        component={ProductsScreen}
      />
      <Redirect to="/dashboard/customer" />
    </Switch>
  );
};

const AdminNavigator: React.FC = () => {
  return (
    <>
      <Route path={"/admin/login"} component={AdminLoginScreen} />
      <Redirect to="/admin/login" />
    </>
  );
};

const AdminAuthNavigator: React.FC = () => {
  return (
    <Switch>
      <Route exact path={`/admin/promo`} component={AdminPromotionsScreen} />
      <Route exact path={`/admin/products`} component={AdminProductsScreen} />
      <Route exact path={`/admin/config`} component={AdminSetupScreen} />
      <Redirect to="/admin/promo" />
    </Switch>
  );
};
