import { Flex, Spinner, Text, Box, Alert, AlertIcon } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PrimaryLooversText } from "../../components/LooversText/LooversText";
import { mainTheme } from "../../config/theme";
import { useStores } from "../../hooks/UseStores";

export const PageNotFoundScreen = observer(() => {
  const { t } = useTranslation();
  const { session } = useStores();

  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/login");
    }, 1500);
  }, [history]);

  return (
    <Flex
      flexDirection={"column"}
      gridGap={50}
      alignItems={"center"}
      w={"100%"}
      p={3}
    >
      {session.sessionError ? (
        <Alert
          status={"error"}
          color={mainTheme.colors.danger}
          borderRadius={"5px"}
          margin={"2rem 0"}
        >
          <AlertIcon />
          {t("auth.messages.sessionError")}
        </Alert>
      ) : (
        <PrimaryLooversText text={t("common.pageNotFound")} />
      )}
      <Box
        p={4}
        alignItems={"center"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Text paddingBottom={"10px"}>{t("common.redirect")}</Text>
        <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor={mainTheme.colors.lightPurple}
          color={mainTheme.colors.darkPurple}
          size="md"
        />
      </Box>
    </Flex>
  );
});
