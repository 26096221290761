import {
  Box,
  Button,
  Flex,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  useToast,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { HeaderMio } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { useStores } from "../../hooks/UseStores";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FETCH_EVERY_PRODUCTS } from "../Admin/AdminProducts/graphql";
import { Product } from "../../entities/Product";
import { Table, Tr, Td } from "@chakra-ui/react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  AddProductReviewForm,
  ProductReviewFormType,
} from "../../components/CustomerDashboard/AddProductReview/AddProductReviewForm";
import {
  ADD_PRODUCT_REVIEW,
  DELETE_PRODUCT_REVIEW,
  FETCH_CUSTOMER_PRODUCT_REVIEWS,
  UPDATE_PRODUCT_REVIEW,
} from "./graphql";
import { ProductReview } from "../../entities/ProductReview";
import { ADD_CUSTOMER_LOOVERS_POINTS } from "../../services/graphql";
import { ProductBox } from "../../components/ProductBox";
import EmptyProductImage from "../../assets/images/empty-product.png";
import { Image } from "@chakra-ui/react";
import ReactStars from "react-rating-stars-component";
import { FaStar } from "react-icons/fa";

export const ProductsScreen = () => {
  const { t } = useTranslation();
  const { session } = useStores();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeProduct, setActiveProduct] = useState<Product | null>(null);
  const toast = useToast();
  const [fetchedReviews, setFetchedReviews] = useState<ProductReview[]>([]);

  const [fetchProducts, { data: productsData }] = useLazyQuery(
    FETCH_EVERY_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [fetchReviews, { data: reviewsData }] = useLazyQuery(
    FETCH_CUSTOMER_PRODUCT_REVIEWS,
    {
      onCompleted: () => {
        setFetchedReviews(reviewsData.customer.product_reviews);
      },
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchReviews({
      variables: {
        customer_id: session.customerUser?.id,
      },
    });
    fetchProducts();
  }, [fetchProducts, fetchReviews, session.customerUser?.id]);

  const handleOpenProduct = useCallback((product: any) => {
    setActiveProduct(product);
  }, []);

  useEffect(() => {
    if (activeProduct) onOpen();
  }, [activeProduct, onOpen]);

  const [addCustomerLooversPoints] = useMutation(ADD_CUSTOMER_LOOVERS_POINTS, {
    onCompleted: (data) => {
      session.mapCustomerUserData(data.addCustomerLooversPoints);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [addProductReview] = useMutation(ADD_PRODUCT_REVIEW, {
    onCompleted: (data) => {
      onClose();
      toast({
        status: "success",
        title: t("dashboard.reviewCreated"),
      });
      fetchReviews({
        variables: {
          customer_id: session.customerUser?.id,
        },
      });
      setActiveProduct(null);
      addCustomerLooversPoints({
        variables: {
          customerID: session.customerUser?.id,
          points_to_add: 50,
        },
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateProductReview] = useMutation(UPDATE_PRODUCT_REVIEW, {
    onCompleted: (data) => {
      onClose();
      toast({
        status: "success",
        title: t("dashboard.reviewUpdated"),
      });
      fetchReviews({
        variables: {
          customer_id: session.customerUser?.id,
        },
      });
      setActiveProduct(null);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [deleteReview] = useMutation(DELETE_PRODUCT_REVIEW, {
    onCompleted: () => {
      onClose();
      toast({
        status: "success",
        title: t("dashboard.reviewDeleted"),
      });
      fetchReviews({
        variables: {
          customer_id: session.customerUser?.id,
        },
      });
      setActiveProduct(null);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleSubmit = useCallback(
    (data: ProductReviewFormType) => {
      const avgRating = Math.round(
        (data.lookAndFeelRating +
          data.packagingRating +
          data.petOpinionRating +
          data.productFeaturesRating +
          data.totalRating) /
          5
      );

      if (data && data.id) {
        updateProductReview({
          variables: {
            id: data.id,
            title: data.title,
            comment: data.comment,
            date: new Date(data.date).toISOString(),
            image: data.image,
            customer_id: session.customerUser?.id,
            lookAndFeelRating: data.lookAndFeelRating,
            packagingRating: data.packagingRating,
            petOpinionRating: data.petOpinionRating,
            productFeaturesRating: data.productFeaturesRating,
            totalRating: data.totalRating,
            avgRating: avgRating,
            product_id: activeProduct?.id,
          },
        });
      } else {
        addProductReview({
          variables: {
            customer_id: session.customerUser?.id,
            product_id: activeProduct?.id,
            title: data.title,
            comment: data.comment,
            date: new Date(data.date).toISOString(),
            image: data.image,
            lookAndFeelRating: data.lookAndFeelRating,
            packagingRating: data.packagingRating,
            petOpinionRating: data.petOpinionRating,
            productFeaturesRating: data.productFeaturesRating,
            totalRating: data.totalRating,
            avgRating: avgRating,
          },
        });
      }

      onClose();
    },
    [
      activeProduct?.id,
      addProductReview,
      onClose,
      session.customerUser?.id,
      updateProductReview,
    ]
  );

  const handleDelete = useCallback(
    (data: ProductReviewFormType) => {
      if (data && data.id) {
        deleteReview({
          variables: {
            id: data.id,
            product_id: activeProduct?.id,
          },
        });
      }

      onClose();
    },
    [activeProduct, deleteReview, onClose]
  );

  const getActiveReview = (): ProductReview => {
    const productID = activeProduct?.id;

    for (let index = 0; index < fetchedReviews.length; index++) {
      const element: ProductReview = fetchedReviews[index];
      if (element.product && element.product.id === productID) {
        return element;
      }
    }

    return new ProductReview();
  };

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <HeaderMio
        selectedTab="products"
        editIcon={false}
        dark
        textAlign={"center"}
        customerUser={session.customerUser}
      />
      <Flex
        marginTop="40px"
        justify={["center", "center", "center", "flex-start"]}
        align={"center"}
      >
        {session.customerUser && (
          <Flex w="80%" maxW="960px" align={"center"} justify={"center"}>
            {/* <Text>Guadagna 50 punti per ogni recensione!</Text> */}
            <Flex
              w={"100%"}
              flexDirection={"row"}
              justify={["center", "center", "center", "flex-start"]}
              align={"center"}
              wrap={"wrap"}
            >
              {productsData &&
                productsData.products
                  .sort((p1: any, p2: any) => p1.id - p2.id)
                  .reverse()
                  .map((product: any) => (
                    <ProductBox
                      key={product.id}
                      product={product}
                      onClick={() => handleOpenProduct(product)}
                      hasReview={Boolean(
                        fetchedReviews.find(
                          (review) => review.product?.id === product.id
                        )
                      )}
                    />
                  ))}
            </Flex>

            {activeProduct && (
              <Modal
                onOverlayClick={() => {
                  onClose();
                  setActiveProduct(null);
                }}
                onEsc={() => {
                  onClose();
                  setActiveProduct(null);
                }}
                isOpen={isOpen}
                onClose={onClose}
                size={"lg"}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>{activeProduct.name}</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Stack>
                      <Image
                        src={
                          activeProduct.product_images &&
                          activeProduct.product_images.length > 0
                            ? JSON.parse(activeProduct.product_images).length >
                              0
                              ? JSON.parse(activeProduct.product_images)[0]
                              : EmptyProductImage
                            : EmptyProductImage
                        }
                        alt={activeProduct.name}
                        style={{
                          maxWidth: "215px",
                          maxHeight: "172px",
                          margin: "0 auto",
                        }}
                      />

                      <Text pt={"0.5rem"}>{activeProduct.description}</Text>

                      <Text as="h6" size="xs" fontWeight={500} pt="1rem">
                        Dettagli prodotto
                      </Text>
                      <Table size="sm" pb={"1rem"}>
                        {activeProduct.brand && (
                          <Tr>
                            <Td>Brand</Td>
                            <Td>
                              <Box
                                color="gray.500"
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                ml="2"
                              >
                                {activeProduct.brand.name}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                        {activeProduct.company && (
                          <Tr>
                            <Td>Company</Td>
                            <Td>
                              <Box
                                color="gray.500"
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                ml="2"
                              >
                                {activeProduct.company}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                        {activeProduct.ean_code && (
                          <Tr>
                            <Td>Codice EAN</Td>
                            <Td>
                              <Box
                                color="gray.500"
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                ml="2"
                              >
                                {activeProduct.ean_code}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                        {activeProduct.flavor && (
                          <Tr>
                            <Td>Gusto</Td>
                            <Td>
                              <Box
                                color="gray.500"
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                ml="2"
                              >
                                {activeProduct.flavor}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                        {activeProduct.country_of_production && (
                          <Tr>
                            <Td>Paese di produzione</Td>
                            <Td>
                              <Box
                                color="gray.500"
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                ml="2"
                              >
                                {activeProduct.country_of_production}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                        {activeProduct.ingredients_features && (
                          <Tr>
                            <Td>Caratteristiche/ingredienti</Td>
                            <Td>
                              <Box
                                color="gray.500"
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                ml="2"
                              >
                                {activeProduct.ingredients_features}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                        {activeProduct.available_formats && (
                          <Tr>
                            <Td>Formati disponibili</Td>
                            <Td>
                              <Box
                                color="gray.500"
                                fontWeight="semibold"
                                letterSpacing="wide"
                                fontSize="xs"
                                textTransform="uppercase"
                                ml="2"
                              >
                                {activeProduct.available_formats}
                              </Box>
                            </Td>
                          </Tr>
                        )}
                      </Table>

                      {activeProduct.ingredients_features && (
                        <Box pt="1rem">
                          <Text as="h6" size="xs" fontWeight={500}>
                            Caratteristiche/ingredienti
                          </Text>
                          <Text>{activeProduct.ingredients_features}</Text>
                        </Box>
                      )}

                      {activeProduct.greenFootprintComment && (
                        <Box pt="1rem">
                          <HStack justifyContent="space-between">
                            <Text as="h6" size="xs" fontWeight={500}>
                              Impronta verde
                            </Text>
                            <ReactStars
                              count={5}
                              value={activeProduct.greenFootprintRating}
                              size={"14px"}
                              isHalf={false}
                              emptyIcon={
                                <FaStar color={"grey"} size={"14px"} />
                              }
                              fullIcon={<FaStar color={"grey"} size={"14px"} />}
                              activeColor="#ffd700"
                              edit={false}
                            />
                          </HStack>
                          <Text>{activeProduct.greenFootprintComment}</Text>
                        </Box>
                      )}

                      <hr
                        style={{ marginTop: "1rem", paddingBottom: "1rem" }}
                      />

                      <Text style={{ fontWeight: 500, paddingBottom: "1rem" }}>
                        Recensione
                      </Text>

                      {session.customerUser &&
                        activeProduct &&
                        activeProduct.brand &&
                        activeProduct.special_needs && (
                          <AddProductReviewForm
                            review={getActiveReview()}
                            onSubmit={handleSubmit}
                            onDelete={handleDelete}
                            customerUser={session.customerUser}
                            brandName={activeProduct.brand.name}
                            specialNeedName={activeProduct.special_needs.name}
                          />
                        )}
                    </Stack>
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      onClick={() => {
                        onClose();
                        setActiveProduct(null);
                      }}
                    >
                      Chiudi
                    </Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </Flex>
        )}
      </Flex>

      <Footer dark />
    </Box>
  );
};
