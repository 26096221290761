import React, { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { EditStoreForm } from "../../components/PartnerDashboard/EditStoreForm";
import { PartnerDashbaordDataSection } from "../../components/PartnerDashboard/PartnerDashbaordDataSection";
import { PartnerHeader } from "../../components/PartnerHeader";
import { mainTheme } from "../../config/theme";
import { useStores } from "../../hooks/UseStores";
import { GET_STORE_BY_ID } from "../../services/graphql";
import {
  CREATE_STORE2_ADDRESS,
  DELETE_STORE_IMAGE,
  UPDATE_OPENING_TIME_STORE,
  UPDATE_STORE,
  UPDATE_STORE2_ADDRESS,
  UPDATE_STORE_AND_BRANDS_SERVICES,
  UPDATE_STORE_PHOTOS,
} from "./graphql";
import { Footer } from "../Footer/Footer";
import { OpeningTimeForm } from "../../components/PartnerSignUpForm/CompleteSignUpPartner/OpeningTimeForm";
import { StorePhotos } from "../../components/PartnerDashboard/StorePhotos";
import { Store_Service } from "../../entities/Store_Service";
import { Store_Brand } from "../../entities/Store_Brand";
import { ServicesBrandsForm } from "../../components/PartnerDashboard/ServicesBrandsForm/ServicesBrandsForm";
import { useMediaQuery } from "react-responsive";
import { StoreOpeningDaysObject } from "../../entities/PartnerUser";
import { Carousel } from "react-responsive-carousel";
import { FETCH_CAROUSELS } from "../Admin/AdminSetup/graphql";

export const PartnerDashboardScreen: React.FC = observer(() => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const { session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();
  const [openingTimeJson, setOpeningTimeJson] = useState<
    StoreOpeningDaysObject | undefined
  >();
  const partnerServices: string[] = session.partnerUser!.services.map(
    (ss: Store_Service) => {
      return ss.service.id.toString();
    }
  );
  const partnerBrands = session.partnerUser!.brands.map((sb: Store_Brand) => {
    return sb.brand.id.toString();
  });
  const [servicesIds, setServicesIds] = useState<string[]>(partnerServices);
  const [brandsIds, setBrandsIds] = useState<string[]>(partnerBrands);
  const [otherServiceOne, setOtherServiceOne] = useState<string>(
    session.partnerUser!.other_service_one
  );
  const [otherServiceTwo, setOtherServiceTwo] = useState<string>(
    session.partnerUser!.other_service_two
  );

  const [fetchCarousels, { data: carousesData }] = useLazyQuery(
    FETCH_CAROUSELS,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchCarousels();
  }, []);

  const [getPartnerById, { loading, data: partner }] = useLazyQuery(
    GET_STORE_BY_ID,
    {
      fetchPolicy: "no-cache",
      variables: {
        userId: session.partnerUser?.id,
      },
      onCompleted: () => {
        session.mapPartnerUserData(partner.store);
      },
      onError: (error) => {
        console.log("Error updating partner", error);
      },
    }
  );

  const [updateStore, { loading: mutationSubmitting }] = useMutation(
    UPDATE_STORE,
    {
      onCompleted: () => {
        getPartnerById();
        toast({
          status: "success",
          title: t("partnerdashboard.store.storeEdit1Success"),
        });
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [updateStore2Address, { loading: mutation2Submitting }] = useMutation(
    UPDATE_STORE2_ADDRESS,
    {
      onCompleted: () => {
        return true;
      },
      onError: (error) => {
        console.log(error);
        return false;
      },
    }
  );

  const [createStore2Address, { loading: mutation3Submitting }] = useMutation(
    CREATE_STORE2_ADDRESS,
    {
      onCompleted: () => {
        return true;
      },
      onError: (error) => {
        console.log(error);
        return false;
      },
    }
  );

  const editStore = useCallback(
    async (values) => {
      const {
        store_image,
        businessName,
        addressBusiness,
        addressBusinessCity,
        addressBusinessDistrict,
        addressBusinessCap,
        addressStore,
        addressStoreCity,
        addressStoreDistrict,
        addressStoreCap,
        addressStore2,
        addressStore2City,
        addressStore2District,
        addressStore2Cap,
        bannerName,
        mobilePhoneB2B,
        phoneB2B,
        vatNumber,
        taxCode,
        pec,
        sdi,
      } = values;

      if (addressStore2) {
        if (
          !!session.partnerUser?.addresses.find(
            (address) => address.type === "store2Address"
          )
        ) {
          await updateStore2Address({
            variables: {
              address_store2_id: parseInt(
                session.partnerUser?.addresses.find(
                  (address) => address.type === "store2Address"
                )!.addressId!
              ),
              address_store2: addressStore2,
              address_store2_city_id: parseInt(
                session.partnerUser?.addresses.find(
                  (address) => address.type === "store2Address"
                )!.cityId!
              ),
              address_store2_city: addressStore2City,
              address_store2_district: addressStore2District.value,
              address_store2_cap: addressStore2Cap,
            },
          });
        } else {
          createStore2Address({
            variables: {
              id: session.partnerUser?.id,
              address_store2: addressStore2,
              address_store2_city: addressStore2City,
              address_store2_district: addressStore2District.value,
              address_store2_cap: addressStore2Cap,
            },
          });
        }
      }

      updateStore({
        variables: {
          id: session.partnerUser?.id,
          store_image: store_image,
          business_name: businessName,
          address_business_id: parseInt(
            session.partnerUser?.addresses.find(
              (address) => address.type === "businessAddress"
            )!.addressId!
          ),
          address_business: addressBusiness,
          address_business_city_id: parseInt(
            session.partnerUser?.addresses.find(
              (address) => address.type === "businessAddress"
            )!.cityId!
          ),
          address_business_city: addressBusinessCity,
          address_business_district: addressBusinessDistrict.value,
          address_business_cap: addressBusinessCap,
          address_store_id: parseInt(
            session.partnerUser?.addresses.find(
              (address) => address.type === "storeAddress"
            )!.addressId!
          ),
          address_store: addressStore,
          address_store_city_id: parseInt(
            session.partnerUser?.addresses.find(
              (address) => address.type === "storeAddress"
            )!.cityId!
          ),
          address_store_city: addressStoreCity,
          address_store_district: addressStoreDistrict.value,
          address_store_cap: addressStoreCap,
          banner_name: bannerName,
          mobile_phone_b2b: mobilePhoneB2B,
          phone_b2b: phoneB2B,
          vat_number: vatNumber,
          tax_code: taxCode,
          pec,
          sdi,
        },
      });
    },
    [
      createStore2Address,
      session.partnerUser?.addresses,
      session.partnerUser?.id,
      updateStore,
      updateStore2Address,
    ]
  );

  const [updateStoreOpeningTime, { loading: openingTimeMutationSubmitting }] =
    useMutation(UPDATE_OPENING_TIME_STORE, {
      onCompleted: () => {
        getPartnerById();

        if (session.partnerUser && openingTimeJson) {
          const days = {
            monday: openingTimeJson.monday,
            tuesday: openingTimeJson.tuesday,
            wednesday: openingTimeJson.wednesday,
            thursday: openingTimeJson.thursday,
            friday: openingTimeJson.friday,
            saturday: openingTimeJson.saturday,
            sunday: openingTimeJson.sunday,
          };
          session.partnerUser.store_opening_days = days;
        }

        toast({
          status: "success",
          title: t("partnerdashboard.store.storeEdit1Success"),
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });

  const handleOpeningTimeOnClick = () => {
    updateStoreOpeningTime({
      variables: {
        id: session.partnerUser?.id,
        store_opening_days: JSON.stringify(openingTimeJson),
      },
    });
  };

  const [updateStorePhotosMutation] = useMutation(UPDATE_STORE_PHOTOS, {
    onCompleted: () => {
      getPartnerById();
      toast({
        status: "success",
        title: t("partnerdashboard.store.storeEdit1Success"),
      });
      window.location.reload();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const updateStorePhotos = (fileUrls: string[]) => {
    if (session.partnerUser) {
      const urls: string[] = [...session.partnerUser.store_photos, ...fileUrls];

      updateStorePhotosMutation({
        variables: {
          id: session.partnerUser.id,
          store_photos: JSON.stringify(urls),
        },
      });
    }
  };

  const onDeletePhoto = (fileUrl: string) => {
    if (session.partnerUser) {
      const storePhotos = session.partnerUser.store_photos;
      const urls = storePhotos.filter((e) => e !== fileUrl);

      console.log(urls);

      updateStorePhotosMutation({
        variables: {
          id: session.partnerUser.id,
          store_photos: JSON.stringify(urls),
        },
      });
    }
  };

  const [updateStoreServicesBrands, { loading: servicesMutationSubmitting }] =
    useMutation(UPDATE_STORE_AND_BRANDS_SERVICES, {
      onCompleted: (data) => {
        getPartnerById();

        toast({
          status: "success",
          title: t("partnerdashboard.store.storeEdit1Success"),
        });

        const dataSS: Store_Service[] =
          data.updateStoreAndBrandsServices.services;
        const dataSB: Store_Brand[] = data.updateStoreAndBrandsServices.brands;

        if (session.partnerUser) {
          const partnerS: string[] = dataSS.map((ss: Store_Service) => {
            return ss.service.id.toString();
          });

          session.partnerUser.services = dataSS;
          setServicesIds(partnerS);

          const partnerB: string[] = dataSB.map((sb: Store_Brand) => {
            return sb.brand.id.toString();
          });

          session.partnerUser.brands = dataSB;
          setBrandsIds(partnerB);
        }
      },
      onError: (error) => {
        console.log("updateStoreServicesBrands", error);
      },
    });

  const handleServicesBrandsFormOnClick = () => {
    if (session.partnerUser) {
      const brandsToDelete: Store_Brand[] = session.partnerUser.brands.filter(
        (sb: Store_Brand) => {
          let toDelete = true;

          brandsIds.forEach((id: string) => {
            if (id === sb.brand.id.toString()) toDelete = false;
          });

          return toDelete;
        }
      );

      const brandsIdsToDelete: string[] = [];
      for (const brandToDelete of brandsToDelete) {
        brandsIdsToDelete.push(brandToDelete.brand.id.toString());
      }

      const brandsIdsToAdd: string[] = brandsIds.filter((id: string) => {
        let toFilter = true;

        session.partnerUser!.brands.forEach((sb: Store_Brand) => {
          if (id === sb.brand.id.toString()) toFilter = false;
        });

        return toFilter;
      });

      updateStoreServicesBrands({
        variables: {
          id: session.partnerUser?.id,
          services_ids: JSON.stringify(servicesIds),
          brands_ids: JSON.stringify(brandsIdsToAdd),
          brands_ids_to_delete: JSON.stringify(brandsIdsToDelete),
          other_service_one: otherServiceOne,
          other_service_two: otherServiceTwo,
        },
      });
    }
  };

  const [deleteStoreImageMutation] = useMutation(DELETE_STORE_IMAGE, {
    onCompleted: () => {
      getPartnerById();
      toast({
        status: "success",
        title: t("partnerdashboard.store.storeEdit1Success"),
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleCarouselItemClick = useCallback(
    (index: number) => {
      if (carousesData) {
        const carousel = carousesData.carousels[index];

        window.open(carousel.link, "_blank");
      }
    },
    [carousesData]
  );

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <PartnerHeader dark session={session} selectedTab={"home"} />

      <VStack>
        <Flex
          flexDirection="column"
          w="100%"
          paddingX={isDesktop ? "4rem" : "1rem"}
        >
          <Box
            marginBottom="1rem"
            w="100%"
            h="1rem"
            bgColor={mainTheme.colors.primaryGreen}
          />

          <HStack width={"100%"} maxW={"900px"} paddingBottom={"1em"}>
            <Heading
              as="h3"
              fontSize={["lg", "xl"]}
              fontFamily={"Noe Display, Helvetica, Arial, sans-serif"}
            >
              {t("partnerdashboard.welcome")}
            </Heading>
            <Text fontSize={["lg", "xl"]}>
              {session.partnerUser?.businessName}
            </Text>
          </HStack>

          <Text
            alignSelf="flex-start"
            color={mainTheme.colors.textPrimary}
            fontSize={["sm", "md"]}
          >
            {t("partnerdashboard.dashboardInfo")}
          </Text>
        </Flex>

        <Flex
          flexDirection="row"
          w="100%"
          paddingX={isDesktop ? "4rem" : "1rem"}
          paddingBottom={isDesktop ? "2rem" : "0.5rem"}
        >
          <Stack spacing={"0px"} w={"100%"}>
            <Box
              // marginBottom="1rem"
              w="100%"
              h="2rem"
              bgColor={mainTheme.colors.primaryYellow}
              borderColor={mainTheme.colors.primaryGreen}
              borderWidth="1px"
            />
            <Tabs size="sm" variant="soft-rounded" isLazy>
              <TabList
                padding={"1rem"}
                backgroundColor={mainTheme.colors.darkPurple}
              >
                <Tab textColor="white">
                  {t("partnerdashboard.store.section1")}
                </Tab>
                <Tab textColor="white">
                  {t("partnerdashboard.store.section2")}
                </Tab>
                <Tab textColor="white">
                  {t("partnerdashboard.store.section3")}
                </Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <PartnerDashbaordDataSection />
                  <Stack marginTop={"1rem"}>
                    {carousesData && (
                      <Carousel
                        showThumbs={false}
                        onClickItem={handleCarouselItemClick}
                      >
                        {carousesData.carousels.map((carousel: any) => (
                          <div key={carousel.id}>
                            <img src={carousel.image} />
                            {/* <p className="legend">Legend 1</p> */}
                          </div>
                        ))}
                      </Carousel>
                    )}
                  </Stack>

                  <Flex
                    marginY="1rem"
                    w="100%"
                    bgColor={mainTheme.colors.primaryGreen}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                  >
                    <Text
                      marginLeft={"1.5rem"}
                      color={"#fff"}
                      fontSize={["md", "xl"]}
                    >
                      {t("partnerdashboard.youCanModifyYourInfoRightBelow")}
                    </Text>
                  </Flex>

                  {session.partnerUser && (
                    <StorePhotos
                      storePhotos={session.partnerUser.store_photos}
                      onStorePhotosUpdated={updateStorePhotos}
                      onDeletePhoto={onDeletePhoto}
                    />
                  )}
                  <Text
                    fontSize={["md", "xl"]}
                    marginBottom="1rem"
                    color={mainTheme.colors.textPrimary}
                  >
                    Utilizza questo form per verificare e tenere aggiornate le
                    informazioni generali del tuo negozio.
                  </Text>
                  <EditStoreForm
                    onDeleteStoreImage={() => {
                      deleteStoreImageMutation({
                        variables: { id: session.partnerUser?.id },
                      });
                    }}
                    onSubmit={editStore}
                    isSubmitting={
                      loading ||
                      mutationSubmitting ||
                      mutation2Submitting ||
                      mutation3Submitting
                    }
                    currentStore={session.partnerUser!}
                  />
                </TabPanel>
                <TabPanel>
                  <Text
                    fontSize={["md", "xl"]}
                    marginBottom="1rem"
                    color={mainTheme.colors.textPrimary}
                  >
                    {t("partnerdashboard.useThisFormToUpdateOpeningTime")}
                  </Text>
                  <VStack
                    spacing={"1rem"}
                    alignItems={"center"}
                    border={"1px solid lightgrey"}
                    p={4}
                    borderRadius={"2xl"}
                    boxShadow="md"
                  >
                    <OpeningTimeForm
                      isDashboard
                      store_opening_days={
                        session.partnerUser?.store_opening_days
                      }
                      setOpeningTimeJson={setOpeningTimeJson}
                    />

                    <Button
                      colorScheme={"blue"}
                      onClick={handleOpeningTimeOnClick}
                      disabled={openingTimeMutationSubmitting}
                    >
                      {t("common.confirm")}
                    </Button>
                  </VStack>
                </TabPanel>
                <TabPanel>
                  <Text
                    fontSize={["md", "xl"]}
                    marginBottom="1rem"
                    color={mainTheme.colors.textPrimary}
                  >
                    {t("partnerdashboard.useThisFormToUpdateServicesAndBrands")}
                  </Text>
                  <VStack
                    spacing={"1rem"}
                    alignItems={"center"}
                    border={"1px solid lightgrey"}
                    p={4}
                    borderRadius={"2xl"}
                    boxShadow="md"
                  >
                    {session.partnerUser && (
                      <ServicesBrandsForm
                        storeServices={session.partnerUser.services}
                        storeBrands={session.partnerUser.brands}
                        setServicesIds={setServicesIds}
                        setBrandsIds={setBrandsIds}
                        otherServiceOne={otherServiceOne}
                        setOtherServiceOne={setOtherServiceOne}
                        otherServiceTwo={otherServiceTwo}
                        setOtherServiceTwo={setOtherServiceTwo}
                      />
                    )}

                    <Button
                      colorScheme={"blue"}
                      onClick={handleServicesBrandsFormOnClick}
                      disabled={servicesMutationSubmitting}
                    >
                      {t("common.confirm")}
                    </Button>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        </Flex>
      </VStack>
      <Footer dark />
    </Box>
  );
});
