import Select from "react-select";
import { mainTheme } from "../../../config/theme";

export const colorStyles = {
  control: (styles: any) => ({
    ...styles,
    minHeight: "var(--chakra-sizes-10)",
    borderRadius: "var(--chakra-radii-md)",
    borderColor: "inherit",
    minWidth: "10rem",
    width: "100%",
  }),
  option: (
    styles: any,
    {
      data,
      isDisabled,
      isFocused,
      isSelected,
    }: {
      data: any;
      isDisabled: boolean;
      isFocused: boolean;
      isSelected: boolean;
    }
  ) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? "lightgray"
        : null,
      color: mainTheme.colors.backgroundDark,
    };
  },
};

export const MultiSelect = ({
  options,
  onChange,
  value,
}: {
  options: {
    value: string;
    label: string;
  }[];
  onChange: (values: any) => void;
  value?: {
    value: string;
    label: string;
  }[];
}) => {
  return (
    <Select
      defaultValue={[options[2], options[3]]}
      value={value}
      isMulti
      isSearchable
      name="colors"
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={colorStyles}
      onChange={onChange}
    />
  );
};
