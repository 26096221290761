import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Button,
  VStack,
  Text,
  Td,
  useToast,
  useDisclosure,
  HStack,
  Box,
  AspectRatio,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import QrReader from "react-qr-reader";
import { mainTheme } from "../../../config/theme";
import { Promo } from "../../../entities/Promo";
import { Promo_Code_use } from "../../../entities/Promo_Code_Use";
import { FETCH_CUSTOMER_AVAILABLE_PROMOS } from "../../../screens/CustomerDashboard/graphql";
import { ADD_PROMO_CODE_USE } from "../../../screens/PartnerPromotions/graphql";
import { UPDATE_USED_PROMO } from "../../../screens/PartnerScanQRCodeScreen/graphql";
import { GET_CUSTOMER_BY_ID } from "../../../services/graphql";
import { SecondaryLooversText } from "../../LooversText/LooversText";
import { FileUploadModal } from "../../FileUploadModal";
import { ViewReciept } from "./ViewReceipt";
import { PartnerUser } from "../../../entities/PartnerUser";

type Props = {
  partnerUser: PartnerUser;
}

export const QRCodeReaderModal: React.FC<Props> = ({partnerUser}) => {
  const { t } = useTranslation();
  const toast = useToast();

  // File Upload Modal vars
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [promoForFileUpload, setPromoForFileUpload] = useState<Promo_Code_use>(
    new Promo_Code_use()
  );

  const [isScanning, setIsScanning] = useState(false);

  const [fetchCustomer, { data: customerData, refetch: refetchFetchCustomer }] =
    useLazyQuery(GET_CUSTOMER_BY_ID, {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log("FETCH_PROMOS", error);
      },
    });

  const [fetchPromos, { data: promosData, refetch: refetchFetchPromo }] =
    useLazyQuery(FETCH_CUSTOMER_AVAILABLE_PROMOS, {
      fetchPolicy: "no-cache",
      onCompleted: () => {},
      onError: (error) => {
        console.log("FETCH_PROMOS", error);
      },
    });

  const handleScan = (data: string | null) => {
    if (data !== null) {
      setIsScanning(false);

      const customerId = Number(data);

      if (customerId) {
        fetchCustomer({
          variables: {
            userId: customerId,
          },
        });

        fetchPromos({
          variables: {
            customerId: customerId,
            includeNationalPromos: true,
          },
        });
      } else {
        window.alert("Tessera fedeltà non valida");
      }
    }
  };

  const handleError = (err: string) => {
    console.error("err", err);
  };

  const [addPromoCodeUse] = useMutation(ADD_PROMO_CODE_USE, {
    onCompleted: () => {
      refetchFetchPromo && refetchFetchPromo();
      refetchFetchCustomer && refetchFetchCustomer();
      toast({
        status: "success",
        title: t("qrCodeReaderModal.promoActivated"),
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        status: "error",
        title: t("common.error"),
      });
    },
  });

  const handleUsePromo = useCallback(
    (promo: Promo) => {
      addPromoCodeUse({
        variables: {
          promo_id: promo.id,
          customer_id: customerData.customer.id,
          used_date: new Date().toISOString(),
          store_id: partnerUser.id
        },
      });
    },
    [addPromoCodeUse, customerData, partnerUser]
  );

  const receiptBtn = (item: Promo) => {
    const up = customerData.customer.used_promo.find((used_promo: any) => {
      if (used_promo.promoId === item.id) return used_promo;
      return undefined;
    });

    return up === undefined ? (
      <Td></Td>
    ) : (
      <Td>
        {customerData.customer.used_promo.find(
          (used_promo: any) =>
            used_promo.promoId === item.id &&
            (used_promo.receipt_photo === null ||
              used_promo.receipt_photo === undefined ||
              used_promo.receipt_photo === "")
        ) ? (
          <Button
            colorScheme="teal"
            variant="outline"
            size="sm"
            onClick={() => {
              const pcu: Promo_Code_use = customerData.customer.used_promo.find(
                (used_promo: any) => used_promo.promoId === item.id
              );
              setPromoForFileUpload(pcu);
              onOpen();
            }}
          >
            {t("qrCodeReaderModal.upload")}
          </Button>
        ) : (
          <ViewReciept
            promoForFileUpload={promoForFileUpload}
            setPromoForFileUpload={setPromoForFileUpload}
            used_promo={customerData.customer.used_promo}
            item={item}
          />
        )}
      </Td>
    );
  };

  const [updateUsedPromoReceipt] = useMutation(UPDATE_USED_PROMO, {
    onCompleted: () => {
      onClose();
      // setMessageSuccess("Prodotto aggiornato correttamente!");
      // refetch && refetch();
    },
    onError: (error) => {
      console.log(error);
      // setMessageError("Si è verificato un errore.");
    },
  });

  const onlyActivePromo = useMemo(() => {
    let activePromos: Promo[] = promosData?.customerAvailablePromos || [];

    return activePromos.filter((promo) => {
      const vf = new Date(promo.valid_from);
      const vu = new Date(promo.valid_until);
      vf.setTime(vf.getTime() + vf.getTimezoneOffset() * 60 * 1000);
      vu.setTime(vu.getTime() + vu.getTimezoneOffset() * 60 * 1000);
      vf.setHours(0);
      vf.setMinutes(0);
      vf.setSeconds(0);
      vu.setHours(23);
      vu.setMinutes(59);
      vu.setSeconds(59);

      return (
        new Date().getTime() >= vf.getTime() &&
        new Date().getTime() <= vu.getTime()
      );
    });
  }, [promosData?.customerAvailablePromos]);

  return (
    <>
      <VStack w="100%" spacing="0px">
        <FileUploadModal
          isOpen={isOpen}
          onClose={onClose}
          maxFiles={1}
          onFileUploaded={async (fileUrls: string[]) => {
            if (fileUrls.length === 1) {
              await updateUsedPromoReceipt({
                variables: {
                  id: promoForFileUpload.id,
                  receipt_photo: fileUrls[0],
                },
              });

              refetchFetchPromo && refetchFetchPromo();
              refetchFetchCustomer && refetchFetchCustomer();
            }
          }}
        />
        <Box w="100%" bgColor={mainTheme.colors.primaryGreen}>
          <HStack
            margin={"2rem"}
            flexWrap="wrap"
            w="100%"
            align="center"
            justifyContent="space-between"
          >
            <VStack w="45%">
              <Text color={mainTheme.colors.textWhite} fontSize={["md", "lg"]}>
                Chiedi al cliente di mostrare la tessera fedeltà
              </Text>
              <Button
                isDisabled={isScanning}
                isLoading={isScanning}
                onClick={() => {
                  setIsScanning(true);
                }}
                colorScheme="teal"
                fontSize={["sm", "md", "lg"]}
              >
                {t("qrCodeReaderModal.scanLoyaltyCard")}
              </Button>
            </VStack>
            <Box w="45%">
              {isScanning ? (
                <QrReader
                  delay={300}
                  onError={handleError}
                  onScan={handleScan}
                  style={{
                    width: "50%",
                    maxWidth: "15rem",
                  }}
                />
              ) : (
                <AspectRatio width="50%" maxWidth="15rem" ratio={1}>
                  <Box width="50%" maxWidth="15rem" bgColor="gray" />
                </AspectRatio>
              )}
            </Box>
          </HStack>
        </Box>
        {customerData && customerData.customer && (
          <Box w="100%" bgColor={mainTheme.colors.lightPurple}>
            <VStack w="100%" margin="2rem">
              <Text
                alignSelf="flex-start"
                color={mainTheme.colors.textPrimary}
                fontSize={["md", "lg"]}
              >
                {"Dati cliente"}
              </Text>
              <HStack w="100%">
                <Text color={mainTheme.colors.textPrimary} fontSize="md">
                  {t("customer.firstName")}
                </Text>
                <Text color={mainTheme.colors.textPrimary} fontSize="xl">
                  {customerData.customer?.first_name}
                </Text>
              </HStack>

              <HStack w="100%">
                <Text color={mainTheme.colors.textPrimary} fontSize="md">
                  {t("customer.surname")}
                </Text>
                <Text color={mainTheme.colors.textPrimary} fontSize="xl">
                  {customerData.customer?.surname}
                </Text>
              </HStack>

              <HStack w="100%">
                <Text color={mainTheme.colors.textPrimary} fontSize="md">
                  {t("customer.email")}
                </Text>
                <Text color={mainTheme.colors.textPrimary} fontSize="xl">
                  {customerData.customer?.email}
                </Text>
              </HStack>
            </VStack>
          </Box>
        )}
        {onlyActivePromo.length === 0 && (
          <Box w="100%" bgColor={mainTheme.colors.lightPurple}>
            <VStack w="100%" margin="2rem">
              <SecondaryLooversText
                text={t("qrCodeReaderModal.noPromosAvailable")}
              />
            </VStack>
          </Box>
        )}
        {customerData && customerData.customer && onlyActivePromo.length > 0 && (
          <Box w="100%" bgColor={mainTheme.colors.primaryCyan}>
            <VStack w="100%" maxW="90%" margin="2rem">
              <Accordion w="100%" maxW="90%">
                {onlyActivePromo.map((item: Promo, index: number) => {
                  return (
                    <AccordionItem
                      backgroundColor={index % 2 === 0 ? "gray.100" : "white"}
                      key={item.id}
                    >
                      <h2>
                        <AccordionButton>
                          <Box flex="1" textAlign="left">
                            {item.email_title}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <VStack align="flex-start">
                          <Text>
                            {t("promoForm.id")}: {item.id}
                          </Text>
                          <Text>{item.email_title}</Text>
                          <Text>Fino al {item.valid_until}</Text>
                          <Text>
                            Perimetro:
                            {t(
                              `promoForm.usersPerimeter.${item.users_perimeter}`
                            )}
                          </Text>
                          <Text>
                            Nazionale:
                            {item.is_national
                              ? t("qrCodeReaderModal.yes")
                              : t("qrCodeReaderModal.no")}
                          </Text>
                          <Text>
                            Stato:
                            {customerData.customer.used_promo.find(
                              (used_promo: any) =>
                                used_promo.promoId === item.id
                            )
                              ? t("qrCodeReaderModal.notAvailable")
                              : t("qrCodeReaderModal.available")}
                          </Text>
                          <Text>
                            Usata il:
                            {customerData.customer.used_promo.find(
                              (used_promo: any) =>
                                used_promo.promoId === item.id
                            )?.used_date || "-"}
                          </Text>
                          {!customerData.customer.used_promo.find(
                            (used_promo: any) => used_promo.promoId === item.id
                          ) && (
                            <Button
                              colorScheme="teal"
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                handleUsePromo(item);
                              }}
                            >
                              {t("qrCodeReaderModal.use")}
                            </Button>
                          )}
                          {customerData.customer.used_promo.forEach(
                            (used_promo: any) => {
                              console.log(used_promo);
                              console.log(used_promo.receipt_photo);
                              console.log(
                                used_promo.promoId === item.id &&
                                  (used_promo.receipt_photo === null ||
                                    used_promo.receipt_photo === undefined ||
                                    used_promo.receipt_photo === "")
                              );
                            }
                          )}
                          {receiptBtn(item)}
                        </VStack>
                      </AccordionPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </VStack>
          </Box>
        )}
        )
      </VStack>
    </>
  );
};
