import React from "react";
import { default as ReactDatePicker } from "react-date-picker/dist/entry.nostyle";

import "./Calendar.css";
import { useTranslation } from "react-i18next";

const LightTheme = React.lazy(() => import("./DatePickerLight"));
const DarkTheme = React.lazy(() => import("./DatePickerDark"));

export interface Props {
  name: string | undefined;
  value: Date | Date[] | null | undefined;
  minDate?: Date;
  maxDate?: Date;
  onChange: any;
  disabled?: boolean;
  theme?: "light" | "dark";
  required?: boolean;
}

// https://projects.wojtekmaj.pl/react-date-picker/

export const DatePicker: React.FC<Props> = ({
  name,
  value,
  minDate,
  maxDate,
  onChange,
  disabled = false,
  theme = "light",
  required,
}) => {
  const { i18n } = useTranslation();

  return (
    <>
      <React.Suspense fallback={<></>}>
        {theme === "light" && <LightTheme />}
        {theme === "dark" && <DarkTheme />}
      </React.Suspense>
      <ReactDatePicker
        onChange={onChange}
        value={value}
        locale={i18n.language}
        maxDate={maxDate}
        minDate={minDate}
        dayPlaceholder={"gg"}
        monthPlaceholder={"mm"}
        yearPlaceholder={"aaaa"}
        disabled={disabled}
        required={required}
      />
    </>
  );
};
