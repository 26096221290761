import { useLazyQuery } from "@apollo/client";
import {
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Select,
  VStack,
} from "@chakra-ui/react";
import { Field } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SpecialNeed } from "../../entities/SpecialNeed";
import { FETCH_SPECIAL_NEEDS } from "../../screens/PartnerPromotions/graphql";

type Props = {
  productId: number | null;
  special_need_radio: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  users_perimeter: string;
};

export const ProductSpecialNeedsSelect = ({
  productId,
  special_need_radio,
  setFieldValue,
  users_perimeter,
}: Props) => {
  const { t } = useTranslation();

  const fieldName: string = "special_needs_id";

  const [special_need, setSpecial_need] = useState(special_need_radio);
  const [specialNeedsFilteredData, setSpecialNeedsFilteredData] = useState<
    SpecialNeed[]
  >([]);
  const [specialNeedsData, setSpecialNeedsData] = useState<SpecialNeed[]>([]);
  const [didFetch, setDidFetch] = useState(false);

  const [fetchSpecialNeeds, { data: dataFetchSpecialNeed }] = useLazyQuery(
    FETCH_SPECIAL_NEEDS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const specialNeeds: SpecialNeed[] =
          dataFetchSpecialNeed.specialNeeds.map((item: SpecialNeed) => {
            return {
              id: item.id,
              name: item.name,
              animal: item.animal,
              need_type: item.need_type,
            };
          });

        const filteredSpecialNeedsByName: Set<string> = new Set();
        const filteredSpecialNeeds: SpecialNeed[] = [];

        specialNeeds.forEach((item: SpecialNeed) => {
          if (item.need_type === special_need) {
            if (!filteredSpecialNeedsByName.has(item.name)) {
              if (item.name === "all") filteredSpecialNeeds.unshift(item);
              else filteredSpecialNeeds.push(item);
              filteredSpecialNeedsByName.add(item.name);
            }
          }
        });

        if (!productId) {
          setFieldValue(fieldName, Number(specialNeeds[0].id));
        }

        setSpecialNeedsData(specialNeeds);
        setSpecialNeedsFilteredData(filteredSpecialNeeds);
        setDidFetch(true);
      },
      onError: (error) => {
        console.log("SpecialNeedSelect", error);
      },
    }
  );

  const fetchAllSpecialNeedsByServiceId = useCallback(async () => {
    fetchSpecialNeeds();
  }, [fetchSpecialNeeds]);

  useEffect(() => {
    fetchAllSpecialNeedsByServiceId();
  }, [fetchAllSpecialNeedsByServiceId]);

  useEffect(() => {
    const filteredSpecialNeedsByName: Set<string> = new Set();
    const filteredSpecialNeeds: SpecialNeed[] = [];

    specialNeedsData.forEach((item: SpecialNeed) => {
      if (
        (users_perimeter === "all" || users_perimeter === item.animal) &&
        item.need_type === special_need
      ) {
        if (!filteredSpecialNeedsByName.has(item.name)) {
          if (item.name === "all") filteredSpecialNeeds.unshift(item);
          else filteredSpecialNeeds.push(item);
          filteredSpecialNeedsByName.add(item.name);
        }
      }
    });

    if (didFetch) {
      const arr = Array.from(filteredSpecialNeeds.values());
      setSpecialNeedsFilteredData(arr);

      if (arr.length > 0) setFieldValue(fieldName, Number(arr[0].id));
    }
  }, [
    users_perimeter,
    special_need,
    specialNeedsData,
    didFetch,
    setFieldValue,
  ]);

  return (
    <Field name={"special_needs_id"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={meta.description && meta.touched} as="fieldset">
          <FormLabel>{t("promoForm.specialNeeds")}</FormLabel>
          <VStack align="flex-start">
            <RadioGroup
              name={"needTypeValue"}
              value={special_need}
              onChange={(value) => {
                setFieldValue("special_need_radio", value);
                setSpecial_need(value);
              }}
            >
              <HStack wrap="wrap" spacing="2rem">
                <Radio value={"all"}>{t("promoForm.all")}</Radio>
                <Radio value={"fisiologico"}>{"Fisiologico"}</Radio>
                <Radio value={"dietetico"}>{"Dietetico"}</Radio>
              </HStack>
            </RadioGroup>

            {special_need !== "all" && (
              <Select
                name={fieldName}
                onChange={(event) => {
                  setFieldValue(fieldName, Number(event.target.value));
                }}
                value={field.value.toString()}
              >
                {specialNeedsFilteredData.map((item: SpecialNeed) => {
                  return (
                    <option
                      key={item.id.toString()}
                      id={item.id.toString()}
                      value={item.id.toString()}
                    >
                      {item.name === "all"
                        ? t("promoForm.all")
                        : item.name[0].toUpperCase() + item.name.substring(1)}
                    </option>
                  );
                })}
              </Select>
            )}
          </VStack>
        </FormControl>
      )}
    </Field>
  );
};
