import { useLazyQuery } from "@apollo/client";
import {
  Box,
  useMediaQuery,
  Stack,
  VStack,
  HStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import gql from "graphql-tag";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { mainTheme } from "../../../config/theme";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";

const FETCH_LOYALTY_POINTS = gql`
  query FetchLoyaltyPoints {
    loyalityActions {
      id
      name
      points
    }
  }
`;

export const PointsGrid = () => {
  const { t } = useTranslation();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");

  const [fetchLoyaltyPoints, { loading: isFetching, data }] = useLazyQuery(
    FETCH_LOYALTY_POINTS,
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchLoyaltyPoints();
  }, []);

  return (
    <Stack
      flexDir={"row"}
      flexWrap={"wrap"}
      w="100%"
      padding={isLargerThan768 ? "4rem" : undefined}
      paddingY={isLargerThan768 ? undefined : "4rem"}
      backgroundColor={mainTheme.colors.primaryGreen}
      justify={isLargerThan768 ? "space-between" : "center"}
      spacing={isLargerThan768 ? "0px" : "4rem"}
    >
      <VStack
        maxW={isLargerThan768 ? "900px" : "900px"}
        w={isLargerThan768 ? "45%" : "80%"}
        backgroundColor={mainTheme.colors.primaryGreen}
      >
        <Text
          color={mainTheme.colors.textWhite}
          fontWeight={"bold"}
          fontSize={["md", "xl", "2xl"]}
          fontFamily={"Proxima Nova"}
          textColor={mainTheme.colors.textWhite}
          alignSelf="flex-start"
          textAlign="center"
        >
          {t("dashboard.pointsGrid.howToGetPoints")}
        </Text>

        <HStack spacing={"0px"} w="100%">
          <Table size="lg" fontFamily={"Proxima Nova"}>
            <Thead>
              <Th
                padding={"1rem"}
                h="62px"
                backgroundColor={mainTheme.colors.primaryCyan}
                justify={"center"}
                align={"center"}
                color={mainTheme.colors.textWhite}
                fontWeight={"bold"}
                textAlign="left"
                border="0px"
                fontSize={["s", "md", "xl"]}
              >
                PUNTI
              </Th>
              <Th
                padding={"1rem"}
                h="62px"
                backgroundColor={mainTheme.colors.primaryCyan}
                justify={"center"}
                align={"center"}
                color={mainTheme.colors.textWhite}
                fontWeight={"bold"}
                textAlign="left"
                border="0px"
                fontSize={["s", "md", "xl"]}
              >
                AZIONI
              </Th>
            </Thead>
            <Tbody>
              {data &&
                data.loyalityActions &&
                data.loyalityActions.map((action: any, index: number) => (
                  <Tr
                    key={action.id}
                    backgroundColor={index % 2 === 0 ? "#F2F2F2" : "#FFFFFF"}
                  >
                    <Td
                      border="0px"
                      color={"#6C7796"}
                      fontSize={["s", "md", "xl"]}
                    >
                      {action.points}
                    </Td>
                    <Td
                      border="0px"
                      color={"#6C7796"}
                      fontSize={["s", "md", "xl"]}
                    >
                      {t(`loyalityActions.${action.name}`)}
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </HStack>
      </VStack>

      <VStack
        maxW={isLargerThan768 ? "900px" : "900px"}
        w={isLargerThan768 ? "45%" : "80%"}
        backgroundColor={mainTheme.colors.primaryGreen}
      >
        <Text
          color={mainTheme.colors.textWhite}
          fontWeight={"bold"}
          fontSize={["md", "xl", "2xl"]}
          fontFamily={"Proxima Nova"}
          textColor={mainTheme.colors.textWhite}
          alignSelf="flex-start"
        >
          {t("dashboard.pointsGrid.premi")}
        </Text>

        <HStack spacing={"0px"} w="100%" align="flex-start">
          <VStack
            spacing={"0px"}
            w={isLargerThan768 ? "35%" : undefined}
            justify={"center"}
          >
            <Flex
              w="100%"
              padding={"1rem"}
              h="62px"
              backgroundColor={mainTheme.colors.darkPurple}
              justify={"center"}
              align={"center"}
            >
              <Text
                w="100%"
                h="100%"
                color={mainTheme.colors.textWhite}
                fontWeight={"bold"}
                fontSize={["s", "md", "xl"]}
                fontFamily={"Proxima Nova"}
                textAlign="left"
              >
                PUNTI
              </Text>
            </Flex>
            <Box
              w="100%"
              h={
                isLargerThan768 ? (isLargerThan1280 ? "62px" : "86px") : "132px"
              }
              backgroundColor={"#F2F2F2"}
              justify={"center"}
              padding={"1rem"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                1000 punti
              </Text>
            </Box>
            <Box
              w="100%"
              h={isLargerThan768 ? "62px" : "86px"}
              padding={"1rem"}
              backgroundColor={"#FFFFFF"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                1700 punti
              </Text>
            </Box>
            <Box
              w="100%"
              h={isLargerThan768 ? "62px" : "86px"}
              padding={"1rem"}
              backgroundColor={"#F2F2F2"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                2500 punti
              </Text>
            </Box>
            <Box
              w="100%"
              h={isLargerThan768 ? "62px" : "102px"}
              padding={"1rem"}
              backgroundColor={"#FFFFFF"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                3500 punti
              </Text>
            </Box>
            <Box
              w="100%"
              h={isLargerThan768 ? "102px" : "202px"}
              padding={"1rem"}
              backgroundColor={"#F2F2F2"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                5000 punti
              </Text>
            </Box>
          </VStack>
          <VStack spacing={"0px"} w={isLargerThan768 ? "65%" : undefined}>
            <Box
              w="100%"
              padding={"1rem"}
              backgroundColor={mainTheme.colors.darkPurple}
              h="62px"
            >
              <Text
                fontFamily={"Proxima Nova"}
                color={mainTheme.colors.textWhite}
                fontWeight={"bold"}
                fontSize={["s", "md", "xl"]}
              >
                PREMI
              </Text>
            </Box>
            <Box
              h={
                isLargerThan768 ? (isLargerThan1280 ? "62px" : "86px") : "132px"
              }
              w="100%"
              padding={"1rem"}
              backgroundColor={"#F2F2F2"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                sconto del 10% su acquisto in store
              </Text>
            </Box>
            <Box
              h={isLargerThan768 ? "62px" : "86px"}
              w="100%"
              padding={"1rem"}
              backgroundColor={"#FFFFFF"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                sconto del 30%
              </Text>
            </Box>
            <Box
              w="100%"
              h={isLargerThan768 ? "62px" : "86px"}
              padding={"1rem"}
              backgroundColor={"#F2F2F2"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                copertina Loovers
              </Text>
            </Box>
            <Box
              w="100%"
              h={isLargerThan768 ? "62px" : "102px"}
              padding={"1rem"}
              backgroundColor={"#FFFFFF"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                borraccia portatile Loovers
              </Text>
            </Box>
            <Box
              h={isLargerThan768 ? "102px" : "202px"}
              w="100%"
              padding={"1rem"}
              backgroundColor={"#F2F2F2"}
            >
              <Text
                color={"#6C7796"}
                fontFamily={"Proxima Nova"}
                fontSize={["s", "md", "xl"]}
              >
                set da viaggio Loovers (ciotola - sacchetti - porta sacchetti -
                copertina)
              </Text>
            </Box>
          </VStack>
        </HStack>
      </VStack>
    </Stack>
  );
};
