import { Activity } from "./Activity";
import { Brand } from "./Brand";
import { PartnerUser } from "./PartnerUser";
import { PromoBreed } from "./PromoBreed";
import { Promo_Code_use } from "./Promo_Code_Use";
import { Service } from "./Service";
import { SpecialNeed } from "./SpecialNeed";

export enum ActivityType {
  valueDiscountCoupon = "valueDiscountCoupon",
  percentageDiscountCoupon = "percentageDiscountCoupon",
  freeProductCoupon = "freeProductCoupon",
  promotionalDayCoupon = "promotionalDayCoupon",
}

export enum InterestedBrand {
  all = "all",
  all_brands = "all_brands",
  all_services = "all_services",
  single_brand = "single_brand",
  single_service = "single_service",
}

export enum UsersPerimeter {
  all = "all",
  dog = "dog",
  cat = "cat",
  smallAnimal = "smallAnimal",
}

export enum Genders {
  all = "all",
  male = "male",
  female = "female",
}

export enum Segments {
  tutti = "all",
  alimentoSecco = "Alimento secco",
  alimentoUmido = "Alimento umido",
  snack = "Snack",
  lettiera = "Lettiera",
  accessoriConfort = "Accessori Confort",
  accessoriIgene = "Accessori Igiene",
  antiparassitari = "Antiparassitari",
  giochi = "Giochi",
}

export enum Grooming {
  normalBath,
  medicatedBath,
  ozoneTreatment,
  pesticideBath,
  commercialCut,
  scissorsCut,
  nailTrimming,
  stripping,
}

export enum Vet {
  generalCheckUp,
  completeCheckUp,
  deworming,
  dogAndCatFirstSteps,
  geriatricCheckUp,
  microchip,
  neutering,
  leishmaniaTest,
  filariasis,
  annualVaccineBoostersAndTests,
  insurance,
}

/**
 * @param {number} id Buono sconto a valore, a percentuale, ecc.
 * @param {ActivityType} activity_type Buono sconto a valore, a percentuale, ecc.. Passare stringa al DB
 * @param {InterestedBrand} interested_brand All, singolo brand, servizi. Passare stringa al DB
 * @param {number} activity_id FK id: Bagno classico, bagno medicato, microchip
 * @param {string} email_title Titolo/Oggetto email
 * @param {string} description Descrizione promo
 * @param {number} promo_type 5€ or 27%. If the user wanna gift a product, check the single_brand_id
 * @param {string} users_perimeter All, dog, cat, small animal
 * @param {number} special_needs_id Foreign Key
 * @param {string} _special_need All, fisiologico, dietetico
 */
export class Promo {
  private _id: number;
  private _activity_type: ActivityType;
  private _interested_brand: InterestedBrand;
  private _activity_id: number;
  private _single_brand_id: number;
  private _valid_from: Date;
  private _valid_until: Date;
  private _email_title: string;
  private _description: string;
  private _promo_type: number;
  private _users_perimeter: string;
  private _special_needs_id: number;
  private _is_service: boolean;
  private _promo_breed: PromoBreed;
  private _dog_size: string;
  private _promo_breed_id: number;
  private _activity: Activity;
  private _brand: Brand;
  private _special_needs: SpecialNeed;
  private _size: string;
  private _store: PartnerUser = new PartnerUser();
  private _promo_uses: Promo_Code_use[] = [];
  private _is_national: boolean = false;
  private _special_need: string = "all";
  private _segment: string;
  private _gender: string;
  private _districts: string;
  private _customer_type: string;

  constructor(breed: PromoBreed) {
    this._id = -1;
    this._valid_from = new Date();
    this._valid_until = new Date();
    this._activity_type = ActivityType.valueDiscountCoupon;
    this._interested_brand = InterestedBrand.all;
    this._description = "";
    this._activity_id = -1;
    this._single_brand_id = -1;
    this._email_title = "";
    this._promo_type = 0;
    this._users_perimeter = "";
    this._special_needs_id = -1;
    this._is_service = false;
    this._promo_breed = breed;
    this._dog_size = breed.dog_size;
    this._promo_breed_id = -1;
    this._activity = new Activity(-1, "", -1, new Service(-1, ""));
    this._brand = new Brand(-1, "");
    this._special_needs = new SpecialNeed(-1, "", "", "");
    this._size = "";
    this._segment = "";
    this._gender = "";
    this._districts = "";
    this._customer_type = "";
  }

  public get id(): number {
    return this._id;
  }

  public set id(value: number) {
    this._id = value;
  }

  public get activity_type(): string {
    return this._activity_type;
  }

  public get interested_brand(): InterestedBrand {
    return this._interested_brand;
  }

  public get activity_id(): number {
    return this._activity_id;
  }

  public get single_brand_id(): number {
    return this._single_brand_id;
  }

  public get valid_from(): Date {
    return this._valid_from;
  }

  public get valid_until(): Date {
    return this._valid_until;
  }

  public get email_title(): string {
    return this._email_title;
  }

  public get description(): string {
    return this._description;
  }

  public get promo_type(): number {
    return this._promo_type;
  }

  public get users_perimeter(): string {
    return this._users_perimeter;
  }

  public get special_needs_id(): number {
    return this._special_needs_id;
  }

  public get is_service(): boolean {
    return this._is_service;
  }

  public get promo_breed(): PromoBreed {
    return this._promo_breed;
  }

  public get dog_size() {
    return this._dog_size;
  }

  public get promo_breed_id() {
    return this._promo_breed_id;
  }

  public get activity(): Activity {
    return this._activity;
  }

  public get brand(): Brand {
    return this._brand;
  }

  public get special_needs(): SpecialNeed {
    return this._special_needs;
  }

  public get size(): string {
    return this._size;
  }

  public get store(): PartnerUser {
    return this._store;
  }

  public get promo_uses(): Promo_Code_use[] {
    return this._promo_uses;
  }

  public get is_national(): boolean {
    return this._is_national;
  }

  public get special_need(): string {
    return this._special_need;
  }

  public get segment(): string {
    return this._segment;
  }

  public get gender(): string {
    return this._gender;
  }

  public get districts(): string {
    return this._districts;
  }

  public get customer_type(): string {
    return this._customer_type;
  }
}
