import { Input as CFormInput } from "@chakra-ui/input";
import styled, { css } from "styled-components";

export const FormInput = styled(CFormInput)<{ signUpForm?: boolean }>`
  border-radius: 8px 8px 8px 8px !important;

  ${(props) => {
    return props.$signUpForm
      ? css`
          border-width: 0px 0px 0px 0px !important;
          background-color: ${(props) =>
            props.theme.colors.background} !important;
          color: ${(props) => props.theme.colors.textPrimary} !important;
        `
      : css`
          background-color: initial;
        `;
  }};
`;
