import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { FETCH_BRANDS } from "../../screens/PartnerSignUp/CompleteSignUpPartner/graphql";
import { MultiSelect } from "../PartnerSignUpForm/CompleteSignUpPartner/MultiSelect";

export const BrandsField = ({
  field,
  setFieldValue,
}: {
  field: any;
  setFieldValue: any;
}) => {
  const [brandsData, setBrandsData] = useState<
    { value: string; label: string }[]
  >([]);
  const [selectValue, setSelectValue] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const [fetchBrands, { data }] = useLazyQuery(FETCH_BRANDS, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      const brands: { value: string; label: string }[] = data.brands.map(
        (item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );

      setBrandsData(brands);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const handleOnChange = (values: any) => {
    if (values.length <= 3) {
      let ids = values.map((item: { value: string; label: string }) =>
        Number(item.value)
      );

      setFieldValue(field.name, ids);
      setSelectValue(values);
    }
  };

  return (
    <MultiSelect
      value={selectValue}
      options={brandsData}
      onChange={handleOnChange}
    />
  );
};
