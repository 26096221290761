import React, { useCallback } from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Link, Text } from "@chakra-ui/layout";
import { LostPasswordForm } from "../../components/LostPasswordForm";
import { useStores } from "../../hooks/UseStores";
import { Heading, Stack, useMediaQuery, useToast } from "@chakra-ui/react";
import { Header } from "../../components/Header";
import { useMutation } from "@apollo/client";
import { LOST_PASSWORD } from "../../services/graphql";

export const LostPasswordScreen: React.FC = observer(() => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { session } = useStores();
  const { t } = useTranslation();
  const toast = useToast();

  const lostPasswordSubmitCompleted = useCallback(() => {
    toast({
      status: "success",
      title: t("auth.recoveryLinkSuccess"),
    });
  }, [t, toast]);

  const [lostPasswordSubmit, { loading: mutationSubmitting }] = useMutation(
    LOST_PASSWORD,
    {
      onCompleted: lostPasswordSubmitCompleted,
      onError: (error) => {
        console.log(error);
        toast({
          status: "error",
          title: t("common.error"),
        });
      },
    }
  );

  const handleSubmit = useCallback(
    (email: string) => {
      lostPasswordSubmit({ variables: { email } });
    },
    [lostPasswordSubmit]
  );

  if (session.isLogged()) {
    return <Redirect to={"/"} />;
  }

  return (
    <Stack w={"100%"}>
      <Header dark />
      <Stack alignItems={"center"}>
        <Stack
          width={"100%"}
          maxW={"420px"}
          marginTop={"2rem"}
          style={{
            padding: isLargerThan768 ? undefined : "2rem",
            paddingTop: "0px",
          }}
        >
          <Heading as="h4" size="md" marginBottom={"1rem"}>
            {t("auth.forgotPassword")}
          </Heading>
          <LostPasswordForm
            isLoading={mutationSubmitting}
            onSubmit={handleSubmit}
          />

          <Text style={{ marginTop: "2rem", textAlign: "center" }}>
            <Link color="teal.500" href={"#/login"}>
              {t("auth.backToLogin")}
            </Link>
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
});
