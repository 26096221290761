import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Textarea,
} from "@chakra-ui/react";
import { Field } from "formik";

export const ProductIngredientesFeatures = () => {
  return (
    <Field id={"ingredients_features"} name={"ingredients_features"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor="ingredients_features">
            {"Caratteristiche/ingredienti"}
          </FormLabel>
          <Textarea
            size={"sm"}
            name={field.name}
            value={field.value}
            onChange={(event) => setFieldValue(field.name, event.target.value)}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
