export enum DogSize {
  All='All',
  Mini = "Mini",
  Medium = "Medium",
  Maxi = "Maxi",
  None = "None",
}

export class PromoBreed {
  private _id: number;
  private _name: string;
  private _animal: string;
  private _dog_size: string;

  constructor(id: number, name: string, animal: string, dog_size: string) {
    this._id = id;
    this._name = name;
    this._animal = animal;
    this._dog_size = dog_size;
  }

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get animal() {
    return this._animal;
  }

  public get dog_size() {
    return this._dog_size;
  }
}
