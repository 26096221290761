import React from "react";
import { Text } from "@chakra-ui/layout";
import { ResponsiveValue } from "@chakra-ui/react";

interface Props {
  text: string;
  textAlign?: ResponsiveValue<any> | undefined; // sostituire any con Property.TextAlign
  alignSelf?: ResponsiveValue<any> | undefined;
  padding?: ResponsiveValue<any> | undefined;
  textColor?: ResponsiveValue<any> | undefined;
}

export const PrimaryLooversText: React.FC<Props> = ({
  text,
  textAlign,
  alignSelf,
  padding,
  textColor,
}) => {
  return (
    <Text
      marginTop={4}
      textAlign={textAlign}
      alignSelf={alignSelf}
      padding={padding}
      textColor={textColor}
    >
      {text}
    </Text>
  );
};

export const SecondaryLooversFontSize = {
  base: "1.4rem",
  md: "1.4rem",
  lg: "1.5rem",
  xl: "1.6em",
};

export const SecondaryLooversText: React.FC<Props> = ({
  text,
  textAlign,
  alignSelf,
  padding,
  textColor,
}) => {
  return (
    <Text
      fontSize={SecondaryLooversFontSize}
      textAlign={textAlign}
      alignSelf={alignSelf}
      padding={padding}
      textColor={textColor}
    >
      {text}
    </Text>
  );
};

export const ThirdLooversFontSize = {
  base: "1.1rem",
  md: "1.1rem",
  lg: "1.2rem",
  xl: "1.4em",
};

export const ThirdLooversText: React.FC<Props> = ({
  text,
  textAlign,
  alignSelf,
  padding,
  textColor,
}) => {
  return (
    <Text
      fontSize={ThirdLooversFontSize}
      textAlign={textAlign}
      alignSelf={alignSelf}
      padding={padding}
      textColor={textColor}
    >
      {text}
    </Text>
  );
};

export const FourthLooversFontSize = {
  base: "0.7rem",
  md: "0.7rem",
  lg: "0.8rem",
  xl: "1em",
};

export const FourthLooversText: React.FC<Props> = ({
  text,
  textAlign,
  alignSelf,
  padding,
  textColor,
}) => {
  return (
    <Text
      fontSize={FourthLooversFontSize}
      textAlign={textAlign}
      alignSelf={alignSelf}
      padding={padding}
      textColor={textColor}
    >
      {text}
    </Text>
  );
};
