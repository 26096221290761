import React, { useCallback } from "react";

import LogoWhite from "../../assets/images/logo_bianco.png";
import Logo from "../../assets/images/logo.png";
import { HeaderLogo } from "./styled";
import { VStack, HStack, Link, Spacer, useMediaQuery } from "@chakra-ui/react";
import { Session } from "../../store/Session";
import { useHistory } from "react-router-dom";

interface LinkProps {
  url: string;
  currentSelectedTab: string;
  linkText: string;
  tabName: string;
  onClick?: () => void;
}

const ParterDashboardLink: React.FC<LinkProps> = ({
  url,
  currentSelectedTab,
  linkText,
  tabName,
  onClick,
}) => {
  return (
    <Link
      href={url}
      fontWeight={currentSelectedTab === tabName ? "bold" : "normal"}
      onClick={onClick}
    >
      {linkText}
    </Link>
  );
};

interface Props {
  dark?: boolean;
  session: Session;
  selectedTab: string;
}

export const PartnerHeader: React.FC<Props> = ({
  dark = false,
  session,
  selectedTab,
}) => {
  const history = useHistory();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  const logout = useCallback(() => {
    session.logout();
    history.push("/login");
    window.location.reload();
  }, [history, session]);

  return isLargerThan768 ? (
    <HStack
      style={{
        zIndex: 1,
        padding: "2.8rem 2rem",
        height: "5.2rem",
        paddingRight: "4rem",
      }}
    >
      <a
        style={{ display: "table-cell" }}
        href="https://loovers.eu/"
        target="_blank"
        rel="noreferrer"
      >
        <HeaderLogo
          src={dark ? Logo : LogoWhite}
          alt={"logo"}
          style={{ marginRight: "2rem" }}
        />
      </a>
      <ParterDashboardLink
        url={"#/partner/dashboard"}
        linkText={"Home"}
        currentSelectedTab={selectedTab}
        tabName={"home"}
      />
      <ParterDashboardLink
        url={"#/partner/promotions"}
        linkText={"Promozioni"}
        currentSelectedTab={selectedTab}
        tabName={"promo"}
      />
      <ParterDashboardLink
        url={"#/partner/scanqrcode"}
        linkText={"Scan QR Code"}
        currentSelectedTab={selectedTab}
        tabName={"qrCodeReader"}
      />
      <a
        style={{ display: "table-cell" }}
        href="https://loovers.eu/it/contattaci/"
        target="_blank"
        rel="noreferrer"
      >
        Assistenza
      </a>
      <Spacer />
      <ParterDashboardLink
        url={"#/partner/subscription"}
        linkText={"Abbonamento"}
        currentSelectedTab={selectedTab}
        tabName={"subscription"}
      />
      <ParterDashboardLink
        url={""}
        linkText={"Logout"}
        currentSelectedTab={selectedTab}
        tabName={"logout"}
        onClick={logout}
      />
    </HStack>
  ) : (
    <VStack
      style={{
        zIndex: 1,
        padding: "2.8rem 2.8rem",
      }}
    >
      <a
        style={{ display: "table-cell" }}
        href="https://loovers.eu/"
        target="_blank"
        rel="noreferrer"
      >
        <HeaderLogo
          src={dark ? Logo : LogoWhite}
          alt={"logo"}
          style={{ marginRight: "2rem" }}
        />
      </a>
      <ParterDashboardLink
        url={"#/partner/dashboard"}
        linkText={"Home"}
        currentSelectedTab={selectedTab}
        tabName={"home"}
      />
      <ParterDashboardLink
        url={"#/partner/promotions"}
        linkText={"Promozioni"}
        currentSelectedTab={selectedTab}
        tabName={"promo"}
      />
      <ParterDashboardLink
        url={"#/partner/scanqrcode"}
        linkText={"Scan QR Code"}
        currentSelectedTab={selectedTab}
        tabName={"qrCodeReader"}
      />
      <a
        style={{ display: "table-cell" }}
        href="https://loovers.eu/it/contattaci/"
        target="_blank"
        rel="noreferrer"
      >
        Assistenza
      </a>
      <Spacer />
      <ParterDashboardLink
        url={"#/partner/subscription"}
        linkText={"Abbonamento"}
        currentSelectedTab={selectedTab}
        tabName={"subscription"}
      />
      <ParterDashboardLink
        url={""}
        linkText={"Logout"}
        currentSelectedTab={selectedTab}
        tabName={"logout"}
        onClick={logout}
      />
    </VStack>
  );
};
