import React, { useCallback, useState } from "react";
import {
  Flex,
  Text,
  Button,
  useMediaQuery,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  HStack,
  useToast,
  Stack,
  Box,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { mainTheme } from "../../config/theme";
import { CustomerUser } from "../../entities/CustomerUser";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_STORE_CODE } from "../../screens/CustomerDashboard/graphql";
import { observer } from "mobx-react";
import { FavoriteStore } from "../../entities/FavoriteStore";
import { Store_Address } from "../../entities/Store_Address";
import { CustomerStoreReviewHandler } from "./CustomerStoreReviewHandler/CustomerStoreReviewHandler";

export const StoreSection: React.FC<{ customerUser: CustomerUser }> = ({
  customerUser,
}) => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [favoriteStore, setFavoriteStore] = useState<FavoriteStore>(
    customerUser.favorite_store
  );

  console.log(customerUser);

  return isLargerThan768 ? (
    <DesktopStoreSection
      customerUser={customerUser}
      favoriteStore={favoriteStore}
      setFavoriteStore={setFavoriteStore}
    />
  ) : (
    <MobileStoreSection
      customerUser={customerUser}
      favoriteStore={favoriteStore}
      setFavoriteStore={setFavoriteStore}
    />
  );
};

const MobileStoreSection: React.FC<{
  customerUser: CustomerUser;
  favoriteStore: FavoriteStore;
  setFavoriteStore: React.Dispatch<React.SetStateAction<FavoriteStore>>;
}> = ({ customerUser, favoriteStore, setFavoriteStore }) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      maxW="100%"
      bgColor={mainTheme.colors.darkPurple}
      paddingX={"1rem"}
      paddingY={"0.5rem"}
    >
      <Flex flex="1" align="center" flexDirection="column">
        <Text
          fontSize={["md", "xl"]}
          marginBottom="1rem"
          color={mainTheme.colors.textWhite}
        >
          {t("dashboard.store")}
        </Text>
        {customerUser.favorite_store && customerUser.favorite_store.store_type && (
          <Text
            fontSize={["md", "xl"]}
            marginBottom="1rem"
            color={mainTheme.colors.primaryYellow}
            textAlign={"center"}
          >
            {t(`partner.storeTypes.${customerUser.favorite_store.store_type}`)}
          </Text>
        )}
      </Flex>

      <Flex maxW="100%" flex="1" alignItems="center" justifyContent="center">
        {!favoriteStore ? (
          <SetStoreModal
            customerUser={customerUser}
            favoriteStore={favoriteStore}
            setFavoriteStore={setFavoriteStore}
          />
        ) : (
          <Stack maxW="100%" spacing="0px">
            <Text
              fontSize={["2xl", "3xl"]}
              marginBottom="0.5rem"
              color={mainTheme.colors.textWhite}
            >
              {favoriteStore.banner_name}
            </Text>
            <Text color={mainTheme.colors.textWhite} fontSize={["md", "xl"]}>
              {favoriteStore.business_name}
            </Text>
            {favoriteStore.addresses.map(
              (value: Store_Address, index: number) => {
                if (index === 0) return null;

                return (
                  <Text
                    key={value.address.address}
                    color={mainTheme.colors.textWhite}
                    fontSize={["md", "xl"]}
                  >
                    {`${t("partner.addressStore")} ${index}: ${
                      value.address.address
                    }, ${value.address.city.cap} ${value.address.city.city} (${
                      value.address.city.district
                    })`}
                  </Text>
                );
              }
            )}
            <Stack
              gridGap="1rem"
              justifyContent="center"
              alignItems="center"
              paddingTop="1rem"
              paddingBottom="1rem"
            >
              <SetStoreModal
                customerUser={customerUser}
                favoriteStore={favoriteStore}
                setFavoriteStore={setFavoriteStore}
              />
              <CustomerStoreReviewHandler />
            </Stack>
          </Stack>
        )}
      </Flex>
    </Flex>
  );
};

const DesktopStoreSection: React.FC<{
  customerUser: CustomerUser;
  favoriteStore: FavoriteStore;
  setFavoriteStore: React.Dispatch<React.SetStateAction<FavoriteStore>>;
}> = ({ customerUser, favoriteStore, setFavoriteStore }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="row" w="100%" bgColor={mainTheme.colors.darkPurple}>
      <Flex padding="4rem" flex="1" flexDirection="column">
        <Text
          fontSize={["md", "xl"]}
          marginBottom="1rem"
          color={mainTheme.colors.textWhite}
        >
          {t("dashboard.store")}
        </Text>
        {customerUser.favorite_store && customerUser.favorite_store.store_type && (
          <Text
            fontSize={["md", "xl"]}
            marginBottom="1rem"
            color={mainTheme.colors.primaryYellow}
          >
            {t(`partner.storeTypes.${customerUser.favorite_store.store_type}`)}
          </Text>
        )}
      </Flex>

      <Flex padding="4rem" flex="1" alignItems="center" justifyContent="center">
        {!favoriteStore ? (
          <SetStoreModal
            customerUser={customerUser}
            favoriteStore={favoriteStore}
            setFavoriteStore={setFavoriteStore}
          />
        ) : (
          <HStack maxW="100%" spacing="0px">
            {favoriteStore.store_image && (
              <Box marginRight="1rem">
                <Image
                  src={favoriteStore.store_image}
                  alt={favoriteStore.banner_name}
                  maxW="120px"
                />
              </Box>
            )}
            <Stack>
              {favoriteStore && (
                <>
                  <Text
                    fontSize={{
                      base: "2rem",
                      md: "2.1rem",
                      lg: "2.2rem",
                      xl: "2.3rem",
                    }}
                    marginBottom="0.5rem"
                    color={mainTheme.colors.textWhite}
                  >
                    {favoriteStore.banner_name}
                  </Text>
                  <Text color={mainTheme.colors.textWhite}>
                    {favoriteStore.business_name}
                  </Text>

                  {favoriteStore.addresses.map(
                    (value: Store_Address, index: number) => {
                      if (index === 0) return null;

                      return (
                        <Text
                          key={value.address.address}
                          color={mainTheme.colors.textWhite}
                        >
                          {`${t("partner.addressStore")} ${index}: ${
                            value.address.address
                          }, ${value.address.city.cap} ${
                            value.address.city.city
                          } (${value.address.city.district})`}
                        </Text>
                      );
                    }
                  )}
                  <HStack gridGap={"1rem"}>
                    <SetStoreModal
                      customerUser={customerUser}
                      favoriteStore={favoriteStore}
                      setFavoriteStore={setFavoriteStore}
                    />
                    {favoriteStore.subscription !== "superlight" && (
                      <CustomerStoreReviewHandler />
                    )}
                  </HStack>
                </>
              )}
            </Stack>
          </HStack>
        )}
      </Flex>
    </Flex>
  );
};

export const SetStoreModal: React.FC<{
  customerUser: CustomerUser;
  favoriteStore?: FavoriteStore;
  setFavoriteStore?: React.Dispatch<React.SetStateAction<FavoriteStore>>;
}> = observer(({ customerUser, favoriteStore, setFavoriteStore }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const toast = useToast();

  const handleMutationCompleted = useCallback(
    (data) => {
      toast({
        status: "success",
        title: t("common.actionExecutedSuccessfully"),
      });
      onClose();
      if (data && data.updateCustomerFavoriteStore && setFavoriteStore)
        setFavoriteStore(data.updateCustomerFavoriteStore);

      window.location.reload();
    },
    [onClose, setFavoriteStore, t, toast]
  );

  const [updateStoreCode, { loading: mutationUpdating }] = useMutation(
    UPDATE_CUSTOMER_STORE_CODE,
    {
      onCompleted: handleMutationCompleted,
      onError: () => {
        toast({
          status: "error",
          title: t("common.error"),
        });
      },
    }
  );

  const handleSubmit = useCallback(
    (values) => {
      updateStoreCode({
        variables: {
          customerId: customerUser.id,
          storeCode: values.storeCode,
        },
      });
    },
    [customerUser, updateStoreCode]
  );

  const validationSchema = Yup.object().shape({
    storeCode: Yup.string().required(t("dashboard.storeCodeRequired")),
  });

  const formik = useFormik({
    initialValues: {
      storeCode: "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Button backgroundColor={mainTheme.colors.primaryYellow} onClick={onOpen}>
        {favoriteStore
          ? t("dashboard.updateFavouriteStore")
          : t("dashboard.addFavouriteStore")}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {favoriteStore
              ? t("dashboard.updateFavouriteStore")
              : t("dashboard.addFavouriteStore")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                isInvalid={
                  Boolean(formik.getFieldMeta("storeCode").error) &&
                  formik.getFieldMeta("storeCode").touched
                }
              >
                <FormLabel htmlFor={"storeCode"}>
                  {t("dashboard.storeCode")}
                </FormLabel>
                <Input
                  name={"storeCode"}
                  value={formik.values.storeCode}
                  onChange={(event) =>
                    formik.setFieldValue("storeCode", event.target.value)
                  }
                />
                <FormErrorMessage>
                  {formik.getFieldMeta("storeCode").error}
                </FormErrorMessage>
              </FormControl>

              <HStack justifyContent={"space-between"}>
                <Button colorScheme="teal" variant={"ghost"} onClick={onClose}>
                  {t("common.cancel")}
                </Button>
                <Button
                  colorScheme="teal"
                  type={"submit"}
                  disabled={mutationUpdating}
                >
                  {t("common.confirm")}
                </Button>
              </HStack>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
