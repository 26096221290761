import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { Field } from "formik";
import { Segments } from "../../entities/Promo";

type Props = {
  segment: string;
};

export const ProductSegment = ({ segment }: Props) => {
  return (
    <Field name={"segment"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
          <FormLabel>Segmento</FormLabel>
          <Select
            name={field.name}
            value={segment}
            onChange={(event) => {
              setFieldValue(field.name, event.target.value);
            }}
          >
            <option value={Segments.tutti}>Tutti</option>
            <option value={Segments.alimentoSecco}>Alimento Secco</option>
            <option value={Segments.alimentoUmido}>Alimento Umido</option>
            <option value={Segments.snack}>Snack</option>
            <option value={Segments.lettiera}>Lettiera</option>
            <option value={Segments.accessoriConfort}>Accessori Confort</option>
            <option value={Segments.accessoriIgene}>Accessori Igene</option>
            <option value={Segments.antiparassitari}>Antiparassitari</option>
            <option value={Segments.giochi}>Giochi</option>
          </Select>
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
