import { Promo } from "../../../entities/Promo";
import { Promo_Code_use } from "../../../entities/Promo_Code_Use";
import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Spinner,
} from "@chakra-ui/react";

type Props = {
  promoForFileUpload: Promo_Code_use;
  setPromoForFileUpload: React.Dispatch<React.SetStateAction<Promo_Code_use>>;
  used_promo: any;
  item: Promo;
};

export const ViewReciept = ({
  promoForFileUpload,
  setPromoForFileUpload,
  used_promo,
  item,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scontrino</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={promoForFileUpload.receipt_photo}
              fallback={<Spinner colorScheme="purple" />}
            />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Chiudi
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Button
        colorScheme="teal"
        variant="outline"
        size="sm"
        onClick={() => {
          const pcu: Promo_Code_use = used_promo.find(
            (used_promo: any) => used_promo.promoId === item.id
          );
          setPromoForFileUpload(pcu);
          onOpen();
        }}
      >
        {"Visualizza"}
      </Button>
    </>
  );
};
