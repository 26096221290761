import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  HStack,
  Spacer,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { mainTheme } from "../../../config/theme";
import { CustomerUser } from "../../../entities/CustomerUser";
import { ProductReview } from "../../../entities/ProductReview";
import ReactStars from "react-rating-stars-component";
import { FaStar } from "react-icons/fa";
import { ProductImageReview } from "./ProductImageReview";

export type ProductReviewFormType = {
  id: number | undefined;
  date: Date;
  image: string;
  title: string;
  comment: string;
  totalRating: number;
  packagingRating: number;
  lookAndFeelRating: number;
  petOpinionRating: number;
  productFeaturesRating: number;
  avgRating: number;
};

type Props = {
  customerUser: CustomerUser;
  review: ProductReview;
  brandName: string;
  specialNeedName: string;
  onSubmit: (values: ProductReviewFormType) => void;
  onDelete: (values: ProductReviewFormType) => void;
};

const STAR_SIZE = 24;

export const AddProductReviewForm: React.FC<Props> = ({
  customerUser,
  brandName,
  specialNeedName,
  review,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const today = new Date();

  const validationSchema = Yup.object().shape({
    date: Yup.date().required(t("auth.validations.dateRequired")),
    title: Yup.string()
      .required(t("auth.validations.titleRequired"))
      .min(2, t("auth.validations.titleRequired")),
    comment: Yup.string()
      .required(t("auth.validations.commentRequired"))
      .min(2, t("auth.validations.commentRequired")),
    totalRating: Yup.number()
      .required(t("auth.validations.reviewStarsRequired"))
      .min(1, t("auth.validations.reviewStarsRequired"))
      .max(5, t("auth.validations.reviewStarsRequired")),
    packagingRating: Yup.number()
      .required(t("auth.validations.reviewStarsRequired"))
      .min(1, t("auth.validations.reviewStarsRequired"))
      .max(5, t("auth.validations.reviewStarsRequired")),
    lookAndFeelRating: Yup.number()
      .required(t("auth.validations.reviewStarsRequired"))
      .min(1, t("auth.validations.reviewStarsRequired"))
      .max(5, t("auth.validations.reviewStarsRequired")),
    petOpinionRating: Yup.number()
      .required(t("auth.validations.reviewStarsRequired"))
      .min(1, t("auth.validations.reviewStarsRequired"))
      .max(5, t("auth.validations.reviewStarsRequired")),
    productFeaturesRating: Yup.number()
      .required(t("auth.validations.reviewStarsRequired"))
      .min(1, t("auth.validations.reviewStarsRequired"))
      .max(5, t("auth.validations.reviewStarsRequired")),
  });

  const reviewerName =
    customerUser.username === null || customerUser.username === undefined
      ? customerUser.firstNameAndLastName()
      : customerUser.username;

  return (
    <Formik
      initialValues={{
        id: review.id ? review.id : undefined,
        date: review.id ? review.date : today,
        totalRating: review.id ? review.totalRating : 0,
        packagingRating: review.id ? review.packagingRating : 0,
        lookAndFeelRating: review.id ? review.lookAndFeelRating : 0,
        petOpinionRating: review.id ? review.petOpinionRating : 0,
        productFeaturesRating: review.id ? review.productFeaturesRating : 0,
        avgRating: review.id ? review.avgRating : 0,
        title: review.id ? review.title : "",
        comment: review.id ? review.comment : "",
        image: review.id ? review.image : "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Stack spacing={"0.5rem"} w="100%">
            <Field name="reviewerName" disabled>
              {({ field }: any) => (
                <FormControl id={field.name}>
                  <FormLabel>{t("review.reviewerName")}</FormLabel>
                  <Input disabled value={reviewerName} />
                </FormControl>
              )}
            </Field>

            <Field name={"title"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"title"} $signUpForm>
                    {t("review.title")} *
                  </FormLabel>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"comment"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"comment"} $signUpForm>
                    {t("review.comment")} *
                  </FormLabel>
                  <Textarea
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    maxLength={500}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"packagingRating"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"packagingRating"} $signUpForm>
                    Confezione, Packaging o Design *
                  </FormLabel>
                  <ReactStars
                    count={5}
                    value={field.value}
                    onChange={(newValue: number) => {
                      setFieldValue(field.name, newValue);
                    }}
                    size={STAR_SIZE}
                    isHalf={false}
                    emptyIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    // halfIcon={<FaStarHalfAlt color={"grey"} size={STAR_SIZE} />}
                    fullIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    activeColor="#ffd700"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"lookAndFeelRating"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"lookAndFeelRating"} $signUpForm>
                    Aspetto e odore prodotto *
                  </FormLabel>
                  <ReactStars
                    count={5}
                    value={field.value}
                    onChange={(newValue: number) => {
                      setFieldValue(field.name, newValue);
                    }}
                    size={STAR_SIZE}
                    isHalf={false}
                    emptyIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    // halfIcon={<FaStarHalfAlt color={"grey"} size={STAR_SIZE} />}
                    fullIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    activeColor="#ffd700"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"petOpinionRating"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"petOpinionRating"} $signUpForm>
                    Apprezzamento da parte del Pet *
                  </FormLabel>
                  <ReactStars
                    count={5}
                    value={field.value}
                    onChange={(newValue: number) => {
                      setFieldValue(field.name, newValue);
                    }}
                    size={STAR_SIZE}
                    isHalf={false}
                    emptyIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    // halfIcon={<FaStarHalfAlt color={"grey"} size={STAR_SIZE} />}
                    fullIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    activeColor="#ffd700"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"productFeaturesRating"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"productFeaturesRating"} $signUpForm>
                    Caratteristiche prodotto (risposta del prodotto, copertura
                    odori, capacità agglomerante, resistenza, durata ) *
                  </FormLabel>
                  <ReactStars
                    count={5}
                    value={field.value}
                    onChange={(newValue: number) => {
                      setFieldValue(field.name, newValue);
                    }}
                    size={STAR_SIZE}
                    isHalf={false}
                    emptyIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    // halfIcon={<FaStarHalfAlt color={"grey"} size={STAR_SIZE} />}
                    fullIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    activeColor="#ffd700"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"totalRating"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"totalRating"} $signUpForm>
                    Rapporto qualità prezzo *
                  </FormLabel>
                  <ReactStars
                    count={5}
                    value={field.value}
                    onChange={(newValue: number) => {
                      setFieldValue(field.name, newValue);
                    }}
                    size={STAR_SIZE}
                    isHalf={false}
                    emptyIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    // halfIcon={<FaStarHalfAlt color={"grey"} size={STAR_SIZE} />}
                    fullIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    activeColor="#ffd700"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <ProductImageReview fieldValue={[props.values.image]} />

            <HStack>
              {review && review.id && review?.id > 0 && (
                <Button
                  colorScheme="red"
                  danger
                  disabled={props.isSubmitting}
                  onClick={() => onDelete(props.values)}
                >
                  {t("common.delete")}
                </Button>
              )}

              <Spacer />

              <Button
                type={"submit"}
                colorScheme={"teal"}
                variant={"solid"}
                disabled={props.isSubmitting}
              >
                {t("common.confirm")}
              </Button>
            </HStack>
            {!props.isValid && props.submitCount > 0 && (
              <Text fontSize="sm" color={mainTheme.colors.danger}>
                {t("auth.messages.formError")}
              </Text>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
