import { Checkbox, Box, HStack, VStack, Text, Divider } from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { mainTheme } from "../../../config/theme";
import { DayDatePicker } from "./DayDatePicker";

export const DAY_OPENING_HOUR = "DAY_OPENING_HOUR";
export const DAY_OPENING_MINUTE = "DAY_OPENING_MINUTE";
export const DAY_CLOSING_HOUR = "DAY_CLOSING_HOUR";
export const DAY_CLOSING_MINUTE = "DAY_CLOSING_MINUTE";
export const AFTERNOON_OPENING_HOUR = "AFTERNOON_OPENING_HOUR";
export const AFTERNOON_OPENING_MINUTE = "AFTERNOON_OPENING_MINUTE";
export const AFTERNOON_CLOSING_HOUR = "AFTERNOON_CLOSING_HOUR";
export const AFTERNOON_CLOSING_MINUTE = "AFTERNOON_CLOSING_MINUTE";

export const DayFieldForm = ({
  isDashboard = false,
  dayName,
  store_opening_day,
  setOpeningTime,
  isCheckboxDisabled = false,
}: {
  isDashboard?: boolean;
  dayName: string;
  store_opening_day: string;
  setOpeningTime: React.Dispatch<React.SetStateAction<string>>;
  isCheckboxDisabled?: boolean;
}) => {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [isMorningChecked, setIsMorningChecked] = useState(
    isDashboard
      ? Number(store_opening_day.substring(0, 2)) !== 0
      : !isCheckboxDisabled
  );
  const [isAfternoonChecked, setIsAfternoonChecked] = useState(
    isDashboard
      ? Number(store_opening_day.substring(12, 14)) !== 0
      : !isCheckboxDisabled
  );

  const prettyDate = ({ date }: { date: Date }) => {
    return date.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const [dayTimeOpening, setDayTimeOpening] = useState(
    new Date(
      new Date().setHours(
        isDashboard ? Number(store_opening_day.substring(0, 2)) : 9,
        isDashboard ? Number(store_opening_day.substring(3, 5)) : 0
      )
    )
  );
  const [dayTimeClosing, setDayTimeClosing] = useState(
    new Date(
      new Date().setHours(
        isDashboard ? Number(store_opening_day.substring(6, 8)) : 13,
        isDashboard ? Number(store_opening_day.substring(9, 11)) : 0
      )
    )
  );
  const [dayOpening, setDayOpening] = useState(
    prettyDate({
      date: new Date(
        new Date().setHours(
          isDashboard ? Number(store_opening_day.substring(0, 2)) : 9,
          isDashboard ? Number(store_opening_day.substring(3, 5)) : 0
        )
      ),
    })
  );
  const [dayClosing, setDayClosing] = useState(
    prettyDate({
      date: new Date(
        new Date().setHours(
          isDashboard ? Number(store_opening_day.substring(6, 8)) : 13,
          isDashboard ? Number(store_opening_day.substring(9, 11)) : 0
        )
      ),
    })
  );

  const [afternoonTimeOpening, setAfternoonTimeOpening] = useState(
    new Date(
      new Date().setHours(
        isDashboard ? Number(store_opening_day.substring(12, 14)) : 14,
        isDashboard ? Number(store_opening_day.substring(15, 17)) : 30
      )
    )
  );
  const [afternoonTimeClosing, setAfternoonTimeClosing] = useState(
    new Date(
      new Date().setHours(
        isDashboard ? Number(store_opening_day.substring(18, 20)) : 17,
        isDashboard ? Number(store_opening_day.substring(21, 23)) : 30
      )
    )
  );
  const [afternoonOpening, setAfternoonOpening] = useState(
    prettyDate({
      date: new Date(
        new Date().setHours(
          isDashboard ? Number(store_opening_day.substring(12, 14)) : 14,
          isDashboard ? Number(store_opening_day.substring(15, 17)) : 30
        )
      ),
    })
  );
  const [afternoonClosing, setAfternoonClosing] = useState(
    prettyDate({
      date: new Date(
        new Date().setHours(
          isDashboard ? Number(store_opening_day.substring(18, 20)) : 17,
          isDashboard ? Number(store_opening_day.substring(21, 23)) : 30
        )
      ),
    })
  );

  const getFormattedTime = useCallback(() => {
    const dayOp = isMorningChecked ? dayOpening : "00:00";
    const dayCl = isMorningChecked ? dayClosing : "00:00";
    const aftOp = isAfternoonChecked ? afternoonOpening : "00:00";
    const aftCl = isAfternoonChecked ? afternoonClosing : "00:00";

    return dayOp + "-" + dayCl + " " + aftOp + "-" + aftCl;
  }, [
    afternoonClosing,
    afternoonOpening,
    dayClosing,
    dayOpening,
    isAfternoonChecked,
    isMorningChecked,
  ]);

  useEffect(() => {
    setOpeningTime(getFormattedTime());
  }, [
    dayOpening,
    dayClosing,
    afternoonOpening,
    afternoonClosing,
    isMorningChecked,
    isAfternoonChecked,
    setOpeningTime,
    getFormattedTime,
  ]);

  return (
    <Box>
      <Box
        width="100%"
        bgColor={mainTheme.colors.primaryGreen}
        marginBottom="1.5rem"
        padding="1rem"
        borderRadius="2rem"
      >
        <VStack align="flex-start">
          <Text
            color={mainTheme.colors.textWhite}
            fontSize={["sm", "md"]}
            fontWeight="bold"
          >
            {t(`partner.${dayName}`)}
          </Text>

          <Divider />

          {/**
           * Morning
           */}
          <HStack align="flex-start">
            <HStack spacing={isDesktop ? "1rem" : "0.3rem"} align="center">
              <Checkbox
                isChecked={isMorningChecked}
                onChange={() => setIsMorningChecked(!isMorningChecked)}
                size={isDesktop ? undefined : "sm"}
              />
              <Text
                fontSize={["sm", "md"]}
                textAlign="left"
                color={mainTheme.colors.textWhite}
                minWidth={isDesktop ? "45.64" : undefined}
                cursor="pointer"
                onClick={() => setIsMorningChecked(!isMorningChecked)}
              >
                {t("partner.morning")}
              </Text>

              <DayDatePicker
                disabled={!isMorningChecked}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={"HH:mm"}
                timeFormat={"HH:mm"}
                selectedDate={isMorningChecked ? dayTimeOpening : undefined}
                timeCaption={t("partner.fromTime")}
                onChange={(value: any) => {
                  const dayOpeningFormatted =
                    value === null
                      ? ""
                      : value instanceof Date
                      ? prettyDate({ date: value })
                      : prettyDate({ date: value[0] });
                  setDayTimeOpening(
                    value === null
                      ? ""
                      : value instanceof Date
                      ? value
                      : value[0]
                  );
                  setDayOpening(dayOpeningFormatted);
                }}
              />
              <DayDatePicker
                disabled={!isMorningChecked}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={"HH:mm"}
                timeFormat={"HH:mm"}
                selectedDate={isMorningChecked ? dayTimeClosing : undefined}
                timeCaption={t("partner.toTime")}
                onChange={(value: any) => {
                  const dayClosingFormatted =
                    value === null
                      ? ""
                      : value instanceof Date
                      ? prettyDate({ date: value })
                      : prettyDate({ date: value[0] });
                  setDayTimeClosing(
                    value === null
                      ? ""
                      : value instanceof Date
                      ? value
                      : value[0]
                  );
                  setDayClosing(dayClosingFormatted);
                }}
              />
            </HStack>
          </HStack>

          {/**
           * Afternoon
           */}
          <HStack align="flex-start">
            <HStack spacing={isDesktop ? "1rem" : "0.3rem"} align="center">
              <Checkbox
                isChecked={isAfternoonChecked}
                onChange={() => setIsAfternoonChecked(!isAfternoonChecked)}
                size={isDesktop ? undefined : "sm"}
              />
              <Text
                fontSize={["sm", "md"]}
                textAlign="left"
                minWidth={isDesktop ? "45.64" : undefined}
                color={mainTheme.colors.textWhite}
                cursor="pointer"
                onClick={() => setIsAfternoonChecked(!isAfternoonChecked)}
              >
                {t("partner.afternoon")}
              </Text>

              <DayDatePicker
                disabled={!isAfternoonChecked}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={"HH:mm"}
                timeFormat={"HH:mm"}
                selectedDate={
                  isAfternoonChecked ? afternoonTimeOpening : undefined
                }
                timeCaption={t("partner.fromTime")}
                onChange={(value: any) => {
                  const afternoonOpeningFormatted =
                    value === null
                      ? ""
                      : value instanceof Date
                      ? prettyDate({ date: value })
                      : prettyDate({ date: value[0] });
                  setAfternoonTimeOpening(
                    value === null
                      ? ""
                      : value instanceof Date
                      ? value
                      : value[0]
                  );
                  setAfternoonOpening(afternoonOpeningFormatted);
                }}
              />

              <DayDatePicker
                disabled={!isAfternoonChecked}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                dateFormat={"HH:mm"}
                timeFormat={"HH:mm"}
                selectedDate={
                  isAfternoonChecked ? afternoonTimeClosing : undefined
                }
                timeCaption={t("partner.toTime")}
                onChange={(value: any) => {
                  const afternoonClosingFormatted =
                    value === null
                      ? ""
                      : value instanceof Date
                      ? prettyDate({ date: value })
                      : prettyDate({ date: value[0] });
                  setAfternoonTimeClosing(
                    value === null
                      ? ""
                      : value instanceof Date
                      ? value
                      : value[0]
                  );
                  setAfternoonClosing(afternoonClosingFormatted);
                }}
              />
            </HStack>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};
