import { useMutation } from "@apollo/client";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Header } from "../../components/Header";
import { LOGIN_CUSTOMER, LOGIN_STORE } from "../../services/graphql";
import { Alert, AlertIcon } from "@chakra-ui/alert";
import { LoginForm } from "../../components/LoginForm/LoginForm";
import { useStores } from "../../hooks/UseStores";
import { Box, Flex, Spinner, Text, useMediaQuery } from "@chakra-ui/react";
import { mainTheme } from "../../config/theme";
import { observer } from "mobx-react";
import { UserType } from "../../types";
import { useLocation } from "react-router-dom";

export const LoginScreen: React.FC = observer(() => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const location: any = useLocation();
  const [userType, setUserType] = useState<UserType>(
    location.state && location.state!.initialUserType
      ? location.state!.initialUserType
      : "customer"
  );
  const { t } = useTranslation();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { session } = useStores();

  const toggleUserType = useCallback(() => {
    if (userType === "customer") {
      setUserType("partner");
    } else {
      setUserType("customer");
    }
  }, [userType]);

  const setUserTypeCustomer = useCallback(() => {
    if (userType !== "customer") {
      toggleUserType();
    }
  }, [toggleUserType, userType]);

  const setUserTypePartner = useCallback(() => {
    if (userType !== "partner") {
      toggleUserType();
    }
  }, [toggleUserType, userType]);

  const handleLoginCompleted = useCallback(
    (data) => {
      window.scrollTo(0, 0);
      if (userType === "customer") {
        session.login(
          data.loginCustomer.customer,
          data.loginCustomer.token,
          "customer"
        );
      } else {
        session.login(data.loginStore.store, data.loginStore.token, "partner");
      }
    },
    [session, userType]
  );

  const handleLoginError = useCallback(() => {
    window.scrollTo(0, 0);
    setShowErrorMessage(true);
  }, []);

  const [login, { loading: mutationSubmitting }] = useMutation(
    userType === "customer" ? LOGIN_CUSTOMER : LOGIN_STORE,
    {
      onCompleted: handleLoginCompleted,
      onError: (error) => {
        console.log(error);
        handleLoginError();
      },
    }
  );

  const handleSubmit = useCallback(
    (values) => {
      const { email, password } = values;

      setShowErrorMessage(false);

      login({
        variables: {
          email,
          password,
        },
      });
    },
    [login]
  );

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"1fr auto"}>
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Box
          flexDirection={"column"}
          backgroundColor={mainTheme.colors.background}
          w={"100%"}
          justifyContent={"center"}
        >
          <Header dark textAlign={"center"} />
          <Flex flexDirection={"row"}>
            <Box
              w={"50%"}
              borderBottom={
                userType === "customer"
                  ? `4px solid ${mainTheme.colors.darkPurple}`
                  : ""
              }
              backgroundColor={
                userType === "customer"
                  ? `${mainTheme.colors.darkPurple}`
                  : undefined
              }
              cursor="pointer"
              p={2}
              textAlign={"center"}
              onClick={setUserTypeCustomer}
            >
              <Text
                color={
                  userType === "customer" ? "#fff" : mainTheme.colors.darkPurple
                }
                fontSize={"lg"}
                fontWeight={userType === "customer" ? 600 : 400}
              >
                {t("login.customers")}
              </Text>
            </Box>
            <Box
              w={"50%"}
              borderBottom={
                userType === "partner"
                  ? `4px solid ${mainTheme.colors.darkPurple}`
                  : ""
              }
              backgroundColor={
                userType === "partner"
                  ? `${mainTheme.colors.darkPurple}`
                  : undefined
              }
              cursor="pointer"
              p={2}
              textAlign={"center"}
              onClick={setUserTypePartner}
            >
              <Text
                color={
                  userType === "partner" ? "#fff" : mainTheme.colors.darkPurple
                }
                fontSize={"lg"}
                fontWeight={userType === "partner" ? 600 : 400}
              >
                {t("login.partners")}
              </Text>
            </Box>
          </Flex>
        </Box>
        <FormContainer
          style={{
            padding: isLargerThan768 ? undefined : "2rem",
            paddingTop: "0px",
          }}
        >
          <LoginForm
            onSubmit={handleSubmit}
            isSubmitting={mutationSubmitting}
            userType={userType}
          />

          {showErrorMessage && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {t("auth.messages.loginError")}
            </Alert>
          )}
          {session.sessionError && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {t("auth.messages.sessionError")}
            </Alert>
          )}
          {(mutationSubmitting || session.isLoading) && (
            <Flex
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
              m={6}
              gridGap={0}
            >
              {session.isLoading && (
                <Text color={mainTheme.colors.darkPurple}>
                  {t("common.redirect")}
                </Text>
              )}
              <Spinner
                thickness="3px"
                speed="0.65s"
                emptyColor={mainTheme.colors.lightPurple}
                color={mainTheme.colors.darkPurple}
                size="md"
                margin={"2rem"}
              />
            </Flex>
          )}
        </FormContainer>
      </Flex>
    </Box>
  );
});

const FormContainer = styled.div`
  width: 100%;
  max-width: 420px;
  margin-top: 2rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
`;
