import { useDropzone } from "react-dropzone";
import { uploadToMSBucket } from '../../../my_modules/ms-bucket-file-uploader';
import { MS_SEND_MAIL_API_KEY } from "../../../config/constants";
import { Field, FieldProps } from "formik";
import { FormControl, FormHelperText } from "@chakra-ui/form-control";
import { useTranslation } from "react-i18next";
import {
  VStack,
  Stack,
  Image,
  Spinner,
  Button,
  FormLabel,
} from "@chakra-ui/react";
import { mainTheme } from "../../../config/theme";
import { useCallback, useMemo, useState } from "react";

type Props = {
  fieldValue: string[];
};

export const ProductImageReview = ({ fieldValue }: Props) => {
  const { t } = useTranslation();
  const maxFiles: number = 1;

  const FileUpload = ({
                        onFileUploaded,
                      }: {
    onFileUploaded: (fileUrls: string[]) => void;
  }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [minFilesError, setMinFilesError] = useState<boolean>(false);

    const onDrop = (files: File[]) => {
      if (1) {
        if (files.length < 1) {
          setMinFilesError(true);
        } else {
          setMinFilesError(false);
        }
      }
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      acceptedFiles,
    } = useDropzone({
      maxFiles: maxFiles - fieldValue.length,
      onDrop,
    });

    const dNDStyle = useMemo(() => {
      const baseStyle = {
        flex: 1,
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
      };

      const activeStyle = {
        borderColor: "#2196f3",
      };

      const acceptStyle = {
        borderColor: "#00e676",
      };

      const rejectStyle = {
        borderColor: "#ff1744",
      };

      return {
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      };
    }, [isDragActive, isDragAccept, isDragReject]);

    const handleUpload = useCallback(async () => {
      if (!minFilesError) {
        setIsUploading(true);
        const fileUploaded: string[] = [];

        try {
          for (let file of acceptedFiles) {
            const fileUrl = await uploadToMSBucket(
                MS_SEND_MAIL_API_KEY,
                file,
                false
            );
            fileUploaded.push(fileUrl);
          }

          onFileUploaded(fileUploaded);
        } catch (err) {
          alert(t("common.error"));
          console.log(err);
        } finally {
          setIsUploading(false);
        }
      }
    }, [minFilesError, onFileUploaded, acceptedFiles]);

    const fileList = acceptedFiles.map((file) => (
        <li key={file.name}>{file.name}</li>
    ));

    return (
        <VStack align="flex-start" spacing="0.5rem">
          <div {...getRootProps({ style: dNDStyle })}>
            <input {...getInputProps()} />
            {t("qrCodeReaderModal.dragNDrop")}
          </div>
          <aside>
            <ul>{fileList}</ul>
          </aside>

          {isUploading && <Spinner colorScheme="purple" />}
          <Button
              onClick={handleUpload}
              colorScheme={"green"}
              disabled={acceptedFiles.length === 0 || isUploading || minFilesError}
          >
            {t("qrCodeReaderModal.upload")}
          </Button>
        </VStack>
    );
  };

  return (
      <Field name={"image"}>
        {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <FormLabel htmlFor={"image"}>
                {"Immagine  del tuo pet con il prodotto"}
              </FormLabel>
              {maxFiles - field.value.length > 0 && (
                  <FormHelperText>{`Massimo ${maxFiles - field.value.length} ${
                      maxFiles - field.value.length === 1 ? "immagine" : "immagini"
                  }`}</FormHelperText>
              )}

              <FileUpload
                  onFileUploaded={(fileUrls: string[]) => {
                    if (fileUrls.length > 0) {
                      setFieldValue(field.name, fileUrls[0]);
                    }
                  }}
              />
              <Stack
                  borderRadius={"0.5rem"}
                  padding="0.5rem"
                  bgColor={mainTheme.colors.textWhite}
              >
                {fieldValue[0] !== "" &&
                    fieldValue.map((src: string) => {
                      return (
                          <Stack
                              key={src}
                              p={4}
                              border={"1px solid lightgrey"}
                              boxShadow="md"
                          >
                            <Image
                                border={"1px solid lightgrey"}
                                p={4}
                                boxShadow="md"
                                src={src}
                                boxSize="2xs"
                                objectFit="contain"
                            />
                            <Button
                                onClick={() => setFieldValue(field.name, "")}
                                colorScheme="red"
                                aria-label="Delete image"
                            >
                              {t("common.delete")}
                            </Button>
                          </Stack>
                      );
                    })}
              </Stack>
            </FormControl>
        )}
      </Field>
  );
};
