import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  CircularProgress,
  Flex,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PARTNER_SUBSCRIPTION_PRICES } from "../../config/constants";
import { mainTheme } from "../../config/theme";
import { useStores } from "../../hooks/UseStores";
import { Footer } from "../Footer/Footer";
import { getUrlParams } from "../../utils/utils";
import { PartnerHeader } from "../../components/PartnerHeader";
import { CHECK_PROMO_CODE } from "../../services/graphql";
import { useMutation } from "@apollo/client";
import { useTheme } from "styled-components";

export const PartnerSubscriptionScreen = () => {
  const { session, stripe } = useStores();
  const theme = useTheme();
  const { t } = useTranslation();
  const [promoCodeValue, setPromoCodeValue] = useState<number>(0);

  const checkStripePortalSuccess = useCallback(async () => {
    const params = getUrlParams(window.location.hash);
    if (params.backFromPortal) {
      stripe.stripePortalSuccess = true;
      if (!session.isLogged()) {
        window.location.reload();
      }
    }
  }, [session, stripe]);

  checkStripePortalSuccess();

  const handlePortalAccess = useCallback(async () => {
    try {
      const portalSessionUrl = await stripe.stripeAccessPortal();
      // Redirect to Portal
      if (!!portalSessionUrl) {
        window.location.replace(portalSessionUrl);
      }
    } catch (err) {
      console.log(err);
    }
  }, [stripe]);

  const handleCheckPromoCode = useCallback((data) => {
    if (data.checkPromoCode.isPromoCodeValid) {
      setPromoCodeValue(data.checkPromoCode.promoCodeValue);
    }
  }, []);

  const [checkPromoCodeMutation, { loading: mutationSubmitting }] = useMutation(
    CHECK_PROMO_CODE,
    {
      onCompleted: handleCheckPromoCode,
      onError: (error) => {
        console.log(error);
        setPromoCodeValue(0);
      },
    }
  );

  useEffect(() => {
    if (session.partnerUser?.promoCode) {
      checkPromoCodeMutation({
        variables: {
          promo_code: session.partnerUser?.promoCode,
          selected_subscription: session.partnerUser?.subscription,
          coupon_id: session.partnerUser?.couponId,
        },
      });
    }
  }, [
    checkPromoCodeMutation,
    session.partnerUser?.couponId,
    session.partnerUser?.promoCode,
    session.partnerUser?.subscription,
  ]);

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <PartnerHeader dark session={session} selectedTab={"subscription"} />
      <VStack spacing="0rem">
        <Flex
          flexDirection="row"
          w="100%"
          bgColor={mainTheme.colors.primaryCyan}
          paddingX={"4rem"}
          paddingY={"2rem"}
          flexWrap="wrap"
        >
          <Flex flex="1">
            <Stack>
              <Text
                fontSize={["md", "lg"]}
                marginBottom="1rem"
                color={mainTheme.colors.textWhite}
                textTransform={"uppercase"}
              >
                {t("partnerdashboard.subscription.title")}
              </Text>
              <Text
                fontSize={["md", "lg"]}
                marginBottom="1rem"
                color={mainTheme.colors.textWhite}
                alignItems={"center"}
                display={"flex"}
                flexWrap={"wrap"}
              >
                <Badge margin={"0 0.5em"} colorScheme="purple">
                  {session.partnerUser?.subscription === "superlight-month"
                    ? "Superlight mensile"
                    : session.partnerUser?.subscription === "superlight-year"
                    ? "Superlight annuale"
                    : session.partnerUser?.subscription === "base-month"
                    ? "Base mensile"
                    : session.partnerUser?.subscription === "base-year"
                    ? "Base annuale"
                    : session.partnerUser?.subscription === "premium-month"
                    ? "Premium mensile"
                    : session.partnerUser?.subscription === "premium-year"
                    ? "Premium annuale"
                    : ""}
                </Badge>
                {/* {t("partner.priceMonth") + " "} */}
                {/* {session.partnerUser?.promoCode && (
                  <Text as="del" margin={"0 10px"}>
                    {["superlight-month", "superlight-year"].includes(
                      session.partnerUser?.subscription || ""
                    )
                      ? PARTNER_SUBSCRIPTION_PRICES[0]
                      : ["superlight-base", "superlight-base"].includes(
                          session.partnerUser?.subscription || ""
                        )
                      ? PARTNER_SUBSCRIPTION_PRICES[2]
                      : PARTNER_SUBSCRIPTION_PRICES[4]}
                    {t("common.selectedCurrency")}
                  </Text>
                )} */}
                {/* {mutationSubmitting && (
                  <CircularProgress
                    isIndeterminate
                    color={theme.colors.darkPurple}
                    size={25}
                    marginRight={"0.5em"}
                  />
                )}
                {session.partnerUser?.promoCode
                  ? ["superlight-month", "superlight-year"].includes(
                      session.partnerUser?.subscription || ""
                    )
                    ? PARTNER_SUBSCRIPTION_PRICES[0].toFixed(2)
                    : ["base-month", "base-year"].includes(
                        session.partnerUser?.subscription || ""
                      )
                    ? (
                        PARTNER_SUBSCRIPTION_PRICES[2] *
                        (1 - promoCodeValue / 100)
                      ).toFixed(2)
                    : (
                        PARTNER_SUBSCRIPTION_PRICES[4] *
                        (1 - promoCodeValue / 100)
                      ).toFixed(2)
                  : ["superlight-month", "superlight-year"].includes(
                      session.partnerUser?.subscription || ""
                    )
                  ? PARTNER_SUBSCRIPTION_PRICES[0]
                  : ["base-month", "base-year"].includes(
                      session.partnerUser?.subscription || ""
                    )
                  ? PARTNER_SUBSCRIPTION_PRICES[2]
                  : PARTNER_SUBSCRIPTION_PRICES[4]}
                {t("common.selectedCurrency")} */}
              </Text>
            </Stack>
          </Flex>
          <Flex flex="1" justifyContent="center">
            <Stack>
              <Stack>
                <Text
                  fontSize={["md", "lg"]}
                  color={mainTheme.colors.textWhite}
                >
                  {t("partnerdashboard.subscription.click")}
                </Text>
                <UnorderedList
                  color={mainTheme.colors.textWhite}
                  paddingLeft={"20px"}
                >
                  <ListItem>
                    {t("partnerdashboard.subscription.edit1")}
                  </ListItem>
                  <ListItem>
                    {t("partnerdashboard.subscription.edit2")}
                  </ListItem>
                  <ListItem>
                    {t("partnerdashboard.subscription.edit3")}
                  </ListItem>
                </UnorderedList>
                <Button
                  onClick={handlePortalAccess}
                  colorScheme={"blue"}
                  disabled={stripe.isSubmitting}
                >
                  {t("partnerdashboard.subscription.edit")}
                </Button>
                {stripe.stripePortalSuccess && (
                  <Alert
                    status={"success"}
                    color={mainTheme.colors.textPrimary}
                    borderRadius={"5px"}
                    margin={"2rem 0"}
                  >
                    <AlertIcon />
                    {t("partnerdashboard.subscription.editSuccess")}
                  </Alert>
                )}
                {stripe.stripePortalError && (
                  <Alert
                    status={"error"}
                    color={mainTheme.colors.danger}
                    borderRadius={"5px"}
                    margin={"2rem 0"}
                  >
                    <AlertIcon />
                    {t("partnerdashboard.subscription.editError")}
                  </Alert>
                )}
              </Stack>
            </Stack>
          </Flex>
        </Flex>
      </VStack>
      <Footer dark />
    </Box>
  );
};
