import { useLazyQuery } from "@apollo/client";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Field } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FETCH_BRANDS } from "../../../screens/PartnerSignUp/CompleteSignUpPartner/graphql";
import { MultiSelect } from "./MultiSelect";

export const BrandsField = ({
  setBrandsIds,
}: {
  setBrandsIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation();

  const [brandsData, setBrandsData] = useState<
    { value: string; label: string }[]
  >([]);

  const [fetchBrands, { data }] = useLazyQuery(FETCH_BRANDS, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      const brands: { value: string; label: string }[] = data.brands.map(
        (item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }
      );

      setBrandsData(brands);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const handleOnChange = (values: any) => {
    let ids = values.map(
      (item: { value: string; label: string }) => item.value
    );
    setBrandsIds(ids);
  };

  return (
    <Field name={"brands"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
          <FormLabel>{t("partner.brands")}</FormLabel>
          <MultiSelect
            options={brandsData}
            onChange={(values) => {
              handleOnChange(values);
              setFieldValue(field.name, values);
              console.log(values);
            }}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
