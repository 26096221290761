import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  useDisclosure,
  VStack,
  Text,
  HStack,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PartnerHeader } from "../../components/PartnerHeader";
import { useStores } from "../../hooks/UseStores";
import { CreatePromotionModal } from "../../components/PartnerPromotions/CreatePromotionModal";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_PROMO,
  DELETE_PROMO,
  FETCH_PARTNER_PROMOS,
  UPDATE_PROMO,
} from "./graphql";
import { ActivityType, InterestedBrand, Promo } from "../../entities/Promo";
import { PromoBreed } from "../../entities/PromoBreed";
import { useMediaQuery } from "react-responsive";
import { Promo_Code_use } from "../../entities/Promo_Code_Use";
import { mainTheme } from "../../config/theme";
import { Footer } from "../Footer/Footer";
import { BrandsServicesLengthErrorModal } from "../../components/PartnerPromotions/BrandsServicesLengthErrorModal";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { DownloadCSV } from "../../components/DownloadCSV";

export const PartnerPromotionsScreen: React.FC = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const { session } = useStores();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenBrandsServicesError,
    onOpen: onOpenBrandsServicesError,
    onClose: onCloseBrandsServicesError,
  } = useDisclosure();
  const { t } = useTranslation();
  const [activePromo, setActivePromo] = useState<Promo>(
    new Promo(new PromoBreed(-1, "", "", "None"))
  );
  const [promoCodeUses, setPromoCodeUses] = useState<Promo_Code_use[]>([]);
  const [messageSuccess, setMessageSuccess] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);

  const [activity_id, setActivity_id] = useState(-1);
  const [single_brand_id, setSingle_brand_id] = useState(-1);
  const [special_need, setSpecial_need] = useState("all");
  const [is_service] = useState(false);

  const handleOpenPromo = useCallback(
    (promo: Promo) => {
      setActivity_id(promo.activity_id);
      setSingle_brand_id(promo.single_brand_id);
      setActivePromo(promo);
      onOpen();
    },
    [onOpen]
  );

  const [fetchPromo, { loading: isFetching, data: promosData, refetch }] =
    useLazyQuery(FETCH_PARTNER_PROMOS, {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    });

  const fetchPromos = useCallback(() => {
    // session.partnerUser?.promo.forEach((promo: Promo) => {
    //   promo.promo_uses.forEach((p: Promo_Code_use) => {
    //     arr.push(p);
    //   });
    // });

    // setPromoCodeUses(arr);

    fetchPromo({ variables: { storeId: session.partnerUser?.id } });
  }, [fetchPromo, session.partnerUser?.id]);

  useEffect(() => {
    fetchPromos();
  }, [fetchPromos]);

  const numPromo = useMemo(() => {
    return promosData
      ? promosData.parnerPromos.filter((promo: Promo) => !!!promo.is_national)
          .length
      : 0;
  }, [promosData]);

  const [createPromo, { loading: isCreatingPromo }] = useMutation(
    CREATE_PROMO,
    {
      onCompleted: () => {
        onClose();
        setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
        setMessageSuccess("Promo inserita correttamente!");
        refetch && refetch();
      },
      onError: (error) => {
        console.log(error);
        setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
        setMessageError(
          "Attenzione, non è possibile inserire più promo per lo stesso periodo o nei periodi nei quali sono attive promo nazionali."
        );
      },
    }
  );

  const [updatePromo, { loading: isUpdatingPromo }] = useMutation(
    UPDATE_PROMO,
    {
      onCompleted: () => {
        onClose();
        setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
        setMessageSuccess("Promo aggiornata correttamente!");
        refetch && refetch();
      },
      onError: (error) => {
        console.log(error);
        setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
        setMessageError(
          "Attenzione, non è possibile inserire più promo per lo stesso periodo."
        );
      },
    }
  );

  const handleSubmit = useCallback(
    (data: any) => {
      onClose();

      setMessageError(null);
      setMessageSuccess(null);

      const dvf: Date = new Date(
        Date.UTC(
          // (data.valid_from as Date).getUTCFullYear(),
          // (data.valid_from as Date).getUTCMonth(),
          // (data.valid_from as Date).getUTCDate(),
          (data.valid_from as Date).getFullYear(),
          (data.valid_from as Date).getMonth(),
          (data.valid_from as Date).getDate(),
          0,
          0,
          0
        )
      );

      const dvu: Date = new Date(
        Date.UTC(
          // (data.valid_until as Date).getUTCFullYear(),
          // (data.valid_until as Date).getUTCMonth(),
          // (data.valid_until as Date).getUTCDate(),
          (data.valid_until as Date).getFullYear(),
          (data.valid_until as Date).getMonth(),
          (data.valid_until as Date).getDate(),
          23,
          59,
          59
        )
      );

      if (data.id !== -1) {
        updatePromo({
          variables: {
            id: data.id,
            activity_type: data.activity_type,
            interested_brand: data.interested_brand,
            valid_from: dvf.toISOString(),
            valid_until: dvu.toISOString(),
            email_title: data.email_title,
            description: data.description,
            promo_type: data.promo_type,
            users_perimeter: data.users_perimeter,
            size: data.size,
            activity_id: activity_id,
            single_brand_id: single_brand_id,
            special_needs_id: data.special_needs_id,
            special_need: special_need,
            promo_breed_id: Number(data.promo_breed.value),
            storeId: session.partnerUser?.id,
            segment: data.segment,
          },
        });
      } else {
        createPromo({
          variables: {
            activity_type: data.activity_type,
            interested_brand: data.interested_brand,
            valid_from: dvf.toISOString(),
            valid_until: dvu.toISOString(),
            email_title: data.email_title,
            description: data.description,
            promo_type: data.promo_type,
            users_perimeter: data.users_perimeter,
            size: data.size,
            is_service: is_service,
            activity_id: activity_id,
            single_brand_id: single_brand_id,
            special_needs_id: data.special_needs_id,
            special_need: special_need,
            promo_breed_id: Number(data.promo_breed.value),
            storeId: session.partnerUser?.id,
            segment: data.segment,
          },
        });
      }
      setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
    },
    [
      activity_id,
      single_brand_id,
      onClose,
      updatePromo,
      session.partnerUser?.id,
      createPromo,
      is_service,
      special_need,
    ]
  );

  const [deletePromo] = useMutation(DELETE_PROMO, {
    onCompleted: () => {
      onClose();
      setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
      setMessageSuccess("Promo eliminata correttamente!");
      refetch && refetch();
    },
    onError: (error) => {
      console.log(error);
      setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
      setMessageError("Si è verificato un errore.");
    },
  });

  const handleDelete = useCallback(() => {
    deletePromo({
      variables: {
        id: activePromo.id,
      },
    });

    setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
  }, [activePromo, deletePromo]);

  const getActivityTranslation = (item: any) => {
    switch (item.interested_brand) {
      case InterestedBrand.single_brand:
        return item.brand.name;
      case InterestedBrand.single_service:
        return t(`promoForm.${item.activity.name}`);
      default:
        return t(`promoForm.interested_brand.${item.interested_brand}`);
    }
  };

  const promoDataSet = useMemo(() => {
    return promosData
      ? promosData.parnerPromos.map((item: any, index: number) => {
          const vf = new Date(item.valid_from);
          const vu = new Date(item.valid_until);
          vf.setTime(vf.getTime() + vf.getTimezoneOffset() * 60 * 1000);
          vu.setTime(vu.getTime() + vu.getTimezoneOffset() * 60 * 1000);
          vf.setHours(0);
          vf.setMinutes(0);
          vf.setSeconds(0);
          vu.setHours(23);
          vu.setMinutes(59);
          vu.setSeconds(59);

          const currentDatePlusTwoDays = new Date(
            new Date().getTime() + 24 * 60 * 60 * 1000 * 2
          );
          currentDatePlusTwoDays.setHours(0);
          currentDatePlusTwoDays.setMinutes(0);
          currentDatePlusTwoDays.setSeconds(0);

          const currentDate = new Date(
            new Date().getTime() + 24 * 60 * 60 * 1000 * 2
          );
          currentDate.setHours(0);
          currentDate.setMinutes(0);
          currentDate.setSeconds(0);

          const isPromoModifiable =
            currentDatePlusTwoDays.getTime() < new Date(vf).getTime();

          const isPromoActive = new Date().getTime() < new Date(vu).getTime();

          const promoState = isPromoModifiable
            ? "Pianificata"
            : isPromoActive
            ? "Attiva"
            : "Conclusa";

          const isp: string = item.special_need;
          const specialNeed: string = isp
            ? isp === "all"
              ? "Tutti"
              : isp.substr(0, 1).toUpperCase() + isp.substr(1).toLowerCase()
            : "";

          const isgm: string = item.segment;
          const segment: string = isgm
            ? isgm === "all"
              ? "Tutti"
              : isgm.substr(0, 1).toUpperCase() + isp.substr(1).toLowerCase()
            : "";

          return {
            id: item.id,
            email_title: item.email_title,
            promoState,
            valid_from: item.valid_from,
            valid_until: item.valid_until,
            activity_type: t(`promoForm.${item.activity_type}`),
            plain_activity_type: item.activity_type,
            specialNeed,
            activity: getActivityTranslation(item),
            segment,
            is_national: item.is_national,
            type: item.type,
            users_perimeter: t(
              `promoForm.usersPerimeter.${item.users_perimeter}`
            ),
            promo_type: item.promo_type,
          };
        })
      : [];
  }, [promosData, t]);

  const csvData = useMemo(() => {
    const rows = [
      [
        "ID",
        "TITOLO",
        "STATO",
        "DAL",
        "AL",
        "TIPO",
        "ESIGENZA",
        "ATTIVITÀ",
        "SEGMENTO",
        "TIPOLOGIA",
        "PERIMETRO",
        "TIPO PROMO",
        // "SESSO",
        // "PROVINCIA",
        // "TIPO CONSUMATORE",
      ],
    ];

    for (let item of promoDataSet) {
      if (!item.is_national) {
        rows.push([
          item.id,
          item.email_title,
          item.promoState,
          item.valid_from,
          item.valid_until,
          item.activity_type,
          item.specialNeed,
          item.activity,
          item.segment,
          item.type || "",
          item.users_perimeter,
          item.plain_activity_type === ActivityType.valueDiscountCoupon
            ? `€ ${item.promo_type}`
            : `% ${item.promo_type}`,
          // item.gender,
          // item.districts,
          // item.customer_type,
        ]);
      }
    }

    return rows;
  }, [promoDataSet]);

  const promoCodeUsesCSVData = useMemo(() => {
    const rows = [
      [
        "COGNOME",
        "NOME",
        "PROMOZIONE",
        "DATA_UTILIZZO",
        "ID",
        "TITOLO",
        "DAL",
        "AL",
        "TIPO",
        "ESIGENZA",
        "ATTIVITÀ",
        "SEGMENTO",
        //"TIPOLOGIA",
        "PERIMETRO",
        "TIPO PROMO",
        "NAZIONALE",
      ],
    ];

    if (
      session.partnerUser &&
      session.partnerUser.promoUsed &&
      session.partnerUser.promoUsed.length > 0
    ) {
      for (let promoUsed of session.partnerUser.promoUsed) {
        rows.push([
          promoUsed.customer.surname,
          promoUsed.customer.first_name,
          promoUsed.promo.email_title,
          new Date(promoUsed.used_date).toLocaleDateString(),
          String(promoUsed.promo.id),
          promoUsed.promo.email_title,
          new Date(promoUsed.promo.valid_from).toLocaleDateString(),
          new Date(promoUsed.promo.valid_until).toLocaleDateString(),
          t(`promoForm.${promoUsed.promo.activity_type}`),
          promoUsed.promo.special_need
            ? promoUsed.promo.special_need === "all"
              ? "Tutti"
              : promoUsed.promo.special_need.substr(0, 1).toUpperCase() +
                promoUsed.promo.special_need.substr(1).toLowerCase()
            : "",
          getActivityTranslation(promoUsed.promo),
          promoUsed.promo.segment,
          //promoUsed.promo.type || "",
          t(`promoForm.usersPerimeter.${promoUsed.promo.users_perimeter}`),
          promoUsed.promo.activity_type === ActivityType.valueDiscountCoupon
            ? `€ ${promoUsed.promo.promo_type}`
            : `% ${promoUsed.promo.promo_type}`,
          promoUsed.promo.is_national ? "SI" : "NO",
        ]);
      }
    }

    return rows;
  }, [getActivityTranslation, session.partnerUser, t]);

  const DesktopTable = () => {
    return (
      <Table variant="simple" size="sm">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Titolo</Th>
            <Th>Stato</Th>
            <Th>Dal</Th>
            <Th>Al</Th>
            <Th>Tipo</Th>
            <Th>Esigenza</Th>
            <Th>Attività</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {promoDataSet.map((item: any) => {
            return (
              <Tr>
                <Td>{item.id}</Td>
                <Td>{item.email_title}</Td>
                <Td>{item.promoState}</Td>
                <Td>{item.valid_from}</Td>
                <Td>{item.valid_until}</Td>
                <Td>{item.activity_type}</Td>
                <Td>{item.specialNeed}</Td>
                <Td>{item.activity}</Td>
                <Td>
                  {item.is_national ? (
                    "Promo nazionale"
                  ) : (
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        const promos: Promo[] = promosData.parnerPromos;

                        const promo: Promo | undefined = promos.find(
                          (value: Promo) => value.id === item.id
                        );

                        if (promo) handleOpenPromo(promo);
                      }}
                    >
                      {item.promoState === "Attiva" ||
                      item.promoState === "Conclusa"
                        ? "Visualizza"
                        : "Modifica"}
                    </Button>
                  )}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  };

  const MobileTable = () => {
    return (
      <Accordion w="100%" allowMultiple>
        {promoDataSet.map((item: any, index: number) => {
          return (
            <AccordionItem
              backgroundColor={index % 2 === 0 ? "gray.100" : "white"}
              key={item.id}
              w="100%"
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
            >
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {item.email_title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <VStack align="flex-start">
                  <Text>ID: {item.id}</Text>
                  <Text>Stato: {item.promoState}</Text>
                  <Text>{item.email_title}</Text>
                  <Text>Dal {item.valid_from}</Text>
                  <Text>Al {item.valid_until}</Text>
                  <Text>{item.activity_type}</Text>
                  <Text>Sconto: {item.type}</Text>
                  <Text>Esigenza: {item.specialNeed}</Text>
                  <Text>Attività: {item.activity}</Text>
                  <Text>Segmento: {item.segment}</Text>
                  <Text>
                    Perimetro:
                    {item.users_perimeter}
                  </Text>
                  {item.is_national ? (
                    <Text>Promo nazionale</Text>
                  ) : (
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        const promos: Promo[] = promosData.parnerPromos;

                        const promo: Promo | undefined = promos.find(
                          (value: Promo) => value.id === item.id
                        );

                        if (promo) handleOpenPromo(promo);
                      }}
                    >
                      {item.promoState === "Attiva" ||
                      item.promoState === "Conclusa"
                        ? "Visualizza"
                        : "Modifica"}
                    </Button>
                  )}
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  return (
    <Box
      minHeight="100vh"
      w={"100%"}
      display={"grid"}
      gridTemplateRows={"auto 1fr auto"}
    >
      <PartnerHeader dark session={session} selectedTab={"promo"} />
      <Flex
        flexDir={"column"}
        align={"center"}
        bgColor={mainTheme.colors.primaryGreen}
        w={"100%"}
      >
        <VStack justify={"center"} align={"center"} w={"80%"} paddingY="2rem">
          <Text
            w={"100%"}
            textAlign={"left"}
            color={mainTheme.colors.textPrimary}
            fontSize={["md", "lg"]}
          >
            {["superlight-month", "superlight-year"].includes(
              session.partnerUser?.subscription || ""
            )
              ? "Qui puoi consultare le promo di brand LOOVERS nazionali"
              : ["base-month", "base-year"].includes(
                  session.partnerUser?.subscription || ""
                )
              ? "Qui puoi caricare le promo del tuo punto vendita (max 8)"
              : "Qui puoi caricare le promo del tuo punto vendita"}
          </Text>
          <HStack
            w="100%"
            alignSelf="flex-start"
            margin={isDesktop ? "2rem" : "0.5rem"}
            flexWrap="wrap"
          >
            {["base", "premium"].includes(
              session.partnerUser?.subscription || ""
            ) && (
              <Text
                color={mainTheme.colors.textPrimary}
                fontSize={["md", "lg"]}
                textAlign={"left"}
              >
                Attualmente hai {numPromo} promozioni create.
              </Text>
            )}
            {!["superlight-month", "superlight-year"].includes(
              session.partnerUser?.subscription || ""
            ) && (
              <Button
                colorScheme="teal"
                style={{ alignSelf: "flex-start" }}
                fontSize={["xs", "s", "md"]}
                disabled={
                  ["base-month", "base-year"].includes(
                    session.partnerUser?.subscription || ""
                  ) && numPromo >= 8
                }
                onClick={() => {
                  if (session.partnerUser) {
                    if (
                      session.partnerUser.brands.length > 0 &&
                      session.partnerUser.services.length > 0
                    )
                      onOpen();
                    else onOpenBrandsServicesError();
                  }
                }}
              >
                {t("common.addPromotion")}
              </Button>
            )}
          </HStack>
        </VStack>

        {messageError && (
          <Alert status="error">
            <AlertIcon />
            {messageError}
          </Alert>
        )}

        {messageSuccess && (
          <Alert status="success">
            <AlertIcon />
            {messageSuccess}
          </Alert>
        )}

        <Flex
          align={"center"}
          justify={"center"}
          w="100%"
          padding={isDesktop ? "2rem" : "0rem"}
          bgColor={mainTheme.colors.lightPurple}
        >
          <Flex align={"center"} justify={"center"} w="100%">
            <VStack
              w="100%"
              padding={isDesktop ? "2rem" : "0.5rem"}
              borderRadius={isDesktop ? "2rem" : "0rem"}
              bgColor={mainTheme.colors.textWhite}
            >
              <VStack w="100%" spacing="2px">
                <HStack w="100%" justifyContent={"space-between"}>
                  <Text
                    alignSelf="flex-start"
                    color={mainTheme.colors.textPrimary}
                    fontSize="lg"
                  >
                    Promozioni del tuo store
                  </Text>
                  <DownloadCSV
                    data={csvData}
                    target={"_blank"}
                    separator={";"}
                  />
                </HStack>

                {isFetching && (
                  <HStack alignSelf="flex-start">
                    <Text
                      alignSelf="flex-start"
                      color={mainTheme.colors.textPrimary}
                      fontSize="sm"
                    >
                      Scaricando le promo
                    </Text>
                    <Spinner />
                  </HStack>
                )}
                {isUpdatingPromo && (
                  <HStack alignSelf="flex-start">
                    <Text
                      alignSelf="flex-start"
                      color={mainTheme.colors.textPrimary}
                      fontSize="sm"
                    >
                      Aggiornando la promo
                    </Text>
                    <Spinner />
                  </HStack>
                )}
                {isCreatingPromo && (
                  <HStack alignSelf="flex-start">
                    <Text
                      alignSelf="flex-start"
                      color={mainTheme.colors.textPrimary}
                      fontSize="sm"
                    >
                      Aggiungendo la promo
                    </Text>
                    <Spinner />
                  </HStack>
                )}
              </VStack>
              {promosData &&
                promosData.parnerPromos.length > 0 &&
                isDesktop &&
                DesktopTable()}
              {promosData &&
                promosData.parnerPromos.length > 0 &&
                !isDesktop &&
                MobileTable()}
            </VStack>
          </Flex>
        </Flex>

        <Stack
          justify={"center"}
          w="100%"
          padding={isDesktop ? "2rem" : "0rem"}
          bgColor={mainTheme.colors.darkPurple}
          color={"#fff"}
        >
          <div>
            <b>Ricordiamo che:</b>
          </div>
          <div>
            Promozioni Locali e Nazionali Loovers potranno essere verificate
            tramite esportazione del report redemption promozioni (box
            sottostante). Queste promo sono a carico dell’attività.
          </div>
          <div>
            Promozioni Nazionali Brand : Entro 35 gg dalla chiusura della promo
            riceverai il report con il dettaglio degli scontrini rimborsabili.
            Il rimborso delle “Promo Nazionali Brand” avverrà, da parte del
            brand o azienda fornitrice dello stesso, nei 30 gg successivi alla
            ricezione del “report scontrini rimborsabili”
          </div>
        </Stack>

        <Flex
          w="100%"
          padding={isDesktop ? "2rem" : "0rem"}
          bgColor={mainTheme.colors.primaryCyan}
          justify={"center"}
          align={"center"}
        >
          <VStack
            w="100%"
            padding={isDesktop ? "2rem" : "0.5rem"}
            borderRadius={isDesktop ? "2rem" : "0rem"}
            bgColor={mainTheme.colors.textWhite}
          >
            <HStack w={"100%"}>
              <VStack w="100%" spacing="2px">
                <Text
                  alignSelf="flex-start"
                  color={mainTheme.colors.textPrimary}
                  fontSize="lg"
                >
                  Promozioni usate dai tuoi clienti
                </Text>
                {isDesktop && (
                  <Text
                    alignSelf="flex-start"
                    color={mainTheme.colors.textPrimary}
                    fontSize="sm"
                  >
                    Clicca sulla riga di una promo per visualizzarla
                  </Text>
                )}
              </VStack>
              {session.partnerUser &&
                session.partnerUser.promoUsed &&
                session.partnerUser.promoUsed.length > 0 && (
                  <DownloadCSV
                    data={promoCodeUsesCSVData}
                    target={"_blank"}
                    separator={";"}
                  />
                )}
            </HStack>

            {session.partnerUser &&
              session.partnerUser.promoUsed &&
              session.partnerUser.promoUsed.length > 0 && (
                <Accordion w="100%">
                  {session.partnerUser.promoUsed.map(
                    (p: Promo_Code_use, index: number) => {
                      return (
                        <AccordionItem
                          backgroundColor={
                            index % 2 === 0 ? "gray.100" : "white"
                          }
                          key={p.promo.id}
                          w="100%"
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            cursor: "pointer",
                          }}
                        >
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                <Text fontSize={["sm", "sm", "md", null]}>
                                  {p.customer.email}
                                </Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <VStack align="flex-start">
                              <Text>Nome: {p.customer.first_name}</Text>
                              <Text>Cognome: {p.customer.surname}</Text>
                              <Text>Titolo: {p.promo.email_title}</Text>
                              <Text>
                                {p.promo.is_national
                                  ? "Promozione nazionale"
                                  : "Promozione locale"}
                              </Text>
                              <Text>Data utilizzo: {p.used_date}</Text>

                              <Button
                                colorScheme="teal"
                                variant="outline"
                                size="sm"
                                onClick={() => handleOpenPromo(p.promo)}
                              >
                                Visualizza
                              </Button>
                              <Text></Text>
                            </VStack>
                          </AccordionPanel>
                        </AccordionItem>
                      );
                    }
                  )}
                </Accordion>
              )}
          </VStack>
        </Flex>
      </Flex>
      <Footer dark />
      <BrandsServicesLengthErrorModal
        isOpen={isOpenBrandsServicesError}
        onClose={() => {
          onCloseBrandsServicesError();
          setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
        }}
      />
      <CreatePromotionModal
        promo={activePromo}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setActivePromo(new Promo(new PromoBreed(-1, "", "", "None")));
        }}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        setActivity_id={setActivity_id}
        activity_id={activity_id}
        setSingle_brand_id={setSingle_brand_id}
        single_brand_id={single_brand_id}
        special_need={special_need}
        setSpecial_need={setSpecial_need}
      />
    </Box>
  );
};
