import { useLazyQuery } from "@apollo/client";
import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Field, FieldProps } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FETCH_SERVICES } from "../../../screens/PartnerSignUp/CompleteSignUpPartner/graphql";
import { MultiSelect } from "./MultiSelect";

export const ServicesField = ({
  setServicesIds,
}: {
  setServicesIds: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const { t } = useTranslation();

  const [servicesData, setServicesData] = useState<
    { value: string; label: string }[]
  >([]);

  const [fetchServices, { data }] = useLazyQuery(FETCH_SERVICES, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      const services: { value: string; label: string }[] = data.services.map(
        (item: any) => {
          return {
            value: item.id,
            label: t(`promoForm.${item.name}.label`),
          };
        }
      );

      setServicesData(services);

      const ids: string[] = data.services.map((item: any): string[] => {
        return item.id;
      });

      setServicesIds(ids);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  useEffect(() => {
    fetchServices();
  }, [fetchServices]);

  const handleOnChange = (values: any) => {
    let ids = values.map(
      (item: { value: string; label: string }) => item.value
    );
    setServicesIds(ids);
  };

  return (
    <Field name={"services"}>
      {({ field, form: { setFieldValue }, meta }: FieldProps) => (
        <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
          <FormLabel>{t("partner.services")}</FormLabel>
          <MultiSelect
            options={servicesData}
            onChange={(values) => {
              handleOnChange(values);
              setFieldValue(field.name, values);
            }}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
