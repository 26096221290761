// https://github.com/react-csv/react-csv/blob/master/src/components/Download.js
import { Button } from "@chakra-ui/button";
import React, { useCallback } from "react";

const defaultProps = {
  target: "_blank",
};

/**
 * Simple safari detection based on user agent test
 */
const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isJsons = (array: any) =>
  Array.isArray(array) &&
  array.every((row) => typeof row === "object" && !(row instanceof Array));

const isArrays = (array: any) =>
  Array.isArray(array) && array.every((row) => Array.isArray(row));

const jsonsHeaders = (array: any) =>
  Array.from(
    array
      .map((json: any) => Object.keys(json))
      .reduce((a: any, b: any) => new Set([...a, ...b]), [])
  );

const jsons2arrays = (jsons: any, headers: any) => {
  headers = headers || jsonsHeaders(jsons);

  // allow headers to have custom labels, defaulting to having the header data key be the label
  let headerLabels = headers;
  let headerKeys = headers;
  if (isJsons(headers)) {
    headerLabels = headers.map((header: any) => header.label);
    headerKeys = headers.map((header: any) => header.key);
  }

  const data = jsons.map((object: any) =>
    headerKeys.map((header: any) => getHeaderValue(header, object))
  );
  return [headerLabels, ...data];
};

const getHeaderValue = (property: any, obj: any) => {
  const foundValue = property
    .replace(/\[([^\]]+)]/g, ".$1")
    .split(".")
    .reduce((o: any, p: any, i: any, arr: any) => {
      // if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
      if (o[p] === undefined) {
        return arr.splice(1);
      } else {
        return o[p];
      }
    }, obj);
  // if at any point the nested keys passed do not exist then looks for key `property` in object obj
  return foundValue === undefined
    ? property in obj
      ? obj[property]
      : ""
    : foundValue;
};

const elementOrEmpty = (element: any) =>
  typeof element === "undefined" || element === null ? "" : element;

const joiner = (data: any, separator = ",", enclosingCharacter = '"') => {
  return data
    .filter((e: any) => e)
    .map((row: any) =>
      row
        .map((element: any) => elementOrEmpty(element))
        .map(
          (column: any) => `${enclosingCharacter}${column}${enclosingCharacter}`
        )
        .join(separator)
    )
    .join(`\n`);
};

const arrays2csv = (
  data: any,
  headers: any,
  separator: any,
  enclosingCharacter: any
) => joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter);

const jsons2csv = (
  data: any,
  headers: any,
  separator: any,
  enclosingCharacter: any
) => joiner(jsons2arrays(data, headers), separator, enclosingCharacter);

const string2csv = (
  data: any,
  headers: any,
  separator: any,
  enclosingCharacter = null
) => (headers ? `${headers.join(separator)}\n${data}` : data);

const toCSV = (
  data: any,
  headers: any,
  separator: any,
  enclosingCharacter: any
) => {
  if (isJsons(data))
    return jsons2csv(data, headers, separator, enclosingCharacter);
  if (isArrays(data))
    return arrays2csv(data, headers, separator, enclosingCharacter);
  if (typeof data === "string") return string2csv(data, headers, separator);
  throw new TypeError(
    `Data should be a "String", "Array of arrays" OR "Array of objects" `
  );
};

const buildURI = (
  data: any,
  uFEFF: any,
  headers: any,
  separator: any,
  enclosingCharacter: any,
  filename: any
) => {
  const csv = toCSV(data, headers, separator, enclosingCharacter);
  const type = isSafari() ? "application/csv" : "text/csv";
  const blob = new Blob([uFEFF ? "\uFEFF" : "", csv], { type });
  const dataURI = `data:${type};name=${filename};charset=utf-8,${
    uFEFF ? "\uFEFF" : ""
  }${csv}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === "undefined"
    ? dataURI
    : URL.createObjectURL(blob);
};

type Props = {
  data: string | any;
  headers?: any[];
  target?: string;
  separator?: string;
  filename?: string;
  uFEFF?: boolean;
};

export const DownloadCSV: React.FC<Props> = ({
  data,
  headers = [],
  target = "",
  separator = ",",
  filename = "export.csv",
  uFEFF = true,
}) => {
  const handleDownload = useCallback(() => {
    // window.open(
    //   buildURI(data, uFEFF, headers, separator, "", filename),
    //   target
    // );
    let link = document.createElement("a");
    link.download = filename;
    link.href = buildURI(data, uFEFF, headers, separator, "", filename);
    link.click();
  }, [data, uFEFF, headers, separator, target, filename]);

  return (
    <Button colorScheme={"purple"} variant={"ghost"} onClick={handleDownload}>
      Scarica report
    </Button>
  );
};
