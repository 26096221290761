const config = {
  NODE_ENV: process.env.NODE_ENV || "dev",
  DB_URL:
      process.env.REACT_APP_GRAPHQL_URL || "https://db.loovers.eu/",
  GRAPHQL_URL:
      process.env.REACT_APP_GRAPHQL_URL || "https://db.loovers.eu/graphql",
  API_BASE_URL:
      process.env.REACT_APP_API_BASE_URL || "https://db.loovers.eu/api/v1/",
  STRIPE_LIVE_KEY:
      "pk_live_51JAXCJBhe8TyYufvjhioxUjkMSaEMCicLF22Nnby0i8Xp1rBTPdd2IMk3trUPD1Mr4acTlNcZhDfz5xq2oYYJ7K400NqUtW87I",
  STRIPE_TEST_KEY:
      "sk_test_51JAXCJBhe8TyYufvQe093oji8S1SdVIykOvVoZYwBp5ht4H8F7Hf9bSyA8cl7aVR7Ek3uFsXivbEuZdICAunwxzg00ObrFlQ0u",
};
export const DB_URL =
    "https://db.loovers.eu/"; // "https://dbloovers.b01.me/"; //config.DB_URL;
export default config;



