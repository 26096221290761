export const phoneRegExp =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

export const CUSTOMER_SIGNUP_PRIVACY_POLICY_URL =
  "https://docs.google.com/document/d/e/2PACX-1vR5AQNMwxr-U2U-PCNstO01laspuwLNTCAIyfY5PfZFG8cMALuCwnZyc_aVvBwoBg/pub";

export const CUSTOMER_GENERAL_POLICY_URL =
  "https://docs.google.com/document/d/e/2PACX-1vRpD8gZzIwp9FL_F0bMmJ92ZOpyKNH5JEOoQDrddlP05ePFT7xQsCi887AmIH1QDw/pub";

export const PARTNER_SIGNUP_PRIVACY_POLICY_URL =
  "https://docs.google.com/document/d/e/2PACX-1vSXVaVfuNgob1_l76OYcfsRihLWysOj4BCJ6iHhS-RDq9rzhgf9qE7sSlrmwbgUpA/pub";

export const PARTNER_SIGNUP_TERMS_OF_USE_URL =
  "https://docs.google.com/document/d/e/2PACX-1vQoqG8zA-OCP9daCGBOi-rP45fdBmNZml2Trekr7plGxjVE0IXOxrn_EZx9zKKosQ/pub";

export const PARTNER_SIGNUP_ART_51_URL =
  "https://docs.google.com/document/d/e/2PACX-1vSm8SLQfVc9Z8I92wjn2nN_kQQ3tjRCmHT4tAyYKjLT8OILR1-XqJOSqWy5ifkIvQ/pub";

export const PARTNER_SIGNUP_GENERAL_POLICY =
  "https://docs.google.com/document/d/e/2PACX-1vSNdrMN9UB-rYVNoYPp3W1Xp-uD_PBndu7usjEjkAtCLqoryRj0i8FOMKtoEYRanQ/pub";

export const SUBSCRIPTION_TYPES = [
  "superlight-month",
  "base-month",
  "premium-month",
  "superlight-year",
  "base-year",
  "premium-year",
];

export const PARTNER_SUBSCRIPTION_PRICES = [
  4.95, 55.0, 19.95, 199.0, 34.95, 335.0,
];

export const MS_SEND_MAIL_API_KEY = "gSNs8uzJgqchGAvs";

export const STORE_TYPES = [
  "petshop",
  "toelettatore",
  "allevatore",
  "veterinario",
  "pensioni",
  "agriGarden",
];
