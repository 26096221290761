import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { UsersPerimeter } from "../../entities/Promo";

export const ProductUsersPerimeter = () => {
  const { t } = useTranslation();

  return (
    <Field name={"users_perimeter"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
          <FormLabel>{t("promoForm.usersPerimeter.label")}</FormLabel>
          <Select
            name={field.name}
            defaultValue={field.value}
            onChange={(event) => {
              setFieldValue(field.name, event.target.value);
            }}
          >
            <option value={UsersPerimeter.all}>
              {t("promoForm.usersPerimeter.all")}
            </option>
            <option value={UsersPerimeter.dog}>
              {t("promoForm.usersPerimeter.dog")}
            </option>
            <option value={UsersPerimeter.cat}>
              {t("promoForm.usersPerimeter.cat")}
            </option>
          </Select>
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
