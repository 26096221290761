import { Stack } from "@chakra-ui/layout";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  Avatar,
  Box,
  Button,
  Select,
  Text,
  useDisclosure,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/react";
import { useTheme } from "styled-components";
import { CustomerUser } from "../../../entities/CustomerUser";
import { phoneRegExp } from "../../../config/constants";
import { FileUploadModal } from "../../FileUploadModal";
import { mainTheme } from "../../../config/theme";
import { FormInput } from "../../FormInput";
import { DatePicker } from "../../DatePicker/DatePicker";
import { BrandsField } from "./BrandsField";
import { Customer_Brand } from "../../../entities/Customer_Brand";
import { DistrictsForm } from "../../PartnerPromotions/DistrictsForm";

const MAX_INPUT_WIDTH = "500px";

type CustomerSignUpFormType = {
  firstName: string;
  surname: string;
  email: string;
  username: string;
  profile_image: string;
  password: string;
  mobilePhone: string;
  gender: string;
  birthDate: Date;
  storeCode?: string;
  brandsIds: number[];
  district: {
    label: string;
    value: string;
  };
};

type Props = {
  customerUser: CustomerUser;
  isSubmitting: boolean;
  onSubmit: (values: CustomerSignUpFormType) => void;
};

export const EditProfileDetailsForm: React.FC<Props> = ({
  customerUser,
  isSubmitting,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const today = new Date();

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .required(t("auth.validations.firstNameRequired"))
        .min(2, t("auth.validations.firstNameRequired")),
      surname: Yup.string()
        .required(t("auth.validations.surnameRequired"))
        .min(2, t("auth.validations.surnameRequired")),
      district: Yup.object().required(t("auth.validations.districtRequired")),
      email: Yup.string()
        .required(t("auth.validations.emailRequired"))
        .min(2, t("auth.validations.emailRequired"))
        .email(t("auth.validations.emailInvalid")),
      username: Yup.string()
        .required(t("auth.validations.usernameRequired"))
        .min(3, t("auth.validations.min3"))
        .max(16, t("auth.validations.max16")),
      password: Yup.string().min(6, t("auth.validations.passwordRequired")),
      mobilePhone: Yup.string()
        .nullable()
        .notRequired()
        .when("mobilePhone", {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(phoneRegExp, t("auth.validations.mobilePhoneInvalid")),
        }),
      gender: Yup.string()
        .required(t("auth.validations.genderRequired"))
        .min(1, t("auth.validations.genderRequired"))
        .oneOf(["M", "F", "Other"]),
      birthDate: Yup.date().required(t("auth.validations.birthDateRequired")),
      confirmPassword: Yup.string()
        .min(6, t("auth.validations.confirmPasswordRequired"))
        .oneOf(
          [Yup.ref("password"), null],
          t("auth.validations.passwordsNotMatch")
        ),
    },
    [["mobilePhone", "mobilePhone"]]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Formik
      initialValues={{
        profile_image: customerUser.profile_image,
        firstName: customerUser.firstName,
        surname: customerUser.surname,
        district: {
          label: customerUser.district,
          value: customerUser.district,
        },
        email: customerUser.email,
        username: customerUser.username,
        password: "",
        mobilePhone: customerUser.mobilePhone,
        gender: customerUser.gender,
        birthDate: new Date(customerUser.birthDate),
        storeCode: customerUser.storeCode,
        brandsIds: customerUser.brand.map((item: Customer_Brand) => {
          return item.brand.id;
        }),
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Stack spacing={"1rem"} alignItems={"center"}>
            <Box maxW={MAX_INPUT_WIDTH} w={"100%"}>
              <Field name={"profile_image"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"profile_image"}>
                      {t("customer.profile_image")}
                    </FormLabel>
                    <FileUploadModal
                      isOpen={isOpen}
                      onClose={onClose}
                      maxFiles={1}
                      accept={"image/jpeg"}
                      onFileUploaded={(fileUrls: string[]) => {
                        if (fileUrls.length === 1) {
                          setFieldValue(field.name, fileUrls[0]);
                        }
                      }}
                    />
                    <HStack
                      spacing={"2rem"}
                      borderRadius={"1rem"}
                      padding="1rem"
                      bgColor={mainTheme.colors.textWhite}
                    >
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          onOpen();
                        }}
                      >
                        {t("partner.upload")}
                      </Button>
                      {field.value !== "" && (
                        <Avatar
                          showBorder
                          size="md"
                          src={field.value}
                          bg="teal.500"
                        />
                      )}
                    </HStack>
                  </FormControl>
                )}
              </Field>

              <Field name={"firstName"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"firstName"}>
                      {t("customer.firstName")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"surname"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"surname"}>
                      {t("customer.surname")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"district"}>
                {({ field, form: { setFieldValue }, meta }: any) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"district"}>
                      {t("customer.district")} *
                    </FormLabel>
                    <DistrictsForm
                      districts={props.values.district}
                      canDisableFields={false}
                      setFieldValue={setFieldValue}
                      promo={undefined}
                      field={field}
                      isUser
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"email"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"email"}>
                      {t("customer.email")} *
                    </FormLabel>
                    <FormInput
                      type={"email"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"username"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"username"}>
                      {t("customer.username")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"mobilePhone"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"mobilePhone"}>
                      {t("customer.mobilePhone")}
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormHelperText color={mainTheme.colors.textWhite}>
                      {t("auth.helper.customer.editProfile.mobilePhone")}
                    </FormHelperText>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"gender"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"gender"}>
                      {t("customer.gender")} *
                    </FormLabel>
                    <Select
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      placeholder={t("common.select")}
                      backgroundColor={mainTheme.colors.background}
                      color={mainTheme.colors.textPrimary}
                    >
                      <option value={"M"}>{t("customer.male")}</option>
                      <option value={"F"}>{t("customer.female")}</option>
                      <option value={"Other"}>{t("customer.other")}</option>
                    </Select>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"birthDate"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"birthDate"}>
                      {t("customer.birthDate")} *
                    </FormLabel>
                    <Box color={mainTheme.colors.textPrimary}>
                      <DatePicker
                        name={field.name}
                        onChange={(newDate: any) =>
                          setFieldValue(field.name, newDate)
                        }
                        value={field.value}
                        maxDate={today}
                        theme={"dark"}
                        required={true}
                      />
                    </Box>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"password"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"password"}>
                      {t("auth.password")}
                    </FormLabel>
                    <FormInput
                      type={"password"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"confirmPassword"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"confirmPassword"}>
                      {t("auth.confirmPassword")}
                    </FormLabel>
                    <FormInput
                      type={"password"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"storeCode"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"storeCode"}>
                      {t("customer.storeCode")}
                    </FormLabel>
                    <FormInput
                      type={"storeCode"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormHelperText color={mainTheme.colors.textWhite}>
                      {t("auth.helper.customer.storeCode")}
                    </FormHelperText>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"brandsIds"}>
                {({ field, form: { setFieldValue }, meta }: any) => (
                  <FormControl isInvalid={meta.description && meta.touched}>
                    <FormLabel>{t("customer.brands")}</FormLabel>
                    <BrandsField
                      customerUser={customerUser}
                      field={field}
                      setFieldValue={setFieldValue}
                    />
                    <FormHelperText color={mainTheme.colors.textWhite}>
                      {t("auth.helper.customer.brandsIds")}
                    </FormHelperText>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Text fontSize="md" fontWeight={"bold"} marginTop={"3rem"}>
                * {t("auth.mandatoryFields")}
              </Text>
            </Box>

            <Button
              type={"submit"}
              colorScheme={"blue"}
              disabled={isSubmitting}
            >
              {t("common.confirm")}
            </Button>
            {!props.isValid && props.submitCount > 0 && (
              <Text fontSize="sm" color={theme.colors.danger}>
                {t("auth.messages.formError")}
              </Text>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
