import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Spinner,
  VStack,
  Text,
} from "@chakra-ui/react";
import { MS_SEND_MAIL_API_KEY } from "../config/constants";

const baseStyle = {
  flex: 1,
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

type Props = {
  isOpen: boolean;
  onClose: () => void;
  accept?: string | undefined; //'image/jpeg, image/png'
  minFiles?: number;
  maxFiles: number;
  exactWidth?: number | undefined;
  exactHeight?: number | undefined;
  minWidth?: number | undefined;
  maxWidth?: number | undefined;
  minHeight?: number | undefined;
  maxHeight?: number | undefined;
  onFileUploaded: (fileUrls: string[]) => void;
};

export const FileUploadModal: React.FC<Props> = ({
                                                   isOpen,
                                                   onClose,
                                                   accept,
                                                   minFiles,
                                                   maxFiles,
                                                   exactWidth,
                                                   exactHeight,
                                                   minWidth,
                                                   maxWidth,
                                                   minHeight,
                                                   maxHeight,
                                                   onFileUploaded,
                                                 }) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [minFilesError, setMinFilesError] = useState<boolean>(false);

  const onDrop = (files: File[]) => {
    if (minFiles) {
      if (files.length < minFiles) {
        setMinFilesError(true);
      } else {
        setMinFilesError(false);
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept,
    maxFiles: maxFiles,
    onDrop,
  });

  const dNDStyle = useMemo(
      () => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isDragActive, isDragReject, isDragAccept]
  );

  const handleUpload = useCallback(async () => {
    if (!minFilesError && acceptedFiles.length > 0) {
      setIsUploading(true);
      const formData = new FormData();

      // Se vuoi caricare più file, appendili tutti a formData
      acceptedFiles.forEach(file => {
        formData.append('files', file); // Assicurati che il backend sia configurato per accettare 'files' come chiave

      });

      try {


        const response = await fetch(`https://dbloovers.b01.me/upload`, {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }


        const fileUrls = await response.json(); // Assumi che il server ritorni un array di URL o un oggetto simile
        console.log(fileUrls);
        const urls = fileUrls.files.map(file => file.path);
        onFileUploaded(urls);
        onClose(); // Assumi che onClose sia una funzione per chiudere il modal di upload o un'azione simile
      } catch (err) {
        alert(t("common.error"));
        console.error(err);
      } finally {
        setIsUploading(false);
      }
    }
  }, [minFilesError, onFileUploaded, onClose, acceptedFiles, t]);


  const fileList = acceptedFiles.map((file) => (
      <li key={file.name}>{file.name}</li>
  ));

  return (
      <>
        {isOpen && (
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader id="alert-upload-title">
                  {t("qrCodeReaderModal.upload")}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <VStack align="flex-start" spacing="0.5rem" marginBottom="1rem">
                    {minFiles && (
                        <Text>
                          {t("fileUploadModal.minFiles") +
                              ` ${minFiles} ` +
                              t("fileUploadModal.photos")}
                        </Text>
                    )}
                    {exactWidth && (
                        <Text>
                          {t("fileUploadModal.exactWidth") + ` ${exactWidth}px`}
                        </Text>
                    )}
                    {exactHeight && (
                        <Text>
                          {t("fileUploadModal.exactHeight") + ` ${exactHeight}px`}
                        </Text>
                    )}
                    {minWidth && (
                        <Text>
                          {t("fileUploadModal.minWidth") + ` ${minWidth}px`}
                        </Text>
                    )}
                    {maxWidth && (
                        <Text>
                          {t("fileUploadModal.maxWidth") + ` ${maxWidth}px`}
                        </Text>
                    )}
                    {minHeight && (
                        <Text>
                          {t("fileUploadModal.minHeight") + ` ${minHeight}px`}
                        </Text>
                    )}
                    {maxHeight && (
                        <Text>
                          {t("fileUploadModal.maxHeight") + ` ${maxHeight}px`}
                        </Text>
                    )}
                  </VStack>
                  <div {...getRootProps({ style: dNDStyle })}>
                    <input {...getInputProps()} />
                    {t("qrCodeReaderModal.dragNDrop")}
                  </div>
                  <aside>
                    <ul>{fileList}</ul>
                  </aside>
                </ModalBody>

                <ModalFooter>
                  {isUploading && <Spinner colorScheme="purple" />}
                  <Button onClick={onClose}>{t("common.cancel")}</Button>
                  <Button
                      onClick={handleUpload}
                      colorScheme={"green"}
                      disabled={
                          acceptedFiles.length === 0 || isUploading || minFilesError
                      }
                  >
                    {t("qrCodeReaderModal.upload")}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
        )}
      </>
  );
};
