import { Stack, FormLabel, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { StoreOpeningDaysObject } from "../../../entities/PartnerUser";
import { DayFieldForm } from "./DayFieldForm";

enum Day {
  monday = "monday",
  tuesday = "tuesday",
  wednesday = "wednesday",
  thursday = "thursday",
  friday = "friday",
  saturday = "saturday",
  sunday = "sunday",
}

export const OpeningTimeForm = ({
  isDashboard = false,
  setOpeningTimeJson,
  store_opening_days = {
    monday: "",
    tuesday: "",
    wednesday: "",
    thursday: "",
    friday: "",
    saturday: "",
    sunday: "",
  },
}: {
  isDashboard?: boolean;
  setOpeningTimeJson: React.Dispatch<
    React.SetStateAction<StoreOpeningDaysObject | undefined>
  >;
  store_opening_days?: StoreOpeningDaysObject;
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [monday, setMonday] = useState(
    isDashboard ? store_opening_days.monday : ""
  );
  const [tuesday, setTuesday] = useState(
    isDashboard ? store_opening_days.tuesday : ""
  );
  const [wednesday, setWednesday] = useState(
    isDashboard ? store_opening_days.wednesday : ""
  );
  const [thursday, setThursday] = useState(
    isDashboard ? store_opening_days.thursday : ""
  );
  const [friday, setFriday] = useState(
    isDashboard ? store_opening_days.friday : ""
  );
  const [saturday, setSaturday] = useState(
    isDashboard ? store_opening_days.saturday : ""
  );
  const [sunday, setSunday] = useState(
    isDashboard ? store_opening_days.sunday : ""
  );

  useEffect(() => {
    const days: StoreOpeningDaysObject = {
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thursday: thursday,
      friday: friday,
      saturday: saturday,
      sunday: sunday,
    };

    setOpeningTimeJson(days);
  }, [
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    setOpeningTimeJson,
  ]);

  return (
    <Stack>
      <FormLabel>{t("partner.openingTime")} *</FormLabel>

      <HStack
        w="100%"
        wrap="wrap"
        bgColor={"white"}
        padding={isDesktop ? "2rem" : "0.5rem"}
        spacing="0px"
        borderRadius="2xl"
        justify="space-around"
      >
        <DayFieldForm
          isDashboard={isDashboard}
          dayName={Day.monday}
          store_opening_day={monday}
          setOpeningTime={setMonday}
        />
        <DayFieldForm
          isDashboard={isDashboard}
          dayName={Day.tuesday}
          store_opening_day={tuesday}
          setOpeningTime={setTuesday}
        />
        <DayFieldForm
          isDashboard={isDashboard}
          dayName={Day.wednesday}
          store_opening_day={wednesday}
          setOpeningTime={setWednesday}
        />
        <DayFieldForm
          isDashboard={isDashboard}
          dayName={Day.thursday}
          store_opening_day={thursday}
          setOpeningTime={setThursday}
        />
        <DayFieldForm
          isDashboard={isDashboard}
          dayName={Day.friday}
          store_opening_day={friday}
          setOpeningTime={setFriday}
        />
        <DayFieldForm
          isDashboard={isDashboard}
          dayName={Day.saturday}
          store_opening_day={saturday}
          setOpeningTime={setSaturday}
        />
        <DayFieldForm
          isDashboard={isDashboard}
          dayName={Day.sunday}
          store_opening_day={sunday}
          setOpeningTime={setSunday}
          isCheckboxDisabled={true}
        />
      </HStack>
    </Stack>
  );
};
