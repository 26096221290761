import { gql } from "@apollo/client";

export const FETCH_EVERY_PRODUCTS = gql`
  query FetchProducts {
    products {
      id
      name
      description
      product_images
      brand {
        id
        name
      }
      brandId
      company
      segment
      users_perimeter
      special_need_radio
      special_needs {
        id
        name
      }
      size
      country_of_production
      flavor
      available_formats
      ingredients_features
      ean_code
      recommendations
      greenFootprintRating
      greenFootprintComment
    }
  }
`;

export const FETCH_ADMIN_PRODUCTS = gql`
  query FetchProducts($adminId: Int!) {
    admin(where: { id: $adminId }) {
      products {
        id
        name
        description
        product_images
        brand {
          id
          name
        }
        brandId
        company
        segment
        users_perimeter
        special_need_radio
        special_needs {
          id
          name
        }
        size
        country_of_production
        flavor
        available_formats
        ingredients_features
        ean_code
        recommendations
        greenFootprintRating
        greenFootprintComment
      }
    }
  }
`;

export const CREATE_ADMIN_PRODUCT = gql`
  mutation CreateProduct(
    $adminId: Int!
    $name: String!
    $description: String!
    $product_images: String!
    $brandId: Int!
    $company: String!
    $segment: String!
    $users_perimeter: String!
    $special_need_radio: String!
    $special_needs_id: Int!
    $size: String!
    $country_of_production: String!
    $flavor: String!
    $available_formats: String!
    $ingredients_features: String!
    $ean_code: String!
    $recommendations: String!
    $greenFootprintRating: Int!
    $greenFootprintComment: String!
  ) {
    createAdminProduct(
      adminId: $adminId
      name: $name
      product_images: $product_images
      description: $description
      brandId: $brandId
      company: $company
      segment: $segment
      users_perimeter: $users_perimeter
      special_need_radio: $special_need_radio
      special_needs_id: $special_needs_id
      size: $size
      country_of_production: $country_of_production
      flavor: $flavor
      available_formats: $available_formats
      ingredients_features: $ingredients_features
      ean_code: $ean_code
      recommendations: $recommendations
      greenFootprintRating: $greenFootprintRating
      greenFootprintComment: $greenFootprintComment
    ) {
      id
      name
      description
    }
  }
`;

export const UPDATE_ADMIN_PRODUCT = gql`
  mutation UpdateProduct(
    $id: Int!
    $name: String!
    $description: String!
    $product_images: String!
    $brandId: Int!
    $company: String!
    $segment: String!
    $users_perimeter: String!
    $special_need_radio: String!
    $special_needs_id: Int!
    $size: String!
    $country_of_production: String!
    $flavor: String!
    $available_formats: String!
    $ingredients_features: String!
    $ean_code: String!
    $recommendations: String!
    $greenFootprintRating: Int!
    $greenFootprintComment: String!
  ) {
    updateAdminProduct(
      id: $id
      name: $name
      product_images: $product_images
      description: $description
      brandId: $brandId
      company: $company
      segment: $segment
      users_perimeter: $users_perimeter
      special_need_radio: $special_need_radio
      special_needs_id: $special_needs_id
      size: $size
      country_of_production: $country_of_production
      flavor: $flavor
      available_formats: $available_formats
      ingredients_features: $ingredients_features
      ean_code: $ean_code
      recommendations: $recommendations
      greenFootprintRating: $greenFootprintRating
      greenFootprintComment: $greenFootprintComment
    ) {
      id
      name
      description
    }
  }
`;

export const DELETE_ADMIN_PRODUCT = gql`
  mutation DeleteProduct($id: Int!) {
    deleteAdminProduct(id: $id) {
      id
    }
  }
`;
