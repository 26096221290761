import {
  AspectRatio,
  Button,
  useDisclosure,
  Image,
  HStack,
  Text,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import { mainTheme } from "../../config/theme";
import { useTranslation } from "react-i18next";
import { FileUploadModal } from "../FileUploadModal";
import { useState } from "react";
import { useEffect } from "react";

type Props = {
  storePhotos: string[];
  onStorePhotosUpdated: (fileUrls: string[]) => void;
  onDeletePhoto: (fileUrl: string) => void;
};

export const StorePhotos = ({
  storePhotos,
  onStorePhotosUpdated,
  onDeletePhoto,
}: Props) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenImg,
    onOpen: onOpenImg,
    onClose: onCloseImg,
  } = useDisclosure();
  const [currentUrl, setCurrentUrl] = useState<string>();

  const minFiles = 1,
    maxFiles = 10,
    exactWidth = 1024,
    exactHeight = 768;

  const baseStyle = {
    flex: 1,
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#2196f3",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  useEffect(() => {
    if (currentUrl) onOpenImg();
  }, [currentUrl, onOpenImg]);

  return (
    <VStack align="flex-start" marginBottom="2rem" w="100%">
      <Modal isOpen={isOpenImg} onClose={onCloseImg}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {t("partnerdashboard.storePhotosModalTitle")}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio
              w={"100%"}
              ratio={exactWidth / exactHeight}
              align={"center"}
              border={"1px solid lightgrey"}
              p={4}
              boxShadow="md"
            >
              <Image src={currentUrl} />
            </AspectRatio>
          </ModalBody>
          <ModalFooter>
            <Button
              variant="solid"
              colorScheme="red"
              onClick={() => {
                if (currentUrl) {
                  onDeletePhoto(currentUrl);
                  onCloseImg();
                }
              }}
            >
              {t("common.delete")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Text
        fontSize={["lg", "xl"]}
        marginBottom="1rem"
        color={mainTheme.colors.textPrimary}
      >
        {t("partnerdashboard.uploadStorePhotos")}
      </Text>
      <VStack
        border={"1px solid lightgrey"}
        p={4}
        borderRadius={"2xl"}
        boxShadow="md"
        w="100%"
      >
        <div style={baseStyle}>
          <Button
            onClick={onOpen}
            colorScheme="teal"
            variant="outline"
            size="sm"
          >
            {storePhotos.length > 0
              ? t("partnerdashboard.uploadOrUpdateStorePhotos")
              : t("partnerdashboard.clickHere")}
          </Button>
        </div>
        <HStack w="100%" wrap="wrap" justify="space-around" spacing={"0px"}>
          {storePhotos.map((url: string, index: number) => {
            const Img = () => {
              return <Image src={url} />;
            };

            return (
              <AspectRatio
                key={url}
                minW="10rem"
                maxW="56rem"
                w={"22%"}
                style={{ marginBottom: "1rem" }}
                ratio={exactWidth / exactHeight}
                align={"center"}
                border={"1px solid lightgrey"}
                p={4}
                boxShadow="md"
                cursor={"pointer"}
                onClick={() => setCurrentUrl(url)}
              >
                <Img />
              </AspectRatio>
            );
          })}
        </HStack>
      </VStack>
      <FileUploadModal
        isOpen={isOpen}
        onClose={onClose}
        minFiles={minFiles}
        maxFiles={maxFiles}
        // exactWidth={exactWidth}
        // exactHeight={exactHeight}
        accept={"image/jpeg"}
        onFileUploaded={onStorePhotosUpdated}
      />
    </VStack>
  );
};
