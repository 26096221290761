import styled from "styled-components";

export const StyledHeader = styled.nav`
  padding: 28px 20px;
  height: 52px;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const HeaderLogo = styled.img`
  width: 170px;
  height: auto;
`;
