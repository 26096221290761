import { makeAutoObservable } from "mobx";
import api from "../services/api";

import { Stores } from "../types";

export class Stripe {
  isSubmitting: boolean;
  stripeCheckoutError: boolean;
  stripeSessionCheckoutError: boolean;
  stripePortalError: boolean;
  stripePortalSuccess: boolean;

  private stores: Stores;

  constructor(stores: Stores) {
    this.stores = stores;
    this.stripeCheckoutError = false;
    this.stripeSessionCheckoutError = false;
    this.stripePortalError = false;
    this.stripePortalSuccess = false;

    this.isSubmitting = false;

    makeAutoObservable(this);
  }

  stripeCheckout = async (couponId: string) => {
    this.stripeCheckoutError = false;
    this.isSubmitting = true;

    try {
      await api.stripeCheckout(
        this.stores.session.partnerUser!.emailB2B,
        couponId
      );
    } catch (err: any) {
      console.log(err);
      if (err.message === "logout") {
        this.stores.session.logout();
        this.stores.session.setSessionError(true);
      }
      this.stripeCheckoutError = true;
    } finally {
      this.isSubmitting = false;
    }
  };

  stripeSessionCheckout = async (sessionId: string) => {
    this.stripeSessionCheckoutError = false;
    this.isSubmitting = true;

    try {
      const userData = await api.stripeSessionCheckout(
        sessionId,
        this.stores.session.partnerUser!.emailB2B
      );
      if (userData) {
        this.stores.session.mapPartnerUserData(userData);
      }
    } catch (err) {
      console.log(err);
      this.stripeSessionCheckoutError = true;
      return false;
    } finally {
      this.isSubmitting = false;
      return true;
    }
  };

  stripeAccessPortal = async (): Promise<string> => {
    this.stripePortalError = false;
    this.stripePortalSuccess = false;
    this.isSubmitting = true;

    try {
      const portalSessionUrl = await api.stripeAccessPortal(
        this.stores.session.partnerUser!.stripe_id,
        this.stores.session.partnerUser!.emailB2B
      );
      return portalSessionUrl;
    } catch (err) {
      console.log(err);
      if (err.message === "logout") {
        this.stores.session.logout();
        this.stores.session.setSessionError(true);
      }
      this.stripePortalError = true;
      return "";
    } finally {
      this.isSubmitting = false;
    }
  };
}
