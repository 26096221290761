import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Promo } from "../../entities/Promo";
import { FETCH_PROVINCES } from "../../screens/PartnerPromotions/graphql";

type Province = {
  id: number;
  nome: string;
  idregione: number;
  sigla: string;
};

export interface item {
  value: string;
  label: string;
}

type Props = {
  field: any;
  districts: item;
  canDisableFields: boolean;
  setFieldValue: (name: string, value: any) => void;
  promo: Promo | undefined;
  isUser?: boolean;
};

export const DistrictsForm = ({
  districts,
  canDisableFields,
  setFieldValue,
  promo,
  field,
  isUser = false,
}: Props) => {
  const [data, setData] = useState<Province[]>([]);

  const [fetchAllProvinces, { data: dataFetchProvince }] = useLazyQuery(
    FETCH_PROVINCES,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const province: Province[] = dataFetchProvince.provinces.map(
          (item: Province) => {
            return {
              id: item.id,
              nome: item.nome,
              idregione: item.idregione,
              sigla: item.sigla,
            };
          }
        );

        const pFound: Province | undefined = isUser
          ? undefined
          : province.find((p) => p.nome === "Tutte");

        if (pFound) {
          const i: item = {
            label: pFound.nome,
            value: pFound.sigla.toString(),
          };

          if (promo === undefined) {
            if (field.value === "") setFieldValue(field.name, i);
            else {
              const f = province.find(
                (p) => p.sigla === districts.value.toString()
              );

              setFieldValue(field.name, {
                label: f ? f.nome.toString() : "Tutte",
                value: f ? f.sigla.toString() : "Tutte",
              });
            }
          } else if (promo.id === -1) {
            setFieldValue(field.name, i);
          } else {
            const f = province.find(
              (p) => p.sigla === districts.value.toString()
            );

            setFieldValue(field.name, {
              label: f ? f.nome.toString() : "Tutte",
              value: f ? f.sigla.toString() : "Tutte",
            });
          }
        }

        province.sort(function (a: Province, b: Province) {
          if (a.nome < b.nome) {
            return -1;
          }
          if (a.nome > b.nome) {
            return 1;
          }
          return 0;
        });

        const orderedArr: Province[] = [];

        province.forEach((p) => {
          if (p.nome === "Tutte") orderedArr.unshift(p);
          else orderedArr.push(p);
        });

        if (isUser) orderedArr.shift();

        if (pFound === undefined) {
          const i: item = {
            label: orderedArr[0].nome,
            value: orderedArr[0].sigla,
          };

          if (field.value === "") setFieldValue(field.name, i);
          else {
            const f = province.find(
              (p) => p.sigla === districts.value.toString()
            );

            setFieldValue(field.name, {
              label: f ? f.nome.toString() : i.label,
              value: f ? f.sigla.toString() : i.value,
            });
          }
        }

        setData(orderedArr);
      },
      onError: (error) => {
        console.log("FETCH_PROVINCES", error);
      },
    }
  );

  useEffect(() => {
    fetchAllProvinces();
  }, [fetchAllProvinces]);

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      color: "black",
    }),
    control: (provided: any) => ({
      ...provided,
      color: "black",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "black",
    }),
  };

  return (
    <Select
      isDisabled={canDisableFields}
      isMulti={false}
      isSearchable
      name="colors"
      value={field.value}
      options={data.map((pb: Province): item => {
        return {
          label: pb.nome.toString(),
          value: pb.sigla.toString(),
        };
      })}
      className="basic-multi-select"
      classNamePrefix="select"
      onChange={(i: item | null) => {
        if (i !== null) {
          setFieldValue(field.name, i);
        }
      }}
      styles={customStyles}
    />
  );
};
