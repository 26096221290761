import {
  Image,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  src: string;
};

export const StoreImageModal = ({ isOpen, onClose, onDelete, src }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("partnerdashboard.storePhotosModalTitle")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Image w={"100%"} src={src} />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="solid"
            colorScheme="red"
            onClick={() => {
              if (src) {
                onDelete();
                onClose();
              }
            }}
          >
            {t("common.delete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
