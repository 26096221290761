import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Button,
  Box,
  HStack,
  useDisclosure,
  VStack,
  Image,
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { mainTheme } from "../../../config/theme";
import { StoreOpeningDaysObject } from "../../../entities/PartnerUser";
import { FileUploadModal } from "../../FileUploadModal";
import { FormInput } from "../../FormInput";
import { StoreImageModal } from "../../PartnerDashboard/StoreImageModal";
import { DistrictsForm } from "../../PartnerPromotions/DistrictsForm";
import { BrandsField } from "./BrandsField";
import { OpeningTimeForm } from "./OpeningTimeForm";
import { ServicesField } from "./ServicesField";

interface Props {
  onSubmit: (values: any) => void;
  isSubmitting: boolean;
  setOpeningTimeJson: React.Dispatch<
    React.SetStateAction<StoreOpeningDaysObject | undefined>
  >;
  setBrandsIds: React.Dispatch<React.SetStateAction<string[]>>;
  setServicesIds: React.Dispatch<React.SetStateAction<string[]>>;
}

export const CompleteSignUpPartnerForm: React.FC<Props> = ({
  onSubmit,
  isSubmitting,
  setOpeningTimeJson,
  setBrandsIds,
  setServicesIds,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    firstNameB2B: Yup.string().required(
      t("auth.validations.firstNameRequired")
    ),
    surnameB2B: Yup.string().required(t("auth.validations.surnameRequired")),
    addressStore: Yup.string()
      .required(t("auth.validations.addressStoreRequired"))
      .min(2, t("auth.validations.addressStoreRequired")),
    addressStoreCity: Yup.string()
      .required(t("auth.validations.addressStoreCityRequired"))
      .min(2, t("auth.validations.addressStoreCityRequired")),
    addressStoreCap: Yup.string()
      .typeError(t("auth.validations.addressBusinessCapNumber"))
      .required(t("auth.validations.addressStoreCapRequired"))
      .min(2, t("auth.validations.addressStoreCapRequired")),
    services: Yup.array().min(1, t("auth.validations.serviceIdsRequired")),
    brands: Yup.array().min(1, t("auth.validations.brandsIdsRequired")),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenStoreImage,
    onOpen: onOpenStoreImage,
    onClose: onCloseStoreImage,
  } = useDisclosure();

  return (
    <Formik
      initialValues={{
        store_image: "",
        firstNameB2B: "",
        surnameB2B: "",
        openingTime: "",
        addressStore: "",
        addressStoreCity: "",
        addressStoreDistrict: {
          label: "",
          value: "",
        },
        addressStoreCap: "",
        addressStore2: "",
        addressStore2City: "",
        addressStore2District: {
          label: "",
          value: "",
        },
        addressStore2Cap: "",
        otherServiceOne: "",
        otherServiceTwo: "",
        services: [],
        brands: [],
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box maxW={"50rem"} w={"100%"} justify="center">
            <Field name={"firstNameB2B"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"firstNameB2B"}>
                    {t("partner.firstNameB2B")} *
                  </FormLabel>
                  <FormInput
                    name={field.name}
                    value={field.value}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    $signUpForm
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"surnameB2B"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"surnameB2B"}>
                    {t("partner.surnameB2B")} *
                  </FormLabel>
                  <FormInput
                    name={field.name}
                    value={field.value}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    $signUpForm
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Text marginBottom={"0.5em"}>{t("partner.addressStore")}</Text>
            <Field name={"addressStore"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"addressStoreStreet"} $signUpForm>
                    {t("partner.addressBusinessStreet")} *
                  </FormLabel>
                  <FormInput
                    name={field.name}
                    value={field.value}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    $signUpForm
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <HStack>
              <Field name={"addressStoreCity"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"addressStoreCity"} $signUpForm>
                      {t("partner.addressBusinessCity")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"addressStoreDistrict"}>
                {({ field, form: { setFieldValue }, meta }: any) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"addressStoreDistrict"} $signUpForm>
                      {t("partner.addressBusinessDistrict")} *
                    </FormLabel>
                    <DistrictsForm
                      districts={props.values.addressStoreDistrict}
                      canDisableFields={false}
                      setFieldValue={setFieldValue}
                      promo={undefined}
                      field={field}
                      isUser
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"addressStoreCap"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={Boolean(meta.error) && meta.touched}
                    w={"200px"}
                  >
                    <FormLabel htmlFor={"addressStoreCap"} $signUpForm>
                      {t("partner.addressBusinessCap")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </HStack>

            <Text marginBottom={"0.5em"}>{t("partner.addressStore")} 2</Text>
            <Field name={"addressStore2"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"addressStore2Street"} $signUpForm>
                    {t("partner.addressBusinessStreet")}
                  </FormLabel>
                  <FormInput
                    name={field.name}
                    value={field.value}
                    onChange={(event: any) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    $signUpForm
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <HStack>
              <Field name={"addressStore2City"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"addressStore2City"} $signUpForm>
                      {t("partner.addressBusinessCity")}
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"addressStore2District"}>
                {({ field, form: { setFieldValue }, meta }: any) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"addressStore2District"} $signUpForm>
                      {t("partner.addressBusinessDistrict")} *
                    </FormLabel>
                    <DistrictsForm
                      districts={props.values.addressStoreDistrict}
                      canDisableFields={false}
                      setFieldValue={setFieldValue}
                      promo={undefined}
                      field={field}
                      isUser
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"addressStore2Cap"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={Boolean(meta.error) && meta.touched}
                    w={"200px"}
                  >
                    <FormLabel htmlFor={"addressStore2Cap"} $signUpForm>
                      {t("partner.addressBusinessCap")}
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </HStack>

            <OpeningTimeForm setOpeningTimeJson={setOpeningTimeJson} />

            <VStack spacing={"0px"}>
              <ServicesField setServicesIds={setServicesIds} />
              <Field name={"otherServiceOne"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"otherServiceOne"} $signUpForm>
                      {t("partner.otherServiceOne")}
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={"otherServiceTwo"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"otherServiceTwo"} $signUpForm>
                      {t("partner.otherServiceTwo")}
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </VStack>

            <BrandsField setBrandsIds={setBrandsIds} />

            <Field name={"store_image"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"store_image"}>
                    {t("partner.store_image")}
                  </FormLabel>
                  <FileUploadModal
                    isOpen={isOpen}
                    onClose={onClose}
                    maxFiles={1}
                    maxHeight={500}
                    accept={"image/jpeg"}
                    onFileUploaded={(fileUrls: string[]) => {
                      if (fileUrls.length === 1) {
                        setFieldValue(field.name, fileUrls[0]);
                      }
                    }}
                  />
                  <VStack
                    borderRadius={"0.5rem"}
                    padding="0.5rem"
                    bgColor={mainTheme.colors.textWhite}
                  >
                    <Button
                      colorScheme="teal"
                      variant="outline"
                      size="sm"
                      onClick={() => {
                        onOpen();
                      }}
                    >
                      {t("partner.upload")}
                    </Button>
                    {field.value !== "" && (
                      <Image
                        border={"1px solid lightgrey"}
                        p={4}
                        boxShadow="md"
                        onClick={onOpenStoreImage}
                        src={field.value}
                        height={"150px"}
                      />
                    )}
                    <StoreImageModal
                      isOpen={isOpenStoreImage}
                      onClose={onCloseStoreImage}
                      src={field.value}
                      onDelete={() => {
                        setFieldValue(field.name, "");
                      }}
                    />
                  </VStack>
                </FormControl>
              )}
            </Field>

            <Button
              type={"submit"}
              colorScheme={"blue"}
              disabled={isSubmitting}
            >
              {t("common.confirm")}
            </Button>
            {!props.isValid && props.submitCount > 0 && (
              <Text fontSize="sm" color={mainTheme.colors.danger}>
                {t("auth.messages.formError")}
              </Text>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};
