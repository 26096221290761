import { loadStripe, Stripe } from "@stripe/stripe-js";
import config from "../config/config";

/**
 * Function that sets stripe up using the correct API key.
 */

export const getStripe = () => {
  let stripePromise: Promise<Stripe | null>;

  //TODO: Edit keys
  stripePromise = loadStripe(
    config.STRIPE_LIVE_KEY
    // config.NODE_ENV === "development"
    //   ? config.STRIPE_TEST_KEY
    //   : config.STRIPE_TEST_KEY
  );
  return stripePromise;
};

/**
 * Function that, given an hash url, retrieves all the params in that url.
 * @param {string} search - The hash url. es: window.location.hash
 * @returns { [key: string]: string }  - Returns an object containing all the params
 */
export const getUrlParams = (search: string): { [key: string]: string } => {
  let hashes = search.slice(search.indexOf("?") + 1).split("&");
  return hashes.reduce((params, hash) => {
    let [key, val] = hash.split("=");
    return Object.assign(params, { [key]: decodeURIComponent(val) });
  }, {});
};
