import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field } from "formik";

export const ProductCountryOfProduction = () => {
  return (
    <Field id={"country_of_production"} name={"country_of_production"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor="country_of_production">
            {"Paese di produzione"}
          </FormLabel>
          <Input
            name={field.name}
            value={field.value}
            onChange={(event) => setFieldValue(field.name, event.target.value)}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
