import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Flex,
  useMediaQuery,
  Button,
  HStack,
  useDisclosure,
  ModalContent,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Alert,
  AlertIcon,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  VStack,
  AccordionPanel,
  Text,
  Image,
} from "@chakra-ui/react";
import React, { useState, useCallback, useEffect } from "react";
import { AdminHeader } from "../../../components/AdminHeader";
import { ProductForm } from "../../../components/ProductForm";
import { Product } from "../../../entities/Product";
import { useStores } from "../../../hooks/UseStores";
import {
  FETCH_ADMIN_LOYALITY,
  FETCH_CAROUSELS,
  UPDATE_CAROUSEL,
  CREATE_CAROUSEL,
  DELETE_CAROUSEL,
  UPDATE_ADMIN_LOYALITY,
} from "./graphql";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { mainTheme } from "../../../config/theme";
import { useTranslation } from "react-i18next";
import { LoyalityActionForm } from "../../../components/LoyalityActionForm";
import { CarouselForm } from "../../../components/CarouselForm";

export const AdminSetupScreen: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [messageSuccess, setMessageSuccess] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);

  const [activeLoyaltyAction, setActiveLoyaltyAction] = useState<any | null>(
    null
  );
  const [messageSuccessCarousel, setMessageSuccessCarousel] = useState<
    string | null
  >(null);
  const [messageErrorCarousel, setMessageErrorCarousel] = useState<
    string | null
  >(null);
  const [activeCarousel, setActiveCarousel] = useState<any>(null);
  const { session } = useStores();
  const { t } = useTranslation();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenCarousel,
    onOpen: onOpenCarousel,
    onClose: onCloseCarousel,
  } = useDisclosure();

  const [
    fetchLoyalityActions,
    { data: loyalityActions, refetch: refetchLoyalityActions },
  ] = useLazyQuery(FETCH_ADMIN_LOYALITY, {
    fetchPolicy: "no-cache",
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateLoyalityAction] = useMutation(UPDATE_ADMIN_LOYALITY, {
    onCompleted: () => {
      onClose();
      setMessageSuccess("Punteggio aggiornato correttamente!");
      // setActiveProduct(null);
      refetchLoyalityActions && refetchLoyalityActions();
    },
    onError: (error) => {
      console.log(error);
      setMessageError("Si è verificato un errore.");
    },
  });

  const handleSubmit = useCallback((data: any) => {
    if (data.id) {
      updateLoyalityAction({
        variables: {
          id: data.id,
          points: data.points,
        },
      });
      setActiveLoyaltyAction(null);
    }
  }, []);

  const [fetchCarousels, { data: carousesData, refetch: refetchCarousels }] =
    useLazyQuery(FETCH_CAROUSELS, {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    });

  const [createCarousel] = useMutation(CREATE_CAROUSEL, {
    onCompleted: () => {
      onCloseCarousel();
      setMessageSuccessCarousel("Carosello inserito correttamente!");
      //setActiveProduct(null);
      refetchCarousels && refetchCarousels();
    },
    onError: (error) => {
      console.log(error);
      setMessageErrorCarousel("Si è verificato un errore.");
    },
  });

  const [updateCarousel] = useMutation(UPDATE_CAROUSEL, {
    onCompleted: () => {
      onClose();
      setMessageSuccessCarousel("Carosello aggiornato correttamente!");
      // setActiveProduct(null);
      refetchCarousels && refetchCarousels();
    },
    onError: (error) => {
      console.log(error);
      setMessageErrorCarousel("Si è verificato un errore.");
    },
  });

  const [deleteCarousel] = useMutation(DELETE_CAROUSEL, {
    onCompleted: () => {
      onClose();
      setMessageSuccessCarousel("Carosello eliminato correttamente!");
      refetchCarousels && refetchCarousels();
    },
    onError: (error) => {
      console.log(error);
      setMessageErrorCarousel("Si è verificato un errore.");
    },
  });

  useEffect(() => {
    if (fetchLoyalityActions) {
      fetchLoyalityActions();
    }
    if (fetchCarousels) {
      fetchCarousels();
    }
  }, []);

  const handleOpenLoyalityAction = useCallback(
    (loyalityAction: any) => {
      setActiveLoyaltyAction(loyalityAction);
      onOpen();
    },
    [onOpen]
  );

  const handleSubmitCarousel = useCallback(
    (data: any) => {
      if (data.id) {
        updateCarousel({
          variables: {
            id: data.id,
            link: data.link,
            image: data.image[0],
          },
        });
      } else {
        createCarousel({
          variables: {
            link: data.link,
            image: data.image[0],
          },
        });
      }
      onCloseCarousel();
      setActiveCarousel(null);
    },
    [createCarousel, onCloseCarousel, updateCarousel]
  );

  const handleOpenCarousel = useCallback(
    (carousel: any) => {
      setActiveCarousel(carousel);
      onOpenCarousel();
    },
    [onOpenCarousel]
  );

  const handleDeleteCarousel = useCallback(() => {
    if (activeCarousel) {
      deleteCarousel({
        variables: {
          id: activeCarousel.id,
        },
      });
      onCloseCarousel();
      setActiveCarousel(null);
    }
  }, [activeCarousel, deleteCarousel, onCloseCarousel]);

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <AdminHeader dark session={session} selectedTab={"config"} />
      <Flex
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        marginTop={"1.5rem"}
        height={"100%"}
      >
        <Stack
          w="100%"
          padding={isLargerThan768 ? "2rem" : "1.5rem"}
          bgColor={mainTheme.colors.primaryGreen}
        >
          <HStack justifyContent="space-between" w="100%">
            <Text
              alignSelf="flex-start"
              color={mainTheme.colors.textPrimary}
              fontSize={["sm", "md", "lg"]}
            >
              Punti
            </Text>
          </HStack>

          {messageError && (
            <Alert status="error">
              <AlertIcon />
              {messageError}
            </Alert>
          )}
          {messageSuccess && (
            <Alert
              status="success"
              fontSize={isLargerThan768 ? undefined : "sm"}
              size={isLargerThan768 ? undefined : "sm"}
            >
              <AlertIcon />
              {messageSuccess}
            </Alert>
          )}
          <Table variant="striped" colorScheme="teal" size="md">
            <Thead>
              <Tr>
                <Th>Nome</Th>
                <Th>Punteggio</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loyalityActions?.loyalityActions.map((loyalityAction: any) => {
                return (
                  <Tr
                    key={loyalityAction.id}
                    onClick={() => handleOpenLoyalityAction(loyalityAction)}
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    <Td>{t(`loyalityActions.${loyalityAction.name}`)}</Td>
                    <Td>{loyalityAction.points}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Stack>
        {/* Carousels */}
        <Stack
          w="100%"
          padding={isLargerThan768 ? "2rem" : "1.5rem"}
          bgColor={mainTheme.colors.primaryYellow}
        >
          <HStack justifyContent="space-between" w="100%">
            <Text
              alignSelf="flex-start"
              color={mainTheme.colors.textPrimary}
              fontSize={["sm", "md", "lg"]}
            >
              Caroselli
            </Text>
            <Button
              onClick={() => onOpenCarousel()}
              colorScheme={"teal"}
              size={isLargerThan768 ? "md" : "xs"}
              fontSize={isLargerThan768 ? "md" : "xs"}
            >
              Aggiungi
            </Button>
          </HStack>

          {messageErrorCarousel && (
            <Alert status="error">
              <AlertIcon />
              {messageErrorCarousel}
            </Alert>
          )}
          {messageSuccessCarousel && (
            <Alert
              status="success"
              fontSize={isLargerThan768 ? undefined : "sm"}
              size={isLargerThan768 ? undefined : "sm"}
            >
              <AlertIcon />
              {messageSuccessCarousel}
            </Alert>
          )}
          <Table variant="striped" colorScheme="teal" size="md">
            <Thead>
              <Tr>
                <Th>Immagine</Th>
                <Th>Link</Th>
              </Tr>
            </Thead>
            <Tbody>
              {carousesData?.carousels.map((carousel: any) => {
                return (
                  <Tr
                    key={carousel.id}
                    onClick={() => handleOpenCarousel(carousel)}
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    <Td>
                      <Image src={carousel.image} height={100} />
                    </Td>
                    <Td>{carousel.link}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Stack>
      </Flex>

      {activeLoyaltyAction && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modifica punteggio</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <LoyalityActionForm
                loyalityAction={activeLoyaltyAction}
                onCancel={() => {
                  onClose();
                  setActiveLoyaltyAction(null);
                }}
                onSubmit={handleSubmit}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      <Modal isOpen={isOpenCarousel} onClose={onCloseCarousel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Modifica carosello</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CarouselForm
              carousel={activeCarousel}
              onCancel={() => {
                setActiveCarousel(null);
                onCloseCarousel();
              }}
              onDelete={handleDeleteCarousel}
              onSubmit={handleSubmitCarousel}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
