import styled from "styled-components";

export const StyledFooter = styled.nav<{
  backgroundColor?: string;
  textAlign?: string;
}>`
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  z-index: 1;
  background-color: ${(props) => props.backgroundColor};
  text-align: ${(props) => props.textAlign};
`;
