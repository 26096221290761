import { FormLabel, VStack, Stack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Store_Brand } from "../../../entities/Store_Brand";
import { Store_Service } from "../../../entities/Store_Service";
import { FormInput } from "../../FormInput";
import { BrandsForm } from "./BrandsForm";
import { ServicesForm } from "./ServicesForm";

type Props = {
  storeServices: Store_Service[];
  storeBrands: Store_Brand[];
  setServicesIds: React.Dispatch<React.SetStateAction<string[]>>;
  setBrandsIds: React.Dispatch<React.SetStateAction<string[]>>;
  otherServiceOne: string;
  setOtherServiceOne: React.Dispatch<React.SetStateAction<string>>;
  otherServiceTwo: string;
  setOtherServiceTwo: React.Dispatch<React.SetStateAction<string>>;
};

export const ServicesBrandsForm = ({
  storeServices,
  storeBrands,
  setServicesIds,
  setBrandsIds,
  otherServiceOne,
  setOtherServiceOne,
  otherServiceTwo,
  setOtherServiceTwo,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Stack w="100%">
      <FormLabel>{t("partner.servicesAndBrands")} *</FormLabel>

      <VStack
        w="100%"
        wrap="wrap"
        bgColor={"white"}
        spacing={"3rem"}
        padding="2rem"
        borderRadius="2xl"
      >
        <VStack spacing={"1rem"}>
          <ServicesForm
            storeServices={storeServices}
            setServicesIds={setServicesIds}
          />

          <FormLabel htmlFor={"otherServiceOne"} $signUpForm>
            {t("partner.otherServiceOne")}
          </FormLabel>
          <FormInput
            value={otherServiceOne}
            onChange={(event: any) => setOtherServiceOne(event.target.value)}
          />

          <FormLabel htmlFor={"otherServiceTwo"} $signUpForm>
            {t("partner.otherServiceTwo")}
          </FormLabel>
          <FormInput
            value={otherServiceTwo}
            onChange={(event: any) => setOtherServiceTwo(event.target.value)}
          />
        </VStack>

        <BrandsForm storeBrands={storeBrands} setBrandsIds={setBrandsIds} />
      </VStack>
    </Stack>
  );
};
