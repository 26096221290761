import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { StoreReview } from "../../../entities/StoreReview";
import {
  AddReviewForm,
  AddReviewFormType,
} from "../../AddReviewForm/AddReviewForm";

interface ModalProps {
  review: StoreReview | undefined;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (data: AddReviewFormType) => void;
  handleDelete: (data: AddReviewFormType) => void;
}

export const ReviewModal = ({
  review,
  isOpen,
  onClose,
  handleSubmit,
  handleDelete,
}: ModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("dashboard.newReview")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AddReviewForm
            review={review}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
