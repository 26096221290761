import { useMutation } from "@apollo/client";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CustomerSignUpForm } from "../../components/CustomerSignUpForm";
import { Header } from "../../components/Header";
import { SIGNUP_CUSTOMER } from "../../services/graphql";
import { mainTheme } from "../../config/theme";
import { Alert, AlertIcon, Flex, Spinner, Text } from "@chakra-ui/react";
import { useStores } from "../../hooks/UseStores";
import { isValidEmail } from "../../utils/email";

const calculateAge = (birthday: Date) => {
  // birthday is a date
  // @ts-ignore
  var ageDifMs = Date.now() - birthday;
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const SignUpCustomerScreen: React.FC = () => {
  const { t } = useTranslation();
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { session } = useStores();

  const handleSignUpCompleted = useCallback(
    (data) => {
      setShowDoneMessage(true);
      setTimeout(() => {
        session.login(
          data.signupCustomer.customer,
          data.signupCustomer.token,
          "customer"
        );
      }, 700);
    },
    [session]
  );

  const handleSignUpError = useCallback(
    (errorType: string) => {
      setErrorMessage(t(`auth.messages.customerSignup.${errorType}`));
      // if (errorType === "NoStoreCode") {
      //   setErrorMessage(t("auth.messages.noStoreCodeSignupError"));
      // } else {
      //   setErrorMessage(t("auth.messages.storeSignupError"));
      // }
    },
    [t]
  );

  const [signUpCustomer, { loading: mutationSubmitting }] = useMutation(
    SIGNUP_CUSTOMER,
    {
      onCompleted: handleSignUpCompleted,
      onError: (error) => {
        console.log(error.message);
        let errorType = "UserAlreadyExists";

        if (error?.message?.includes("Email already used")) {
          errorType = "EmailAlreadyUsed";
        } else if (error?.message?.includes("Username already used")) {
          errorType = "UsernameAlreadyUsed";
        } else if (error?.message?.includes("Invalid store code")) {
          errorType = "InvalidStoreCode";
        } else {
          try {
            if (
              error.graphQLErrors[0].extensions?.exception.meta.field_name ===
              "Customer_store_code_fkey (index)"
            ) {
              errorType = "NoStoreCode";
            }
          } catch (error) {
            console.log(error);
          }
        }

        handleSignUpError(errorType);
      },
    }
  );

  const handleSubmit = useCallback(
    (values) => {
      const {
        profile_image,
        firstName,
        surname,
        district,
        email,
        username,
        password,
        mobilePhone,
        gender,
        birthDate,
        privacyPolicy,
        marketingTermsCustomer,
        storeCode,
        brandsIds,
      } = values;

      if (calculateAge(birthDate) <= 14) {
        window.alert(
          "Devi avere più di 14 anni per completare la registrazione!"
        );
      } else {
        setShowDoneMessage(false);
        setErrorMessage("");

        if (isValidEmail(email)) {
          signUpCustomer({
            variables: {
              profile_image,
              first_name: firstName,
              surname,
              district: district.value,
              email,
              username,
              password,
              mobile_phone: mobilePhone,
              gender,
              birth_date: birthDate.toDateString(),
              privacy_contract_confirmation: privacyPolicy,
              marketingTermsCustomer: marketingTermsCustomer,
              store_code: storeCode,
              brands_ids: JSON.stringify(brandsIds),
            },
          });
        } else {
          setErrorMessage(t(`auth.messages.customerSignup.InvalidEmail`));
        }
      }
    },
    [signUpCustomer, t]
  );

  return (
    <>
      <Header dark />
      <Container>
        <FormContainer>
          <CustomerSignUpForm
            onSubmit={handleSubmit}
            isSubmitting={mutationSubmitting}
          />
          {!!errorMessage && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}
          {showDoneMessage && (
            <Flex direction={"column"} alignItems={"center"} m={6} gridGap={3}>
              <Alert
                status={"success"}
                color={mainTheme.colors.primaryGreen}
                backgroundColor={mainTheme.colors.background}
                borderRadius={"5px"}
              >
                <AlertIcon />
                {t("auth.messages.storeSignupCompleted")}
              </Alert>
              <Flex gridGap={5}>
                <Text>{t("common.redirect")}</Text>
                <Spinner
                  thickness="3px"
                  speed="0.65s"
                  emptyColor={mainTheme.colors.lightPurple}
                  color={mainTheme.colors.darkPurple}
                  size="md"
                />
              </Flex>
            </Flex>
          )}
        </FormContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primaryCyan};
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textWhite};
  padding: 3rem 1.5rem;
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;
