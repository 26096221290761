import { makeAutoObservable } from "mobx";
import { AdminUser } from "../entities/AdminUser";
import { CustomerUser } from "../entities/CustomerUser";
import { FavoriteStore } from "../entities/FavoriteStore";
import { PartnerUser } from "../entities/PartnerUser";

import i18n, { I18NLang } from "../i18n";
import { Animal } from "../models/Animal";
import api from "../services/api";
import { Stores, UserType } from "../types";

export class Session {
  language?: I18NLang;

  sessionToken?: string = undefined;
  partnerUser?: PartnerUser = undefined;
  customerUser?: CustomerUser = undefined;
  adminUser?: AdminUser = undefined;

  isLoading: boolean = false;
  sessionError: boolean = false;

  private stores: Stores;

  constructor(stores: Stores) {
    this.stores = stores;

    makeAutoObservable(this);
  }

  setLanguage = (lang?: I18NLang) => {
    i18n.changeLanguage(lang);
    this.language = lang;
  };

  addAnimalToCustomer = (animal: Animal) => {
    this.customerUser?.animals.push();
  };

  setSessionError = (error: boolean) => {
    this.sessionError = error;
  };

  isLogged(): boolean {
    return (
      (this.partnerUser !== undefined ||
        this.customerUser !== undefined ||
        this.adminUser !== undefined) &&
      !!this.sessionToken
    );
  }

  userToBeCompleted(): boolean {
    return (
      this.partnerUser !== undefined && !this.partnerUser.isRegistrationComplete
    );
  }

  login = async (user: any, token: string, userType: UserType) => {
    this.isLoading = true;
    this.setSessionError(false);

    try {
      if (userType === "partner") {
        this.mapPartnerUserData(user);
      } else if (userType === "customer") {
        this.mapCustomerUserData(user);
      } else if (userType === "admin") {
        this.mapAdminUserData(user);
      }
      this.sessionToken = token;
      api.setSession(token);
      this.persistSession(userType);
    } catch (err) {
      console.log(err);
      throw err;
    } finally {
      this.isLoading = false;
    }
  };

  logout = () => {
    this.reset();
  };

  setUser = async (user: any, userType: UserType) => {
    this.setSessionError(false);
    this.isLoading = true;

    try {
      if (userType === "partner") {
        this.mapPartnerUserData(user);
      } else if (userType === "customer") {
        this.mapCustomerUserData(user);
      } else {
        this.mapAdminUserData(user);
      }
    } catch (err) {
      console.log(err);
      this.setSessionError(true);
    } finally {
      this.isLoading = false;
    }
  };

  restoreSession = async () => {
    this.isLoading = true;

    const sessionItem = localStorage.getItem("session");
    const lastAccessTimestamp = localStorage.getItem("last_access");
    const todayPlusOneWeek: Date = new Date();
    todayPlusOneWeek.setDate(todayPlusOneWeek.getDate() + 7);

    if (lastAccessTimestamp != null) {
      if (
        new Date().getTime() >
        new Date(lastAccessTimestamp).getTime() + todayPlusOneWeek.getTime()
      ) {
        this.reset();
        return;
      }
    }

    localStorage.setItem("last_access", new Date().toString());

    if (sessionItem) {
      const session = JSON.parse(sessionItem);
      this.sessionToken = session.token;

      api.setSession(session.token);
    }
    this.isLoading = false;
  };

  private reset() {
    console.debug("Reset store");
    this.sessionToken = undefined;
    this.partnerUser = undefined;
    this.customerUser = undefined;
    this.setSessionError(false);
    localStorage.setItem(
      "session",
      JSON.stringify({ userId: "", token: "", userType: "" })
    );
    localStorage.setItem("last_access", new Date().toString());
    api.clearSession();
  }

  mapPartnerUserData(store: any) {
    if (this.partnerUser === undefined) this.partnerUser = new PartnerUser();

    this.partnerUser.mapPartnerUserData({
      id: store.id,
      firstNameB2B: store["first_name_b2b"],
      surnameB2B: store["surname_b2b"],
      first_name_b2b: store["first_name_b2b"],
      surname_b2b: store["surname_b2b"],
      openingTime: store["store_opening_hours"],
      services: store["services"],
      brands: store["brands"],
      emailB2B: store["email_b2b"],
      mobileB2B: store["mobile_phone_b2b"],
      phoneB2B: store["phone_b2b"],
      pec: store["pec"],
      vatNumber: store["vat_number"],
      taxCode: store["tax_code"],
      sdi: store["sdi"],
      businessName: store["business_name"],
      storeType: store["store_type"],
      bannerName: store["banner_name"],
      subscription: store.subscription,
      promoCode: store["promo_code"],
      couponId: store["coupon_id"],
      isPaymentRegistrationComplete: store["is_payment_registration_complete"],
      isRegistrationComplete: store["is_registration_complete"],
      store_code: store["store_code"],
      stripe_id: store["stripe_id"],
      subscription_id: store["subscription_id"],
      promo: store["promo"],
      store_opening_days: store["store_opening_days"],
      addresses: store["addresses"]
        ? store["addresses"].map((address: any) => {
            return {
              id: address.id,
              type: address.type,
              addressId: address.address.id,
              address: address.address.address,
              cityId: address.address.city.id,
              city: address.address.city.city,
              district: address.address.city.district,
              cap: address.address.city.cap,
            };
          })
        : [],
      store_image: store["store_image"],
      store_photos: store["store_photos"],
      other_service_one: store["other_service_one"],
      other_service_two: store["other_service_two"],
      promoUsed: store["promoUsed"],
    });
  }

  mapCustomerUserData(customer: any) {
    if (this.customerUser === undefined) this.customerUser = new CustomerUser();

    this.customerUser.mapCustomerUserData({
      id: customer.id,
      firstName: customer["first_name"],
      surname: customer.surname,
      district: customer["district"],
      email: customer.email,
      username: customer["username"],
      profile_image: customer["profile_image"],
      mobilePhone: customer["mobile_phone"],
      gender: customer.gender,
      birthDate: customer["birth_date"],
      storeCode: customer["store_code"],
      animals: customer["animals"],
      used_promo: customer["used_promo"],
      favorite_store: customer["favorite_store"],
      brand: customer["brand"],
      loyality_points: customer["loyality_points"],
    });
  }

  mapAdminUserData(admin: any) {
    if (!this.adminUser) {
      this.adminUser = new AdminUser();
    }

    this.adminUser?.mapAdminUserData({
      id: admin.id,
      email: admin.email,
      first_name: admin.first_name,
      surname: admin.surname,
      mobilePhone: admin.mobile_phone,
      promo: admin.promo,
    });
  }

  updateCustomerFavoriteStore(favoriteStore: FavoriteStore) {
    if (this.customerUser?.favorite_store)
      this.customerUser?.favorite_store(favoriteStore);
  }

  private persistSession = async (userType: UserType) => {
    console.debug("Persist session");
    const data = {
      userId:
        userType === "partner" ? this.partnerUser?.id : this.customerUser?.id,
      token: this.sessionToken,
      userType: userType,
    };
    localStorage.setItem("session", JSON.stringify(data));
    localStorage.setItem("last_access", new Date().toString());
  };
}
