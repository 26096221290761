import { Select } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Activity } from "../../entities/Activity";
import { Promo } from "../../entities/Promo";

type Props = {
  field: any;
  setFieldValue: (name: string, value: any) => void;
  activitiesData: Activity[];
  setActivity_id: React.Dispatch<React.SetStateAction<number>>;
  activity_id: number;
  promo: Promo;
};

export const ActivitiesSelect = ({
  field,
  setFieldValue,
  activitiesData,
  activity_id,
  setActivity_id,
  promo,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Select
      name={field.name}
      defaultValue={promo.id === -1 ? -1 : String(promo.activity.id)}
      value={promo.id === -1 ? activity_id : promo.activity.id.toString()}
      onChange={(event) => {
        setActivity_id(Number(event.target.value));
        setFieldValue(field.name, Number(event.target.value));
      }}
    >
      {activitiesData.map((item: Activity) => {
        return (
          <option
            key={item.id.toString()}
            id={item.id.toString()}
            value={item.id.toString()}
          >
            {t(`promoForm.${item.service.name}.${item.name}`)}
          </option>
        );
      })}
    </Select>
  );
};
