import { Select } from "@chakra-ui/react";
import { Brand } from "../../entities/Brand";
import { Promo } from "../../entities/Promo";

type Props = {
  field: any;
  setFieldValue: (name: string, value: any) => void;
  brandsData: Brand[];
  single_brand_id: number;
  setSingle_brand_id: React.Dispatch<React.SetStateAction<number>>;
  promo: Promo;
};

export const BrandSelect = ({
  field,
  setFieldValue,
  brandsData,
  setSingle_brand_id,
  single_brand_id,
  promo,
}: Props) => {
  return (
    <Select
      name={field.name}
      value={promo.id === -1 ? single_brand_id : promo.brand.id.toString()}
      onChange={(event) => {
        setSingle_brand_id(Number(event.target.value));
        setFieldValue(field.name, Number(event.target.value));
      }}
    >
      {brandsData.map((item: Brand) => {
        return (
          <option
            key={item.id.toString()}
            id={item.name}
            value={item.id.toString()}
          >
            {item.name}
          </option>
        );
      })}
    </Select>
  );
};
