const it = {
  footer: {
    email: "info@loovers.eu",
    description:
      "© 2021 LOOVERS s.r.l. \n\n Sede legale: Via Lentasio, 9 - 20122 Milano \n\n C.F. e P.IVA: 11691220963",
  },
  login: {
    customers: "Utente Loovers",
    partners: "Partner",
  },
  animal: {
    name: "Nome",
    breed: "Razza/Famiglia",
    weight: "Peso",
    specialNeeds: "Esigenza specifica",
    image: "Foto animale",
  },
  partner: {
    servicesAndBrands: "Servizi e brand",
    otherServiceOne: "Altro servizio (campo digitabile)",
    otherServiceTwo: "Altro servizio (campo digitabile)",
    upload: "Carica",
    logoUploaded: "Logo caricato",
    signUpMessage1: "Un ultimo passo\n per diventare\n Loovers partner!",
    signUpMessage2:
      "Compila i seguenti campi ed entra subito nel mondo Loovers",
    firstNameB2B: "Nome referente",
    surnameB2B: "Cognome referente",
    openingTime: "Orari di apertura",
    opening: "Apertura",
    closing: "Chiusura",
    morning: "Matt.",
    afternoon: "Pome.",
    fromTime: "Da",
    toTime: "A",
    monday: "Lunedì",
    tuesday: "Martedì",
    wednesday: "Mercoledì",
    thursday: "Giovedì",
    friday: "Venerdì",
    saturday: "Sabato",
    sunday: "Domenica",
    services: "Servizi",
    brands: "Brand commercializzati",
    businessName: "Ragione sociale",
    storeType: "Tipologia attività",
    storeTypes: {
      petshop: "Petshop o negozio indipendente",
      toelettatore: "Toelettatore",
      allevatore: "Allevatore",
      veterinario: "Veterinario",
      pensioni: "Pensioni",
      agriGarden: "Agri Garden",
    },
    bannerName: "Insegna",
    emailB2B: "Email",
    pec: "PEC",
    mobilePhoneB2B: "Cellulare",
    phoneB2B: "Telefono Fisso",
    vatNumber: "Partita Iva",
    taxCode: "Codice Fiscale",
    sdi: "SDI",
    store_image: "Logo store",
    addCode: "Hai un codice sconto?",
    promoCode: "Codice sconto",
    addSubscriptionInfo: "Scegli il tuo abbonamento",
    selectSubscription: "Seleziona abbonamento",
    popularSubscription: "Più scelto",
    month: "mese",
    year: "anno",
    priceMonth: "Prezzo al mese:",
    priceYear: "Prezzo all'anno:",
    addressBusiness: "Indirizzo ragione sociale",
    addressBusinessStreet: "Indirizzo",
    addressBusinessCity: "Città",
    addressBusinessDistrict: "Provincia",
    addressBusinessCap: "Cap",
    addressStore: "Indirizzo negozio",
    dashboard: {
      welcome: "Benvenuto in Loovers",
      completeSignup: {
        title:
          "Compila le informazioni relative alla tua attività così che tu possa accedere al mondo Loovers e farti trovare da chiunque!",
        addPaymentInfo: "Paga con carta di credito",
        cardDetails: "Carta di credito",
      },
    },
  },
  customer: {
    profileImageSuccess: "Immagine profilo cambiata con successo",
    profileImageError: "Immagine profilo non cambiata",
    firstName: "Nome",
    surname: "Cognome",
    email: "Email",
    district: "Provincia",
    username: "Username",
    profile_image: "Immagine profilo",
    mobilePhone: "Cellulare",
    gender: "Sesso",
    birthDate: "Data di nascita",
    storeCode: "Codice negozio",
    sdi: "SDI",
    male: "M",
    female: "F",
    other: "Altro",
    brands: "Brand preferiti",
  },
  dashboard: {
    welcome: "Benvenuto,",
    loyaltyCard: "TESSERA FEDELTÀ",
    loyaltyCardDescription:
      "Questa è la tua Tessera Fedeltà che ti dà accesso a sconti e premi, usala ogni volta!",
    points1: "Complimenti!\n Hai accumulato",
    points2: "Loovers points!",
    animals: "I tuoi pets",
    store: "Il tuo store di fiducia",
    addFavouriteStore: "Aggiungi store preferito",
    updateFavouriteStore: "Modifica store preferito",
    yourLastReviews: "Le tue ultime recensioni",
    yourLastProductsReviews: "Le tue ultime recensioni dei prodotti",
    manageReviews: "Gestisci le recensioni",
    newReview: "Lascia una recensione",
    editReview: "Modifica recensione",
    reviewCreated: "Recensione inserita correttamente",
    reviewUpdated: "Recensione aggiornata correttamente",
    reviewDeleted: "Recensione cancellata correttamente",
    reviewNotDeleted: "Errore, recensione non cancellata",
    userPromo:
      "Promozioni in esclusiva per te e i tuoi amici pet attive solo nei punti vendita LOOVERS!  Ricordati di abbinare i tuoi pet per ricevere le promozioni!",
    storeCodeRequired: "Il codice store è richiesto",
    storeCode: "Codice Store",
    petCreated: "Pet aggiunto correttamente",
    petUpdated: "Pet aggiornato correttamente",
    petDeleted: "Pet cancellato correttamente",
    pointsGrid: {
      howToGetPoints: "Come acquisire punti",
      premi: "Premi",
      points: "Punti",
      activity: "Attività",
      signup: "Registrazione",
      socialPost: "Post sui social",
      productReview: "Recensioni prodotto",
      storeReview: "Recensioni store",
      receiptUpload: "Caricamento scontrino",
      articleSocialShare: "Condivisione di un articolo sui social",
      bringAFriend: "Porta un amico",
      participateToEvent: "Partecipa ad un evento",
    },
  },
  partnerdashboard: {
    welcome: "Benvenuto,",
    youCanModifyYourInfoRightBelow: "Sotto puoi modificare le tue informazioni",
    uploadStorePhotos: "Foto del tuo negozio",
    storePhotosModalTitle: "Foto",
    useThisFormToUpdateOpeningTime:
      "Utilizza questo form per verificare e tenere aggiornati gli orari di apertura del tuo negozio",
    useThisFormToUpdateServicesAndBrands:
      "Utilizza questo form per verificare e tenere aggiornati i servizi e i brand del tuo negozio",
    clickHere: "Carica le foto",
    uploadOrUpdateStorePhotos: "Carica/aggiorna le foto",
    min3Photos: "Almeno 3 foto",
    dataDescription: "Ecco alcuni dati che potrebbero interessarti",
    yourCode: "Il tuo codice azienda:",
    promoCreated: "Promozioni totali create:",
    promoUsed: "Conteggio promozioni usate dagli utenti:",
    dashboardInfo:
      " Questa è la tua dashboard dove puoi gestire il tuo abbonamento, le promozioni del tuo store, scannerizzare una tessera fedeltà e cambiare i tuoi dati personali.",
    subscription: {
      title: "Il tuo abbonamento",
      edit: "Modifica Informazioni",
      editSuccess: "Modifica avvenuta con successo",
      editError: "Errore di accesso",
      click: "Clicca per:",
      edit1: "Gestire, cancellare o modificare l'abbonamento",
      edit2: "Cambiare il metodo di pagamento",
      edit3: "Consultare la lista di pagamenti",
    },
    store: {
      title: "Il tuo negozio",
      section1: "Generali",
      section2: "Orari e giorni di apertura",
      section3: "Servizi e Brand",
      storeEdit1Success: "Informazioni generali aggiornate con successo!",
    },
  },
  review: {
    reviewerName: "Nome recensore",
    title: "Titolo recensione",
    comment: "Commento recensione",
    description: "Descrizione",
    stars: "Voto",
    date: "Rilasciata il: ",
    brandName: "Brand",
    life_stage: "Life stage",
    life_style: "Life style",
    specialNeedName: "Esigenza specifica",
  },
  auth: {
    notYetSignedUp: "Non ancora registrato?",
    forgotPassword: "Password dimenticata?",
    forgotPasswordDescription: "Inserisci l'e-mail che vuoi recuperare",
    forgotPasswordEmailSentMessage:
      "E-mail inviata correttamente. Controlla la tua posta in arrivo",
    forgotPasswordSendEmail: "Recupera account",
    signUp: "Registrati",
    alreadyHaveAnAccount: "Ho già un account",
    clickHereToSignUp: "Clicca qui per registrarti",
    password: "Password",
    confirmPassword: "Conferma password",
    termsOfUse: "Dichiaro di aver letto e accettato le Condizioni Generali",
    termsOfUse1:
      "Inserendo i tuoi dati e cliccando su CONFERMA dichiari di aver letto e accettato le ",
    termsOfUse2: " e di aver letto e compreso l'",
    privacyPolicy:
      "Accetto espressamente le clausole elencate all’art. 33 delle Condizioni Generali della piattaforma (obbligatorio)",
    clauses:
      "Accetto espressamente le clausole elencate al punto 51 delle Condizioni Generali",
    marketingTerms:
      "Acconsento al trattamento dei miei dati per finalità promozionali e per ricevere offerte e aggiornamenti da Loovers in merito a servizi propri e/o di terzi",
    marketingTermsCustomer:
      "Acconsento al trattamento dei miei dati per ricevere e-mail, SMS o notifiche, personalizzate sulla base dei miei gusti, acquisiti e preferenze, relativi ad offerte, promozioni ed iniziative di marketing di Loovers e di terzi",
    read: "Leggi",
    login: "Accedi",
    subscriptionSelected:
      "Abbonamento <1>{{selectedSubscription}}</1> selezionato!",
    subscriptionBenefits: "Vantaggi abbonamento",
    promoCodeApplied:
      "Uno sconto del <1>{{PROMO_CODE_VALUE}} %</1> è stato applicato!",
    mandatoryFields: "Campi obbligatori",
    sendRecoveryLink: "Invia link di recupero",
    recoveryLinkSuccess:
      "Richiesta eseguita correttamente. Riceverai una mail con le indicazioni da seguire!",
    recoveryLinkError: "Verifica se l'indirizzo email è corretto.",
    backToLogin: "Torna al login",
    changePassword: "Cambio password",
    confirmNewPassword: "Conferma password",
    addPayment: "Inserisci informazioni di pagamento",

    helper: {
      customer: {
        mobilePhone:
          "Inserisci il tuo cellulare per ricevere 50 Loovers point e poter ricevere offerte imperdibili",
        storeCode:
          "Chiedi al tuo negozio di fiducia il suo codice LOOVERS e ricevi i primi Loovers Point",
        brandsIds: "Seleziona massimo 3 brand",
        editProfile: {
          mobilePhone:
            "Inserisci il tuo cellulare per ricevere offerte imperdibili",
        },
      },
    },

    validations: {
      // Common
      emailRequired: "Inserisci l'indirizzo email!",
      emailInvalid: "Indirizzo email non valido!",
      mobilePhoneRequired: "Inserisci il numero di cellulare!",
      passwordRequired: "Inserisci la password! (lunghezza minima 6 caratteri)",
      confirmPasswordRequired:
        "Conferma la password! (lunghezza minima 6 caratteri)",
      passwordsNotMatch: "Le password devono essere identiche!",
      privacyPolicyRequired: "Devi accettare l'informativa sulla privacy",
      min3: "Minimo 3 caratteri",
      max16: "Massimo 16 caratteri",

      // Store
      businessNameRequired: "Inserisci la Ragione sociale!",
      storeTypeRequired: "Inserisci il tipo di store!",
      addressBusinessRequired: "Inserisci indirizzo Ragione Sociale!",
      addressBusinessCityRequired: "Inserisci città indirizzo Ragione Sociale!",
      addressBusinessDistrictyRequired:
        "Inserisci provincia indirizzo Ragione Sociale!",
      addressBusinessCapRequired: "Inserisci cap indirizzo Ragione Sociale!",
      addressBusinessCapNumber: "Il cap deve essere numerico",
      addressStoreRequired: "Inserisci indirizzo Negozio!",
      addressStoreCityRequired: "Inserisci città indirizzo Negozio!",
      addressStoreDistrictRequired: "Inserisci provincia indirizzo Negozio!",
      addressStoreCapRequired: "Inserisci cap indirizzo Negozio!",
      bannerNameRequired: "Insersci l'insegna!",
      vatNumberRequired: "Inserisci la Partita Iva!",
      taxCodeRequired: "Inserisci il Codice Fiscale!",
      sdiRequired: "Inserisci l'SDI",
      pecRequired: "Inserisci l'indirizzo email PEC!",
      termsOfUseRequired: "Devi accettare i termini d'utilizzo!",
      subscriptionRequired: "Seleziona un abbonamento!",
      calusesRequired: "Devi accettare le clausole!",
      cardDetailsRequired: "Inserisci una carta di credito valida!",
      descriptionMin3: "Scrivere almeno 3 caratteri",
      serviceIdsRequired: "Aggiungere almeno un servizio",
      brandsIdsRequired: "Aggiungere almeno un brand",

      // Customer
      firstNameRequired: "Inserisci il tuo nome!",
      surnameRequired: "Inserisci il tuo cognome!",
      districtRequired: "Inserisci la tua provincia!",
      usernameRequired: "Inserisci uno username!",
      genderRequired: "Inserisci il sesso!",
      birthDateRequired: "Inserisci la data di nascita!",
      birthDateBeforeToday:
        "La data di nascita inserita è successiva alla data odierna!",
      mobilePhoneInvalid: "Il numero di telefono inserito non è valido!",

      // Review
      titleRequired:
        "Il titolo della recensione deve essere almeno di 5 caratteri",
      commentRequired: "Il commento deve essere di almeno 2 caratteri",
      descriptionRequired: "Deve essere almeno di 10 caratteri",
      reviewStarsRequired: "Inserire il voto (da 1 a 5)",
      lifeStageRequired: "Inserire life stage",
      lifeStyleRequired: "Inserire life style",

      // Add animal form
      dogName: "Inserisci il nome",
      min1: "Almeno un carattere",
      weightMin: "Minimo 0.1 Kg",
      special_needRequired: "Inserire esigenza specifica",
      imageRequired: "Caricare un'immagine",
    },
    messages: {
      storeSignupCompleted: "Registrazione eseguita correttamente!",
      storeSignupPart2Completed: "Dati inseriti correttamente!",
      storeSignupError: "Attenzione, profilo già registrato!",
      noStoreCodeSignupError:
        "Attenzione, il codice negozio inserito non corrisponde a nessun negozio!",
      loginError: "Email e/o password sbagliate. Riprova.",
      sessionError:
        "Sessione scaduta! Per favore effettua nuovamente l'accesso.",
      formError:
        "Attenzione, uno o più campi inseriti sono errati! Ricontrolla e riprova.",
      dataUpdateError:
        "Attenzione, non è stato possibile inserire i dati di pagamento, riprova!",
      servicesError: "Attenzione, selezionare almeno un servizio!",
      customerSignup: {
        NoStoreCode:
          "Attenzione, il codice negozio inserito non corrisponde a nessun negozio!",
        EmailAlreadyUsed: "Attenzione, email già registrata!",
        UsernameAlreadyUsed: "Attenzione, username già registrato!",
        UserAlreadyExists: "Attenzione, utente già registrato!",
        InvalidStoreCode:
          "Attenzione, il codice negozio inserito non corrisponde a nessun negozio!",
        InvalidEmail: "Attenzione, email non valida!",
      },
    },
  },
  subscriptionBenefits: {
    superlight: {
      1: "Registrazione su sito",
      2: "Geolocalizzazione Attività",
      3: "Orari store",
      4: "Servizi offerti store",
      5: "Campagna I'mloovers",
      6: "Foto con tag instagram",
      7: "Campagna info loovers (tips e pillole)",
      8: "Campagne promo LOOVERS",
      9: "Campagne Promo di brand LOOVERS nazionale",
    },
    base: {
      1: "CRM",
      2: "Brand visibility su mappa",
      3: "Recensioni attività",
      4: "Campagne Promo punto di vendita (max 8)",
      5: "Utenti esclusivi",
      6: "Naming cliente su newsletter",
    },
    premium: {
      1: "Mailing su clienti loovers",
      2: "Promo mese dedicata 1 mese",
      3: "Promo cross category - generata da Algoritmo",
    },
  },
  common: {
    add: "Aggiungi",
    addPromotion: "Aggiungi promozione",
    scanQR: "Scan QR code",
    confirm: "Conferma",
    cancel: "Annulla",
    delete: "Cancella",
    select: "Seleziona",
    selectedCurrency: "€",
    verify: "Verifica",
    redirect: "Reindirizzamento in corso...",
    error: "Qualcosa è andato storto. Per favore riprova",
    actionExecutedSuccessfully: "Azione eseguita correttamente",
    pageNotFound: "Errore 404 - Pagina non trovata!",
  },
  fileUploadModal: {
    minFiles: "Caricare almeno",
    photos: "foto",
    exactWidth: "La larghezza deve essere di",
    exactHeight: "L'altezza deve essere di",
    minWidth: "La larghezza minima è",
    maxWidth: "La larghezza massima è",
    minHeight: "L'altezza minima è",
    maxHeight: "L'altezza massima è",
  },
  qrCodeReaderModal: {
    use: "Usa",
    usePromo: "Usa promo",
    usedPromo: "Promo usata",
    correctlyUsedPromo: "Promo correttamente usata",
    scanPromo: "Scannerizza promo",
    scanLoyaltyCard: "Scannerizza tessera cliente",
    scanAnOtherLoyaltyCard: "Scannerizza un'altra tessera cliente",
    national: "Nazionale",
    yes: "Sì",
    no: "No",
    noPromosAvailable: "Non ci sono promo disponibili",
    promoActivated: "Promo attivata",
    status: "Stato",
    available: "Non utilizzata",
    notAvailable: "Utilizzata",
    usedDate: "Data utilizzo",
    receipt: "Scontrino",
    upload: "Carica",
    uploadReceipt: "Carica scontrino",
    dragNDrop: "Clicca qui o trascina qui un file",
  },
  productForm: { name: "Nome", min1: "Scrivere il nome" },
  promoForm: {
    checkbox: "Dichiaro di essere responsabile di questa promozione",
    brandsServicesLengthErrorTitle: "Aggiungere almeno un servizio e un brand",
    all: "Tutti",
    allRaces: "Tutte le razze",
    activity: "Attività",
    emailTitle: "Titolo email",
    downloadQRCode: "Scarica codice QR",
    pdvCode: "Codice PDV",
    pdvCodeHelper: "Non puoi modificare il codice PDV",
    type: "Tipo di attività",
    discountValue: "Valore",
    valueDiscountCoupon: "Buono sconto a valore",
    percentageDiscountCoupon: "Buono sconto percentuale",
    freeProductCoupon: "Prodotto omaggio",
    promotionalDayCoupon: "Giornata promozionale",
    interested_brand: {
      label: "Tipologia promozione",
      all: "Tutti brand/servizi",
      all_brands: "Tutti i brand",
      all_services: "Tutti i servizi",
      single_brand: "Singolo brand",
      singleBrand: "Singolo brand",
      services: "Servizi",
      single_service: "Singolo servizio",
    },
    services: {
      label: "Servizi",
    },
    normalBath: "Bagno classico",
    medicatedBath: "Bagno medicato",
    ozoneTreatment: "Trattamento ozono",
    pesticideBath: "Bagno antiparassitario",
    commercialCut: "Taglio commerciale",
    scissorsCut: "Taglio a forbice",
    nailTrimming: "Taglio unghie",
    stripping: "Stripping",
    generalCheckUp: "Visita generale",
    completeCheckUp: "Check-up completo",
    deworming: "Sverminazione",
    dogAndCatFirstSteps: "Primi passi Cucciolo e Gattino",
    geriatricCheckUp: "Visita Geriatrica",
    microchip: "Microchip",
    leishmaniaTest: "Test Leishmania",
    filariasis: "Test Filaria",
    annualVaccineBoostersAndTests: "Richiami annuali per vaccini, test vari",
    insurance: "Assicurazione",
    grooming: {
      label: "Toelettatura",
      normalBath: "Bagno classico",
      medicatedBath: "Bagno medicato",
      ozoneTreatment: "Trattamento ozono",
      pesticideBath: "Bagno antiparassitario",
      commercialCut: "Taglio commerciale",
      scissorsCut: "Taglio a forbice",
      nailTrimming: "Taglio unghie",
      stripping: "Stripping",
    },
    vet: {
      label: "Veterinario",
      generalCheckUp: "Visita generale",
      completeCheckUp: "Check-up completo",
      deworming: "Sverminazione",
      dogAndCatFirstSteps: "Primi passi Cucciolo e Gattino",
      geriatricCheckUp: "Visita Geriatrica",
      microchip: "Microchip",
      leishmaniaTest: "Test Leishmania",
      filariasis: "Test Filaria",
      annualVaccineBoostersAndTests: "Richiami annuali per vaccini, test vari",
      insurance: "Assicurazione",
    },
    breederTrainer: {
      label: "Allevatore/Addestratore",
    },
    foodSales: {
      label: "Vendita alimenti",
    },
    accessorySales: {
      label: "Vendita accessori",
    },
    laundry: {
      label: "Lavanderia",
    },
    homeDelivery: {
      label: "Consegna a domicilio",
    },
    pickAndPay: {
      label: "Pick & Pay",
    },
    parapharmacy: {
      label: "Parafarmacia",
    },
    dogs: {
      label: "Cuccioli di cane",
    },
    cats: {
      label: "Cuccioli di gatto",
    },
    fishSale: {
      label: "Vendita pesci",
    },
    birdsSale: {
      label: "Vendita volatili",
    },
    smallMammalsSale: {
      label: "Vendita piccoli mammiferi",
    },
    reptilesSale: {
      label: "Vendita rettili",
    },
    aquariology: {
      label: "Acquariologia",
    },
    amphibiansSale: {
      label: "Vendita anfibi ",
    },
    startDate: "Data inizio",
    endDate: "Data fine",
    promoDescription: "Descrizione promo",
    promoType: "Tipo promo",
    usersPerimeter: {
      label: "Perimetro utenti",
      all: "Tutti",
      dog: "Cane",
      cat: "Gatto",
      smallAnimal: "Piccoli animali",
    },
    weightIsNotRequired: "Il peso non è obbligatorio",
    startDateRequired: "Data inizo obbligatoria",
    endDateRequired: "Data fine obbligatoria",
    descriptionRequired: "Descrizione obbligatoria",
    emailRequired: "Email obbligatoria",
    min5: "Scrivere almento 5 caratteri",
    min10: "Scrivere almeno 10 caratteri",
    max40: "Scrivere massimo 40 caratteri",
    max150: "Scrivere massimo 400 caratteri",
    promoTypeTypeError: "Inserire solo numeri",
    promoTypeRequired: "Campo obbligatorio",
    promoTypePositive: "Inserire solo numeri positivi",
    valid_until_after_valid_from:
      "Data fine deve essere maggiore di Data inizio",
    specialNeeds: "Esigenze specifiche",
    specialNeedsRadio: "Tipo esigenza",
    specialNeedDietetic: "Dietetico",
    specialNeedPhysiologic: "Fisiologico",
    id: "ID",
    title: "Titolo",
  },
  loyalityActions: {
    signup: "Registrazione",
    socialPost: "Post sui social",
    productReview: "Recensioni prodotto",
    storeReview: "Recensioni store",
    receiptUpload: "Caricamento scontrino",
    articleSocialShare: "Condivisione di un articolo sui social",
    bringAFriend: "Porta un amico",
    participateToEvent: "Partecipa ad un evento",
    phoneNumber: "Lascia il numero di cellulare in fase di registrazione",
  },
};

export default it;
