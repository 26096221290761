import { Service } from "./Service";

export class Activity {
  private _id: number;
  private _name: string;
  private _serviceId: number;
  private _service: Service;

  constructor(id: number, name: string, serviceId: number, service: Service) {
    this._id = id;
    this._name = name;
    this._serviceId = serviceId;
    this._service = service;
  }

  public toJson(): { id: number; name: string; isService: boolean } {
    return { id: this._id, name: this._name, isService: true };
  }

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get serviceId() {
    return this._serviceId;
  }

  public get service() {
    return this._service;
  }
}
