import { gql } from "@apollo/client";

const customerFields = `
id
first_name
surname
email
mobile_phone
store_code
used_promo {
  promo {
    id
  }
}
animals {
  id
  name
  birth_date
  weight
  size
  breed {
    id
    name
  } 
  animal_type {
    name
  }
  special_need {
    name
    animal
    need_type
  }
}
`;

export const FETCH_CUSTOMER_BY_ID = gql`
  query fetchCustomerByID {
    customers {
      ${customerFields}
    }
  }
`;

export const FETCH_PROMOS = gql`
  query fetchPromos {
    promos {
      id
      valid_from
      valid_until
      promo_type
      users_perimeter
      description
      activity_type
      is_national
      brand {
        id
        name
      }
      special_needs {
        id
        name
      }
      promo_breed {
        id
        name
      }
      activity {
        id
        name
      }
      store {
        id
        store_code
        business_name
      }
    }
  }
`;

export const UPDATE_USED_PROMO = gql`
  mutation UpdateUseProduct($id: Int!, $receipt_photo: String!) {
    updateOnePromo_Code_Use(
      where: { id: $id }
      data: { receipt_photo: { set: $receipt_photo } }
    ) {
      id
      receipt_photo
    }
  }
`;
