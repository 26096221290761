import { Box } from "@chakra-ui/react";
import { QRCodeReaderModal } from "../../components/CustomerDashboard/PartnerScanQRCodeReader/QRCodeReaderModal";
import { PartnerHeader } from "../../components/PartnerHeader";
import { useStores } from "../../hooks/UseStores";
import { Footer } from "../Footer/Footer";

export const PartnerScanQRCodeScreen = () => {
  const { session } = useStores();

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <PartnerHeader dark session={session} selectedTab={"qrCodeReader"} />
      {session.partnerUser && (
        <QRCodeReaderModal partnerUser={session.partnerUser} />
      )}
      <Footer dark />
    </Box>
  );
};
