export class Customer {
  private _id: number;
  private _name: string;
  private _points: number;

  constructor(id: number, name: string, points: number) {
    this._id = id;
    this._name = name;
    this._points = points;
  }

  public get id() {
    return this._id;
  }

  public get name() {
    return this._name;
  }

  public get points() {
    return this._points;
  }
}
