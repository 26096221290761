import { Field, Form, Formik } from "formik";
import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { ModalFooterForm } from "../ModalFooterForm";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { CarouselImage } from "./CarouselImage";

type Props = {
  carousel: any;
  onCancel: any;
  onSubmit: any;
  onDelete: any;
};

export const CarouselForm: React.FC<Props> = ({
  carousel,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    link: Yup.string()
      .required("Specifica il link")
      .min(1, "Specifica il link"),
    image: Yup.array().min(1, "Inserisci un'immagine"),
  });

  return (
    <Formik
      initialValues={{
        id: carousel?.id,
        image: carousel ? [carousel.image] : [],
        link: carousel?.link,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Field name={"link"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl isInvalid={meta.error && meta.touched}>
                <FormLabel>{"Link"}</FormLabel>
                <Input
                  name={field.name}
                  value={field.value}
                  onChange={(e) => setFieldValue(field.name, e.target.value)}
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <CarouselImage
            fieldValue={props.values.image}
            meta={props.getFieldMeta("link")}
          />

          <ModalFooterForm
            onCancel={onCancel}
            onDelete={carousel?.id && onDelete}
          />
        </Form>
      )}
    </Formik>
  );
};
