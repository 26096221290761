import { Alert, AlertIcon, Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { HeaderMio } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { EditProfileDetailsForm } from "../../components/CustomerDashboard/EditProfileDetails/EditProfileDetailsForm";
import { useStores } from "../../hooks/UseStores";
import { useCallback, useState } from "react";
import { mainTheme } from "../../config/theme";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { UPDATE_CUSTOMER_DETAILS } from "../../services/graphql";

export const EditProfileDetailsScreen = () => {
  const { t } = useTranslation();
  const { session } = useStores();
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSignUpCompleted = useCallback((data) => {
    setShowDoneMessage(true);
    window.location.reload();
  }, []);

  const handleSignUpError = useCallback(
    (errorType: string) => {
      if (errorType === "NoStoreCode") {
        setErrorMessage(t("auth.messages.noStoreCodeSignupError"));
      } else {
        setErrorMessage(t("auth.messages.storeSignupError"));
      }
    },
    [t]
  );

  const [updateCustomerDetails, { loading: mutationSubmitting }] = useMutation(
    UPDATE_CUSTOMER_DETAILS,
    {
      onCompleted: handleSignUpCompleted,
      onError: (error) => {
        console.log(error);
        let errorType = "";

        handleSignUpError(errorType);
      },
    }
  );

  const handleSubmit = useCallback(
    (values) => {
      const {
        profile_image,
        firstName,
        surname,
        district,
        email,
        username,
        password,
        mobilePhone,
        gender,
        birthDate,
        storeCode,
        brandsIds,
      } = values;

      setShowDoneMessage(false);
      setErrorMessage("");

      updateCustomerDetails({
        variables: {
          id: session.customerUser!.id,
          profile_image,
          first_name: firstName,
          surname,
          district: district.value,
          email,
          username,
          password,
          mobile_phone: mobilePhone,
          gender,
          birth_date: birthDate.toDateString(),
          store_code: storeCode,
          brands_ids: JSON.stringify(brandsIds),
        },
      });
    },
    [session.customerUser, updateCustomerDetails]
  );

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <HeaderMio
        selectedTab="editProfile"
        editIcon={false}
        dark
        textAlign={"center"}
        customerUser={session.customerUser}
      />

      <Flex justify="center">
        <Flex
          w="80%"
          maxW="46rem"
          align="flex-start"
          justify="center"
          bgColor={mainTheme.colors.primaryGreen}
          padding={"10px"}
          borderRadius={"10px"}
        >
          {session.customerUser && (
            <EditProfileDetailsForm
              customerUser={session.customerUser}
              isSubmitting={mutationSubmitting}
              onSubmit={handleSubmit}
            />
          )}
        </Flex>
      </Flex>

      {!!errorMessage && (
        <Alert
          status={"error"}
          color={mainTheme.colors.danger}
          borderRadius={"5px"}
          margin={"2rem 0"}
        >
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}
      {showDoneMessage && (
        <Flex direction={"column"} alignItems={"center"} m={6} gridGap={3}>
          <Alert
            status={"success"}
            color={mainTheme.colors.primaryGreen}
            backgroundColor={mainTheme.colors.background}
            borderRadius={"5px"}
          >
            <AlertIcon />
            {t("auth.messages.storeSignupCompleted")}
          </Alert>
          <Flex gridGap={5}>
            <Text>{t("common.redirect")}</Text>
            <Spinner
              thickness="3px"
              speed="0.65s"
              emptyColor={mainTheme.colors.lightPurple}
              color={mainTheme.colors.darkPurple}
              size="md"
            />
          </Flex>
        </Flex>
      )}

      <Footer dark />
    </Box>
  );
};
