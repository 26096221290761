import { Field, FieldProps, Form, Formik } from "formik";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  Image,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Select,
  Text,
  FormHelperText,
  RadioGroup,
  Radio,
  HStack,
  Spacer,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { DatePicker } from "../../DatePicker/DatePicker";
import { ThirdLooversFontSize } from "../../LooversText/LooversText";
import { Promo, UsersPerimeter } from "../../../entities/Promo";
import { BreedSelect, item } from "./BreedSelect";
import { SpecialNeedSelect } from "./SpecialNeedSelect";
import { AnimalSizeSelect } from "../../PartnerPromotions/AnimalSize";
import { PromoBreed } from "../../../entities/PromoBreed";
import { mainTheme } from "../../../config/theme";
import { Animal } from "../../../models/Animal";
import { useDropzone } from "react-dropzone";
import { MS_SEND_MAIL_API_KEY } from "../../../config/constants";
import {DB_URL} from "../../../config/config";

export type AnimalFormType = {
  id: number | undefined;
  name: string;
  birth_date: Date;
  breed: item;
  weight: number;
  special_need_id: number;
  animal_image: string;
  size: string;
  animal_type: string;
};

type Props = {
  onSubmit: (values: AnimalFormType) => void;
  onDelete: (values: AnimalFormType) => void;
  animal?: Animal;
};

export const AnimalForm: React.FC<Props> = ({ onSubmit, animal, onDelete }) => {
  const { t } = useTranslation();

  const today = new Date();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required(t("auth.validations.dogName"))
        .min(1, t("auth.validations.dogName")),
    birth_date: Yup.string().required(t("auth.validations.birthDateRequired")),
    weight: Yup.number()
        .required(t("auth.validations.weightRequired"))
        .min(0, t("auth.validations.weightMin")),
    special_need_id: Yup.number().required(
        t("auth.validations.special_needRequired")
    ),
  });

  const FileUpload = ({
                        onFileUploaded,
                      }: {
    onFileUploaded: (fileUrls: string[]) => void;
  }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [minFilesError, setMinFilesError] = useState<boolean>(false);

    const onDrop = (files: File[]) => {
      if (1) {
        if (files.length < 1) {
          setMinFilesError(true);
        } else {
          setMinFilesError(false);
        }
      }
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      acceptedFiles,
    } = useDropzone({
      maxFiles: 1,
      onDrop,
    });

    const dNDStyle = useMemo(() => {
      const baseStyle = {
        flex: 1,
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
      };

      const activeStyle = {
        borderColor: "#2196f3",
      };

      const acceptStyle = {
        borderColor: "#00e676",
      };

      const rejectStyle = {
        borderColor: "#ff1744",
      };

      return {
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      };
    }, [isDragActive, isDragAccept, isDragReject]);

    const handleUpload = useCallback(async () => {
      if (!minFilesError && acceptedFiles.length > 0) {
        setIsUploading(true);
        const formData = new FormData();

        // Se vuoi caricare più file, appendili tutti a formData
        acceptedFiles.forEach(file => {
          formData.append('files', file); // Assicurati che il backend sia configurato per accettare 'files' come chiave

        });

        try {


          const response = await fetch(`https://dbloovers.b01.me/upload`, {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }


          const fileUrls = await response.json(); // Assumi che il server ritorni un array di URL o un oggetto simile
          console.log(fileUrls);
          const urls = fileUrls.files.map(file => DB_URL+file.path);
          onFileUploaded(urls);
        } catch (err) {
          alert(t("common.error"));
          console.error(err);
        } finally {
          setIsUploading(false);
        }
      }
    }, [minFilesError, onFileUploaded, acceptedFiles, t]);

    const fileList = acceptedFiles.map((file) => (
        <li key={file.name}>{file.name}</li>
    ));

    return (
        <VStack align="flex-start" spacing="0.5rem" marginBottom="1rem">
          <div {...getRootProps({ style: dNDStyle })}>
            <input {...getInputProps()} />
            {t("qrCodeReaderModal.dragNDrop")}
          </div>
          <aside>
            <ul>{fileList}</ul>
          </aside>

          {isUploading && <Spinner colorScheme="purple" />}
          <Button
              onClick={handleUpload}
              colorScheme={"green"}
              disabled={acceptedFiles.length === 0 || isUploading || minFilesError}
          >
            {t("qrCodeReaderModal.upload")}
          </Button>
        </VStack>
    );
  };

  return (
      <Formik
          initialValues={{
            id: animal ? animal.id : undefined,
            animal_image: animal ? animal.image : "",
            name: animal ? animal.name : "",
            birth_date: animal ? animal.birth_date : new Date(),
            animal_type: animal ? animal.animal_type.name : "dog",
            breed: animal
                ? {
                  value: animal.breed.id.toString(),
                  label: animal.breed.name.toString(),
                }
                : {
                  value: "-1",
                  label: "",
                },
            weight: animal ? animal.weight : 0,
            special_need_id: animal ? animal.special_need.id : -1,
            size: animal ? animal.size : "Mini",
            special_need_radio: animal
                ? animal.special_need.need_type
                : "fisiologico",
          }}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={onSubmit}
      >
        {(props) => (
            <Form>
              <Stack spacing={"0.5rem"}>
                <Field name={"animal_image"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                      <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                        <FormLabel
                            fontSize={ThirdLooversFontSize}
                            htmlFor={"animal_image"}
                        >
                          {t("animal.image")}
                        </FormLabel>
                        <FileUpload
                            onFileUploaded={(fileUrls: string[]) => {
                              if (fileUrls.length === 1) {
                                setFieldValue(field.name, fileUrls[0]);
                              }
                            }}
                        />
                        <VStack
                            borderRadius={"0.5rem"}
                            padding="0.5rem"
                            bgColor={mainTheme.colors.textWhite}
                        >
                          {field.value !== "" && (
                              <Image
                                  border={"1px solid lightgrey"}
                                  p={4}
                                  boxShadow="md"
                                  src={field.value}
                                  boxSize="2xs"
                                  objectFit="contain"
                              />
                          )}
                        </VStack>
                      </FormControl>
                  )}
                </Field>

                <Field name={"name"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                      <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                        <FormLabel fontSize={ThirdLooversFontSize} htmlFor={"name"}>
                          {t("animal.name")} *
                        </FormLabel>
                        <Input
                            name={field.name}
                            value={field.value}
                            onChange={(event) =>
                                setFieldValue(field.name, event.target.value)
                            }
                            fontSize={ThirdLooversFontSize}
                        />
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                  )}
                </Field>

                <Field name={"birth_date"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                      <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                        <FormLabel
                            fontSize={ThirdLooversFontSize}
                            htmlFor={"birth_date"}
                        >
                          {t("customer.birthDate")} *
                        </FormLabel>
                        <DatePicker
                            name={field.name}
                            onChange={(newDate: Date) =>
                                setFieldValue(field.name, newDate)
                            }
                            value={field.value}
                            maxDate={today}
                        />
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                  )}
                </Field>

                <Field name={"animal_type"}>
                  {({ field, form: { setFieldValue }, meta }: any) => (
                      <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                        <FormLabel fontSize={ThirdLooversFontSize}>
                          {"Tipo di animale"} *
                        </FormLabel>
                        <Select
                            name={field.name}
                            value={field.value}
                            onChange={(event) => {
                              setFieldValue(field.name, event.target.value);
                            }}
                        >
                          <option value={UsersPerimeter.dog}>
                            {t("promoForm.usersPerimeter.dog")}
                          </option>
                          <option value={UsersPerimeter.cat}>
                            {t("promoForm.usersPerimeter.cat")}
                          </option>
                          <option value={UsersPerimeter.smallAnimal}>
                            {t("promoForm.usersPerimeter.smallAnimal")}
                          </option>
                        </Select>
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                  )}
                </Field>

                {(props.values.animal_type === "dog" ||
                    props.values.animal_type === "cat") && (
                    <Field name={"breed"}>
                      {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                          <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                            <FormLabel
                                fontSize={ThirdLooversFontSize}
                                htmlFor={"breed"}
                            >
                              {t("animal.breed")} *
                            </FormLabel>
                            <BreedSelect
                                animal_id={Number(props.values.id)}
                                field={field}
                                setFieldValue={setFieldValue}
                                animal_type={props.values.animal_type}
                            />
                            <FormErrorMessage>{meta.error}</FormErrorMessage>
                          </FormControl>
                      )}
                    </Field>
                )}

                {UsersPerimeter.dog === props.values.animal_type && (
                    <Field name={"size"}>
                      {({ field, form: { setFieldValue }, meta }: any) => (
                          <FormControl
                              isInvalid={meta.description && meta.touched}
                              as="fieldset"
                          >
                            <FormLabel>{"Taglia"} *</FormLabel>
                            <AnimalSizeSelect
                                field={field}
                                setFieldValue={setFieldValue}
                                formikProps={props.values}
                                promo={new Promo(new PromoBreed(-1, "", "", ""))}
                                isAnimalForm
                            />
                          </FormControl>
                      )}
                    </Field>
                )}

                <Field name={"weight"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                      <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                        <FormLabel fontSize={ThirdLooversFontSize} htmlFor={"weight"}>
                          {t("animal.weight")} (Kg)
                        </FormLabel>
                        <Input
                            name={field.name}
                            value={field.value}
                            onChange={(event) =>
                                setFieldValue(field.name, event.target.value)
                            }
                            fontSize={ThirdLooversFontSize}
                        />
                        <FormHelperText>
                          {t("promoForm.weightIsNotRequired")}
                        </FormHelperText>
                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                      </FormControl>
                  )}
                </Field>

                {props.values.animal_type !== "smallAnimal" && (
                    <Field name={"special_need_radio"}>
                      {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                          <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                            <FormLabel
                                fontSize={ThirdLooversFontSize}
                                htmlFor={"special_need_radio"}
                            >
                              {t("promoForm.specialNeedsRadio")} *
                            </FormLabel>

                            <RadioGroup
                                onChange={(nextValue: string) => {
                                  setFieldValue(field.name, nextValue);
                                }}
                                value={field.value}
                            >
                              <Stack direction="row">
                                <Radio value="fisiologico">
                                  {t("promoForm.specialNeedPhysiologic")}
                                </Radio>
                                <Radio value="dietetico">
                                  {t("promoForm.specialNeedDietetic")}
                                </Radio>
                              </Stack>
                            </RadioGroup>
                            <FormErrorMessage>{meta.error}</FormErrorMessage>
                          </FormControl>
                      )}
                    </Field>
                )}

                {props.values.animal_type !== "smallAnimal" && (
                    <Field name={"special_need_id"}>
                      {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                          <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                            <FormLabel
                                fontSize={ThirdLooversFontSize}
                                htmlFor={"special_need_id"}
                            >
                              {t("animal.specialNeeds")} *
                            </FormLabel>

                            <SpecialNeedSelect
                                field={field}
                                setFieldValue={setFieldValue}
                                animal_type={props.values.animal_type}
                                formikProps={props}
                            />
                            <FormErrorMessage>{meta.error}</FormErrorMessage>
                          </FormControl>
                      )}
                    </Field>
                )}
                <HStack>
                  {animal && animal?.id > 0 && (
                      <Button
                          colorScheme="red"
                          disabled={props.isSubmitting}
                          onClick={() => onDelete(props.values)}
                      >
                        {t("common.delete")}
                      </Button>
                  )}

                  <Spacer />

                  <Button
                      type={"submit"}
                      colorScheme={"teal"}
                      variant={"solid"}
                      disabled={props.isSubmitting}
                  >
                    {t("common.confirm")}
                  </Button>
                </HStack>
                {!props.isValid && props.submitCount > 0 && (
                    <Text fontSize="sm" color={mainTheme.colors.danger}>
                      {t("auth.messages.formError")}
                    </Text>
                )}
              </Stack>
            </Form>
        )}
      </Formik>
  );
};
