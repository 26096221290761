import React, { useCallback, useState } from "react";
import {
  Button,
  Flex,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { mainTheme } from "../../../config/theme";

import { ReviewModal } from "./ReviewModal";
import {
  ADD_REVIEW,
  DELETE_REVIEW,
  FETCH_CUSTOMER_REVIEWS,
  UPDATE_REVIEW,
} from "../../../screens/CustomerDashboard/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useStores } from "../../../hooks/UseStores";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { StoreReview } from "../../../entities/StoreReview";
import { AddReviewFormType } from "../../AddReviewForm/AddReviewForm";

export const CustomerStoreReviewHandler: React.FC = observer(() => {
  const { session } = useStores();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [review, setReview] = useState<StoreReview>();
  const toast = useToast();

  const [fetchCustomerReviews, { loading, refetch }] = useLazyQuery(
    FETCH_CUSTOMER_REVIEWS,
    {
      fetchPolicy: "no-cache",
      onCompleted: (review) => {
        setReview(
          review.customer.store_reviews.find(
            (review: any) =>
              review.store.id ===
              (session.customerUser?.favorite_store
                ? session.customerUser?.favorite_store.id
                : -1)
          )
        );
      },
      onError: (error) => {
        console.log("Fetch customer reviews failed:", error);
      },
    }
  );

  const [addReview] = useMutation(ADD_REVIEW, {
    onCompleted: (data) => {
      setReview(data.createStoreReview);
      onClose();
      refetch && refetch();
      toast({
        status: "success",
        title: t("dashboard.reviewCreated"),
      });
      window.location.reload();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [updateReview] = useMutation(UPDATE_REVIEW, {
    onCompleted: (data) => {
      setReview(data.updateOneStore_Review);
      onClose();
      refetch && refetch();
      toast({
        status: "success",
        title: t("dashboard.reviewUpdated"),
      });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const [deleteReview] = useMutation(DELETE_REVIEW, {
    onCompleted: () => {
      setReview(undefined);
      onClose();
      refetch && refetch();
      toast({
        status: "success",
        title: t("dashboard.reviewDeleted"),
      });
      window.location.reload();
    },
    onError: (error) => {
      console.log(error);
      toast({
        status: "error",
        title: t("dashboard.reviewNotDeleted"),
      });
    },
  });

  useEffect(() => {
    if (session.customerUser) {
      fetchCustomerReviews({
        variables: { customerId: session.customerUser.id },
      });
    }
  }, [fetchCustomerReviews, session]);

  const handleSubmit = useCallback(
    (data: AddReviewFormType) => {
      if (data.id) {
        updateReview({
          variables: {
            id: data.id,
            title: data.title,
            description: data.description,
            stars: data.stars,
            date: new Date(data.date),
          },
        });
      } else {
        addReview({
          variables: {
            customer_id: session.customerUser?.id,
            store_id: session.customerUser?.favorite_store
              ? session.customerUser?.favorite_store.id
              : -1,
            title: data.title,
            description: data.description,
            stars: data.stars,
            date: data.date.toDateString(),
          },
        });
      }

      onClose();
    },
    [
      addReview,
      onClose,
      session.customerUser?.favorite_store,
      session.customerUser?.id,
      updateReview,
    ]
  );

  const handleDelete = useCallback(
    (data: AddReviewFormType) => {
      if (data.id && session.customerUser) {
        deleteReview({
          variables: {
            id: data.id,
            customer_id: session.customerUser.id,
          },
        });
      }

      onClose();
    },
    [deleteReview, onClose, session.customerUser]
  );

  const openModal = useCallback(
    (review?: StoreReview) => {
      setReview(review);
      onOpen();
    },
    [onOpen]
  );

  if (!session.customerUser) {
    return null;
  }

  return (
    <Flex>
      {loading && (
        <Flex
          direction={"row"}
          alignItems={"center"}
          justifyContent={"center"}
          m={6}
          gridGap={0}
        >
          <Spinner
            thickness="3px"
            speed="0.65s"
            emptyColor={mainTheme.colors.lightPurple}
            color={mainTheme.colors.darkPurple}
            size="md"
            margin={"2rem"}
          />
        </Flex>
      )}
      <Button
        backgroundColor={mainTheme.colors.primaryGreen}
        color={mainTheme.colors.textWhite}
        // border={`1px solid ${mainTheme.colors.background}`}
        colorScheme={"whiteAlpha"}
        onClick={() => openModal(review)}
        disabled={loading}
      >
        {review ? t("dashboard.editReview") : t("dashboard.newReview")}
      </Button>
      <ReviewModal
        review={review}
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
      />
    </Flex>
  );
});
