import { useDropzone } from "react-dropzone";
import { uploadToMSBucket } from '../../my_modules/ms-bucket-file-uploader';
import { MS_SEND_MAIL_API_KEY } from "../../config/constants";
import { Field, FieldProps } from "formik";
import { FormControl, FormHelperText } from "@chakra-ui/form-control";
import { useTranslation } from "react-i18next";
import {
  VStack,
  Stack,
  Image,
  Spinner,
  Button,
  FormLabel,
} from "@chakra-ui/react";
import { mainTheme } from "../../config/theme";
import { useCallback, useMemo, useState } from "react";
import {DB_URL} from "../../config/config";

type Props = {
  fieldValue: string[];
};

export const ProductImages = ({ fieldValue }: Props) => {
  const { t } = useTranslation();
  const maxFiles: number = 4;

  const FileUpload = ({
                        onFileUploaded,
                      }: {
    onFileUploaded: (fileUrls: string[]) => void;
  }) => {
    const [isUploading, setIsUploading] = useState(false);
    const [minFilesError, setMinFilesError] = useState<boolean>(false);

    const onDrop = (files: File[]) => {
      if (1) {
        if (files.length < 1) {
          setMinFilesError(true);
        } else {
          setMinFilesError(false);
        }
      }
    };

    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      acceptedFiles,
    } = useDropzone({
      maxFiles: maxFiles - fieldValue.length,
      onDrop,
    });

    const dNDStyle = useMemo(() => {
      const baseStyle = {
        flex: 1,
        alignItems: "center",
        padding: "20px",
        borderWidth: 2,
        borderRadius: 2,
        borderColor: "#eeeeee",
        borderStyle: "dashed",
        backgroundColor: "#fafafa",
        color: "#bdbdbd",
        outline: "none",
        transition: "border .24s ease-in-out",
      };

      const activeStyle = {
        borderColor: "#2196f3",
      };

      const acceptStyle = {
        borderColor: "#00e676",
      };

      const rejectStyle = {
        borderColor: "#ff1744",
      };

      return {
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      };
    }, [isDragActive, isDragAccept, isDragReject]);

    const handleUpload = useCallback(async () => {
      if (!minFilesError && acceptedFiles.length > 0) {
        setIsUploading(true);
        const formData = new FormData();

        // Se vuoi caricare più file, appendili tutti a formData
        acceptedFiles.forEach(file => {
          formData.append('files', file); // Assicurati che il backend sia configurato per accettare 'files' come chiave

        });

        try {


          const response = await fetch(`https://dbloovers.b01.me/upload`, {
            method: 'POST',
            body: formData,
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }


          const fileUrls = await response.json(); // Assumi che il server ritorni un array di URL o un oggetto simile
          console.log(fileUrls);
          const urls = fileUrls.files.map(file => DB_URL+file.path);
          onFileUploaded(urls);
        } catch (err) {
          alert(t("common.error"));
          console.error(err);
        } finally {
          setIsUploading(false);
        }
      }
    }, [minFilesError, onFileUploaded,  acceptedFiles, t]);

    const fileList = acceptedFiles.map((file) => (
        <li key={file.name}>{file.name}</li>
    ));

    return (
        <VStack align="flex-start" spacing="0.5rem" marginBottom="1rem">
          <div {...getRootProps({ style: dNDStyle })}>
            <input {...getInputProps()} />
            {t("qrCodeReaderModal.dragNDrop")}
          </div>
          <aside>
            <ul>{fileList}</ul>
          </aside>

          {isUploading && <Spinner colorScheme="purple" />}
          <Button
              onClick={handleUpload}
              colorScheme={"green"}
              disabled={acceptedFiles.length === 0 || isUploading || minFilesError}
          >
            {t("qrCodeReaderModal.upload")}
          </Button>
        </VStack>
    );
  };

  const deleteImage = (
      src: string,
      fieldName: string,
      fieldValue: string[],
      setFieldValue: (
          field: string,
          value: any,
          shouldValidate?: boolean | undefined
      ) => void
  ) => {
    const newValue: string[] = fieldValue.filter((itemSrc: string) => {
      return src !== itemSrc;
    });

    setFieldValue(fieldName, newValue);
  };

  return (
      <Field name={"product_images"}>
        {({ field, form: { setFieldValue }, meta }: FieldProps) => (
            <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
              <FormLabel htmlFor={"product_images"}>
                {"Immagini prodotto"}
              </FormLabel>
              {maxFiles - field.value.length > 0 && (
                  <FormHelperText>{`Massimo ${maxFiles - field.value.length} ${
                      maxFiles - field.value.length === 1 ? "immagine" : "immagini"
                  }`}</FormHelperText>
              )}

              <FileUpload
                  onFileUploaded={(fileUrls: string[]) => {
                    if (fileUrls.length > 0) {
                      setFieldValue(field.name, [...field.value, ...fileUrls]);
                    }
                  }}
              />
              <Stack
                  borderRadius={"0.5rem"}
                  padding="0.5rem"
                  bgColor={mainTheme.colors.textWhite}
              >
                {field.value.map((src: string) => {
                  return (
                      <Stack p={4} border={"1px solid lightgrey"} boxShadow="md">
                        <Image
                            border={"1px solid lightgrey"}
                            p={4}
                            boxShadow="md"
                            src={src}
                            boxSize="2xs"
                            objectFit="contain"
                        />
                        <Button
                            onClick={() =>
                                deleteImage(src, field.name, field.value, setFieldValue)
                            }
                            colorScheme="red"
                            aria-label="Delete image"
                        >
                          {t("common.delete")}
                        </Button>
                      </Stack>
                  );
                })}
              </Stack>
            </FormControl>
        )}
      </Field>
  );
};
