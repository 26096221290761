import { Field, Form, Formik } from "formik";
import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Textarea } from "@chakra-ui/textarea";
import { ModalFooterForm } from "../ModalFooterForm";
import { Product } from "../../entities/Product";
import { ProductImages } from "./ProductImages";
import { ProductBrandSelect } from "./ProductBrandSelect";
import { ProductCompany } from "./ProductCompany";
import { ProductSegment } from "./ProductSegment";
import { ProductSpecialNeedsSelect } from "./ProductSpecialNeedsSelect";
import { ProductUsersPerimeter } from "./ProductUsersPerimeter";
import { ProductSizeSelect } from "./ProductAnimalSize";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { ProductEanCode } from "./ProductEanCode";
import { ProductFlavor } from "./ProductFlavor";
import { ProductCountryOfProduction } from "./ProductCountryOfProduction";
import { ProductIngredientesFeatures } from "./ProductIngredientsFeatures";
import { ProductRecommendations } from "./ProductRecommendations";
import { ProductAvailableFormats } from "./ProductAvailableFormats";
import ReactStars from "react-rating-stars-component";
import { FaStar } from "react-icons/fa";

type Props = {
  product: Product | null;
  onCancel: any;
  onSubmit: any;
  onDelete?: any;
};

export const ProductForm: React.FC<Props> = ({
  product,
  onCancel,
  onSubmit,
  onDelete,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("productForm.nameRequired"))
      .min(1, t("productForm.min1")),
  });

  const productImages: string[] = product?.product_images
    ? JSON.parse(product.product_images)
    : [];

  return (
    <Formik
      initialValues={{
        id: product?.id || null,
        name: product?.name || "",
        description: product?.description || "",
        product_images: productImages,
        brandId: product?.brand.id || null,
        company: product?.company || "",
        segment: product?.segment || "",
        users_perimeter: product?.users_perimeter || "all",
        special_need_radio: product?.special_need_radio || "all",
        special_needs_id: product?.special_needs.id || -1,
        size: product?.size || "",
        country_of_production: product?.country_of_production || "",
        flavor: product?.flavor || "",
        available_formats: product?.available_formats || "",
        ingredients_features: product?.ingredients_features || "",
        ean_code: product?.ean_code || "",
        recommendations: product?.recommendations || "",
        greenFootprintRating: product?.greenFootprintRating || 0,
        greenFootprintComment: product?.greenFootprintComment || "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Field id={"name"} name={"name"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl isInvalid={meta.error && meta.touched}>
                <FormLabel htmlFor="name">{"Nome"}</FormLabel>
                <Input
                  name={field.name}
                  value={field.value}
                  onChange={(event) =>
                    setFieldValue(field.name, event.target.value)
                  }
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={"description"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl isInvalid={meta.description && meta.touched}>
                <FormLabel htmlFor={"description"}>Descrizione</FormLabel>
                <Textarea
                  name={field.name}
                  value={field.value}
                  onChange={(event) =>
                    setFieldValue(field.name, event.target.value)
                  }
                  size={"sm"}
                />

                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <ProductImages fieldValue={props.values.product_images} />

          <ProductBrandSelect
            productId={props.values.id}
            setFieldValue={props.setFieldValue}
          />

          <ProductCompany />

          <ProductEanCode />

          <ProductFlavor />

          <ProductCountryOfProduction />

          <ProductIngredientesFeatures />

          <ProductRecommendations />

          <ProductAvailableFormats />

          <ProductSegment segment={props.values.segment} />

          <ProductUsersPerimeter />

          <ProductSpecialNeedsSelect
            productId={props.values.id}
            setFieldValue={props.setFieldValue}
            special_need_radio={props.values.special_need_radio}
            users_perimeter={props.values.users_perimeter}
          />

          <ProductSizeSelect />

          <Field name={"greenFootprintRating"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                <FormLabel htmlFor={"greenFootprintComment"}>
                  Impronta verde
                </FormLabel>
                <ReactStars
                  count={5}
                  value={field.value}
                  onChange={(newValue: number) => {
                    setFieldValue(field.name, newValue);
                  }}
                  size={25}
                  isHalf={false}
                  emptyIcon={<FaStar color={"grey"} size={5} />}
                  // halfIcon={<FaStarHalfAlt color={"grey"} size={STAR_SIZE} />}
                  fullIcon={<FaStar color={"grey"} size={5} />}
                  activeColor="#ffd700"
                />
                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={"greenFootprintComment"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl
                isInvalid={meta.greenFootprintComment && meta.touched}
              >
                <FormLabel htmlFor={"greenFootprintComment"}>
                  Descrizione impronta verde
                </FormLabel>
                <Textarea
                  name={field.name}
                  value={field.value}
                  onChange={(event) =>
                    setFieldValue(field.name, event.target.value)
                  }
                  size={"sm"}
                />

                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <ModalFooterForm
            onCancel={onCancel}
            onDelete={product?.id && onDelete}
          />
        </Form>
      )}
    </Formik>
  );
};
