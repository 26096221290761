import {
  Avatar,
  HStack,
  useMediaQuery,
  VStack,
  Progress,
  Box,
  Text,
} from "@chakra-ui/react";
import { useStores } from "../../../hooks/UseStores";
import { Session } from "../../../store/Session";
import SeniorIcon from "../../../assets/images/Loovers-punti-utente-senior.png";
import JuniorIcon from "../../../assets/images/Loovers-punti-utente-junior.png";
import AbassadorIcon from "../../../assets/images/Loovers-punti-utente-ambassador.png";

interface Props {
  avatarSize: string;
  session: Session;
}

export const CustomerLevels = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const avatarSize: string = isLargerThan768 ? "6rem" : "4rem";
  const { session } = useStores();

  return isLargerThan768
    ? DesktopCustomerLevels({ avatarSize, session })
    : MobileCustomerLevels({ avatarSize, session });
};

const DesktopCustomerLevels: React.FC<Props> = ({ avatarSize, session }) => {
  const isIndeterminate = false;

  return (
    <VStack spacing={"0.5rem"}>
      <HStack spacing={0} w="100%">
        <Avatar
          w={avatarSize}
          h={avatarSize}
          alt="first level. animal image"
          src={JuniorIcon}
        />

        <Progress
          flex={1}
          h="1rem"
          hasStripe
          value={
            session.customerUser ? session.customerUser.loyality_points : 0
          }
          max={2000}
          isIndeterminate={isIndeterminate}
          colorScheme="green"
        />

        <Avatar
          w={avatarSize}
          h={avatarSize}
          name="Level 2"
          alt="second level. animal image"
          src={SeniorIcon}
        />

        <Progress
          flex={1}
          h="1rem"
          hasStripe
          value={
            session.customerUser
              ? session.customerUser.loyality_points >= 2000
                ? session.customerUser.loyality_points - 2000
                : 0
              : 0
          }
          max={2000}
          isIndeterminate={isIndeterminate}
          colorScheme="green"
        />

        <Avatar
          w={avatarSize}
          h={avatarSize}
          alt="third level. animal image"
          src={AbassadorIcon}
        />
      </HStack>

      <HStack spacing={0} w="100%" align="flex-start">
        <Box w={avatarSize}>
          <Text textAlign="center">Junior</Text>
        </Box>

        <Box flex={1} h="1rem" bgColor="white" />

        <VStack w={avatarSize} align={"center"}>
          <Box w={avatarSize}>
            <Text textAlign="center">{"Expert"}</Text>
          </Box>
          <Text fontWeight="bold">{"2.000"}</Text>
          <Text textAlign="center">{"Loovers points"}</Text>
        </VStack>

        <Box flex={1} h="1rem" bgColor="white" />

        <VStack w={avatarSize} align={"center"}>
          <Box w={avatarSize}>
            <Text textAlign="center">{"Ambassador"}</Text>
            <Text textAlign="center" fontWeight="bold">
              {"4.000"}
            </Text>
            <Text textAlign="center">{"Loovers points"}</Text>
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
};

const MobileCustomerLevels: React.FC<Props> = ({ avatarSize, session }) => {
  const isIndeterminate = false;

  return (
    <VStack spacing={"0.5rem"}>
      <HStack spacing={0} w="100%">
        <Avatar
          w={avatarSize}
          h={avatarSize}
          alt="first level. animal image"
          src={JuniorIcon}
        />

        <Progress
          flex={1}
          h="1rem"
          hasStripe
          value={session.customerUser?.loyality_points}
          max={2000}
          isIndeterminate={isIndeterminate}
          colorScheme="green"
        />

        <Avatar
          w={avatarSize}
          h={avatarSize}
          name="Level 2"
          alt="second level. animal image"
          src={AbassadorIcon}
        />
      </HStack>

      <HStack spacing={0} w="100%" align="flex-start">
        <Box w={avatarSize}>
          <Text textAlign="center">Junior</Text>
        </Box>

        <Box flex={1} h="1rem" bgColor="white" />

        <VStack>
          <Box w={avatarSize}>
            <Text textAlign="center">Expert</Text>
            <Text fontWeight="bold" textAlign="center">
              2.000
            </Text>
            <Text textAlign="center">Loovers points</Text>
          </Box>
        </VStack>
      </HStack>
    </VStack>
  );
};
