import { Button } from "@chakra-ui/button";
import { Checkbox } from "@chakra-ui/checkbox";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/layout";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormLabel } from "../../FormLabel";
import * as Yup from "yup";
import { FormInput } from "../../FormInput";
import {
    PARTNER_SIGNUP_ART_51_URL,
    PARTNER_SIGNUP_GENERAL_POLICY,
    PARTNER_SIGNUP_PRIVACY_POLICY_URL,
    PARTNER_SIGNUP_TERMS_OF_USE_URL,
    PARTNER_SUBSCRIPTION_PRICES,
    phoneRegExp,
    STORE_TYPES,
    SUBSCRIPTION_TYPES,
} from "../../../config/constants";

import {
    Alert,
    AlertIcon,
    Badge,
    Box,
    Flex,
    Heading,
    Text,
    useMediaQuery,
    CircularProgress,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Link,
    HStack,
    Select,
    Tooltip,
} from "@chakra-ui/react";
import { useTheme } from "styled-components";
import { FaCheck, FaTimes, FaRegCheckCircle } from "react-icons/fa";
import { Link as ReachLink } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CHECK_PROMO_CODE } from "../../../services/graphql";
import { DistrictsForm } from "../../PartnerPromotions/DistrictsForm";

const MAX_INPUT_WIDTH = "500px";

type PartnerSignUpFormType = {
    businessName: string;
    addressBusiness: string;
    addressBusinessCity: string;
    addressBusinessDistrict: { label: string; value: string };
    addressBusinessCap: string;
    bannerName: string;
    emailB2B: string;
    mobilePhoneB2B: string;
    phoneB2B?: string;
    vatNumber: string;
    taxCode: string;
    pec: string;
    sdi: string;
    password: string;
    confirmPassword: string;
    promoCode: string;
    subscription: typeof SUBSCRIPTION_TYPES[number];
    termsOfUse: boolean;
    marketing: boolean;
    couponId?: string;
};

type Props = {
    isSubmitting: boolean;
    onSubmit: (values: PartnerSignUpFormType) => void;
};

export const PartnerSignUpForm: React.FC<Props> = ({
                                                       isSubmitting,
                                                       onSubmit,
                                                   }) => {
    const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
    const { t } = useTranslation();

    const SUPERLIGHT_BENEFITS = [
        t("subscriptionBenefits.superlight.1"),
        t("subscriptionBenefits.superlight.2"),
        t("subscriptionBenefits.superlight.3"),
        t("subscriptionBenefits.superlight.4"),
        t("subscriptionBenefits.superlight.5"),
        t("subscriptionBenefits.superlight.6"),
        t("subscriptionBenefits.superlight.7"),
        t("subscriptionBenefits.superlight.8"),
        t("subscriptionBenefits.superlight.9"),
    ];

    const BASE_BENEFITS = [
        ...SUPERLIGHT_BENEFITS,
        t("subscriptionBenefits.base.1"),
        t("subscriptionBenefits.base.2"),
        t("subscriptionBenefits.base.3"),
        t("subscriptionBenefits.base.4"),
        t("subscriptionBenefits.base.5"),
        t("subscriptionBenefits.base.6"),
    ];
    const PREMIUM_BENEFITS = [
        ...SUPERLIGHT_BENEFITS,
        t("subscriptionBenefits.base.1"),
        t("subscriptionBenefits.base.2"),
        t("subscriptionBenefits.base.3"),
        t("subscriptionBenefits.base.5"),
        t("subscriptionBenefits.premium.1"),
        t("subscriptionBenefits.base.6"),
        t("subscriptionBenefits.premium.2"),
        t("subscriptionBenefits.premium.3"),
    ];

    const theme = useTheme();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [promoCodeValid, setPromoCodeValid] = useState<boolean | undefined>(
        undefined
    );
    const [promoCode, setPromoCode] = useState<string>("");
    const [promoCodeValue, setPromoCodeValue] = useState<number>(0);
    const [couponId, setCouponId] = useState<string>("");

    const handleCheckPromoCode = useCallback((data) => {
        if (data.checkPromoCode.isPromoCodeValid) {
            setPromoCodeValid(true);
            setPromoCodeValue(data.checkPromoCode.promoCodeValue);
            setCouponId(data.checkPromoCode.couponId);
        } else {
            setPromoCodeValid(false);
        }
    }, []);

    const [checkPromoCodeMutation, { loading: mutationSubmitting }] = useMutation(
        CHECK_PROMO_CODE,
        {
            onCompleted: handleCheckPromoCode,
            onError: (error) => {
                console.log(error);
                setPromoCodeValid(false);
            },
        }
    );

    const checkPromoCode = useCallback(
        (subscription) => {
            checkPromoCodeMutation({
                variables: {
                    promo_code: promoCode,
                    selected_subscription: subscription,
                },
            });
        },
        [checkPromoCodeMutation, promoCode]
    );

    const handlePromoCodeKeyDown = useCallback(
        (event, subscription) => {
            if (event.key === "Enter") {
                checkPromoCode(subscription);
            }
        },
        [checkPromoCode]
    );

    const resetPromoCode = useCallback((setFieldValue) => {
        setPromoCode("");
        setPromoCodeValid(undefined);
        setPromoCodeValue(0);
        setCouponId("");
        setFieldValue("promoCode", "");
    }, []);

    const validationSchema = Yup.object().shape({
        businessName: Yup.string()
            .required(t("auth.validations.businessNameRequired"))
            .min(2, t("auth.validations.businessNameRequired")),
        storeType: Yup.string().required(t("auth.validations.storeTypeRequired")),
        addressBusiness: Yup.string()
            .required(t("auth.validations.addressBusinessRequired"))
            .min(2, t("auth.validations.addressBusinessRequired")),
        addressBusinessCity: Yup.string()
            .required(t("auth.validations.addressBusinessCityRequired"))
            .min(2, t("auth.validations.addressBusinessCityRequired")),
        addressBusinessDistrict: Yup.object().required(
            t("auth.validations.districtRequired")
        ),
        addressBusinessCap: Yup.string()
            .typeError(t("auth.validations.addressBusinessCapNumber"))
            .required(t("auth.validations.addressBusinessCapRequired"))
            .min(2, t("auth.validations.addressBusinessCapRequired")),
        bannerName: Yup.string()
            .required(t("auth.validations.bannerNameRequired"))
            .min(2, t("auth.validations.bannerNameRequired")),
        emailB2B: Yup.string()
            .required(t("auth.validations.emailRequired"))
            .min(2, t("auth.validations.emailRequired"))
            .email(t("auth.validations.emailInvalid")),
        mobilePhoneB2B: Yup.string()
            .required(t("auth.validations.mobilePhoneRequired"))
            .min(2, t("auth.validations.mobilePhoneRequired"))
            .matches(phoneRegExp, t("auth.validations.mobilePhoneInvalid")),
        phoneB2B: Yup.string().matches(
            phoneRegExp,
            t("auth.validations.mobilePhoneInvalid")
        ),
        vatNumber: Yup.string()
            .required(t("auth.validations.vatNumberRequired"))
            .min(2, t("auth.validations.vatNumberRequired")),
        taxCode: Yup.string()
            .required(t("auth.validations.taxCodeRequired"))
            .min(2, t("auth.validations.taxCodeRequired")),
        pec: Yup.string()
            .required(t("auth.validations.pecRequired"))
            .min(2, t("auth.validations.pecRequired"))
            .email(t("auth.validations.emailInvalid")),
        password: Yup.string()
            .required(t("auth.validations.passwordRequired"))
            .min(4, t("auth.validations.passwordRequired")),
        confirmPassword: Yup.string()
            .required(t("auth.validations.confirmPasswordRequired"))
            .min(4, t("auth.validations.confirmPasswordRequired"))
            .oneOf(
                [Yup.ref("password"), null],
                t("auth.validations.passwordsNotMatch")
            ),
        subscription: Yup.string()
            .required(t("auth.validations.subscriptionRequired"))
            .oneOf(SUBSCRIPTION_TYPES),
        termsOfUse: Yup.boolean().oneOf(
            [true],
            t("auth.validations.termsOfUseRequired")
        ),
        clausesConfirmation: Yup.boolean().oneOf([true], "Accettazione richiesta"),
    });

    return (
        <Formik
            initialValues={{
                businessName: "",
                storeType: undefined,
                addressBusiness: "",
                addressBusinessCity: "",
                addressBusinessDistrict: {
                    label: "",
                    value: "",
                },
                addressBusinessCap: "",
                bannerName: "",
                emailB2B: "",
                mobilePhoneB2B: "",
                phoneB2B: "",
                vatNumber: "",
                taxCode: "",
                pec: "",
                sdi: "",
                password: "",
                confirmPassword: "",
                promoCode: "",
                subscription: "",
                termsOfUse: false,
                marketing: false,
                clausesConfirmation: false,
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
                onSubmit({ ...values, couponId });
            }}
        >
            {(props) => (
                <Form>
                    <Stack spacing={"1rem"} alignItems={"center"}>
                        <Box maxW={MAX_INPUT_WIDTH} w={"100%"}>
                            <Field name={"businessName"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"businessName"} $signUpForm>
                                            {t("partner.businessName")} *
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"storeType"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"storeType"} $signUpForm>
                                            {t("partner.storeType")} *
                                        </FormLabel>
                                        <Select
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event) => {
                                                setFieldValue(field.name, event.target.value);
                                            }}
                                            backgroundColor={"white"}
                                            color={"black"}
                                        >
                                            <option value={undefined}>---</option>
                                            {STORE_TYPES.map((option) => (
                                                <option key={option} value={option}>
                                                    {t(`partner.storeTypes.${option}`)}
                                                </option>
                                            ))}
                                        </Select>
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Text marginBottom={"0.5em"}>{t("partner.addressBusiness")}</Text>
                            <Field name={"addressBusiness"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"addressBusinessStreet"} $signUpForm>
                                            {t("partner.addressBusinessStreet")} *
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <HStack>
                                <Field name={"addressBusinessCity"}>
                                    {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                        <FormControl
                                            isInvalid={Boolean(meta.error) && meta.touched}
                                        >
                                            <FormLabel htmlFor={"addressBusinessCity"} $signUpForm>
                                                {t("partner.addressBusinessCity")} *
                                            </FormLabel>
                                            <FormInput
                                                name={field.name}
                                                value={field.value}
                                                onChange={(event: any) =>
                                                    setFieldValue(field.name, event.target.value)
                                                }
                                                $signUpForm
                                            />
                                            <FormErrorMessage>{meta.error}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name={"addressBusinessDistrict"}>
                                    {({ field, form: { setFieldValue }, meta }: any) => (
                                        <FormControl
                                            isInvalid={Boolean(meta.error) && meta.touched}
                                        >
                                            <FormLabel
                                                htmlFor={"addressBusinessDistrict"}
                                                $signUpForm
                                            >
                                                {t("partner.addressBusinessDistrict")} *
                                            </FormLabel>
                                            <DistrictsForm
                                                districts={props.values.addressBusinessDistrict}
                                                canDisableFields={false}
                                                setFieldValue={setFieldValue}
                                                promo={undefined}
                                                field={field}
                                                isUser
                                            />
                                            <FormErrorMessage>{meta.error}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>

                                <Field name={"addressBusinessCap"}>
                                    {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                        <FormControl
                                            isInvalid={Boolean(meta.error) && meta.touched}
                                            w={"200px"}
                                        >
                                            <FormLabel htmlFor={"addressBusinessCap"} $signUpForm>
                                                {t("partner.addressBusinessCap")} *
                                            </FormLabel>
                                            <FormInput
                                                name={field.name}
                                                value={field.value}
                                                onChange={(event: any) =>
                                                    setFieldValue(field.name, event.target.value)
                                                }
                                                $signUpForm
                                            />
                                            <FormErrorMessage>{meta.error}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                            </HStack>

                            <Field name={"bannerName"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"bannerName"} $signUpForm>
                                            {t("partner.bannerName")} *
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"emailB2B"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"emailB2B"} $signUpForm>
                                            {t("partner.emailB2B")} *
                                        </FormLabel>
                                        <FormInput
                                            type={"email"}
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"pec"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"pec"} $signUpForm>
                                            {t("partner.pec")} *
                                        </FormLabel>
                                        <FormInput
                                            type={"email"}
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"mobilePhoneB2B"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"mobilePhoneB2B"} $signUpForm>
                                            {t("partner.mobilePhoneB2B")} *
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"phoneB2B"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"phoneB2B"} $signUpForm>
                                            {t("partner.phoneB2B")}
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"vatNumber"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"vatNumber"} $signUpForm>
                                            {t("partner.vatNumber")} *
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"taxCode"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"taxCode"} $signUpForm>
                                            {t("partner.taxCode")} *
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"sdi"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"sdi"} $signUpForm>
                                            {t("partner.sdi")}
                                        </FormLabel>
                                        <FormInput
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"password"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"password"} $signUpForm>
                                            {t("auth.password")} *
                                        </FormLabel>
                                        <FormInput
                                            type={"password"}
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                            autoComplete={"password"}
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field name={"confirmPassword"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                                        <FormLabel htmlFor={"confirmPassword"} $signUpForm>
                                            {t("auth.confirmPassword")} *
                                        </FormLabel>
                                        <FormInput
                                            type={"password"}
                                            name={field.name}
                                            value={field.value}
                                            onChange={(event: any) =>
                                                setFieldValue(field.name, event.target.value)
                                            }
                                            $signUpForm
                                            autoComplete={"password"}
                                        />
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Heading
                                as="h3"
                                size="lg"
                                textAlign={"center"}
                                marginBottom={"2.5em"}
                                marginTop={"1em"}
                            >
                                {t("partner.addSubscriptionInfo")}
                            </Heading>
                        </Box>

                        <Field name={"subscription"}>
                            {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                <>
                                    <FormControl
                                        isInvalid={Boolean(meta.error) && meta.touched}
                                        style={{
                                            marginTop: "-1.5rem",
                                        }}
                                    >
                                        <Flex
                                            flexDirection={isLargerThan768 ? "row" : "column"}
                                            maxW={"100%"}
                                            alignItems={isLargerThan768 ? "flex-start" : "center"}
                                            justifyContent={"center"}
                                        >
                                            <SubscriptionCard
                                                title={"SuperLight"}
                                                borderColor={
                                                    ["superlight-month", "superlight-year"].includes(
                                                        field.value
                                                    )
                                                        ? theme.colors.primaryYellow
                                                        : theme.colors.background
                                                }
                                                borderWidth={
                                                    ["superlight-month", "superlight-year"].includes(
                                                        field.value
                                                    )
                                                        ? "4px"
                                                        : "1px"
                                                }
                                                backgroundColor={theme.colors.darkPurple}
                                                price1={0} //imposto il prezzo a 0 per il piano free
                                                price2={0} //imposto il prezzo a 0 per il piano free
                                                isPrice1Selected={field.value === "superlight-free"}
                                                isPrice2Selected={field.value === "superlight-free"}
                                                onPrice1Select={() => {
                                                    setFieldValue(field.name, "superlight-free");
                                                    resetPromoCode(setFieldValue);
                                                }}
                                                onPrice2Select={() => {
                                                    setFieldValue(field.name, "superlight-free");
                                                    resetPromoCode(setFieldValue);
                                                }}
                                                isLoading={isLoading}
                                                setIsLoading={setIsLoading}
                                                isDesktop={isLargerThan768}
                                                benefits={SUPERLIGHT_BENEFITS}
                                            />
                                            <SubscriptionCard
                                                title={"Base"}
                                                borderColor={
                                                    ["base-month", "base-year"].includes(field.value)
                                                        ? theme.colors.primaryYellow
                                                        : theme.colors.background
                                                }
                                                borderWidth={
                                                    ["base-month", "base-year"].includes(field.value)
                                                        ? "4px"
                                                        : "1px"
                                                }
                                                backgroundColor={theme.colors.darkPurple}
                                                price1={PARTNER_SUBSCRIPTION_PRICES[2]}
                                                price2={PARTNER_SUBSCRIPTION_PRICES[3]}
                                                isPrice1Selected={field.value === "base-month"}
                                                isPrice2Selected={field.value === "base-year"}
                                                onPrice1Select={() => {
                                                    setFieldValue(field.name, "base-month");
                                                    resetPromoCode(setFieldValue);
                                                }}
                                                onPrice2Select={() => {
                                                    setFieldValue(field.name, "base-year");
                                                    resetPromoCode(setFieldValue);
                                                }}
                                                isLoading={isLoading}
                                                setIsLoading={setIsLoading}
                                                isDesktop={isLargerThan768}
                                                benefits={BASE_BENEFITS}
                                            />
                                            <SubscriptionCard
                                                title={"Premium"}
                                                badgeText={t("partner.popularSubscription")}
                                                borderColor={
                                                    ["premium-month", "premium-year"].includes(
                                                        field.value
                                                    )
                                                        ? theme.colors.primaryYellow
                                                        : theme.colors.background
                                                }
                                                borderWidth={
                                                    ["premium-month", "premium-year"].includes(
                                                        field.value
                                                    )
                                                        ? "4px"
                                                        : "1px"
                                                }
                                                backgroundColor={theme.colors.darkPurple}
                                                price1={PARTNER_SUBSCRIPTION_PRICES[4]}
                                                price2={PARTNER_SUBSCRIPTION_PRICES[5]}
                                                isPrice1Selected={field.value === "premium-month"}
                                                isPrice2Selected={field.value === "premium-year"}
                                                onPrice1Select={() => {
                                                    setFieldValue(field.name, "premium-month");
                                                    resetPromoCode(setFieldValue);
                                                }}
                                                onPrice2Select={() => {
                                                    setFieldValue(field.name, "premium-year");
                                                    resetPromoCode(setFieldValue);
                                                }}
                                                isLoading={isLoading}
                                                setIsLoading={setIsLoading}
                                                isDesktop={isLargerThan768}
                                                benefits={PREMIUM_BENEFITS}
                                            />
                                        </Flex>
                                        <FormErrorMessage style={{ justifyContent: "center" }}>
                                            {meta.error}
                                        </FormErrorMessage>
                                    </FormControl>
                                </>
                            )}
                        </Field>

                        <Box maxW={MAX_INPUT_WIDTH} w={"100%"}>
                            {!!props.values.subscription.length && !isLoading && (
                                <Alert
                                    status="success"
                                    variant="solid"
                                    backgroundColor={theme.colors.darkPurple}
                                    color={theme.colors.primaryYellow}
                                    style={{ display: "flex", alignItems: "center" }}
                                >
                                    <AlertIcon />
                                    <Trans i18nKey="auth.subscriptionSelected">
                                        Abbonamento
                                        <Badge margin={"0 0.5em"} colorScheme="purple">
                                            {{
                                                selectedSubscription:
                                                    props.values.subscription === "superlight-month"
                                                        ? "Superlight mensile"
                                                        : props.values.subscription === "superlight-year"
                                                            ? "Superlight annuale"
                                                            : props.values.subscription === "base-month"
                                                                ? "Base mensile"
                                                                : props.values.subscription === "base-year"
                                                                    ? "Base annuale"
                                                                    : props.values.subscription === "premium-month"
                                                                        ? "Premium mensile"
                                                                        : props.values.subscription === "premium-year"
                                                                            ? "Premium annuale"
                                                                            : "",
                                            }}
                                        </Badge>
                                        selezionato!
                                    </Trans>
                                </Alert>
                            )}
                            {isLoading && (
                                <CircularProgress
                                    isIndeterminate
                                    color={theme.colors.darkPurple}
                                    size={25}
                                    marginRight={"0.5em"}
                                />
                            )}
                            <Heading
                                as="h3"
                                size="lg"
                                textAlign={"center"}
                                marginBottom={"1.5em"}
                                marginTop={"1em"}
                            >
                                {t("partner.addCode")}
                            </Heading>
                            <Tooltip
                                label={t("auth.validations.subscriptionRequired")}
                                isDisabled={props.values.subscription !== ""}
                            >
                                <Flex alignItems={"center"} gridGap={5}>
                                    <Field name={"promoCode"}>
                                        {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                            <FormControl
                                                isInvalid={Boolean(meta.error) && meta.touched}
                                            >
                                                <FormLabel htmlFor={"promoCode"} $signUpForm>
                                                    {t("partner.promoCode")}
                                                </FormLabel>
                                                <FormInput
                                                    type={"text"}
                                                    name={field.name}
                                                    value={field.value}
                                                    onChange={(event: any) => {
                                                        setPromoCode(event.target.value);
                                                        setFieldValue(field.name, event.target.value);
                                                    }}
                                                    onFocus={() => {
                                                        setPromoCodeValid(undefined);
                                                    }}
                                                    onKeyDown={(e: KeyboardEvent) => {
                                                        handlePromoCodeKeyDown(
                                                            e,
                                                            props.values.subscription
                                                        );
                                                    }}
                                                    style={{
                                                        fontFamily: "Noe Display !important",
                                                        fontStyle: "italic",
                                                    }}
                                                    disabled={!!promoCode && !!promoCodeValue}
                                                    $signUpForm
                                                />
                                                <FormErrorMessage>{meta.error}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    {promoCodeValid === undefined ? (
                                        <></>
                                    ) : promoCodeValid ? (
                                        <FaCheck
                                            color={theme.colors.primaryGreen}
                                            style={{ marginLeft: "-40px", zIndex: 2 }}
                                        />
                                    ) : (
                                        <FaTimes
                                            color={theme.colors.danger}
                                            style={{ marginLeft: "-40px", zIndex: 2 }}
                                        />
                                    )}

                                    <Button
                                        backgroundColor={theme.colors.primaryYellow}
                                        onClick={() => {
                                            checkPromoCode(props.values.subscription);
                                        }}
                                        disabled={
                                            (!!promoCode && !!promoCodeValue) ||
                                            props.values.subscription === ""
                                        }
                                    >
                                        {t("common.verify")}
                                    </Button>
                                </Flex>
                            </Tooltip>

                            {mutationSubmitting && (
                                <CircularProgress
                                    isIndeterminate
                                    color={theme.colors.darkPurple}
                                    size={25}
                                    marginRight={"0.5em"}
                                />
                            )}
                            {promoCodeValid !== undefined && promoCodeValid && (
                                <Box marginBottom={"2rem"} maxW={MAX_INPUT_WIDTH}>
                                    <Box>
                                        <Text>
                                            {t("partner.priceMonth")}{" "}
                                            <del>
                                                {props.values.subscription === "superlight-month"
                                                    ? PARTNER_SUBSCRIPTION_PRICES[0]
                                                    : props.values.subscription === "superlight-year"
                                                        ? PARTNER_SUBSCRIPTION_PRICES[1]
                                                        : props.values.subscription === "base-month"
                                                            ? PARTNER_SUBSCRIPTION_PRICES[2]
                                                            : props.values.subscription === "base-year"
                                                                ? PARTNER_SUBSCRIPTION_PRICES[3]
                                                                : props.values.subscription === "premium-month"
                                                                    ? PARTNER_SUBSCRIPTION_PRICES[4]
                                                                    : PARTNER_SUBSCRIPTION_PRICES[5]}
                                                {t("common.selectedCurrency")}
                                            </del>{" "}
                                            {props.values.subscription === "superlight-month"
                                                ? (
                                                    PARTNER_SUBSCRIPTION_PRICES[0] *
                                                    (1 - promoCodeValue / 100)
                                                ).toFixed(2)
                                                : props.values.subscription === "superlight-year"
                                                    ? (
                                                        PARTNER_SUBSCRIPTION_PRICES[1] *
                                                        (1 - promoCodeValue / 100)
                                                    ).toFixed(2)
                                                    : props.values.subscription === "base-month"
                                                        ? (
                                                            PARTNER_SUBSCRIPTION_PRICES[2] *
                                                            (1 - promoCodeValue / 100)
                                                        ).toFixed(2)
                                                        : props.values.subscription === "base-year"
                                                            ? (
                                                                PARTNER_SUBSCRIPTION_PRICES[3] *
                                                                (1 - promoCodeValue / 100)
                                                            ).toFixed(2)
                                                            : props.values.subscription === "premium-month"
                                                                ? (
                                                                    PARTNER_SUBSCRIPTION_PRICES[4] *
                                                                    (1 - promoCodeValue / 100)
                                                                ).toFixed(2)
                                                                : (
                                                                    PARTNER_SUBSCRIPTION_PRICES[5] *
                                                                    (1 - promoCodeValue / 100)
                                                                ).toFixed(2)}
                                            {t("common.selectedCurrency")}
                                        </Text>
                                    </Box>
                                    <Alert
                                        status="success"
                                        variant="solid"
                                        maxW={MAX_INPUT_WIDTH}
                                        backgroundColor={theme.colors.darkPurple}
                                        color={theme.colors.primaryYellow}
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <AlertIcon />
                                        <Trans i18nKey="auth.promoCodeApplied">
                                            Uno sconto del
                                            <Badge margin={"0 0.5em"} colorScheme="purple">
                                                {{
                                                    PROMO_CODE_VALUE: promoCodeValue,
                                                }}
                                                %
                                            </Badge>
                                            è stato applicato!
                                        </Trans>
                                    </Alert>
                                </Box>
                            )}

                            <Field id={"termsOfUse"} name={"termsOfUse"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl
                                        isInvalid={Boolean(meta.error) && meta.touched}
                                        style={{ marginTop: "4rem" }}
                                    >
                                        <HStack>
                                            <Checkbox
                                                name={field.name}
                                                isChecked={field.value}
                                                onChange={(event: any) => {
                                                    setFieldValue(field.name, event.target.checked);
                                                }}
                                            />
                                            <Text>
                                                {t("auth.termsOfUse1")}
                                                <Link
                                                    href={PARTNER_SIGNUP_GENERAL_POLICY}
                                                    target={"_blank"}
                                                >
                          <span
                              style={{
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                              }}
                          >
                            Condizioni generali
                          </span>
                                                </Link>

                                                {t("auth.termsOfUse2")}
                                                <Link
                                                    href={PARTNER_SIGNUP_PRIVACY_POLICY_URL}
                                                    target={"_blank"}
                                                >
                          <span
                              style={{
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                              }}
                          >
                            Informativa Privacy
                          </span>
                                                </Link>
                                            </Text>
                                        </HStack>
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field id={"clausesConfirmation"} name={"clausesConfirmation"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl
                                        isInvalid={Boolean(meta.error) && meta.touched}
                                        style={{ marginTop: "2rem" }}
                                    >
                                        <HStack>
                                            <Checkbox
                                                name={field.name}
                                                isChecked={field.value}
                                                onChange={(event: any) => {
                                                    setFieldValue(field.name, event.target.checked);
                                                }}
                                            />
                                            <Text>
                                                <Link
                                                    href={PARTNER_SIGNUP_GENERAL_POLICY}
                                                    target={"_blank"}
                                                >
                          <span
                              style={{
                                  textDecoration: "underline",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                              }}
                          >
                            Accetto espressamente le clausole elencate all’art.
                            51 delle Condizioni Generali della piattaforma
                            (obbligatorio)*
                          </span>
                                                </Link>
                                            </Text>
                                        </HStack>
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Field id={"marketing"} name={"marketing"}>
                                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                                    <FormControl
                                        isInvalid={Boolean(meta.error) && meta.touched}
                                        style={{
                                            paddingTop: "1.2rem",
                                        }}
                                    >
                                        <Flex justifyContent={"space-between"}>
                                            <Checkbox
                                                name={field.name}
                                                isChecked={field.value}
                                                onChange={(event) => {
                                                    setFieldValue(field.name, event.target.checked);
                                                }}
                                            >
                                                {t("auth.marketingTerms")}
                                            </Checkbox>
                                        </Flex>
                                        <FormErrorMessage>{meta.error}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>
                            <Text fontSize="md" fontWeight={"bold"} marginTop={"3rem"}>
                                * {t("auth.mandatoryFields")}
                            </Text>
                        </Box>
                        <Button
                            type={"submit"}
                            colorScheme={"blue"}
                            disabled={isSubmitting}
                        >
                            {t("common.confirm")}
                        </Button>
                        {!props.isValid && props.submitCount > 0 && (
                            <Text fontSize="sm" color={theme.colors.danger}>
                                {t("auth.messages.formError")}
                            </Text>
                        )}
                        <Link
                            as={ReachLink}
                            to={{ pathname: "/login", state: { initialUserType: "partner" } }}
                            color="black"
                            paddingTop={"2rem"}
                        >
                            {t("auth.alreadyHaveAnAccount")}
                        </Link>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

interface SubscriptionCardProps {
    backgroundColor?: string;
    borderColor?: string;
    badgeText?: string;
    title: string;
    borderWidth?: string;
    maxWidth?: number;
    buttonSize?: string;
    price1: number;
    price2: number;
    isPrice1Selected: boolean;
    isPrice2Selected: boolean;
    isLoading?: boolean;
    isDesktop: boolean;
    benefits?: string[];
    setIsLoading?: (newValue: boolean) => void;
    onPrice1Select?: (event?: any) => void;
    onPrice2Select?: (event?: any) => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
                                                               backgroundColor = "transparent",
                                                               borderColor = "white",
                                                               badgeText,
                                                               title,
                                                               borderWidth = "1px",
                                                               maxWidth = 98,
                                                               buttonSize = "sm",
                                                               price1,
                                                               price2,
                                                               isPrice1Selected,
                                                               isPrice2Selected,
                                                               isLoading,
                                                               isDesktop,
                                                               benefits,
                                                               setIsLoading,
                                                               onPrice1Select,
                                                               onPrice2Select,
                                                           }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const price1Decimal = price1.toString().split(".")[1];
    const price2Decimal = price2.toString().split(".")[1];

    const onClickButton1 = useCallback(() => {
        if (onPrice1Select && setIsLoading) {
            setIsLoading(true);
            onPrice1Select();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, [onPrice1Select, setIsLoading]);

    const onClickButton2 = useCallback(() => {
        if (onPrice2Select && setIsLoading) {
            setIsLoading(true);
            onPrice2Select();
            setTimeout(() => {
                setIsLoading(false);
            }, 500);
        }
    }, [onPrice2Select, setIsLoading]);

    return (
        <Box
            borderWidth={borderWidth}
            boxShadow="xl"
            boxSize={"sm"}
            borderRadius="lg"
            overflow="hidden"
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            maxW={maxWidth + "%"}
            margin={
                maxWidth !== 100
                    ? `0 ${(100 - maxWidth) / 2}% 2em ${(100 - maxWidth) / 2}%`
                    : "0 0 2em 0"
            }
            height={"100%"}
        >
            {badgeText && (
                <Badge
                    borderRadius="full"
                    px="3"
                    backgroundColor={theme.colors.primaryYellow}
                    color={theme.colors.darkPurple}
                    margin={"1em"}
                    float={"right"}
                >
                    {badgeText}
                </Badge>
            )}
            <Box p="4">
                <Box d="flex" alignItems="baseline">
                    <Box
                        color={theme.colors.textWhite}
                        fontWeight="bold"
                        letterSpacing="wide"
                        fontSize="xl"
                        textTransform="uppercase"
                        ml="2"
                    >
                        {title}
                    </Box>
                </Box>

                {benefits && (
                    <Accordion
                        key={isDesktop.toString()}
                        margin={"1rem 0 1.5em 0"}
                        allowToggle={true}
                        {...(isDesktop && { defaultIndex: [0] })}
                    >
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                        {t("auth.subscriptionBenefits")}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                {benefits.map((benefit) => {
                                    return (
                                        <Flex
                                            key={benefit}
                                            alignItems={"baseline"}
                                            padding={"0.5rem 0.25rem"}
                                        >
                                            <Box
                                                style={{
                                                    marginRight: "0.5rem",
                                                    width: "15px",
                                                    minWidth: "15px",
                                                }}
                                            >
                                                <FaRegCheckCircle />
                                            </Box>
                                            <Box>
                                                <Text>{benefit}</Text>
                                            </Box>
                                        </Flex>
                                    );
                                })}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                )}

                <Stack>
                 {/*   {onPrice1Select && (
                        <Box
                            d="flex"
                            mt="2"
                            alignItems="center"
                            justifyContent={"center"}
                            width={"100%"}
                        >
                            <Button
                                colorScheme={"cyan"}
                                backgroundColor={
                                    isPrice1Selected
                                        ? theme.colors.primaryYellow
                                        : theme.colors.primaryCyan
                                }
                                color={theme.colors.textWhite}
                                size={buttonSize}
                                padding={"1em 2em"}
                                onClick={onClickButton1}
                                disabled={isLoading}
                                height={"100px"}
                                width={"100%"}
                            >
                                <Stack>
                                    <Flex alignItems={"center"}>
                                        <Box flexDirection={"column"}>
                                            <Text as={"span"} fontWeight={"bold"} fontSize={"5xl"}>
                                                {Math.trunc(price1)}
                                            </Text>
                                        </Box>
                                        <Box flexDirection={"column"}>
                                            {price1Decimal && (
                                                <Text fontSize="xl">,{price1Decimal}</Text>
                                            )}
                                            <Box as="p" fontSize="sm">
                                                {t("common.selectedCurrency")} / {t("partner.month")} +
                                                iva
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <p>{t("partner.selectSubscription")}</p>
                                </Stack>
                            </Button>
                        </Box>
                    )}*/}

                    {onPrice2Select && (
                        <Box
                            d="flex"
                            mt="2"
                            alignItems="center"
                            justifyContent={"center"}
                            width={"100%"}
                        >
                            <Button
                                colorScheme={"cyan"}
                                backgroundColor={
                                    isPrice2Selected
                                        ? theme.colors.primaryYellow
                                        : theme.colors.primaryCyan
                                }
                                color={theme.colors.textWhite}
                                size={buttonSize}
                                padding={"1em 2em"}
                                onClick={onClickButton2}
                                disabled={isLoading}
                                height={"100px"}
                                width={"100%"}
                            >
                                <Stack>
                                    <Flex alignItems={"center"}>
                                        <Box flexDirection={"column"}>
                                            <Text as={"span"} fontWeight={"bold"} fontSize={"5xl"}>
                                                {Math.trunc(price2)}
                                            </Text>
                                        </Box>
                                        <Box flexDirection={"column"}>
                                            {price2Decimal && (
                                                <Text fontSize="xl">,{price2Decimal}</Text>
                                            )}
                                            <Box as="p" fontSize="sm">
                                                {t("common.selectedCurrency")} / anno + iva
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <p>{t("partner.selectSubscription")}</p>
                                </Stack>
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Box>
        </Box>
    );
};