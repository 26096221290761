import { gql } from "@apollo/client";
import { storeFields } from "../../services/graphql";

export const UPDATE_OPENING_TIME_STORE = gql`
  mutation updateOpeningTimeStore($id: Int!, $store_opening_days: String!) {
    updateOpeningTime(id: $id, store_opening_days: $store_opening_days) {
      store_opening_days
    }
  }
`;

export const UPDATE_STORE_AND_BRANDS_SERVICES = gql`
  mutation updateStoreAndBrandsServices(
    $id: Int!
    $services_ids: String!
    $brands_ids: String!
    $brands_ids_to_delete: String!
    $other_service_one: String!
    $other_service_two: String!
  ) {
    updateStoreAndBrandsServices(
      id: $id
      services_ids: $services_ids
      brands_ids: $brands_ids
      brands_ids_to_delete: $brands_ids_to_delete
      other_service_one: $other_service_one
      other_service_two: $other_service_two
    ) {
      services {
        id
        service {
          id
          name
        }
      }
      brands {
        id
        brand {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_STORE_IMAGE = gql`
  mutation deleteStoreImage($id: Int!) {
    deleteStoreImage(id: $id) {
      id
    }
  }
`;

export const UPDATE_STORE_PHOTOS = gql`
  mutation updateStorePhotos($id: Int!, $store_photos: String!) {
    updateStorePhotos(id: $id, store_photos: $store_photos) {
      store_photos
    }
  }
`;

export const UPDATE_STORE = gql`
mutation updateStore(
  $id: Int!
  $store_image: String!
  $business_name: String! 
  $address_business_id: Int!
  $address_business: String!
  $address_business_city_id: Int!
  $address_business_city: String!
  $address_business_district: String!
  $address_business_cap: String!
  $address_store_id: Int!
  $address_store: String!
  $address_store_city_id: Int!
  $address_store_city: String!
  $address_store_district: String!
  $address_store_cap: String!
  $banner_name: String! 
  $mobile_phone_b2b: String! 
  $phone_b2b: String!
  $vat_number: String! 
  $tax_code: String! 
  $pec: String! 
  $sdi: String! 
) {
  updateStore(
    id: $id 
    store_image: $store_image
    business_name: $business_name 
    address_store: $address_store
    address_store_city: $address_store_city 
    banner_name: $banner_name 
    mobile_phone_b2b: $mobile_phone_b2b 
    phone_b2b: $phone_b2b
    vat_number: $vat_number 
    tax_code: $tax_code 
    pec: $pec 
    sdi: $sdi 
  ) {
    ${storeFields}
  }

  updateOneAddress(
    where: { id: $address_business_id }
    data: {
      address: { set: $address_business }
    }
  ) {
    id
  }

  updateOneCity(
    where: { id: $address_business_city_id }
    data: {
      city: { set: $address_business_city }
      district: { set: $address_business_district }
      cap: { set: $address_business_cap }
    }
  ) {
    id
  }

  address_store: updateOneAddress(
    where: { id: $address_store_id }
    data: {
      address: { set: $address_store }
    }
  ) {
      id
  }

  address_store_city: updateOneCity(
    where: { id: $address_store_city_id }
    data: {
      city: { set: $address_store_city }
      district: { set: $address_store_district }
      cap: { set: $address_store_cap }
    }
  ) {
       id
  }
}
`;

export const UPDATE_STORE2_ADDRESS = gql`
  mutation updateStore2Address(
    $address_store2_id: Int!
    $address_store2: String
    $address_store2_city_id: Int!
    $address_store2_city: String
    $address_store2_district: String
    $address_store2_cap: String
  ) {
    updateOneAddress(
      where: { id: $address_store2_id }
      data: { address: { set: $address_store2 } }
    ) {
      id
    }

    updateOneCity(
      where: { id: $address_store2_city_id }
      data: {
        city: { set: $address_store2_city }
        district: { set: $address_store2_district }
        cap: { set: $address_store2_cap }
      }
    ) {
      id
    }
  }
`;

export const CREATE_STORE2_ADDRESS = gql`
  mutation createStore2Address(
    $id: Int!
    $address_store2: String!
    $address_store2_city: String!
    $address_store2_district: String!
    $address_store2_cap: String!
  ) {
    createStore2Address(
      id: $id
      address_store2: $address_store2
      address_store2_city: $address_store2_city
      address_store2_district: $address_store2_district
      address_store2_cap: $address_store2_cap
    ) {
      id
    }
  }
`;
