import { FormControl, Select, FormLabel } from "@chakra-ui/react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { DogSize } from "../../entities/PromoBreed";

export const ProductSizeSelect = () => {
  const { t } = useTranslation();

  return (
    <Field name={"size"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={meta.description && meta.touched} as="fieldset">
          <FormLabel>{"Taglia"}</FormLabel>
          <Select
            name={field.name}
            value={field.value}
            onChange={(event) => {
              setFieldValue(field.name, event.target.value);
            }}
          >
            <option value={DogSize.All}>{t("promoForm.all")}</option>
            <option value={DogSize.Mini}>{"Mini (1-10kg)"}</option>
            <option value={DogSize.Medium}>{"Medium (11-30kg)"}</option>
            <option value={DogSize.Maxi}>{"Maxi (da 31kg)"}</option>
          </Select>
        </FormControl>
      )}
    </Field>
  );
};
