import { DefaultTheme } from "styled-components";

const mainTheme: DefaultTheme = {
  name: "default",
  colors: {
    primaryGreen: "#00b485",
    primaryYellow: "#ffcb3a",
    primaryCyan: "#37c0ca",

    secondaryCyan: "#BCE0FD",

    lightPurple: "#cfbbe6",
    darkPurple: "#7D59A5",

    background: "#FFFFFF",
    backgroundDark: "#000000",

    textPrimary: "#212221",
    textWhite: "#FFFFFF",

    danger: "#e53e3e",
  },
};

export { mainTheme };
