import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  HStack,
  Spacer,
  Textarea,
} from "@chakra-ui/react";
import { StoreReview } from "../../entities/StoreReview";
import { ThirdLooversFontSize } from "../LooversText/LooversText";
import { mainTheme } from "../../config/theme";
import ReactStars from "react-rating-stars-component";
import { FaStar } from "react-icons/fa";

const STAR_SIZE = 35;

export type AddReviewFormType = {
  id: number | undefined;
  title: string;
  description: string;
  stars: number;
  date: Date;
};

type Props = {
  onSubmit: (values: AddReviewFormType) => void;
  onDelete: (values: AddReviewFormType) => void;
  review?: StoreReview;
};

export const AddReviewForm: React.FC<Props> = ({
  onSubmit,
  review,
  onDelete,
}) => {
  const { t } = useTranslation();

  const today = new Date();

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("auth.validations.titleRequired"))
      .min(5, t("auth.validations.titleRequired")),
    description: Yup.string()
      .required(t("auth.validations.descriptionRequired"))
      .min(10, t("auth.validations.descriptionRequired")),
    stars: Yup.number()
      .min(1, t("auth.validations.reviewStarsRequired"))
      .required(t("auth.validations.reviewStarsRequired")),
  });

  return (
    <Formik
      initialValues={{
        id: review ? review.id : undefined,
        title: review ? review.title : "",
        description: review ? review.description : "",
        stars: review ? review.stars : 0,
        date: review ? review.date : today,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Stack spacing={"0.5rem"}>
            <Field name={"title"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel fontSize={ThirdLooversFontSize} htmlFor={"title"}>
                    {t("review.title")} *
                  </FormLabel>
                  <Input
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    fontSize={ThirdLooversFontSize}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={"description"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel
                    fontSize={ThirdLooversFontSize}
                    htmlFor={"description"}
                  >
                    {t("review.description")} *
                  </FormLabel>
                  <Textarea
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    fontSize={ThirdLooversFontSize}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"stars"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <ReactStars
                    count={5}
                    value={field.value}
                    onChange={(newValue: number) => {
                      setFieldValue(field.name, newValue);
                    }}
                    size={STAR_SIZE}
                    isHalf={false}
                    emptyIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    // halfIcon={<FaStarHalfAlt color={"grey"} size={STAR_SIZE} />}
                    fullIcon={<FaStar color={"grey"} size={STAR_SIZE} />}
                    activeColor="#ffd700"
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"date"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel fontSize={ThirdLooversFontSize} htmlFor={"date"}>
                    {t("review.date")}
                    {review ? review.date : today.toLocaleDateString()}
                  </FormLabel>

                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <HStack>
              {review && review?.id > 0 && (
                <Button
                  colorScheme="red"
                  danger
                  disabled={props.isSubmitting}
                  onClick={() => onDelete(props.values)}
                >
                  {t("common.delete")}
                </Button>
              )}

              <Spacer />

              <Button
                type={"submit"}
                colorScheme={"teal"}
                variant={"solid"}
                disabled={props.isSubmitting}
              >
                {t("common.confirm")}
              </Button>
            </HStack>
            {!props.isValid && props.submitCount > 0 && (
              <Text fontSize="sm" color={mainTheme.colors.danger}>
                {t("auth.messages.formError")}
              </Text>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
