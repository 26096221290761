import { Customer } from "./Customer";
import { Product } from "./Product";

export class ProductReview {
  private _id: number | undefined = undefined;
  private _title: string = "";
  private _comment: string = "";
  private _image: string = "";
  private _totalRating: number = 0;
  private _packagingRating: number = 0;
  private _lookAndFeelRating: number = 0;
  private _petOpinionRating: number = 0;
  private _productFeaturesRating: number = 0;
  private _avgRating: number = 0;
  private _date: Date = new Date();
  private _customer: Customer = new Customer(-1, "", 0);
  private _product: Product | undefined = undefined;

  public get id(): number | undefined {
    return this._id;
  }

  public get customer() {
    return this._customer;
  }

  public get product() {
    return this._product;
  }

  public get image() {
    return this._image;
  }

  public get title(): string {
    return this._title;
  }

  public set title(title: string) {
    this._title = title;
  }

  public get comment(): string {
    return this._comment;
  }

  public set comment(comment: string) {
    this._comment = comment;
  }

  public get totalRating(): number {
    return this._totalRating;
  }

  public set totalRating(stars: number) {
    this._totalRating = stars;
  }

  public get packagingRating(): number {
    return this._packagingRating;
  }

  public set packagingRating(stars: number) {
    this._packagingRating = stars;
  }

  public get lookAndFeelRating(): number {
    return this._lookAndFeelRating;
  }

  public set lookAndFeelRating(stars: number) {
    this._lookAndFeelRating = stars;
  }

  public get petOpinionRating(): number {
    return this._petOpinionRating;
  }

  public set petOpinionRating(stars: number) {
    this._petOpinionRating = stars;
  }

  public get productFeaturesRating(): number {
    return this._productFeaturesRating;
  }

  public set productFeaturesRating(stars: number) {
    this._productFeaturesRating = stars;
  }

  public get avgRating(): number {
    return this._avgRating;
  }

  public set avgRating(stars: number) {
    this._avgRating = stars;
  }

  public get date(): Date {
    return this._date;
  }

  public set date(date: Date) {
    this._date = date;
  }
}
