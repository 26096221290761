import { useLazyQuery } from "@apollo/client";
import { FormLabel, Spinner, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Store_Brand } from "../../../entities/Store_Brand";
import { FETCH_BRANDS } from "../../../screens/PartnerSignUp/CompleteSignUpPartner/graphql";
import { MultiSelect } from "../../PartnerSignUpForm/CompleteSignUpPartner/MultiSelect";

type Props = {
  storeBrands: Store_Brand[];
  setBrandsIds: React.Dispatch<React.SetStateAction<string[]>>;
};

export const BrandsForm = ({ storeBrands, setBrandsIds }: Props) => {
  const { t } = useTranslation();

  const storeBrandsData = storeBrands.map((sb: Store_Brand) => {
    return {
      value: sb.brand.id.toString(),
      label: sb.brand.name,
    };
  });
  const [selectValue, setSelectValue] =
    useState<{ value: string; label: string }[]>(storeBrandsData);
  const [brandsData, setBrandsData] = useState<
    { value: string; label: string }[]
  >([]);

  const [fetchBrands, { data, loading: isFetching }] = useLazyQuery(
    FETCH_BRANDS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const brands: { value: string; label: string }[] = data.brands.map(
          (item: any) => {
            return {
              value: item.id.toString(),
              label: item.name,
            };
          }
        );

        setBrandsData(brands);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  const handleOnChange = (values: any) => {
    setSelectValue(values);

    let ids = values.map(
      (item: { value: string; label: string }) => item.value
    );
    setBrandsIds(ids);
  };

  return (
    <VStack w="100%" spacing={"0px"}>
      <FormLabel>{t("partner.brands")}</FormLabel>
      {isFetching && <Spinner />}
      <MultiSelect
        value={selectValue}
        options={brandsData}
        onChange={handleOnChange}
      />
    </VStack>
  );
};
