import { FormLabel as CFormLabel } from "@chakra-ui/form-control";
import styled, { css } from "styled-components";

export const FormLabel = styled(CFormLabel)<{ signUpForm?: boolean }>`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  font-family: Proxima Nova, Helvetica, Arial, sans-serif;
  line-height: 1rem;

  ${(props) => {
    return props.$signUpForm
      ? css`
          color: ${(props) => props.theme.colors.textWhite};
        `
      : css`
          color: ${(props) => props.theme.colors.textPrimary};
        `;
  }};
`;
