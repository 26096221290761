import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { PartnerSignUpForm } from "../../../components/PartnerSignUpForm/PartnerSignUpForm1";

import BckgHeroMobile from "../../../assets/images/partner_signup_background_mobile.jpg";
import BckgHero from "../../../assets/images/partner_signup_background.jpg";
import { Header } from "../../../components/Header";
import { useMutation } from "@apollo/client";
import { SIGNUP_PARTNER } from "../../../services/graphql";
import { Alert, AlertIcon, Flex, Spinner, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@chakra-ui/media-query";
import { mainTheme } from "../../../config/theme";
import { useStores } from "../../../hooks/UseStores";

export const SignUpPartnerScreen: React.FC = () => {
  const { t } = useTranslation();
  const [showDoneMessage, setShowDoneMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { session } = useStores();

  const handleSignUpCompleted = useCallback(
    (data) => {
      setShowDoneMessage(true);
      setTimeout(() => {
        session.login(
          data.signupStore.store,
          data.signupStore.token,
          "partner"
        );
      }, 700);
    },
    [session]
  );

  const handleSignUpError = useCallback(() => {
    setShowErrorMessage(true);
  }, []);

  const [signUpPartner, { loading: mutationSubmitting }] = useMutation(
    SIGNUP_PARTNER,
    {
      onCompleted: handleSignUpCompleted,
      onError: (error) => {
        console.log(error);
        handleSignUpError();
      },
    }
  );

  const handleSubmit = useCallback(

  (values) => {
      const {
        businessName,
        storeType,
        addressBusiness,
        addressBusinessCity,
        addressBusinessDistrict,
        addressBusinessCap,
        bannerName,
        emailB2B,
        mobilePhoneB2B,
        phoneB2B,
        vatNumber,
        taxCode,
        pec,
        sdi,
        password,
        promoCode,
        subscription,
        termsOfUse,
        marketing,
        couponId,
        clausesConfirmation,
      } = values;
    const isPaymentRegistrationComplete = subscription.includes('superlight');

      setShowDoneMessage(false);
      setShowErrorMessage(false);

      signUpPartner({
        variables: {
          business_name: businessName,
          store_type: storeType,
          address_business: addressBusiness,
          address_business_city: addressBusinessCity,
          address_business_district: addressBusinessDistrict.value,
          address_business_cap: addressBusinessCap,
          banner_name: bannerName,
          email_b2b: emailB2B,
          vat_number: vatNumber,
          tax_code: taxCode,
          pec,
          sdi,
          password,
          mobile_phone_b2b: mobilePhoneB2B,
          phone_b2b: phoneB2B,
          promo_code: promoCode,
          subscription: subscription,
          supply_contract_confirmation: termsOfUse,
          privacy_contract_confirmation: termsOfUse,
          clauses_confirmation: clausesConfirmation,
          marketing_confirmation: marketing,
          coupon_id: couponId,
          is_payment_registration_complete: isPaymentRegistrationComplete,

        },
      });
    },
    [signUpPartner]
  );

  return (
    <>
      <HeroContainer>
        <Header dark={!isLargerThan768} />
        <HeroOverlay />
        <HeroSection>
          <HeroContent>
            <HeroH1>{t("partner.signUpMessage1")}</HeroH1>
            <HeroH2>{t("partner.signUpMessage2")}</HeroH2>
          </HeroContent>
        </HeroSection>
      </HeroContainer>
      <Container>
        <FormContainer>
          <PartnerSignUpForm
            onSubmit={handleSubmit}
            isSubmitting={mutationSubmitting}
          />
          {showErrorMessage && (
            <Alert
              status={"error"}
              color={mainTheme.colors.danger}
              borderRadius={"5px"}
              margin={"2rem 0"}
            >
              <AlertIcon />
              {t("auth.messages.storeSignupError")}
            </Alert>
          )}
          {showDoneMessage && (
            <Flex direction={"column"} alignItems={"center"} m={6} gridGap={3}>
              <Alert
                status={"success"}
                color={mainTheme.colors.primaryGreen}
                backgroundColor={mainTheme.colors.background}
                borderRadius={"5px"}
              >
                <AlertIcon />
                {t("auth.messages.storeSignupCompleted")}
              </Alert>
              <Flex gridGap={5}>
                <Text>{t("common.redirect")}</Text>
                <Spinner
                  thickness="3px"
                  speed="0.65s"
                  emptyColor={mainTheme.colors.lightPurple}
                  color={mainTheme.colors.darkPurple}
                  size="md"
                />
              </Flex>
            </Flex>
          )}
        </FormContainer>
      </Container>
    </>
  );
};

const HeroContainer = styled.div`
  background: url("${BckgHeroMobile}") no-repeat center center;
  background-size: cover;
  position: relative;
  min-height: 350px;
  padding: 1em;

  @media (min-width: 767px) {
    background: url("${BckgHero}") no-repeat center center;
    background-size: cover;
  }
`;

const HeroSection = styled.section`
  padding: 200px 120px 220px 120px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 10px;
  }
`;

const HeroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.backgroundDark};
  opacity: 0.35;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  max-width: 1170px;
  z-index: 2;
`;

const HeroH1 = styled.h1`
  color: ${(props) => props.theme.colors.darkPurple};
  font-size: 2.5rem;
  line-height: 1em;
  font-weight: bold;
  font-family: "Noe Display", Sans-serif;
  white-space: pre-line;

  @media (min-width: 767px) {
    font-size: 3.5rem;
    color: ${(props) => props.theme.colors.primaryYellow};
  }
`;

const HeroH2 = styled.h2`
  color: ${(props) => props.theme.colors.primaryYellow};
  font-size: 1rem;
  font-weight: bold;
  font-family: "Proxima Nova", Sans-serif;

  @media (min-width: 767px) {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.textWhite};
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primaryGreen};
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textWhite};
  padding: 3rem 1.5rem;
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;
