import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { Header } from "../../../components/Header";
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { mainTheme } from "../../../config/theme";
import { useStores } from "../../../hooks/UseStores";
import { getUrlParams } from "../../../utils/utils";
import { observer } from "mobx-react";

export const PaymentSignUpPartnerScreen: React.FC = observer(() => {
  const { session, stripe } = useStores();
  const { t } = useTranslation();

  const [showDoneMessage, setShowDoneMessage] = useState(false);

  const checkStripeNoSubscription = useCallback(async () => {
    const params = getUrlParams(window.location.hash);
    if (params.cancel) {
      stripe.stripeCheckoutError = true;
      if (!session.isLogged()) {
        window.location.reload();
      }
    } else {
      stripe.stripeCheckoutError = false;
    }
  }, [session, stripe]);

  const checkStripeSingUp = useCallback(async () => {
    const params = getUrlParams(window.location.hash);
    const sessionId = params["session_id"];
    if (sessionId) {
      const stripeSessionOk = await stripe.stripeSessionCheckout(sessionId);

      if (stripeSessionOk) {
        setShowDoneMessage(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  }, [stripe]);

  checkStripeNoSubscription();
  checkStripeSingUp();

  const handleCheckout = useCallback(async () => {
    try {
      await stripe.stripeCheckout(session.partnerUser?.couponId!);
    } catch (err) {
      console.log(err);
    }
  }, [session.partnerUser?.couponId, stripe]);

  return (
    <Flex flexDirection={"column"} height={"100vh"}>
      <Header dark />

      <Flex
        flexDirection={"column"}
        p={"5rem 10px"}
        backgroundColor={mainTheme.colors.primaryGreen}
      >
        <HeroH1>{t("partner.dashboard.welcome") + ", "}</HeroH1>
        <HeroH3>{session.partnerUser?.businessName}</HeroH3>
        <HeroH2>{t("partner.dashboard.completeSignup.title")}</HeroH2>
      </Flex>
      <Container>
        {stripe.stripeCheckoutError && (
          <Alert
            status={"error"}
            color={mainTheme.colors.danger}
            borderRadius={"5px"}
            margin={"2rem 0"}
          >
            <AlertIcon />
            {t("auth.messages.dataUpdateError")}
          </Alert>
        )}

        {showDoneMessage && (
          <Flex direction={"column"} alignItems={"center"} m={6} gridGap={3}>
            <Alert
              status={"success"}
              color={mainTheme.colors.primaryGreen}
              backgroundColor={mainTheme.colors.background}
              borderRadius={"5px"}
            >
              <AlertIcon />
              {t("auth.messages.storeSignupPart2Completed")}
            </Alert>
            <Flex gridGap={5}>
              <Text>{t("common.redirect")}</Text>
              <Spinner
                thickness="3px"
                speed="0.65s"
                emptyColor={mainTheme.colors.lightPurple}
                color={mainTheme.colors.darkPurple}
                size="md"
              />
            </Flex>
          </Flex>
        )}
        <Button
          onClick={handleCheckout}
          colorScheme={"blue"}
          disabled={stripe.isSubmitting}
        >
          {t("auth.addPayment")}
        </Button>
      </Container>
    </Flex>
  );
});

const HeroH1 = styled.h1`
  color: ${(props) => props.theme.colors.textWhite};
  font-size: 2.5rem;
  line-height: 1em;
  font-weight: bold;
  font-family: Noe Display, Helvetica, Arial, sans-serif;
  white-space: pre-line;
  margin-bottom: 1.5rem;

  @media (min-width: 767px) {
    font-size: 3.5rem;
  }
`;

const HeroH3 = styled.h1`
  color: ${(props) => props.theme.colors.textWhite};
  font-size: 2.5rem;
  line-height: 1em;
  font-family: Proxima Nova, Helvetica, Arial, sans-serif;
  white-space: pre-line;
  margin-bottom: 1.5rem;

  @media (min-width: 767px) {
    font-size: 3.5rem;
  }
`;

const HeroH2 = styled.h2`
  color: ${(props) => props.theme.colors.textWhite};
  font-size: 1rem;
  font-family: "Proxima Nova", Sans-serif;

  @media (min-width: 767px) {
    font-size: 1.5rem;
  }
`;

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primaryGreen};
  display: flex;
  flex-direction: column;
  justfy-content: center;
  align-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textWhite};
  padding: 3rem 1.5rem;
  flex: 1;
`;
