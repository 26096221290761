import React, { useState } from "react";
import {
  Text,
  useMediaQuery,
  HStack,
  Avatar,
  AvatarBadge,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { mainTheme } from "../../config/theme";
import { CustomerUser } from "../../entities/CustomerUser";
import { FileUploadModal } from "../FileUploadModal";
import { useStores } from "../../hooks/UseStores";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_PROFILE_IMAGE } from "../../services/graphql";

interface UsernameImageProfileProps {
  editIcon?: boolean;
  customerUser?: CustomerUser;
}

export const UsernameImageProfile: React.FC<UsernameImageProfileProps> = ({
  editIcon = true,
  customerUser,
}) => {
  const { session } = useStores();
  const { t } = useTranslation();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const avatarSize = isLargerThan768 ? "xl" : "md";
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [profileImageUrl, setProfileImageUrl] = useState(
    customerUser?.profile_image
  );

  const [updateProfileImageMutation] = useMutation(
    UPDATE_CUSTOMER_PROFILE_IMAGE,
    {
      onCompleted: (data) => {
        setProfileImageUrl(data.updateCustomerProfileImage.profile_image);
        toast({
          status: "success",
          title: t("customer.profileImageSuccess"),
        });
      },
      onError: (error) => {
        console.log(error);
        toast({
          status: "error",
          title: t("customer.profileImageError"),
        });
      },
    }
  );

  const updateProfileImage = (fileUrl: string) => {
    if (session.customerUser) {
      updateProfileImageMutation({
        variables: {
          id: session.customerUser.id,
          profile_image: fileUrl,
        },
      });
    }
  };

  return (
    <HStack
      alignItems={"center"}
      justifyContent={isLargerThan768 ? "center" : "space-between"}
    >
      <Text fontSize={["md", "xl"]}>
        {customerUser?.firstName} {customerUser?.surname}
      </Text>
      <FileUploadModal
        isOpen={isOpen}
        onClose={onClose}
        maxFiles={1}
        accept={"image/jpeg"}
        onFileUploaded={(fileUrls: string[]) => {
          if (fileUrls.length === 1) {
            updateProfileImage(fileUrls[0]);
          }
        }}
      />
      <Avatar
        src={profileImageUrl}
        alt="profile image"
        cursor={"pointer"}
        onClick={onOpen}
      >
        {editIcon && (
          <AvatarBadge
            borderColor={mainTheme.colors.primaryGreen}
            bgColor={mainTheme.colors.primaryGreen}
            size={avatarSize}
          >
            <EditIcon />
          </AvatarBadge>
        )}
      </Avatar>
    </HStack>
  );
};
