import { Box, Text } from "@chakra-ui/react";
import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Stack } from "@chakra-ui/layout";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormLabel } from "../FormLabel";
import * as Yup from "yup";
import { Link } from "@chakra-ui/react";
import { UserType } from "../../types";

type LoginFormType = {
  email: string;
  password: string;
};

type Props = {
  isSubmitting: boolean;
  userType: UserType;
  onSubmit: (values: LoginFormType) => void;
};

export const LoginForm: React.FC<Props> = ({
  isSubmitting,
  userType,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("auth.validations.emailRequired"))
      .min(2, t("auth.validations.emailRequired"))
      .email(t("auth.validations.emailInvalid")),
    password: Yup.string().required(t("auth.validations.passwordRequired")),
  });

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Stack spacing={"0.5rem"}>
            <Field name={"email"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"email"}>{t("customer.email")}</FormLabel>
                  <Input
                    type={"email"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    autoComplete={"email"}
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={"password"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <FormLabel htmlFor={"password"}>
                    {t("auth.password")}
                  </FormLabel>
                  <Input
                    type={"password"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                    autoComplete={"current-password"}
                  />

                  <Box height={"0.4rem"}></Box>

                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button
              type={"submit"}
              colorScheme={"purple"}
              disabled={isSubmitting}
            >
              {t("auth.login")}
            </Button>

            <Text style={{ marginTop: "2rem" }}>
              {t("auth.forgotPassword")}{" "}
              <Link color="teal.500" href={"#/lost-password"}>
                {t("auth.forgotPasswordSendEmail")}
              </Link>
            </Text>

            {userType !== "admin" && (
              <Text style={{ marginTop: "1rem" }}>
                {t("auth.notYetSignedUp")}{" "}
                <Link
                  color="teal.500"
                  href={
                    userType === "partner"
                      ? "#/signup/partner"
                      : "#/signup/customer"
                  }
                >
                  {t("auth.clickHereToSignUp")}
                </Link>
              </Text>
            )}

            {/* <Link
              as={ReachLink}
              to={
                userType === "partner" ? "/signup/partner" : "/signup/customer"
              }
              color="teal"
              paddingTop={"2rem"}
            >
              {t("auth.clickHereToSignUp")}
            </Link> */}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
