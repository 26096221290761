import {
  Flex,
  Text,
  Box,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalBody,
  useMediaQuery,
  Center,
  VStack,
  Button,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import { useCallback } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { mainTheme } from "../../config/theme";
import { useStores } from "../../hooks/UseStores";
import { Session } from "../../store/Session";
var QRCode = require("qrcode.react");

/**
 * @returns Desktop/Mobile component containing loyalty card QR code and loyalty card points
 */
export const LoyaltyCardQRCode: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const { session } = useStores();

  return isLargerThan768
    ? DesktopLoyaltyCardQRCode(t, session)
    : MobileLoyaltyCardQRCode(t, session);
};

const MobileLoyaltyCardQRCode = (
  t: TFunction<"translation">,
  session: Session
) => {
  return (
    <Flex
      flexDirection={"column"}
      backgroundColor={mainTheme.colors.primaryGreen}
      justifyContent="space-between"
      alignItems="center"
      paddingTop="1rem"
      paddingBottom="1rem"
      maxW="100%"
    >
      <Text fontSize={["md"]} marginBottom="1rem">
        {t("dashboard.loyaltyCard")}
      </Text>

      <QRCodeModal
        customerID={session.customerUser!.id}
        customerFirstName={session.customerUser!.firstName}
        customerSurname={session.customerUser!.surname}
        isMobile
      />

      <Box paddingLeft="2rem" paddingRight="2rem">
        <Text
          fontSize={["md"]}
          color={mainTheme.colors.textWhite}
          whiteSpace={"pre-line"}
        >
          {`${t("dashboard.points1")} ${
            session.customerUser!.loyality_points
          } ${t("dashboard.points2")}`}
        </Text>
      </Box>
    </Flex>
  );
};

const DesktopLoyaltyCardQRCode = (
  t: TFunction<"translation">,
  session: Session
) => {
  return (
    <Flex flexDirection="row" w="100%" bgColor={mainTheme.colors.primaryGreen}>
      <Flex padding="4rem" flex="1" align="center">
        <Text
          fontSize={["md"]}
          marginBottom="1rem"
          color={mainTheme.colors.textWhite}
        >
          {t("dashboard.loyaltyCardDescription")}
        </Text>
      </Flex>

      <Flex
        cursor="display"
        padding="4rem"
        flexDirection={"column"}
        alignItems="center"
        flex="1"
      >
        <QRCodeModal
          customerID={session.customerUser!.id}
          customerFirstName={session.customerUser!.firstName}
          customerSurname={session.customerUser!.surname}
        />

        <Text
          fontSize={["md"]}
          color={mainTheme.colors.textWhite}
          whiteSpace={"pre-line"}
        >
          {`${t("dashboard.points1")} ${
            session.customerUser!.loyality_points
          } ${t("dashboard.points2")}`}
        </Text>
      </Flex>
    </Flex>
  );
};

const QRCodeModal: React.FC<{
  customerID: number;
  customerFirstName: string;
  customerSurname: string;
  isMobile?: boolean;
}> = ({ customerID, customerFirstName, customerSurname, isMobile = false }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  const downloadQR = useCallback(() => {
    const canvas = document.getElementById(
      customerID.toString()
    ) as HTMLCanvasElement;
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode-" + customerID + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }, [customerID]);

  return (
    <>
      <Center marginBottom={"2rem"}>
        <VStack justify="center" onClick={onOpen} cursor="pointer">
          <Stack
            backgroundColor={mainTheme.colors.textWhite}
            paddingX={isMobile ? "3rem" : "6rem"}
            paddingY={isMobile ? "1rem" : "2rem"}
            borderRadius={"2rem"}
          >
            <QRCode id={customerID.toString()} value={customerID.toString()} />
            <Text
              marginTop={"1rem"}
            >{`${customerFirstName} ${customerSurname}`}</Text>
          </Stack>
        </VStack>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW={isMobile ? "80%" : "35%"}>
          <ModalHeader fontSize={["md", "xl"]}>Fidelity card</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex flexDirection="column" alignItems="center" w="100%">
              <QRCode
                id={customerID.toString()}
                value={customerID.toString()}
              />
              <Button colorScheme="teal" variant="ghost" onClick={downloadQR}>
                {t("promoForm.downloadQRCode")}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
