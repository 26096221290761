import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Field } from "formik";

export const ProductEanCode = () => {
  return (
    <Field id={"ean_code"} name={"ean_code"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl isInvalid={meta.error && meta.touched}>
          <FormLabel htmlFor="ean_code">{"Codice EAN"}</FormLabel>
          <Input
            name={field.name}
            value={field.value}
            onChange={(event) => setFieldValue(field.name, event.target.value)}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
