import { gql } from "@apollo/client";

export const FETCH_ADMIN_LOYALITY = gql`
  query FetchLoyalityActions {
    loyalityActions {
      id
      action
      name
      points
    }
  }
`;

export const UPDATE_ADMIN_LOYALITY = gql`
  mutation UpdateLoyalityAction($id: Int!, $points: Int!) {
    updateOneLoyality_Action(
      where: { id: $id }
      data: { points: { set: $points } }
    ) {
      id
      action
      name
      points
    }
  }
`;

export const FETCH_CAROUSELS = gql`
  query FetchCarousels {
    carousels {
      id
      image
      link
    }
  }
`;

export const CREATE_CAROUSEL = gql`
  mutation CreateCarousel($image: String!, $link: String!) {
    createOneCarousel(data: { image: $image, link: $link }) {
      id
      image
      link
    }
  }
`;

export const UPDATE_CAROUSEL = gql`
  mutation UpdateCarousel($id: Int!, $image: String!, $link: String!) {
    updateOneCarousel(
      where: { id: $id }
      data: { image: { set: $image }, link: { set: $link } }
    ) {
      id
      image
      link
    }
  }
`;

export const DELETE_CAROUSEL = gql`
  mutation DeleteCarousel($id: Int!) {
    deleteOneCarousel(where: { id: $id }) {
      id
    }
  }
`;
