import { HStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  onDelete?: (data?: any) => any;
  onCancel: () => any;
  isOnConfirmDisabled?: boolean;
};

export const ModalFooterForm: React.FC<Props> = ({
  onDelete,
  onCancel,
  isOnConfirmDisabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      {
        <div style={{ flexGrow: 1 }}>
          {onDelete && (
            <Button colorScheme="red" danger onClick={onDelete}>
              {t("common.delete")}
            </Button>
          )}
        </div>
      }
      <HStack>
        <Button colorScheme={"teal"} onClick={onCancel} variant={"ghost"}>
          {t("common.cancel")}
        </Button>

        <Button
          isDisabled={isOnConfirmDisabled}
          colorScheme={"teal"}
          variant={"solid"}
          type={"submit"}
        >
          {t("common.confirm")}
        </Button>
      </HStack>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 2em;
  margin-bottom: 0.5rem;
`;
