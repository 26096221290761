import styled from "styled-components";

export const StyledHeader = styled.nav<{ textAlign: string }>`
  padding: 10px 20px;
  height: 84px;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.textAlign};
`;

export const HeaderLogo = styled.img`
  width: 170px;
  height: auto;
`;
