import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Animal } from "../../../models/Animal";
import { AnimalForm, AnimalFormType } from "../AddAnimal/AnimalForm";

interface ModalProps {
  animal: Animal | undefined;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (data: AnimalFormType) => void;
  handleDelete: (data: AnimalFormType) => void;
}

export const AnimalModal = ({
  animal,
  isOpen,
  onClose,
  handleSubmit,
  handleDelete,
}: ModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Scheda Animale</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <AnimalForm
            animal={animal}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
