import { gql } from "@apollo/client";

// SERVICES == Vet, Grooming, BreederTrainer
export const FETCH_SERVICES = gql`
  query FetchServices {
    services {
      name
      id
    }
  }
`;

export const FETCH_BRANDS = gql`
  query FetchBrands {
    brands {
      name
      id
    }
  }
`;

export const COMPLETE_STORE_SIGNUP = gql`
  mutation completeSignupStore(
    $id: Int!
    $first_name: String!
    $address_store: String!
    $address_store_city: String!
    $address_store_cap: String!
    $address_store_district: String!
    $address_store2: String
    $address_store2_city: String
    $address_store2_district: String!
    $address_store2_cap: String
    $surname: String!
    $store_opening_days: String!
    $services: String!
    $other_service_one: String!
    $other_service_two: String!
    $brands: String!
    $store_image: String!
    
  ) {
    completeSignupStore(
      id: $id
      first_name: $first_name
      address_store: $address_store
      address_store_city: $address_store_city
      address_store_district: $address_store_district
      address_store_cap: $address_store_cap
      address_store2: $address_store2
      address_store2_city: $address_store2_city
      address_store2_district: $address_store2_district
      address_store2_cap: $address_store2_cap
      surname: $surname
      store_opening_days: $store_opening_days
      services: $services
      other_service_one: $other_service_one
      other_service_two: $other_service_two
      brands: $brands
      store_image: $store_image
  ) {
      id
    }
  }
`;
