import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { Field } from "formik";
import { useState } from "react";
import { FormatOptionLabelMeta } from "react-select";
import CreatableSelect from "react-select/creatable";

const CreatableMulti = ({
  onChange,
  selectValues,
  isDisabled,
}: {
  onChange: (values: any) => void;
  selectValues: any;
  isDisabled: boolean;
}) => {
  return (
    <CreatableSelect
      isDisabled={isDisabled}
      isMulti
      value={selectValues}
      onChange={onChange}
      options={[{ value: "Tutti", label: "Tutti" }]}
      formatCreateLabel={(inputValue: string) =>
        `Aggiungi ${
          inputValue[0].toUpperCase() + inputValue.substring(1).toLowerCase()
        }`
      }
    />
  );
};

type Props = {
  customerType: string;
  canDisableFields: boolean;
};

export const CustomerTypeForm = ({ customerType, canDisableFields }: Props) => {
  const parsedCustomerType: string[] = customerType
    ? JSON.parse(customerType)
    : [];

  const [selectValues, setSelectValue] = useState<
    { value: string; label: string }[]
  >(
    parsedCustomerType.map((item: string) => {
      return { value: item, label: item };
    })
  );

  return (
    <Field name={"customer_type"}>
      {({ field, form: { setFieldValue }, meta }: any) => (
        <FormControl
          isInvalid={Boolean(meta.error) && meta.touched}
          isDisabled={canDisableFields}
        >
          <FormLabel>Tipo consumatore</FormLabel>
          <CreatableMulti
            isDisabled={canDisableFields}
            selectValues={selectValues}
            onChange={(values: any) => {
              setSelectValue(
                values.map((item: { value: string; label: string }) => {
                  return {
                    value:
                      item.value[0].toUpperCase() +
                      item.value.substring(1).toLowerCase(),
                    label:
                      item.label[0].toUpperCase() +
                      item.label.substring(1).toLowerCase(),
                  };
                })
              );

              const districts: string[] = values.map(
                (item: { value: string; label: string }) =>
                  item.label[0].toUpperCase() +
                  item.label.substring(1).toLowerCase()
              );

              setFieldValue(field.name, JSON.stringify(districts));
            }}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
};
