import { useLazyQuery, useMutation } from "@apollo/client";
import {
  Box,
  Flex,
  useMediaQuery,
  Button,
  HStack,
  useDisclosure,
  ModalContent,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Alert,
  AlertIcon,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  VStack,
  AccordionPanel,
  Text,
} from "@chakra-ui/react";
import React, { useState, useCallback, useEffect } from "react";
import { AdminHeader } from "../../../components/AdminHeader";
import { ProductForm } from "../../../components/ProductForm";
import { Product } from "../../../entities/Product";
import { useStores } from "../../../hooks/UseStores";
import {
  FETCH_ADMIN_PRODUCTS,
  CREATE_ADMIN_PRODUCT,
  DELETE_ADMIN_PRODUCT,
  UPDATE_ADMIN_PRODUCT,
} from "./graphql";
import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";
import { mainTheme } from "../../../config/theme";

export const AdminProductsScreen: React.FC = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [messageSuccess, setMessageSuccess] = useState<string | null>(null);
  const [messageError, setMessageError] = useState<string | null>(null);
  const [activeProduct, setActiveProduct] = useState<Product | null>(null);
  const { session } = useStores();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [fetchProducts, { data: productsData, refetch }] = useLazyQuery(
    FETCH_ADMIN_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const [createProduct] = useMutation(CREATE_ADMIN_PRODUCT, {
    onCompleted: () => {
      onClose();
      setMessageSuccess("Prodotto inserito correttamente!");
      setActiveProduct(null);
      refetch && refetch();
    },
    onError: (error) => {
      console.log(error);
      setMessageError("Si è verificato un errore.");
    },
  });

  const [updateProduct] = useMutation(UPDATE_ADMIN_PRODUCT, {
    onCompleted: () => {
      onClose();
      setMessageSuccess("Prodotto aggiornato correttamente!");
      setActiveProduct(null);
      refetch && refetch();
    },
    onError: (error) => {
      console.log(error);
      setMessageError("Si è verificato un errore.");
    },
  });

  const [deleteProduct] = useMutation(DELETE_ADMIN_PRODUCT, {
    onCompleted: () => {
      onClose();
      setMessageSuccess("Prodotto eliminato correttamente!");
      refetch && refetch();
    },
    onError: (error) => {
      console.log(error);
      setMessageError("Si è verificato un errore.");
    },
  });

  const handleSubmit = useCallback(
    (data: any) => {
      console.log(data);
      setMessageError(null);
      setMessageSuccess(null);

      if (data.id) {
        updateProduct({
          variables: {
            id: data.id,
            name: data.name,
            description: data.description,
            product_images: JSON.stringify(data.product_images),
            brandId: data.brandId,
            company: data.company,
            segment: data.segment,
            users_perimeter: data.users_perimeter,
            special_need_radio: data.special_need_radio,
            special_needs_id: data.special_needs_id,
            size: data.size,
            country_of_production: data.country_of_production,
            flavor: data.flavor,
            available_formats: data.available_formats,
            ingredients_features: data.ingredients_features,
            ean_code: data.ean_code,
            recommendations: data.recommendations,
            greenFootprintComment: data.greenFootprintComment,
            greenFootprintRating: data.greenFootprintRating,
          },
        });

        setActiveProduct(null);
      } else {
        createProduct({
          variables: {
            adminId: session.adminUser?.id,
            name: data.name,
            description: data.description,
            product_images: JSON.stringify(data.product_images),
            brandId: data.brandId,
            company: data.company,
            segment: data.segment,
            users_perimeter: data.users_perimeter,
            special_need_radio: data.special_need_radio,
            special_needs_id: data.special_needs_id,
            size: data.size,
            country_of_production: data.country_of_production,
            flavor: data.flavor,
            available_formats: data.available_formats,
            ingredients_features: data.ingredients_features,
            ean_code: data.ean_code,
            recommendations: data.recommendations,
            greenFootprintComment: data.greenFootprintComment,
            greenFootprintRating: data.greenFootprintRating,
          },
        });
      }
      setActiveProduct(null);
    },
    [createProduct, session.adminUser?.id, updateProduct]
  );

  const handleOpenProduct = useCallback(
    (product: any) => {
      setActiveProduct(product);
      onOpen();
    },
    [onOpen]
  );

  const handleDelete = useCallback(() => {
    if (activeProduct) {
      deleteProduct({
        variables: {
          id: activeProduct.id,
        },
      });
    }
  }, [activeProduct, deleteProduct]);

  useEffect(() => {
    fetchProducts({ variables: { adminId: session.adminUser?.id } });
  }, [fetchProducts, session.adminUser?.id]);

  const handleButtonOnClick = () => {
    setActiveProduct(null);
    onOpen();
  };

  return (
    <Box minHeight="100vh" display={"grid"} gridTemplateRows={"auto 1fr auto"}>
      <AdminHeader dark session={session} selectedTab={"products"} />
      <Flex
        flexDirection={"row"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        marginTop={"1.5rem"}
        height={"100%"}
      >
        <Stack
          w="100%"
          padding={isLargerThan768 ? "2rem" : "1.5rem"}
          borderRadius="2rem"
          bgColor={mainTheme.colors.textWhite}
        >
          <HStack justifyContent={"flex-end"}>
            <Button
              onClick={handleButtonOnClick}
              colorScheme={"teal"}
              size={isLargerThan768 ? "md" : "xs"}
              fontSize={isLargerThan768 ? "md" : "xs"}
            >
              Aggiungi prodotto
            </Button>
          </HStack>
          {messageError && (
            <Alert status="error">
              <AlertIcon />
              {messageError}
            </Alert>
          )}
          {messageSuccess && (
            <Alert
              status="success"
              fontSize={isLargerThan768 ? undefined : "sm"}
              size={isLargerThan768 ? undefined : "sm"}
            >
              <AlertIcon />
              {messageSuccess}
            </Alert>
          )}
          {productsData && isLargerThan768 && (
            <Table variant="striped" colorScheme="teal" size="md">
              <Thead>
                <Tr>
                  <Th>Nome</Th>
                  <Th>Brand</Th>
                  <Th>Company</Th>
                  <Th>Ean code</Th>
                </Tr>
              </Thead>
              <Tbody>
                {productsData.admin.products.map((product: Product) => {
                  return (
                    <Tr
                      id={product.id + product.name}
                      key={product.id + product.name}
                      onClick={() => handleOpenProduct(product)}
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        cursor: "pointer",
                      }}
                    >
                      <Td>{product.name}</Td>
                      <Td>{product.brand.name}</Td>
                      <Td>{product.company || ""}</Td>
                      <Td>{product.ean_code || ""}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          )}
          {productsData && !isLargerThan768 && (
            <Accordion w="100%">
              {productsData.admin.products.map((product: Product) => {
                return (
                  <AccordionItem key={product.id + product.name} w="100%">
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          {product.name}
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <VStack align="flex-start">
                        {product.brand.name && (
                          <Text>Brand: {product.brand.name}</Text>
                        )}
                        {product.company && (
                          <Text>Company: {product.company}</Text>
                        )}
                        {product.ean_code && (
                          <Text>EAN code: {product.ean_code}</Text>
                        )}
                        <Button
                          colorScheme="teal"
                          variant="outline"
                          size="sm"
                          onClick={() => handleOpenProduct(product)}
                        >
                          {"Modifica"}
                        </Button>
                      </VStack>
                    </AccordionPanel>
                  </AccordionItem>
                );
              })}
            </Accordion>
          )}
        </Stack>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Scheda prodotto</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProductForm
              product={activeProduct}
              onCancel={() => {
                onClose();
                setActiveProduct(null);
              }}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
