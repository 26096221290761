import { Field, Form, Formik } from "formik";
import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { ModalFooterForm } from "../ModalFooterForm";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { NumberInput, NumberInputField } from "@chakra-ui/react";

type Props = {
  loyalityAction: any;
  onCancel: any;
  onSubmit: any;
};

export const LoyalityActionForm: React.FC<Props> = ({
  loyalityAction,
  onCancel,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    points: Yup.number()
      .required("Specifica il punteggio")
      .min(0, "Il puteggio deve essere maggiore di 0!"),
  });

  return (
    <Formik
      initialValues={{
        id: loyalityAction.id,
        points: loyalityAction.points,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FormControl>
            <FormLabel>{"Nome"}</FormLabel>
            <Input
              value={String(t(`loyalityActions.${loyalityAction.name}`))}
              disabled
            />
          </FormControl>

          <Field name={"points"}>
            {({ field, form: { setFieldValue }, meta }: any) => (
              <FormControl isInvalid={meta.description && meta.touched}>
                <FormLabel htmlFor={"points"}>Punteggio</FormLabel>
                <NumberInput
                  step={1}
                  defaultValue={0}
                  min={0}
                  name={field.name}
                  value={field.value}
                  onChange={(valueString) =>
                    setFieldValue(field.name, Number(valueString))
                  }
                  size={"sm"}
                >
                  <NumberInputField />
                </NumberInput>

                <FormErrorMessage>{meta.error}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <ModalFooterForm onCancel={onCancel} />
        </Form>
      )}
    </Formik>
  );
};
