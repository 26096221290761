import { Image, ResponsiveValue, Stack, Text } from "@chakra-ui/react";
import { mainTheme } from "../../../config/theme";
import { Animal } from "../../../models/Animal";
import DogImage from "../../../assets/images/dog.png";
import CatImage from "../../../assets/images/cat.png";
import SmallAnimalImage from "../../../assets/images/paw.png";

export const AnimalImage = ({
  boxWidth,
  boxHeight,
  boxRightMargin,
  boxJustifyContent,
  boxTextAlign,
  animal,
  onClick,
  isLargerThan768,
}: {
  boxWidth: string;
  boxHeight?: string | undefined;
  boxRightMargin?: string | undefined;
  boxJustifyContent?: ResponsiveValue<any> | undefined;
  boxTextAlign?: ResponsiveValue<any> | undefined;
  animal: Animal | undefined;
  onClick: () => void;
  isLargerThan768: boolean;
}) => {
  return (
    <Stack
      w={boxWidth}
      h={"15rem"}
      maxW={"15rem"}
      maxH={"15rem"}
      marginRight={boxRightMargin}
      backgroundColor={mainTheme.colors.textWhite}
      justifyContent={boxJustifyContent}
      textAlign={boxTextAlign}
      padding="1rem"
      borderRadius="2rem"
      marginX={isLargerThan768 ? "1rem" : undefined}
      cursor={"pointer"}
      onClick={onClick}
      marginBottom={"1rem"}
    >
      <Image
        src={
          animal
            ? animal.image
              ? animal.image
              : animal.animal_type.name === "dog"
              ? DogImage
              : animal.animal_type.name === "cat"
              ? CatImage
              : SmallAnimalImage
            : SmallAnimalImage
        }
        objectFit="contain"
        borderRadius="2rem"
        maxH="140px"
      />

      <Text fontWeight={600} fontSize={"1.2rem"}>
        {animal?.name}
      </Text>
      <Text>
        {animal
          ? animal.animal_type.name === "dog" ||
            animal.animal_type.name === "cat"
            ? animal.breed.name
            : "\n"
          : "\n"}
      </Text>
    </Stack>
  );
};
