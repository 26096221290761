import {
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from "@apollo/client";
import {
  Flex,
  VStack,
  Tbody,
  Tr,
  Th,
  Table,
  Td,
  Thead,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  AccordionIcon,
  SimpleGrid,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { mainTheme } from "../../../config/theme";
import { InterestedBrand, Promo } from "../../../entities/Promo";
import { useStores } from "../../../hooks/UseStores";
import { GET_CUSTOMER_BY_ID } from "../../../services/graphql";
import { PrimaryLooversText } from "../../LooversText/LooversText";
import { PromoBox } from "../../PromoBox";

type Props = {
  fetchPromos: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void;
  dataFetchPromo: Promo[];
};

export const PromotionBanner = ({ fetchPromos, dataFetchPromo }: Props) => {
  const { session } = useStores();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  const [fetchCustomer, { data: customerData }] = useLazyQuery(
    GET_CUSTOMER_BY_ID,
    {
      fetchPolicy: "no-cache",
      onError: (error) => {
        console.log("FETCH_PROMOS", error);
      },
    }
  );

  useEffect(() => {
    if (session.customerUser) {
      fetchPromos({
        variables: {
          customerId: session.customerUser.id,
          includeNationalPromos: true,
        },
      });

      fetchCustomer({
        variables: {
          userId: session.customerUser.id,
        },
      });
    }
  }, [fetchCustomer, fetchPromos, session.customerUser]);

  const getActivityTranslation = (item: Promo) => {
    switch (item.interested_brand) {
      case InterestedBrand.single_brand:
        return item.brand.name;
      case InterestedBrand.single_service:
        return t(`promoForm.${item.activity.name}`);
      default:
        return t(`promoForm.interested_brand.${item.interested_brand}`);
    }
  };

  const getPromoType = (item: Promo) => {
    switch (item.activity_type) {
      case "valueDiscountCoupon":
        return "€ " + item.promo_type;
      case "percentageDiscountCoupon":
        return "% " + item.promo_type;
      default:
        return "/";
    }
  };

  const DesktopPromotionsTable: React.FC<{
    promos: Promo[];
    customerUsedPromo: any;
  }> = ({ promos, customerUsedPromo }) => {
    const { t } = useTranslation();
    return (
      <Table size="sm" variant="striped" colorScheme="teal">
        <Thead>
          <Tr>
            <Th>{t("promoForm.type")}</Th>
            <Th>{t("promoForm.activity")}</Th>
            <Th>{t("promoForm.discountValue")}</Th>
            <Th>{t("promoForm.startDate")}</Th>
            <Th>{t("promoForm.endDate")}</Th>
            <Th>{t("promoForm.usersPerimeter.label")}</Th>
            <Th>{t("qrCodeReaderModal.national")}</Th>
            <Th>{t("qrCodeReaderModal.status")}</Th>
            <Th>{t("qrCodeReaderModal.usedDate")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {promos.map((item: Promo) => {
            return (
              <Tr key={"tr" + item.id}>
                <Td>{t(`promoForm.${item.activity_type}`)}</Td>
                <Td>{getActivityTranslation(item)}</Td>
                <Td>{getPromoType(item)}</Td>
                <Td>{item.valid_from}</Td>
                <Td>{item.valid_until}</Td>
                <Td>{t(`promoForm.usersPerimeter.${item.users_perimeter}`)}</Td>
                <Td>
                  {item.is_national
                    ? t("qrCodeReaderModal.yes")
                    : t("qrCodeReaderModal.no")}
                </Td>
                <Td>
                  {customerUsedPromo.find(
                    (used_promo: any) => used_promo.promoId === item.id
                  )
                    ? t("qrCodeReaderModal.notAvailable")
                    : t("qrCodeReaderModal.available")}
                </Td>
                <Td>
                  {
                    customerUsedPromo.find(
                      (used_promo: any) => used_promo.promoId === item.id
                    )?.used_date
                  }
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  };

  const MobilePromotionsTable: React.FC<{
    promos: Promo[];
    customerUsedPromo: any;
  }> = ({ promos, customerUsedPromo }) => {
    return (
      <Accordion w="100%" allowMultiple>
        {promos.map((item: Promo) => {
          return (
            <AccordionItem key={item.id} w="100%">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    {item.email_title}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <VStack align="flex-start">
                  <Text>{getActivityTranslation(item)}</Text>
                  <Text>{getPromoType(item)}</Text>
                  <Text>Fino al {item.valid_until}</Text>
                  <Text>
                    {customerUsedPromo.find(
                      (used_promo: any) => used_promo.promoId === item.id
                    )?.used_date
                      ? "Usata il " +
                        customerUsedPromo.find(
                          (used_promo: any) => used_promo.promoId === item.id
                        )?.used_date
                      : ""}
                  </Text>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  return (
    <Flex
      w="100%"
      flexDirection={"column"}
      alignItems="center"
      justifyContent="center"
    >
      <VStack
        w="100%"
        maxW={"70rem"}
        spacing={"1rem"}
        padding="0px 2rem 2rem 2rem"
        borderRadius="2rem"
        bgColor={mainTheme.colors.textWhite}
      >
        <PrimaryLooversText text={t("dashboard.userPromo")} textAlign="left" />

        {dataFetchPromo.length === 0 && (
          <Text>Nessuna promozione disponible!</Text>
        )}

        <SimpleGrid columns={[1, null, 4]} spacing="40px">
          {dataFetchPromo.map((promo) => (
            <PromoBox
              promo={promo}
              available={
                !!(customerData ? customerData.customer.used_promo : []).find(
                  (used_promo: any) => used_promo.promoId === promo.id
                )
              }
              usedDate={
                (customerData ? customerData.customer.used_promo : []).find(
                  (used_promo: any) => used_promo.promoId === promo.id
                )?.used_date
              }
            />
          ))}
        </SimpleGrid>

        {/* {isDesktop
          ? dataFetchPromo.length > 0 && (
              <DesktopPromotionsTable
                promos={dataFetchPromo}
                customerUsedPromo={
                  customerData ? customerData.customer.used_promo : []
                }
              />
            )
          : dataFetchPromo.length > 0 && (
              <MobilePromotionsTable
                promos={dataFetchPromo}
                customerUsedPromo={
                  customerData ? customerData.customer.used_promo : []
                }
              />
            )} */}
      </VStack>
    </Flex>
  );
};
