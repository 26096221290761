import React, { useCallback } from "react";
import { Formik, Field, Form, FormikHelpers, FieldProps } from "formik";
import { useTranslation } from "react-i18next";
import { Stack } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/input";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import styled from "styled-components";
import { Button } from "@chakra-ui/button";

interface Values {
  email: string;
  newPassword: string;
  confirmNewPassword: string;
}

type ChangePasswordProps = {
  isLoading: boolean;
  email: string | null;
  onSubmit: (email: string, password: string) => any;
};

export const ChangePasswordForm: React.FC<ChangePasswordProps> = ({
  isLoading,
  email,
  onSubmit,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
      setSubmitting(false);
      onSubmit(values.email, values.newPassword);
    },
    [onSubmit]
  );

  return (
    <Formik
      initialValues={{
        email: email || "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack spacing={"1rem"}>
            <Field id={"email"} name={"email"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <StyledFormLabel htmlFor="email">
                    {t("customer.email")}
                  </StyledFormLabel>
                  <Input
                    type={"email"}
                    name={field.name}
                    value={field.value}
                    disabled
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field id={"newPassword"} name={"newPassword"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <StyledFormLabel htmlFor="newPassword">
                    {t("auth.password")}
                  </StyledFormLabel>
                  <Input
                    type={"password"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field id={"confirmNewPassword"} name={"confirmNewPassword"}>
              {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                  <StyledFormLabel htmlFor="confirmNewPassword">
                    {t("auth.confirmNewPassword")}
                  </StyledFormLabel>
                  <Input
                    type={"password"}
                    name={field.name}
                    value={field.value}
                    onChange={(event) =>
                      setFieldValue(field.name, event.target.value)
                    }
                  />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button
              colorScheme={"teal"}
              type={"submit"}
              isLoading={isLoading}
              disabled={
                props.values.email.length === 0 ||
                props.values.newPassword.length === 0 ||
                props.values.confirmNewPassword.length === 0 ||
                props.values.confirmNewPassword !== props.values.newPassword
              }
            >
              {t("common.confirm")}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const StyledFormLabel = styled(FormLabel)`
  font-size: 0.85rem !important;
  font-weight: 400 !important;
  line-height: 1rem;
`;
