import { Badge, Box } from "@chakra-ui/layout";
import { InterestedBrand, Promo } from "../../entities/Promo";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

type Props = {
  promo: Promo;
  available: boolean;
  usedDate?: Date;
  onClick?: () => void;
};

export const PromoBox: React.FC<Props> = ({
  promo,
  available,
  usedDate,
  onClick,
}) => {
  const { t } = useTranslation();

  const getActivityTranslation = useMemo(() => {
    switch (promo.interested_brand) {
      case InterestedBrand.single_brand:
        return promo.brand.name;
      case InterestedBrand.single_service:
        return t(`promoForm.${promo.activity.name}`);
      default:
        return t(`promoForm.interested_brand.${promo.interested_brand}`);
    }
  }, [promo.activity.name, promo.brand.name, promo.interested_brand, t]);

  const getPromoType = useMemo(() => {
    switch (promo.activity_type) {
      case "valueDiscountCoupon":
        return "€ " + promo.promo_type;
      case "percentageDiscountCoupon":
        return "% " + promo.promo_type;
      default:
        return "/";
    }
  }, [promo.activity_type, promo.promo_type]);

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      //onClick={onClick}
      cursor="pointer"
      backgroundColor={
        usedDate
          ? "#00b485"
          : promo.valid_until < new Date()
          ? "#fff"
          : "#ffcb3a"
      }
    >
      <Box p="6">
        <Box display="flex" alignItems="baseline" justifyContent="flex-end">
          <Badge
            borderRadius="full"
            px="2"
            colorScheme={usedDate ? "green" : "yellow"}
          >
            {!usedDate ? t("qrCodeReaderModal.available") : `Utilizzata`}
          </Badge>
        </Box>
        <Box textAlign="center" mb="2">
          <Box mt="1" fontWeight="semibold" as="h2" fontSize={"xx-large"}>
            {getPromoType}
          </Box>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {t(`promoForm.${promo.activity_type}`)}
          </Box>
          {promo && promo.valid_from && promo.valid_until && (
            <Box
              color="gray.500"
              fontWeight="medium"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {format(new Date(promo.valid_from), "dd/MM/yyyy")} -{" "}
              {format(new Date(promo.valid_until), "dd/MM/yyyy")}
            </Box>
          )}
        </Box>

        <hr />

        <Box mt="2" fontWeight="semibold" as="h4" lineHeight="tight">
          {promo.email_title}
        </Box>

        <Box>{promo.description}</Box>

        <Box
          color="gray.500"
          fontWeight="semibold"
          letterSpacing="wide"
          fontSize="xs"
          textTransform="uppercase"
          ml="2"
          mt="3"
        >
          {getActivityTranslation} &bull;{" "}
          {promo.users_perimeter === "all"
            ? "Disponibile per tutti"
            : t(`promoForm.usersPerimeter.${promo.users_perimeter}`)}
          {promo.is_national ? <> &bull; {"Promozione nazionale"}</> : ""}
        </Box>

        {/* <Box>{product.description}</Box> */}

        {/* <Box display="flex" mt="2" alignItems="center">
          {product.greenFootprintRating && (
            <ReactStars
              count={5}
              value={product.greenFootprintRating}
              size={8}
              isHalf={false}
              emptyIcon={<FaStar color={"grey"} size={5} />}
              fullIcon={<FaStar color={"grey"} size={5} />}
              activeColor="#ffd700"
              edit={false}
            />
          )}
        </Box> */}
      </Box>
    </Box>
  );
};
