import { Button } from "@chakra-ui/button";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/layout";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Text,
  FormLabel,
  Input,
  Flex,
  Spinner,
  HStack,
  useDisclosure,
  VStack,
  Image,
} from "@chakra-ui/react";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import { phoneRegExp } from "../../config/constants";
import { PartnerUser } from "../../entities/PartnerUser";
import { mainTheme } from "../../config/theme";
import { FileUploadModal } from "../FileUploadModal";
import { StoreImageModal } from "./StoreImageModal";
import { useMediaQuery } from "react-responsive";
import { DistrictsForm } from "../PartnerPromotions/DistrictsForm";

type PartnerEditFormType = {
  businessName: string;
  storeType: string;
  addressBusiness: string;
  addressBusinessCity: string;
  addressBusinessDistrict: {
    label: string;
    value: string;
  };
  addressBusinessCap: string;
  addressStore: string;
  addressStoreCity: string;
  addressStoreDistrict: {
    label: string;
    value: string;
  };
  addressStoreCap: string;
  addressStore2: string;
  addressStore2City: string;
  addressStore2District: {
    label: string;
    value: string;
  };
  addressStore2Cap: string;
  bannerName: string;
  emailB2B: string;
  mobilePhoneB2B: string;
  phoneB2B: string;
  vatNumber: string;
  taxCode: string;
  pec: string;
  sdi: string;
};

type Props = {
  isSubmitting: boolean;
  currentStore: PartnerUser;
  onSubmit: (values: PartnerEditFormType) => void;
  onDeleteStoreImage: () => void;
};

export const EditStoreForm: React.FC<Props> = ({
  isSubmitting,
  currentStore,
  onSubmit,
  onDeleteStoreImage,
}) => {
  const { t } = useTranslation();

  const businessAddress =
    currentStore.addresses.find(
      (address) => address.type === "businessAddress"
    ) || null;

  const storeAddress =
    currentStore.addresses.find((address) => address.type === "storeAddress") ||
    null;

  const store2Address =
    currentStore.addresses.find(
      (address) => address.type === "store2Address"
    ) || null;

  const theme = useTheme();

  const validationSchema = Yup.object().shape({
    businessName: Yup.string()
      .required(t("auth.validations.businessNameRequired"))
      .min(2, t("auth.validations.businessNameRequired")),
    addressBusiness: Yup.string()
      .required(t("auth.validations.addressBusinessRequired"))
      .min(2, t("auth.validations.addressBusinessRequired")),
    addressBusinessCity: Yup.string()
      .required(t("auth.validations.addressBusinessCityRequired"))
      .min(2, t("auth.validations.addressBusinessCityRequired")),
    addressBusinessCap: Yup.string()
      .typeError(t("auth.validations.addressBusinessCapNumber"))
      .required(t("auth.validations.addressBusinessCapRequired"))
      .min(2, t("auth.validations.addressBusinessCapRequired")),
    addressStore: Yup.string()
      .required(t("auth.validations.addressBusinessRequired"))
      .min(2, t("auth.validations.addressBusinessRequired")),
    addressStoreCity: Yup.string()
      .required(t("auth.validations.addressBusinessCityRequired"))
      .min(2, t("auth.validations.addressBusinessCityRequired")),
    addressStoreCap: Yup.string()
      .typeError(t("auth.validations.addressBusinessCapNumber"))
      .required(t("auth.validations.addressBusinessCapRequired"))
      .min(2, t("auth.validations.addressBusinessCapRequired")),
    bannerName: Yup.string()
      .required(t("auth.validations.bannerNameRequired"))
      .min(2, t("auth.validations.bannerNameRequired")),
    emailB2B: Yup.string()
      .required(t("auth.validations.emailRequired"))
      .min(2, t("auth.validations.emailRequired"))
      .email(t("auth.validations.emailInvalid")),
    mobilePhoneB2B: Yup.string()
      .required(t("auth.validations.mobilePhoneRequired"))
      .min(2, t("auth.validations.mobilePhoneRequired"))
      .matches(phoneRegExp, t("auth.validations.mobilePhoneInvalid")),
    phoneB2B: Yup.string().matches(
      phoneRegExp,
      t("auth.validations.mobilePhoneInvalid")
    ),
    vatNumber: Yup.string()
      .required(t("auth.validations.vatNumberRequired"))
      .min(2, t("auth.validations.vatNumberRequired")),
    taxCode: Yup.string()
      .required(t("auth.validations.taxCodeRequired"))
      .min(2, t("auth.validations.taxCodeRequired")),
    pec: Yup.string()
      .required(t("auth.validations.pecRequired"))
      .min(2, t("auth.validations.pecRequired"))
      .email(t("auth.validations.emailInvalid")),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenImageModal,
    onOpen: onOpenImageModal,
    onClose: onCloseImageModal,
  } = useDisclosure();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <Formik
      initialValues={{
        store_image: currentStore.store_image ? currentStore.store_image : "",
        businessName: currentStore.businessName,
        storeType: currentStore.storeType
          ? t(`partner.storeTypes.${currentStore.storeType}`)
          : "-",
        addressBusiness: businessAddress ? businessAddress.address : "",
        addressBusinessCity: businessAddress ? businessAddress.city : "",
        addressBusinessDistrict: {
          label: businessAddress ? businessAddress.district : "",
          value: businessAddress ? businessAddress.district : "",
        },
        addressBusinessCap: businessAddress ? businessAddress.cap : "",
        addressStore: storeAddress ? storeAddress.address : "",
        addressStoreCity: storeAddress ? storeAddress.city : "",
        addressStoreDistrict: {
          label: storeAddress ? storeAddress.district : "",
          value: storeAddress ? storeAddress.district : "",
        },
        addressStoreCap: storeAddress ? storeAddress.cap : "",
        addressStore2: store2Address ? store2Address.address : "",
        addressStore2City: store2Address ? store2Address.city : "",
        addressStore2District: {
          label: store2Address ? store2Address.district : "",
          value: store2Address ? store2Address.district : "",
        },
        addressStore2Cap: store2Address ? store2Address.cap : "",
        bannerName: currentStore.bannerName,
        emailB2B: currentStore.emailB2B,
        mobilePhoneB2B: currentStore.mobileB2B,
        phoneB2B: currentStore.phoneB2B,
        vatNumber: currentStore.vatNumber,
        taxCode: currentStore.taxCode,
        pec: currentStore.pec,
        sdi: currentStore.sdi,
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Stack
            spacing={"1rem"}
            alignItems={"center"}
            border={"1px solid lightgrey"}
            p={isDesktop ? 4 : 1}
            borderRadius={"2xl"}
            boxShadow="md"
          >
            <Box w={"100%"} flexDirection={"column"} flexWrap={"wrap"}>
              <Field name={"store_image"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"store_image"}>
                      {t("partner.store_image")}
                    </FormLabel>
                    <FileUploadModal
                      isOpen={isOpen}
                      onClose={onClose}
                      maxFiles={1}
                      maxHeight={500}
                      accept={"image/jpeg"}
                      onFileUploaded={(fileUrls: string[]) => {
                        if (fileUrls.length === 1) {
                          setFieldValue(field.name, fileUrls[0]);
                        }
                      }}
                    />
                    <VStack
                      borderRadius={isDesktop ? "2xl" : "1rem"}
                      padding="0.5rem"
                      bgColor={mainTheme.colors.textWhite}
                      spacing={"1rem"}
                      alignItems={"center"}
                      border={"1px solid lightgrey"}
                      p={4}
                      boxShadow="md"
                    >
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        size="sm"
                        onClick={onOpen}
                      >
                        {t("partner.upload")}
                      </Button>
                      {field.value !== "" && (
                        <Image
                          border={"1px solid lightgrey"}
                          p={4}
                          boxShadow="md"
                          src={field.value}
                          height={"150px"}
                          onClick={onOpenImageModal}
                        />
                      )}
                      <StoreImageModal
                        isOpen={isOpenImageModal}
                        onClose={onCloseImageModal}
                        onDelete={() => {
                          setFieldValue(field.name, "");
                        }}
                        src={field.value}
                      />
                    </VStack>
                  </FormControl>
                )}
              </Field>

              <Field name={"businessName"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"businessName"}>
                      {t("partner.businessName")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"storeType"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"storeType"}>
                      {t("partner.storeType")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      disabled
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Text marginBottom={"0.5em"}>{t("partner.addressBusiness")}</Text>
              <Field name={"addressBusiness"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"addressBusinessStreet"}>
                      {t("partner.addressBusinessStreet")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <HStack flexWrap="wrap">
                <Field name={"addressBusinessCity"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                    >
                      <FormLabel htmlFor={"addressBusinessCity"}>
                        {t("partner.addressBusinessCity")} *
                      </FormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event: any) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name={"addressBusinessDistrict"}>
                  {({ field, form: { setFieldValue }, meta }: any) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                    >
                      <FormLabel htmlFor={"addressBusinessDistrict"}>
                        {t("partner.addressBusinessDistrict")} *
                      </FormLabel>
                      <DistrictsForm
                        districts={props.values.addressBusinessDistrict}
                        canDisableFields={false}
                        setFieldValue={setFieldValue}
                        promo={undefined}
                        field={field}
                        isUser
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name={"addressBusinessCap"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                      w={"200px"}
                    >
                      <FormLabel htmlFor={"addressBusinessCap"}>
                        {t("partner.addressBusinessCap")} *
                      </FormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event: any) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <Text marginBottom={"0.5em"}>{t("partner.addressStore")}</Text>
              <Field name={"addressStore"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"addressStoreStreet"}>
                      {t("partner.addressBusinessStreet")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <HStack flexWrap="wrap">
                <Field name={"addressStoreCity"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                    >
                      <FormLabel htmlFor={"addressStoreCity"}>
                        {t("partner.addressBusinessCity")} *
                      </FormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event: any) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name={"addressStoreDistrict"}>
                  {({ field, form: { setFieldValue }, meta }: any) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                    >
                      <FormLabel htmlFor={"addressStoreDistrict"}>
                        {t("partner.addressBusinessDistrict")} *
                      </FormLabel>
                      <DistrictsForm
                        districts={props.values.addressStoreDistrict}
                        canDisableFields={false}
                        setFieldValue={setFieldValue}
                        promo={undefined}
                        field={field}
                        isUser
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name={"addressStoreCap"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                      w={"200px"}
                    >
                      <FormLabel htmlFor={"addressStoreCap"}>
                        {t("partner.addressBusinessCap")} *
                      </FormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event: any) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <Text marginBottom={"0.5em"}>{t("partner.addressStore")} 2</Text>
              <Field name={"addressStore2"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"addressStore2Street"}>
                      {t("partner.addressBusinessStreet")}
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <HStack flexWrap="wrap">
                <Field name={"addressStore2City"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                    >
                      <FormLabel htmlFor={"addressStore2City"}>
                        {t("partner.addressBusinessCity")}
                      </FormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event: any) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name={"addressStore2District"}>
                  {({ field, form: { setFieldValue }, meta }: any) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                    >
                      <FormLabel htmlFor={"addressStore2District"}>
                        {t("partner.addressBusinessDistrict")}
                      </FormLabel>
                      <DistrictsForm
                        districts={props.values.addressStore2District}
                        canDisableFields={false}
                        setFieldValue={setFieldValue}
                        promo={undefined}
                        field={field}
                        isUser
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name={"addressStore2Cap"}>
                  {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                    <FormControl
                      isInvalid={Boolean(meta.error) && meta.touched}
                      w={"200px"}
                    >
                      <FormLabel htmlFor={"addressStore2Cap"}>
                        {t("partner.addressBusinessCap")}
                      </FormLabel>
                      <Input
                        name={field.name}
                        value={field.value}
                        onChange={(event: any) =>
                          setFieldValue(field.name, event.target.value)
                        }
                      />
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </HStack>

              <Field name={"bannerName"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"bannerName"}>
                      {t("partner.bannerName")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"emailB2B"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"emailB2B"}>
                      {t("partner.emailB2B")} *
                    </FormLabel>
                    <Input
                      type={"email"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      disabled
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"pec"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"pec"}>{t("partner.pec")} *</FormLabel>
                    <Input
                      type={"email"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"mobilePhoneB2B"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"mobilePhoneB2B"}>
                      {t("partner.mobilePhoneB2B")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"phoneB2B"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"phoneB2B"}>
                      {t("partner.phoneB2B")}
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"vatNumber"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"vatNumber"}>
                      {t("partner.vatNumber")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"taxCode"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"taxCode"}>
                      {t("partner.taxCode")} *
                    </FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"sdi"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"sdi"}>{t("partner.sdi")}</FormLabel>
                    <Input
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box>

            {isSubmitting && (
              <Flex
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                m={6}
                gridGap={0}
              >
                <Spinner
                  thickness="3px"
                  speed="0.65s"
                  emptyColor={mainTheme.colors.lightPurple}
                  color={mainTheme.colors.darkPurple}
                  size="md"
                  margin={"2rem"}
                />
              </Flex>
            )}

            <Button
              type={"submit"}
              colorScheme={"blue"}
              disabled={isSubmitting}
            >
              {t("common.confirm")}
            </Button>
            {!props.isValid && props.submitCount > 0 && (
              <Text fontSize="sm" color={theme.colors.danger}>
                {t("auth.messages.formError")}
              </Text>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
