import { Animal } from "../models/Animal";
import { Customer_Brand } from "./Customer_Brand";
import { FavoriteStore } from "./FavoriteStore";
import { Promo } from "./Promo";

type Props = {
  id: number;
  firstName: string;
  surname: string;
  district: string;
  email: string;
  username: string;
  profile_image: string;
  mobilePhone: string;
  gender: string;
  birthDate: string;
  storeCode?: string;
  animals: Animal[];
  used_promo: Promo[];
  favorite_store: FavoriteStore;
  brand: Customer_Brand[];
  loyality_points: number;
};

export class CustomerUser {
  private _id: number;
  private _firstName: string;
  private _first_name: string = "";
  private _surname: string;
  private _district: string = "";
  private _email: string;
  private _username: string = "";
  private _profile_image: string = "";
  private _mobilePhone: string;
  private _gender: string;
  private _birthDate: string;
  private _storeCode?: string;
  private _animals: Animal[];
  private _used_promo: Promo[];
  private _favorite_store: FavoriteStore;
  private _brand: Customer_Brand[];
  private _loyality_points: number;

  constructor() {
    this._id = -1;
    this._firstName = "";
    this._surname = "";
    this._email = "";
    this._mobilePhone = "";
    this._gender = "";
    this._birthDate = "";
    this._storeCode = "";
    this._animals = [];
    this._used_promo = [];
    this._favorite_store = new FavoriteStore(-1, "", "", "", [], null, "");
    this._brand = [];
    this._loyality_points = 0;
  }

  public mapCustomerUserData({
    id,
    firstName,
    surname,
    district,
    email,
    username,
    profile_image,
    mobilePhone,
    gender,
    birthDate,
    storeCode,
    animals,
    used_promo,
    favorite_store,
    brand,
    loyality_points,
  }: Props) {
    this._id = id;
    this._firstName = firstName;
    this._first_name = firstName;
    this._surname = surname;
    this._district = district;
    this._email = email;
    this._username = username;
    this._profile_image = profile_image;
    this._mobilePhone = mobilePhone;
    this._gender = gender;
    this._birthDate = birthDate;
    this._storeCode = storeCode;
    this._animals = animals;
    this._used_promo = used_promo;
    this._favorite_store = favorite_store;
    this._brand = brand;
    this._loyality_points = loyality_points;
  }

  public firstNameAndLastName(): string {
    return this._firstName + " " + this._surname.substring(0, 1) + ".";
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get firstName(): string {
    return this._firstName;
  }

  public get first_name(): string {
    return this._first_name;
  }

  public set firstName(value: string) {
    this._firstName = value;
  }

  public get surname(): string {
    return this._surname;
  }
  public set surname(value: string) {
    this._surname = value;
  }

  public get district(): string {
    return this._district;
  }

  public get email(): string {
    return this._email;
  }
  public set email(value: string) {
    this._email = value;
  }

  public get username() {
    return this._username;
  }

  public get profile_image() {
    return this._profile_image;
  }

  public get mobilePhone(): string {
    return this._mobilePhone;
  }
  public set mobilePhone(value: string) {
    this._mobilePhone = value;
  }
  public get gender(): string {
    return this._gender;
  }
  public set gender(value: string) {
    this._gender = value;
  }

  public get birthDate(): string {
    return this._birthDate;
  }
  public set birthDate(value: string) {
    this._birthDate = value;
  }

  public get storeCode(): string {
    return this._storeCode ? this._storeCode : "";
  }

  public set storeCode(value: string) {
    this._storeCode = value;
  }

  public get animals() {
    return this._animals;
  }

  public get used_promo() {
    return this._used_promo;
  }

  public get loyality_points() {
    // TODO
    return this._loyality_points;
  }
  public set loyality_points(loyality_points: number) {
    this._loyality_points = loyality_points;
  }

  public get favorite_store() {
    return this._favorite_store;
  }

  public set favorite_store(favorite_store: any) {
    this._favorite_store = favorite_store;
  }

  public get brand() {
    return this._brand;
  }
}
