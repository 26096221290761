import { Checkbox } from "@chakra-ui/checkbox";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "@chakra-ui/form-control";
import { Stack } from "@chakra-ui/layout";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormLabel } from "../FormLabel";
import * as Yup from "yup";
import { DatePicker } from "../DatePicker/DatePicker";
import {
  CUSTOMER_GENERAL_POLICY_URL,
  CUSTOMER_SIGNUP_PRIVACY_POLICY_URL,
  phoneRegExp,
} from "../../config/constants";
import {
  Avatar,
  Box,
  Button,
  Link,
  Select,
  Text,
  useDisclosure,
  HStack,
} from "@chakra-ui/react";
import { useTheme } from "styled-components";
import { FormInput } from "../FormInput";
import { Link as ReachLink } from "react-router-dom";
import { mainTheme } from "../../config/theme";
import { FileUploadModal } from "../FileUploadModal";
import { BrandsField } from "./BrandsField";
import { DistrictsForm } from "../PartnerPromotions/DistrictsForm";

const MAX_INPUT_WIDTH = "500px";

type CustomerSignUpFormType = {
  firstName: string;
  surname: string;
  email: string;
  district: { label: string; value: string };
  username: string;
  profile_image: string;
  password: string;
  mobilePhone: string;
  gender: string;
  birthDate: string;
  privacyPolicy: boolean;
  marketingTermsCustomer: boolean;
  storeCode?: string;
  brandsIds: number[];
};

type Props = {
  isSubmitting: boolean;
  onSubmit: (values: CustomerSignUpFormType) => void;
};

export const CustomerSignUpForm: React.FC<Props> = ({
  isSubmitting,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const today = new Date();

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string()
        .required(t("auth.validations.firstNameRequired"))
        .min(2, t("auth.validations.firstNameRequired")),
      surname: Yup.string()
        .required(t("auth.validations.surnameRequired"))
        .min(2, t("auth.validations.surnameRequired")),
      email: Yup.string()
        .required(t("auth.validations.emailRequired"))
        .min(2, t("auth.validations.emailRequired"))
        .email(t("auth.validations.emailInvalid")),
      district: Yup.object().required(t("auth.validations.districtRequired")),
      username: Yup.string()
        .required(t("auth.validations.usernameRequired"))
        .min(3, t("auth.validations.min3"))
        .max(16, t("auth.validations.max16")),
      password: Yup.string()
        .required(t("auth.validations.passwordRequired"))
        .min(6, t("auth.validations.passwordRequired")),
      mobilePhone: Yup.string()
        .nullable()
        .notRequired()
        .when("mobilePhone", {
          is: (value: any) => value?.length,
          then: (rule: any) =>
            rule.matches(phoneRegExp, t("auth.validations.mobilePhoneInvalid")),
        }),
      gender: Yup.string()
        .required(t("auth.validations.genderRequired"))
        .min(1, t("auth.validations.genderRequired"))
        .oneOf(["M", "F", "Other"]),
      birthDate: Yup.string()
        .required(t("auth.validations.birthDateRequired"))
        .min(2, t("auth.validations.birthDateRequired"))
        .nullable(),
      confirmPassword: Yup.string()
        .required(t("auth.validations.confirmPasswordRequired"))
        .min(6, t("auth.validations.confirmPasswordRequired"))
        .oneOf(
          [Yup.ref("password"), null],
          t("auth.validations.passwordsNotMatch")
        ),
      privacyPolicy: Yup.boolean().oneOf(
        [true],
        t("auth.validations.privacyPolicyRequired")
      ),
    },
    [["mobilePhone", "mobilePhone"]]
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Formik
      initialValues={{
        profile_image: "",
        firstName: "",
        surname: "",
        district: { label: "", value: "" },
        email: "",
        username: "",
        password: "",
        mobilePhone: "",
        gender: "",
        birthDate: "",
        privacyPolicy: false,
        marketingTermsCustomer: false,
        storeCode: "",
        brandsIds: [],
      }}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <Form>
          <Stack spacing={"1rem"} alignItems={"center"}>
            <Box maxW={MAX_INPUT_WIDTH} w={"100%"}>
              <Field name={"firstName"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"firstName"} $signUpForm>
                      {t("customer.firstName")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"surname"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"surname"} $signUpForm>
                      {t("customer.surname")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"district"}>
                {({ field, form: { setFieldValue }, meta }: any) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"district"} $signUpForm>
                      {t("customer.district")} *
                    </FormLabel>
                    <DistrictsForm
                      districts={props.values.district}
                      canDisableFields={false}
                      setFieldValue={setFieldValue}
                      promo={undefined}
                      field={field}
                      isUser
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"email"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"email"} $signUpForm>
                      {t("customer.email")} *
                    </FormLabel>
                    <FormInput
                      type={"email"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"username"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"username"} $signUpForm>
                      {t("customer.username")} *
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"mobilePhone"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"mobilePhone"} $signUpForm>
                      {t("customer.mobilePhone")}
                    </FormLabel>
                    <FormInput
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormHelperText color={mainTheme.colors.textWhite}>
                      {t("auth.helper.customer.mobilePhone")}
                    </FormHelperText>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"gender"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"gender"} $signUpForm>
                      {t("customer.gender")} *
                    </FormLabel>
                    <Select
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      placeholder={t("common.select")}
                      backgroundColor={mainTheme.colors.background}
                      color={mainTheme.colors.textPrimary}
                    >
                      <option value={"M"}>{t("customer.male")}</option>
                      <option value={"F"}>{t("customer.female")}</option>
                      <option value={"Other"}>{t("customer.other")}</option>
                    </Select>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"birthDate"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"birthDate"} $signUpForm>
                      {t("customer.birthDate")} *
                    </FormLabel>
                    <Box color={mainTheme.colors.textPrimary}>
                      <DatePicker
                        name={field.name}
                        onChange={(newDate: any) =>
                          setFieldValue(field.name, newDate)
                        }
                        value={field.value}
                        maxDate={today}
                        theme={"dark"}
                        required={true}
                      />
                    </Box>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"password"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"password"} $signUpForm>
                      {t("auth.password")} *
                    </FormLabel>
                    <FormInput
                      type={"password"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"confirmPassword"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"confirmPassword"} $signUpForm>
                      {t("auth.confirmPassword")} *
                    </FormLabel>
                    <FormInput
                      type={"password"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"storeCode"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"storeCode"} $signUpForm>
                      {t("customer.storeCode")}
                    </FormLabel>
                    <FormInput
                      type={"storeCode"}
                      name={field.name}
                      value={field.value}
                      onChange={(event: any) =>
                        setFieldValue(field.name, event.target.value)
                      }
                      $signUpForm
                    />
                    <FormHelperText color={mainTheme.colors.textWhite}>
                      {t("auth.helper.customer.storeCode")}
                    </FormHelperText>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"brandsIds"}>
                {({ field, form: { setFieldValue }, meta }: any) => (
                  <FormControl isInvalid={meta.description && meta.touched}>
                    <FormLabel $signUpForm>{t("customer.brands")}</FormLabel>
                    <BrandsField field={field} setFieldValue={setFieldValue} />
                    <FormHelperText color={mainTheme.colors.textWhite}>
                      {t("auth.helper.customer.brandsIds")}
                    </FormHelperText>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={"profile_image"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl isInvalid={Boolean(meta.error) && meta.touched}>
                    <FormLabel htmlFor={"profile_image"} $signUpForm>
                      {t("customer.profile_image")}
                    </FormLabel>
                    <FileUploadModal
                      isOpen={isOpen}
                      onClose={onClose}
                      maxFiles={1}
                      accept={"image/jpeg"}
                      onFileUploaded={(fileUrls: string[]) => {
                        if (fileUrls.length === 1) {
                          setFieldValue(field.name, fileUrls[0]);
                        }
                      }}
                    />
                    <HStack
                      spacing={"2rem"}
                      borderRadius={"1rem"}
                      padding="1rem"
                      bgColor={mainTheme.colors.textWhite}
                    >
                      <Button
                        colorScheme="teal"
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          onOpen();
                        }}
                      >
                        {t("partner.upload")}
                      </Button>
                      {field.value !== "" && (
                        <Avatar
                          showBorder
                          size="md"
                          src={field.value}
                          bg="teal.500"
                        />
                      )}
                    </HStack>
                  </FormControl>
                )}
              </Field>

              <Text>
                {t("auth.termsOfUse1")}
                <Link href={CUSTOMER_GENERAL_POLICY_URL} target={"_blank"}>
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Condizioni generali
                  </span>
                </Link>

                {t("auth.termsOfUse2")}
                <Link
                  href={CUSTOMER_SIGNUP_PRIVACY_POLICY_URL}
                  target={"_blank"}
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Informativa Privacy
                  </span>
                </Link>
              </Text>

              <Field id={"privacyPolicy"} name={"privacyPolicy"}>
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={Boolean(meta.error) && meta.touched}
                    style={{ paddingTop: "1rem" }}
                  >
                    <Checkbox
                      name={field.name}
                      isChecked={field.value}
                      onChange={(event) => {
                        setFieldValue(field.name, event.target.checked);
                      }}
                    >
                      <Text>
                        Accetto espressamente le clausole{" "}
                        <Link
                          href={CUSTOMER_GENERAL_POLICY_URL}
                          target={"_blank"}
                        >
                          <span
                            style={{
                              textDecoration: "underline",
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                          >
                            elencate all'art. 33
                          </span>
                        </Link>{" "}
                        delle Condizioni Generali della piattaforma
                        (obbligatorio)
                      </Text>
                    </Checkbox>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field
                id={"marketingTermsCustomer"}
                name={"marketingTermsCustomer"}
              >
                {({ field, form: { setFieldValue }, meta }: FieldProps) => (
                  <FormControl
                    isInvalid={Boolean(meta.error) && meta.touched}
                    style={{ paddingTop: "1rem" }}
                  >
                    <Checkbox
                      name={field.name}
                      isChecked={field.value}
                      onChange={(event) => {
                        setFieldValue(field.name, event.target.checked);
                      }}
                    >
                      {t("auth.marketingTermsCustomer")}
                    </Checkbox>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Text fontSize="md" fontWeight={"bold"} marginTop={"3rem"}>
                * {t("auth.mandatoryFields")}
              </Text>
            </Box>

            <Button
              type={"submit"}
              colorScheme={"blue"}
              disabled={isSubmitting}
            >
              {t("common.confirm")}
            </Button>
            {!props.isValid && props.submitCount > 0 && (
              <Text fontSize="sm" color={theme.colors.danger}>
                {t("auth.messages.formError")}
              </Text>
            )}
            <Link as={ReachLink} to="/login" color="black" paddingTop={"2rem"}>
              {t("auth.alreadyHaveAnAccount")}
            </Link>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
