import { Promo } from "./Promo";

type Props = {
  id: number;
  first_name: string;
  surname: string;
  email: string;
  mobilePhone: string;
  promo: Promo[];
};

export class AdminUser {
  private _id: number;
  private _first_name: string;
  private _surname: string;
  private _email: string;
  private _mobilePhone: string;
  private _promo: Promo[];

  constructor() {
    this._id = -1;
    this._first_name = "";
    this._surname = "";
    this._email = "";
    this._mobilePhone = "";
    this._promo = [];
  }

  public mapAdminUserData({
    id,
    first_name,
    surname,
    email,
    mobilePhone,
    promo,
  }: Props) {
    this._id = id;
    this._first_name = first_name;
    this._surname = surname;
    this._email = email;
    this._mobilePhone = mobilePhone;
    this._promo = promo;
  }

  public get id(): number {
    return this._id;
  }
  public set id(value: number) {
    this._id = value;
  }

  public get first_name(): string {
    return this._first_name;
  }

  public set first_name(value: string) {
    this._first_name = value;
  }

  public get surname(): string {
    return this._surname;
  }
  public set surname(value: string) {
    this._surname = value;
  }

  public get email(): string {
    return this._email;
  }
  public set email(value: string) {
    this._email = value;
  }

  public get mobilePhone(): string {
    return this._mobilePhone;
  }
  public set mobilePhone(value: string) {
    this._mobilePhone = value;
  }

  public get promo(): Promo[] {
    return this._promo;
  }
}
