import React from "react";

import { StyledFooter } from "./styled";
import { Text } from "@chakra-ui/react";
import { mainTheme } from "../../config/theme";
import { useTranslation } from "react-i18next";
import { FourthLooversFontSize } from "../LooversText/LooversText";

interface Props {
  dark?: boolean;
  backgroundColor?: string;
  textAlign?: string;
}

export const Footer: React.FC<Props> = ({
  dark = false,
  textAlign = "left",
}) => {
  const { t } = useTranslation();

  const footerTextColor = dark
    ? mainTheme.colors.textWhite
    : mainTheme.colors.textPrimary;

  const footerBackgroundColor = dark
    ? mainTheme.colors.darkPurple
    : mainTheme.colors.textWhite;

  return (
    <StyledFooter textAlign={textAlign} backgroundColor={footerBackgroundColor}>
      <Text
        fontSize={FourthLooversFontSize}
        color={footerTextColor}
        text-align={textAlign}
      >
        {t("footer.email")}
      </Text>
      <Text
        fontSize={FourthLooversFontSize}
        color={footerTextColor}
        text-align={textAlign}
      >
        {t("footer.description")}
      </Text>
    </StyledFooter>
  );
};
