import { gql } from "@apollo/client";

export const FETCH_PROMO_BREEDS = gql`
  query FetchPromoBreeds {
    promoBreeds {
      id
      name
      animal
      dog_size
    }
  }
`;

export const FETCH_PROVINCES = gql`
  query FetchProvinces {
    provinces {
      id
      nome
      idregione
      sigla
    }
  }
`;

export const FETCH_SPECIAL_NEEDS = gql`
  query FetchSpecialNeeds {
    specialNeeds {
      id
      name
      animal
      need_type
    }
  }
`;

// export const FETCH_PARTNER_PROMOS = gql`
//   query FetchPromo($storeId: Int!) {
//     store(where: { id: $storeId }) {
//       promo {
//         id
//         code
//         activity_type
//         interested_brand
//         valid_from
//         valid_until
//         email_title
//         description
//         promo_type
//         users_perimeter
//         size
//         is_service
//         special_need
//         segment
//         activity {
//           id
//           name
//         }
//         brand {
//           id
//           name
//         }
//         special_needs {
//           id
//           name
//           need_type
//         }
//         promo_breed {
//           id
//           name
//         }
//       }
//     }
//   }
// `;

export const FETCH_PARTNER_PROMOS = gql`
  query FetchPromo($storeId: Int!) {
    parnerPromos(partnerId: $storeId) {
      id
      code
      activity_type
      interested_brand
      valid_from
      valid_until
      email_title
      description
      promo_type
      users_perimeter
      size
      is_service
      special_need
      segment
      is_national
      activity {
        id
        name
      }
      brand {
        id
        name
      }
      special_needs {
        id
        name
        need_type
      }
      promo_breed {
        id
        name
      }
    }
  }
`;

export const FETCH_ADMIN_PROMOS = gql`
  query FetchPromo($adminId: Int!) {
    admin(where: { id: $adminId }) {
      promo {
        id
        code
        activity_type
        interested_brand
        valid_from
        valid_until
        email_title
        description
        promo_type
        users_perimeter
        size
        is_service
        special_need
        segment
        gender
        districts
        customer_type
        activity {
          id
          name
        }
        brand {
          id
          name
        }
        special_needs {
          id
          name
          need_type
        }
        promo_breed {
          id
          name
        }
      }
    }
  }
`;

export const FETCH_SERVICE_ACTIVITIES = gql`
  query FetchServiceActivities($serviceId: Int!) {
    activity(where: { id: $serviceId }) {
      id
      name
    }
  }
`;

export const FETCH_ACTIVITIES = gql`
  query FetchActivities {
    activities {
      id
      name
      serviceId
      service {
        name
      }
    }
  }
`;

export const FETCH_STORE_SERVICES = gql`
  query FetchStoreActivities {
    storeServices {
      id
      store {
        id
      }
      service {
        id
        name
      }
    }
  }
`;

export const FETCH_BRANDS = gql`
  query fetchStoreBrands {
    brands {
      id
      name
    }
  }
`;

export const FETCH_STORE_BRANDS = gql`
  query fetchStoreBrands {
    storeBrands {
      id
      store {
        id
      }
      brand {
        id
        name
      }
    }
  }
`;

export const CREATE_PROMO = gql`
  mutation CreatePromo(
    $activity_type: String!
    $interested_brand: String!
    $valid_from: String!
    $valid_until: String!
    $email_title: String!
    $description: String!
    $promo_type: Float!
    $users_perimeter: String!
    $size: String!
    $is_service: Boolean!
    $special_need: String!
    $activity_id: Int!
    $single_brand_id: Int!
    $special_needs_id: Int!
    $promo_breed_id: Int!
    $storeId: Int!
    $segment: String!
  ) {
    createStorePromo(
      activity_type: $activity_type
      interested_brand: $interested_brand
      valid_from: $valid_from
      valid_until: $valid_until
      email_title: $email_title
      description: $description
      promo_type: $promo_type
      users_perimeter: $users_perimeter
      size: $size
      is_service: $is_service
      special_need: $special_need
      activity_id: $activity_id
      single_brand_id: $single_brand_id
      special_needs_id: $special_needs_id
      promo_breed_id: $promo_breed_id
      store_id: $storeId
      segment: $segment
    ) {
      id
    }
  }
`;
export const CREATE_PROMO_ADMIN = gql`
  mutation CreatePromoAdmin(
    $activity_type: String!
    $interested_brand: String!
    $valid_from: String!
    $valid_until: String!
    $email_title: String!
    $description: String!
    $promo_type: Float!
    $users_perimeter: String!
    $size: String!
    $is_service: Boolean!
    $special_need: String!
    $activity_id: Int!
    $single_brand_id: Int!
    $special_needs_id: Int!
    $promo_breed_id: Int!
    $adminId: Int!
    $is_national: Boolean!
    $segment: String!
    $gender: String!
    $districts: String!
    $customer_type: String!
  ) {
    createAdminPromo(
      activity_type: $activity_type
      interested_brand: $interested_brand
      valid_from: $valid_from
      valid_until: $valid_until
      email_title: $email_title
      description: $description
      promo_type: $promo_type
      users_perimeter: $users_perimeter
      size: $size
      is_service: $is_service
      special_need: $special_need
      activity_id: $activity_id
      single_brand_id: $single_brand_id
      special_needs_id: $special_needs_id
      promo_breed_id: $promo_breed_id
      adminId: $adminId
      is_national: $is_national
      segment: $segment
      gender: $gender
      districts: $districts
      customer_type: $customer_type
    ) {
      id
    }
  }
`;
export const UPDATE_PROMO = gql`
  mutation UpdatePromo(
    $id: Int!
    $activity_type: String!
    $interested_brand: String!
    $valid_from: String!
    $valid_until: String!
    $email_title: String!
    $description: String!
    $promo_type: Float!
    $users_perimeter: String!
    $size: String!
    $special_need: String!
    $storeId: Int!
    $activity_id: Int!
    $single_brand_id: Int!
    $special_needs_id: Int!
    $promo_breed_id: Int!
    $segment: String!
  ) {
    updateStorePromo(
      id: $id
      activity_type: $activity_type
      interested_brand: $interested_brand
      valid_from: $valid_from
      valid_until: $valid_until
      email_title: $email_title
      description: $description
      promo_type: $promo_type
      users_perimeter: $users_perimeter
      size: $size
      special_need: $special_need
      activity_id: $activity_id
      single_brand_id: $single_brand_id
      special_needs_id: $special_needs_id
      promo_breed_id: $promo_breed_id
      store_id: $storeId
      segment: $segment
    ) {
      id
    }
  }
`;
export const UPDATE_PROMO_ADMIN = gql`
  mutation UpdatePromoAdmin(
    $id: Int!
    $activity_type: String!
    $interested_brand: String!
    $valid_from: String!
    $valid_until: String!
    $email_title: String!
    $description: String!
    $promo_type: Float!
    $users_perimeter: String!
    $size: String!
    $special_need: String!
    $adminId: Int!
    $activity_id: Int!
    $single_brand_id: Int!
    $special_needs_id: Int!
    $promo_breed_id: Int!
    $is_national: Boolean!
    $segment: String!
    $gender: String!
    $districts: String!
    $customer_type: String!
  ) {
    updatePromoAdmin(
      id: $id
      activity_type: $activity_type
      interested_brand: $interested_brand
      valid_from: $valid_from
      valid_until: $valid_until
      email_title: $email_title
      description: $description
      promo_type: $promo_type
      users_perimeter: $users_perimeter
      size: $size
      special_need: $special_need
      activity_id: $activity_id
      single_brand_id: $single_brand_id
      special_needs_id: $special_needs_id
      promo_breed_id: $promo_breed_id
      adminId: $adminId
      is_national: $is_national
      segment: $segment
      gender: $gender
      districts: $districts
      customer_type: $customer_type
    ) {
      id
    }
  }
`;

export const DELETE_PROMO = gql`
  mutation DeletePromo($id: Int!) {
    deleteOnePromo(where: { id: $id }) {
      id
    }
  }
`;

export const ADD_PROMO_CODE_USE = gql`
  mutation promo_Code_Use(
    $promo_id: Int!
    $customer_id: Int!
    $used_date: DateTime!
    $store_id: Int!
  ) {
    createOnePromo_Code_Use(
      data: {
        used_date: $used_date
        customer: { connect: { id: $customer_id } }
        promo: { connect: { id: $promo_id } }
        store: { connect: { id: $store_id } }
      }
    ) {
      id
    }
  }
`;
