import { useLazyQuery } from "@apollo/client";
import { HStack, Radio, RadioGroup, Select, VStack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Promo, UsersPerimeter } from "../../entities/Promo";
import { SpecialNeed } from "../../entities/SpecialNeed";
import { FETCH_SPECIAL_NEEDS } from "../../screens/PartnerPromotions/graphql";

type Props = {
  field: any;
  setFieldValue: (name: string, value: any) => void;
  users_perimeter: UsersPerimeter;
  formikProps: any;
  promo: Promo;
  isPromoModifiable: boolean;
  setSpecial_need: React.Dispatch<React.SetStateAction<string>>;
  special_need: string;
};

export const SpecialNeedsSelect = ({
  field,
  setFieldValue,
  users_perimeter,
  formikProps,
  promo,
  isPromoModifiable,
  setSpecial_need,
  special_need,
}: Props) => {
  const { t } = useTranslation();

  const [specialNeedsFilteredData, setSpecialNeedsFilteredData] = useState<
    SpecialNeed[]
  >([]);
  const [specialNeedsData, setSpecialNeedsData] = useState<SpecialNeed[]>([]);
  const [didFetch, setDidFetch] = useState(false);

  const [fetchSpecialNeeds, { data: dataFetchSpecialNeed }] = useLazyQuery(
    FETCH_SPECIAL_NEEDS,
    {
      fetchPolicy: "no-cache",
      onCompleted: () => {
        const specialNeeds: SpecialNeed[] =
          dataFetchSpecialNeed.specialNeeds.map((item: SpecialNeed) => {
            return {
              id: item.id,
              name: item.name,
              animal: item.animal,
              need_type: item.need_type,
            };
          });

        const filteredSpecialNeedsByName: Set<string> = new Set();
        const filteredSpecialNeeds: SpecialNeed[] = [];

        specialNeeds.forEach((item: SpecialNeed) => {
          if (
            (users_perimeter === "all" || users_perimeter === item.animal) &&
            item.need_type === special_need
          ) {
            if (!filteredSpecialNeedsByName.has(item.name)) {
              if (item.name === "all") filteredSpecialNeeds.unshift(item);
              else filteredSpecialNeeds.push(item);
              filteredSpecialNeedsByName.add(item.name);
            }
          }
        });

        if (promo.id === -1) {
          setFieldValue(field.name, Number(specialNeeds[0].id));
        }

        setSpecialNeedsData(specialNeeds);
        setSpecialNeedsFilteredData(filteredSpecialNeeds);
        setDidFetch(true);
      },
      onError: (error) => {
        console.log("SpecialNeedSelect", error);
      },
    }
  );

  const fetchAllSpecialNeedsByServiceId = useCallback(async () => {
    fetchSpecialNeeds();
  }, [fetchSpecialNeeds]);

  useEffect(() => {
    fetchAllSpecialNeedsByServiceId();
  }, [fetchAllSpecialNeedsByServiceId]);

  useEffect(() => {
    const filteredSpecialNeedsByName: Set<string> = new Set();
    const filteredSpecialNeeds: SpecialNeed[] = [];

    specialNeedsData.forEach((item: SpecialNeed) => {
      if (
        (users_perimeter === "all" || users_perimeter === item.animal) &&
        item.need_type === special_need
      ) {
        if (!filteredSpecialNeedsByName.has(item.name)) {
          if (item.name === "all") filteredSpecialNeeds.unshift(item);
          else filteredSpecialNeeds.push(item);
          filteredSpecialNeedsByName.add(item.name);
        }
      }
    });

    if (didFetch) {
      const arr = Array.from(filteredSpecialNeeds.values());
      setSpecialNeedsFilteredData(arr);

      if (arr.length > 0) setFieldValue(field.name, Number(arr[0].id));
    }
  }, [
    users_perimeter,
    special_need,
    specialNeedsData,
    didFetch,
    setFieldValue,
    field.name,
  ]);

  return (
    <VStack align="flex-start">
      {(promo.id === -1 || isPromoModifiable) && (
        <RadioGroup
          name={"needTypeValue"}
          value={special_need}
          onChange={(value) => {
            setSpecial_need(value);
          }}
          disabled={promo.id !== -1 && !isPromoModifiable}
        >
          <HStack wrap="wrap" spacing="2rem">
            <Radio value={"all"}>{t("promoForm.all")}</Radio>
            <Radio value={"fisiologico"}>{"Fisiologico"}</Radio>
            <Radio value={"dietetico"}>{"Dietetico"}</Radio>
          </HStack>
        </RadioGroup>
      )}

      {special_need !== "all" && (
        <Select
          name={field.name}
          onChange={(event) => {
            setFieldValue(field.name, Number(event.target.value));
          }}
          value={
            promo.id === -1 || isPromoModifiable
              ? formikProps.values.special_needs_id.toString()
              : promo.special_needs.id.toString()
          }
        >
          {specialNeedsFilteredData.map((item: SpecialNeed) => {
            return (
              <option
                key={item.id.toString()}
                id={item.id.toString()}
                value={item.id.toString()}
              >
                {item.name === "all"
                  ? t("promoForm.all")
                  : item.name[0].toUpperCase() + item.name.substring(1)}
              </option>
            );
          })}
        </Select>
      )}
    </VStack>
  );
};
